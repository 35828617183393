import styled from 'styled-components';

const Label = styled.label`
  display: inline-flex;
  width: 100%;
  color: ${(props) => props.color || '#000'}
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  justify-content: ${(props) => props.justifyContent || 'flex-start'}
  padding: ${(props) => props.padding || '0 16px'};
  margin-bottom: 4px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 23px;
    padding: ${(props) => props.padding || '0 24px'};
    margin-bottom: 7px;
  }
`;

export { Label };
export default Label;
