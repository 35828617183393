import styled, { css } from 'styled-components';

import ArrowDownSVG from './arrow-down.svg';
import ArrowWhiteDownSVG from './arrow-white-down.svg';

const Select = styled.select`
  width: 100%;
  background-image: url(${ArrowDownSVG});
  background-position: calc(100% - 19px) center;
  background-repeat: no-repeat;
  background-size: 20px 10px;
  background-color: ${(props) => props.bgColor || '#fff'};
  border-width: ${(props) => props.borderWidth || '2'}px;
  border-style: solid;
  border-color: ${(props) => props.borderColor || '#dedede'};
  border-radius: 4px;
  appearance: none;
  color: ${(props) => props.fontColor || '#808080'};
  font-family: 'Gotham';
  font-size: 16px;
  line-height: 19px;
  padding: 14px 64px 11px 20px;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  &:focus {
    box-shadow: inset 0 0 0 3px #ffb612;
    border-color: #ffb612;
  }

  ${(props) => props.IconWhite
    && css`
      background-image: url(${ArrowWhiteDownSVG});

      &:focus {
        box-shadow: inset 0 0 0 1px #fff;
        border-color: #fff;
      }
  `};
`;

export { Select };
export default Select;
