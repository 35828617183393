import React, { useContext } from 'react';
import Context from 'stores/context';
import {
  ProfileInfoAnamnese,
} from 'components';

const ModalInfoHeader = () => {
  const context = useContext(Context);
  const {
    peopleStore,
    i18n,
  } = context;

  const modalInfo = () => {
    let text = '';

    if (peopleStore.hasAnamnesisRepliedByStudent) {
      text = i18n.t('warning_anamnese_filled_anamnesis');
    }

    if (peopleStore.hasTrainingAutomatic) {
      text = i18n.t('warning_anamnese_training_automatic');
    }

    if (text !== '') {
      return (
        <ProfileInfoAnamnese>
          {text}
        </ProfileInfoAnamnese>
      );
    }

    return false;
  };

  return (
    <>
      {modalInfo()}
    </>
  );
};

export { ModalInfoHeader };
export default ModalInfoHeader;
