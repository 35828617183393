import React, { useContext } from 'react';
import { Formik } from 'formik';
import ModalWrapper from 'components/modal/modal-wrapper';
import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import {
  Title,
  Input,
  Button,
  FlexDiv,
  Text,
} from '../../components';

function ModalFormTeacherTitle(props) {
  const context = useContext(Context);
  const { history } = props;
  const {
    teacherTrainingStore,
    modalStore,
    i18n,
    appStore,
    trainingCreationStore,
  } = context;
  const setInitialValues = {
    titleExercise: '',
  };

  const setAliasExercise = (values) => {
    const titleExercise = (values) ? values.titleExercise : teacherTrainingStore.alias;
    teacherTrainingStore.setAlias(titleExercise);
  };

  const saveExerciseTeacher = (values) => {
    setAliasExercise(values);

    let msgTitle;
    let msgDescription;

    modalStore.toggleModal('modalFormTeacher', false);
    appStore.toggleloading(true);
    modalStore.closeModalError();

    teacherTrainingStore
      .doRequestSaveExerciseTeacher()
      .then(() => {
        msgTitle = i18n.t('register_training_title');
        msgDescription = i18n.t('register_training_subtitle');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription(msgDescription);
        teacherTrainingStore.setAlias(null);
        teacherTrainingStore.setReloadListExercise(true);
        appStore.toggleloading(false);

        if (teacherTrainingStore.teacherDuplicateTraining === false) {
          modalStore.toggleModal('modalFormTeacherSucess', true);
        }
      })
      .catch((error) => {
        msgTitle = i18n.t('register_training_error_title');
        msgDescription = i18n.t('register_training_error_subtitle');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription(msgDescription);
        teacherTrainingStore.setReloadListExercise(false);
        appStore.toggleloading(false);
        modalStore.setModalNetworkCallback(saveExerciseTeacher);
        modalStore.showModalError(error);
      });
  };

  const showErros = (values) => {
    const errors = {};
    if (!values.titleExercise) {
      errors.titleExercise = i18n.t('warning_register_exercise_teacher');
    }

    return errors;
  };

  const createTraining = (values) => {
    setAliasExercise(values);
    appStore.toggleloading(true);

    trainingCreationStore
      .doRequestCreateNewTrainingTeacher()
      .then(() => {
        const url = ConcatUnitInUrl('/build-training/build-training-list');
        modalStore.toggleModal('modalFormTeacher', false);
        teacherTrainingStore.setAlias(null);
        appStore.toggleloading(false);
        history.push(url);
      })
      .catch((error) => {
        modalStore.setTitle(i18n.t('unexpected_error'));
        modalStore.setDescription(i18n.t('redirect_to_profile'));
        appStore.toggleloading(false);
        modalStore.toggleModal('modalFormTeacher', false);
        modalStore.setModalNetworkCallback(createTraining);
        window.scroll(0, 0);
        modalStore.showModalError(error);
      });
  };

  const postAlias = (values) => {
    if (teacherTrainingStore.teacherDuplicateTraining) {
      saveExerciseTeacher(values);
    } else if (teacherTrainingStore.teacherInteface) {
      createTraining(values);
    } else {
      saveExerciseTeacher(values);
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:button',
      eventLabel: 'save_workout',
    });
  };

  const closeCallback = (e) => {
    e.preventDefault();
    modalStore.toggleModal('modalFormTeacher', false);
  };

  PostGaAnalytics('send', {
    hitType: 'event',
    eventCategory: `workout:${window.location.pathname}`,
    eventAction: 'modal_message',
    eventLabel: 'save_workout_modal',
  });

  return (
    <ModalWrapper>
      <Title type={2} subTitleColor="#000" color="#000" marginTop="10px">
        {i18n.t('register_training_form_title')}
        <small>{i18n.t('register_training_form_subtitle')}</small>
      </Title>

      <Formik
        initialValues={setInitialValues}
        validate={(values) => showErros(values)}
        onSubmit={(values) => {
          postAlias(values);
        }}
      >
        {(props) => {
          const {
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            values,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <FlexDiv minHeight="0" direction="column" marginBottom="32px">
                <Text color="red">
                  {errors.titleExercise && touched.titleExercise && errors.titleExercise}
                </Text>
              </FlexDiv>

              <Input
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.titleExercise}
                name="titleExercise"
              />
              <div className="wrapper-button-modal">
                <Button formatText minWidth="166px" onClick={(e) => { closeCallback(e); }}>{i18n.t('cancel')}</Button>
                <Button formatText minWidth="166px" type="submit">{i18n.t('save')}</Button>
              </div>
            </form>
          );
        }}
      </Formik>

    </ModalWrapper>
  );
}

export default ModalFormTeacherTitle;
