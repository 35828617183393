import React, { useContext } from 'react';
import {
  FormGroup,
  Label,
  Input,
} from 'components';
import Context from 'stores/context';

const LabelPatternExercise = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;
  const {
    infoLabel,
  } = props;
  const {
    values,
    handleChange,
    handleBlur,
  } = infoLabel;

  const series = (value) => {
    if (value.series > 0) {
      document.querySelector('#min_repetitions').focus();

      return true;
    }

    return false;
  };

  const minRepetitions = (value) => {
    if (value.min_repetitions >= 10) {
      if (value.max_repetitions === '' || (value.max_repetitions >= value.min_repetitions)) {
        document.querySelector('#min_interval').focus();

        return true;
      }

      document.querySelector('#max_repetitions').focus();
    }

    return false;
  };

  const maxRepetitions = (value) => {
    if (value.max_repetitions >= 10 && (value.max_repetitions >= value.min_repetitions)) {
      document.querySelector('#min_interval').focus();

      return true;
    }

    return false;
  };

  const minInterval = (value) => {
    if (value.min_interval >= 10) {
      if (value.max_interval !== '' && (value.max_interval < value.min_interval)) {
        document.querySelector('#max_interval').focus();

        return true;
      }
    }

    return false;
  };

  return (
    <div className="wrapper">
      <div className="w-training-main-info w-training-weight-training">
        <FormGroup justifyContent="center" noMarginBottom="true">
          <Label htmlFor="series">{i18n.t('series')}</Label>
          <Input
            type="number"
            id="series"
            square="true"
            small="true"
            width="48px"
            textAlign="center"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={() => series(values)}
            placeholder="0"
            value={values.series}
          />
        </FormGroup>

        <FormGroup justifyContent="center" noMarginBottom="true">
          <Label htmlFor="min_repetitions">{i18n.t('repeats')}</Label>
          <Input
            type="number"
            id="min_repetitions"
            square="true"
            small="true"
            width="48px"
            textAlign="center"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={() => minRepetitions(values)}
            placeholder="Min"
            value={values.min_repetitions || ''}
          />

          <Input
            type="number"
            id="max_repetitions"
            square="true"
            small="true"
            width="48px"
            textAlign="center"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={() => maxRepetitions(values)}
            placeholder="Max"
            value={values.max_repetitions || ''}
          />
        </FormGroup>

        <FormGroup justifyContent="center" noMarginBottom="true">
          <Label htmlFor="min_interval">{i18n.t('interval')}</Label>
          <Input
            type="number"
            id="min_interval"
            square="true"
            small="true"
            width="48px"
            textAlign="center"
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={() => minInterval(values)}
            placeholder="Min"
            value={values.min_interval || ''}
          />

          <Input
            type="number"
            id="max_interval"
            square="true"
            small="true"
            width="48px"
            textAlign="center"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Max"
            value={values.max_interval || ''}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default LabelPatternExercise;
