import React, { Component } from 'react';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Anamnese,
  Button,
  Title,
  SubTitle,
  Recommend,
} from 'components';
import Context from '../../stores/context';
import ModalError from '../../components/modal/modal-error';
import ModalNetwork from '../../components/modal/modal-network';
import { ConcatUnitInUrl, PostGaAnalytics } from '../../utils';

class Division extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.selectedProfile = this.selectedProfile.bind(this);
    this.createTitleToTrainingWallet = this.createTitleToTrainingWallet.bind(this);
    this.createNewTraining = this.createNewTraining.bind(this);
    this.createTraining = this.createTraining.bind(this);
    this.createSizeTraining = this.createSizeTraining.bind(this);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: ' workout_division',
    });
  }

  getLabel(trainingGroups) {
    const label = trainingGroups.reduce((total, current) => `${total}/${current.label}`, []);

    return label.substring(1);
  }

  orderByTrainingGroups(profilesFiltered) {
    profilesFiltered.map((item) => {
      item.index = item.training_groups.length;
      return item;
    });
    return orderBy(profilesFiltered, ['index'], ['asc']);
  }

  createTitleToTrainingWallet() {
    const {
      teacherTrainingStore,
      i18n,
      trainingCreationStore,
      modalStore,
      appStore,
    } = this.context;
    const { history } = this.props;
    const url = ConcatUnitInUrl('/lesion-and-restriction/question-lesion-restriction');

    appStore.toggleloading(true);

    trainingCreationStore
      .doRequestCreateNewTrainingTeacher()
      .then(() => {
        appStore.toggleloading(false);
        teacherTrainingStore.setAlias(null);
        history.push(url);
      })
      .catch((error) => {
        modalStore.setTitle(i18n.t('unexpected_error'));
        modalStore.setDescription(i18n.t('redirect_to_profile'));
        appStore.toggleloading(false);
        modalStore.toggleModal('modalFormTeacher', false);
        modalStore.setModalNetworkCallback(this.createTitleToTrainingWallet);
        window.scroll(0, 0);
        modalStore.showModalError(error);
      });
  }

  selectedProfile(profile) {
    const {
      trainingCreationStore,
      toggleStore,
    } = this.context;

    trainingCreationStore.setProfileSelected(profile);
    trainingCreationStore.setPaisPremisses(profile);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:division',
      eventAction: 'click:button',
      eventLabel: `division_${profile}`,
    });

    const hasSizeTraining = toggleStore.validateToggle({
      toggleUnitAllow: 'allowShowTrainingSizeUnit',
      toggleCountry: 'allowShowTrainingSizeCountry',
    });

    if(hasSizeTraining) {
      this.createSizeTraining();
    } else {
      this.createTraining();
    }
  }

  createTraining() {
    const {
      teacherTrainingStore,
    } = this.context;

    if (teacherTrainingStore.teacherInteface) {
      this.createTitleToTrainingWallet();
    } else {
      this.createNewTraining();
    }

    this.callRulesPais();
  }

  async createSizeTraining() {
    const {
      rulesPaisStore,
    } = this.context;
    const { history } = this.props;
    const url = ConcatUnitInUrl('/training-creation/training-size');
    await this.callRulesPais();

    if(rulesPaisStore.hasTraingDuration()) {
      history.push(url);
    } else {
      this.createTraining();
    }
  }

  async callRulesPais() {
    const {
      trainingCreationStore,
      rulesPaisStore,
      programStore,
      modalStore,
    } = this.context;

    const objParams = {
      programId: programStore.programId,
      levelId: programStore.levelId,
      profileId: trainingCreationStore.profileSelected,
      paisPremisses: trainingCreationStore.paisPremisses,
    };

    await rulesPaisStore
      .doGetRules(objParams)
      .then(() => {})
      .catch((error) => {
        modalStore.showModalError(error);
      });
  }

  createNewTraining() {
    const {
      buildTrainingStore,
      trainingCreationStore,
      modalStore,
      i18n,
    } = this.context;

    const { history } = this.props;

    buildTrainingStore.setAnalyticsTypeTrainingSuggestion('');
    buildTrainingStore.setToggleAllowEditDateTraining(true);

    trainingCreationStore
      .doRequestCreateNewTraining()
      .then(() => {
        const url = ConcatUnitInUrl('/lesion-and-restriction/question-lesion-restriction');
        history.push(url);
      })
      .catch((error) => {
        modalStore.setTitle(i18n.t('unexpected_error'));
        modalStore.setDescription(i18n.t('redirect_to_profile'));
        modalStore.setModalNetworkCallback(this.createNewTrainig);
        window.scroll(0, 0);
        modalStore.showModalError(error);
      });
  }

  render() {
    const {
      i18n,
      trainingCreationStore,
      modalStore,
      peopleStore,
    } = this.context;
    const { history } = this.props;
    const { profilesFiltered } = trainingCreationStore;
    const modalError = () => (
      <ModalError
        onClick={() => {
          const url = ConcatUnitInUrl('/profile');
          history.push(url);
        }}
      />
    );

    let msgNotify;
    let msgTitle;
    let msgDescription;

    if (!trainingCreationStore.weightTrainingProfiles.length) {
      msgNotify = i18n.t('empty_profiles');
      msgTitle = i18n.t('error_division');
      msgDescription = i18n.t('redirect_perfil_student');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.modalError = true;
      return modalError();
    }

    if (!profilesFiltered.length) {
      msgNotify = i18n.t('error_filtered');
      msgTitle = i18n.t('error_unexpected_filtered');
      msgDescription = i18n.t('redirect_perfil_student');

      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.modalError = true;
      return modalError();
    }

    const renderRecommend = (trainingGroups) => {
      if (trainingGroups.length === peopleStore.getFrequencyActive()) {
        return (
          <Recommend>{i18n.t('recommended')}</Recommend>
        );
      }

      return false;
    };

    return (
      <div>
        <Anamnese>
          <Title type={1} as="h2" subTitleColor="#fff">
            {i18n.t('training_creation_division_title')}
            <SubTitle fontWeight="500">{i18n.t('training_creation_division_description')}</SubTitle>
          </Title>

          {this.orderByTrainingGroups(profilesFiltered).map((profile, index) => (
            <Button
              id={profile.id}
              key={profile.id}
              secondary="true"
              large="true"
              block="true"
              className={`division-${index} button-position`}
              onClick={() => {
                this.selectedProfile(profile);
              }}
            >
              {renderRecommend(profile.training_groups)}
              {this.getLabel(profile.training_groups)}
            </Button>
          ))}
        </Anamnese>
        {modalStore.modalNetwork && (
          <ModalNetwork
            tryAgain={() => { this.createTitleToTrainingWallet(); }}
          />
        )}
      </div>
    );
  }
}

Division.defaultProps = {
  history: {},
};

Division.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(Division);
