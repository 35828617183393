import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Flex, Button as OutlineButton } from 'rebass';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'views/NotFound/NotFound';
import {
  HeaderSession, GridContainer, Row, Column, PrivateRoute,
} from 'components';

import ModalLeave from 'components/modal/modal-leave';
import context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import TrainingCreationFrequency from './Frequency';
import TrainingCreationDivision from './Division';
import { NewTrainingObservation, EditTrainingObservation } from './TrainingObservation';
import TrainingCreationRestriction from './ObservationAndRestriction';
import Emphasis from './Emphasis';
import TrainingSize from './TrainingSize';

class Anamnesis extends Component {
  static contextType = context;

  render() {
    const { history } = this.props;
    const {
      i18n,
      modalStore,
      teacherTrainingStore,
      trainingCreationStore,
      sessionStore,
    } = this.context;

    const isDivision = /training-creation\/division/.test(history.location.pathname);

    const openModal = () => {
      const msgDescription = i18n.t('abandon_training');
      modalStore.setTitle('');
      modalStore.setDescription(msgDescription);
      modalStore.toggleModal('modalAnamnesis', true);
    };

    const hasEdit = () => trainingCreationStore.editObservationOrRestriction;

    const active = () => {
      if (hasEdit()) {
        trainingCreationStore.setEditObservationOrRestriction(false);
        history.goBack();
      } else {
        PostGaAnalytics('send', {
          hitType: 'event',
          eventCategory: `workout:${window.location.pathname}`,
          eventAction: 'click:button',
          eventLabel: 'close_page',
        });

        openModal();
      }
    };

    const headerText = () => {
      if (hasEdit()) {
        return {
          title: i18n.t('profile_button_edit_training'),
          subTitle: i18n.t('training_edit_title'),
        };
      }

      return {
        title: i18n.t('profile_button_new_training'),
        subTitle: i18n.t('training_creation_title'),
      };
    };

    const eventButtonHeader = () => {
      active();
    };

    const contentHeader = headerText();

    const buttonBack = () => {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:division',
        eventAction: 'click:button',
        eventLabel: 'previous_question',
      });

      history.goBack();
    };

    return (
      <>
        <GridContainer>
          <Row>
            <Column sm="12" xs="12">
              <HeaderSession
                statusActionButton={false}
                isBack={false}
                eventButton={eventButtonHeader}
                nameClass="w-btn-back"
                title={sessionStore.renderListNavigator(contentHeader.title)}
                subTitle={contentHeader.subTitle}
              />
            </Column>

            <Column sm="12" xs="12">
              <Switch>
                <PrivateRoute
                  path="/training-creation/emphasis"
                  component={Emphasis}
                />
                <PrivateRoute
                  path="/training-creation/frenquency"
                  component={TrainingCreationFrequency}
                />
                <PrivateRoute
                  path="/training-creation/division"
                  component={TrainingCreationDivision}
                />
                <PrivateRoute
                  path="/training-creation/new-observation"
                  component={NewTrainingObservation}
                />
                <PrivateRoute
                  path="/training-creation/edit-observation"
                  component={EditTrainingObservation}
                />
                <PrivateRoute
                  path="/training-creation/restriction"
                  component={TrainingCreationRestriction}
                />

                <PrivateRoute
                  path="/training-creation/training-size"
                  component={TrainingSize}
                />

                <Route path="*" component={NotFound} />
              </Switch>

              <Flex justifyContent="center" mb={[4]}>
                {isDivision && (
                  <OutlineButton variant="outline" onClick={buttonBack}>
                    {i18n.t('anamnesis_question_back')}
                  </OutlineButton>
                )}
              </Flex>
            </Column>
          </Row>
        </GridContainer>
        {modalStore.modalAnamnesis && (
          <ModalLeave
            onClickLeave={() => {
              let url = teacherTrainingStore.teacherInteface ? '/select-option-work/list-exercise' : '/profile';
              url = ConcatUnitInUrl(url);
              history.push(url);
            }}
          />
        )}
      </>
    );
  }
}

export default observer(Anamnesis);
