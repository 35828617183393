import React, { useContext } from 'react';
import { ListExerciseStyle } from 'components';
import Context from 'stores/context';

import RepsSVG from '../assets/reps.svg';
import SeriesSVG from '../assets/series.svg';
import TimerSVG from '../assets/timer.svg';
import ItemExercise from './ItemExercise';

const ListExercise = (props) => {
  const context = useContext(Context);
  const { i18n, buildTrainingStore } = context;
  const { exercises, show } = props;

  if (!show) {
    return false;
  }

  const orderExercise = buildTrainingStore.orderListExerciseSuggestion(exercises);
  const listItems = orderExercise.map(exercise => <ItemExercise key={exercise.id} exercise={exercise} />);

  return (
    <ListExerciseStyle>
      <ul className="list-title">
        <li className="title-exercise">
          <span className="show-info">{i18n.t('exercise')}</span>
        </li>
        <li className="series">
          <img src={SeriesSVG} alt={i18n.t('series')} />
        </li>
        <li className="repeats">
          <img src={RepsSVG} alt={`${i18n.t('repeats')} ${i18n.t('min_max')}`} />
        </li>
        <li className="interval">
          <img src={TimerSVG} alt={`${i18n.t('interval')} ${i18n.t('min_max')}`} />
        </li>
      </ul>
      <ul className="list-info-exercise">
        {listItems}
      </ul>
    </ListExerciseStyle>
  );
};

export default ListExercise;
