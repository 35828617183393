
import {
  typeAmountExerciseEmphasis,
} from './IrulesPaisStore';

import {
  WeightTrainingExercises,
  Training,
} from '../IgenericTypes';

class RulesPaisGlobalTraining {
  rootStore = {};

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  listExerciseWithEmphasis: WeightTrainingExercises[] = []
  listEmphasisTraining: string[] = [];
  listAmountExercise: typeAmountExerciseEmphasis[] = [];
  listResultRules: boolean[] = [];

  getWarningErros() {
    if(this.listEmphasisTraining.length <= 0) {
      return true;
    }

    return this.listResultRules.includes(true);
  }

  getAmountExercisesWithEmphasis() {
    return this.listExerciseWithEmphasis.length;
  }

  // quantidade de exercicios de perna é maior que todos os exercicios
  // somados juntos
  amountAllExerciseDiffLegs(exercise: typeAmountExerciseEmphasis) {
    const allExerciseDiffLegs = this.listAmountExercise
      .reduce((initial, currentValue) => {
        if(currentValue.enfase !== 'legs') {
          return initial + currentValue.amount;
        }

        return initial;
      }, 0);


    return exercise.amount >= allExerciseDiffLegs;
  }

  amountAllOthersExercise(emphasis: string) {
    const result = this.listAmountExercise.map((exercise: typeAmountExerciseEmphasis) => {
      const hasAmountOtherExerciseWithEmphasis = (exercise.amount >= this.listExerciseWithEmphasis.length);

      if(exercise.enfase === 'legs') {
        return this.amountAllExerciseDiffLegs(exercise);
      }

      return (exercise.enfase !== emphasis && hasAmountOtherExerciseWithEmphasis);
    });

    this.listResultRules.push(...result);
  }

  amountExerciseToEmphasis(training: Training) {
    if(training.weight_training_exercises.length <= 0) {
      return;
    }

    const listExercise = this.listEmphasisTraining.map((enfase: string) => {
      const list = training.weight_training_exercises.filter((exercise) => {
        return exercise.body_emphasis == enfase;
      });

      return {
        enfase: enfase,
        amount: list.length
      };
    });

    this.listAmountExercise.push(...listExercise);
  }

  allEmphasisTraining(training: Training) {
    const listEmphasis = training.weight_training_exercises.map((exercise) => {
      return exercise.body_emphasis;
    });

    const keyEmphasis = listEmphasis.reduce(
          (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
          [],
        );

    keyEmphasis.forEach((emphasis: string) => {
      const hasEmphasis = this.listEmphasisTraining.includes(emphasis);
      if(hasEmphasis === false) {
        this.listEmphasisTraining.push(emphasis);
      }
    });
  }

  exercisesWithEmphasis(training: Training, emphasis: string) {
    const list = training.weight_training_exercises.filter((exercise) => {
      return exercise.body_emphasis === emphasis;
    });

    this.listExerciseWithEmphasis.push(...list);
  }

  reset() {
    this.listExerciseWithEmphasis = [];
    this.listEmphasisTraining = [];
    this.listAmountExercise = [];
    this.listResultRules = [];
  }

  loadingRulesPaisGlobal(
    trainings: Training[],
    emphasis: string,
    listToggleShowRulesPremissas: string[],
  ) {
    this.reset();

    const hasRulesList = listToggleShowRulesPremissas
      .includes('emphasis');

    if(emphasis === '' || hasRulesList === false) return;

    trainings.forEach((training: Training) => {
      this.exercisesWithEmphasis(training, emphasis);
      this.allEmphasisTraining(training);
      this.amountExerciseToEmphasis(training);
    });

    this.amountAllOthersExercise(emphasis);
  }
}

export default RulesPaisGlobalTraining;
