import styled, { css } from 'styled-components';

const ProfileInfoModal = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000;
  position: relative;
  margin-bottom: 30px;

  ${(props) => props.highlight
    && css`
      padding-left: 12px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        border-radius: 8px;
        background-color: #ffb612;
      }
    `};

  small {
    display: block;
    font-weight: 900;
    font-size: 13px;
    color: #808080;
    text-transform: uppercase;
  }
`;

export { ProfileInfoModal };
export default ProfileInfoModal;
