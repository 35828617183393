import React, { Component } from 'react';
import styled from 'styled-components';

import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';

const StyledHeaderStudent = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 24px;
  /* background-image: linear-gradient(90deg, #000000 0%, #202020 100%); */
  background-color: #111;
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  .show-history {
    background-color: transparent;
    border: 0;
    color: #ffb612;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    position: relative;
    text-decoration: underline;
  }

  .name-student {
    padding-right: 8px;
  }

  @media screen and (max-width: 450px) {
    .name-student {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
      display: block;
      padding-right: 9px;
    }
  }
`;

export default class HeaderStudent extends Component {
  static contextType = Context;

  constructor() {
    super();
    this.openDataProfile = this.openDataProfile.bind(this);
  }

  openDataProfile() {
    const {
      modalStore,
    } = this.context;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:button',
      eventLabel: 'name_profile_global',
    });

    modalStore.toggleModal('modalDataProfile', true);
  }

  render() {
    const { peopleStore, i18n } = this.context;

    return (
      <StyledHeaderStudent>
        <span className="name-student">{peopleStore.peopleName}</span>
        <button
          className="show-history"
          onClick={this.openDataProfile}
          type="button"
        >
          {i18n.t('show_profile')}
        </button>
      </StyledHeaderStudent>
    );
  }
}

export { HeaderStudent };
