/* eslint-disable camelcase */

const ValidateHasHit = ({ series, min_repetitions, max_interval }) => {
  const hasAttributes = (series && min_repetitions && max_interval);
  if (hasAttributes) {
    return (series === 30 && min_repetitions === 30 && max_interval === 30);
  }

  return false;
};

export default ValidateHasHit;
