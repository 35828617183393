import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import { PostGaAnalytics } from 'utils';
import ItemUnit from './ItemUnit';

const ListUnityStyle = styled.ul`
  width: 100%;
  color: #fff;
  margin-top: 60px;
  padding: 0px 8px;

  @media(${props => props.theme.mediaScreen.desktop}) {
    padding: 0px;
  }
`;

const ListUnity = () => {
  const context = useContext(Context);
  const {
    locationStore,
    appStore,
    sessionStore,
    modalStore,
    i18n,
  } = context;

  const onClickToSubmit = async (locationId) => {
    appStore.toggleloading(true);
    try {
      await sessionStore.doRequestLocationConfirmation(locationId);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:changeUnit',
        eventAction: 'click:selected',
        eventLabel: 'click_selected_unit',
      });

      modalStore.toggleModal('modalSuccesUnit', true);
      appStore.toggleloading(false);
    } catch (err) {
      const msgTitle = i18n.t('error_change_unit');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:changeUnit',
        eventAction: 'click:selected',
        eventLabel: 'error_change_unit',
      });

      appStore.toggleloading(false);
    }
  };

  return (
    <ListUnityStyle>
      {locationStore.getAllListLocation.map(location => (
        <ItemUnit
          key={`item-${location.id}`}
          location={location}
          onClickToSubmit={onClickToSubmit}
        />
      ))}
    </ListUnityStyle>
  );
};

export default observer(ListUnity);
