import { observable, action, decorate } from 'mobx';
import { ItoggleStore } from './ItoogleStore';
import ToogleSearch from '../../dataToggle/toggle.json';


class ToggleStore implements ItoggleStore {
  rootStore = {
    sessionStore: {
      acronym: '',
    },
  };

  dataToggle: any = {}

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.dataToggle = ToogleSearch;
  }

  allowRender(nameToggle: string = ''): boolean {
    const obj = this.dataToggle;
    let data: any;

    Object.entries(obj).find(([key, value]) => {
      if (key === nameToggle) {
        data = value;
        return true;
      }

      return false;
    });

    if (data !== null && data !== undefined) {
      return data.includes(this.rootStore.sessionStore.acronym);
    }

    return false;
  }

  allowRenderParams(nameToggle: string = '', value: string= '') {
    const obj = this.dataToggle;
    let data: any;

    const objFind = Object.entries(obj).find(([key, value]) => {
      if (key === nameToggle) {
        data = value;
        return true;
      }

      return false;
    });

   if (objFind === undefined) return false;

    const listCountry = data.filter((item: any) => {
      return item === value;
    });

    return (listCountry.length > 0);
  }

  allowRenderCountry(nameToggle: string = '') {
    const country = process.env.REACT_APP_LANGUAGE || 'pt-BR';
    return this.allowRenderParams(nameToggle, country);
  }

  allowRenderWalletUnit() {
    const country = process.env.REACT_APP_LANGUAGE || 'pt-BR';
    const blockUnit =  this.allowRender('toggleBlockWallterUnidade');
    const unit =  this.allowRender('toggleWallterUnidade');
    const allowWalletCountry = this.allowRenderParams('toggleWallterUnidadeCountry', country);

    if(blockUnit) return false;

    return (unit || allowWalletCountry);
  }

  validateToggle({
    toggleUnitBlock = '',
    toggleUnitAllow = '',
    toggleCountry = '',
  }): boolean {
    const caseUnitBlockRules = this.allowRender(toggleUnitBlock);
    const caseUnitAllowRules = this.allowRender(toggleUnitAllow);
    const caseCountryAllowRules = this.allowRenderCountry(toggleCountry);

    if(caseUnitBlockRules) {
      return false;
    }

    if(caseUnitAllowRules) {
      return caseUnitAllowRules;
    }

    if(caseCountryAllowRules) {
      return caseCountryAllowRules;
    }

    return false;
  }

}

decorate(ToggleStore, {
  dataToggle: observable,
  allowRender: action,
  allowRenderParams: action,
  allowRenderWalletUnit: action,
  allowRenderCountry: action,
  validateToggle: action,
});

export default ToggleStore;
