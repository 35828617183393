import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ButtonDropDownStyle = styled.button`
  background-color: ${(props) => props.theme.colors.darkgray};
  border: 0px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  font-size: 36px;
  line-height: 25px;
  position: relative;

  .text {
    color: ${(props) => props.theme.colors.white};
  }

  .triangle-one {
    display: none;
    position: absolute;
    top: 60px;
    left: 0px;
    top: 51px;
    left: 13px;
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid ${(props) => props.theme.colors.white};
  }

  .triangle-two {
    display: none;
    position: absolute;
    top: 60px;
    left: 0px;
    top: 49px;
    left: 11px;
    z-index: 1;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid #cacace;
  }

  .menu{
    background-color: ${(props) => props.theme.colors.white};
    width: 116px;
    position: absolute;
    top: 60px;
    left: -34px;
    list-style-type: none;
    border-radius: 4px;
    border: 1px solid #cacace;
    display: none;
    z-index: 1;

    li {
      font-family: 'Gotham';
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: ${(props) => props.color || props.theme.colors.black};
      text-transform: lowercase;
      padding: 12px 12px;
      border-bottom: 1px solid #cacace;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }

  &.w-active {
    background-color: ${(props) => props.theme.colors.white};

    .text {
      color: ${(props) => props.theme.colors.black};
    }

    .menu, .triangle-one, .triangle-two {
      display: block;
    }
  }
`;

function ButtonDropDown(props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const {
    children,
  } = props;

  const toggleModal = () => {
    const toggle = !toggleMenu;
    setToggleMenu(toggle);
  };

  const closedMenu = () => {
    setToggleMenu(false);
  };

  return (
    <ButtonDropDownStyle
      onClick={toggleModal}
      className={toggleMenu ? 'wrapper-dropdown w-active' : 'wrapper-dropdown'}
      onBlur={closedMenu}
    >
      <div className="triangle-one" />
      <div className="triangle-two" />
      <span className="text">...</span>

      <ul className="menu">
        {children}
      </ul>
    </ButtonDropDownStyle>
  );
}

ButtonDropDown.defaultProps = {
  children: [],
};

ButtonDropDown.propTypes = {
  children: PropTypes.node,
};

export default ButtonDropDown;
export { ButtonDropDown };
