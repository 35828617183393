import React from 'react';
import ItemTraining from './ItemTraining';

const ListTraining = (props) => {
  const { listExercise } = props;

  if (!listExercise) {
    return false;
  }

  const list = listExercise.map(item => (
    <ItemTraining key={item.id} training={item} />
  ));

  return (
    <ul className="wrapper-list-training">
      {list}
    </ul>
  );
};

export default ListTraining;
