import React, { Component } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import { PostGaAnalytics, ConcatUnitInUrl } from 'utils';
import {
  Anamnese,
  Title,
  SubTitle,
  Button,
} from 'components';
import InfoSizeTraing from './component/InfoSizeTraining';

class TrainingSize extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.selectedSizeTraing = this.selectedSizeTraing.bind(this);
    this.createTitleToTrainingWallet = this.createTitleToTrainingWallet.bind(this);
    this.createNewTraining = this.createNewTraining.bind(this);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: ' workout_size_training',
    });
  }

  selectedSizeTraing(sizeTraining) {
    const {
      trainingCreationStore,
    } = this.context;

    trainingCreationStore.setDuration(sizeTraining.duration);
    this.createTraining();
  }

  createTraining() {
    const {
      teacherTrainingStore,
    } = this.context;

    if (teacherTrainingStore.teacherInteface) {
      this.createTitleToTrainingWallet();
    } else {
      this.createNewTraining();
    }
  }

  createTitleToTrainingWallet() {
    const {
      teacherTrainingStore,
      i18n,
      trainingCreationStore,
      modalStore,
      appStore,
    } = this.context;
    const { history } = this.props;
    const url = ConcatUnitInUrl('/lesion-and-restriction/question-lesion-restriction');

    appStore.toggleloading(true);

    trainingCreationStore
      .doRequestCreateNewTrainingTeacher()
      .then(() => {
        appStore.toggleloading(false);
        teacherTrainingStore.setAlias(null);
        history.push(url);
      })
      .catch((error) => {
        modalStore.setTitle(i18n.t('unexpected_error'));
        modalStore.setDescription(i18n.t('redirect_to_profile'));
        appStore.toggleloading(false);
        modalStore.toggleModal('modalFormTeacher', false);
        modalStore.setModalNetworkCallback(this.createTitleToTrainingWallet);
        window.scroll(0, 0);
        modalStore.showModalError(error);
      });
  }

  createNewTraining() {
    const {
      buildTrainingStore,
      trainingCreationStore,
      modalStore,
      i18n,
    } = this.context;

    const { history } = this.props;

    buildTrainingStore.setAnalyticsTypeTrainingSuggestion('');
    buildTrainingStore.setToggleAllowEditDateTraining(true);

    trainingCreationStore
      .doRequestCreateNewTraining()
      .then(() => {
        const url = ConcatUnitInUrl('/lesion-and-restriction/question-lesion-restriction');
        history.push(url);
      })
      .catch((error) => {
        modalStore.setTitle(i18n.t('unexpected_error'));
        modalStore.setDescription(i18n.t('redirect_to_profile'));
        modalStore.setModalNetworkCallback(this.createNewTrainig);
        window.scroll(0, 0);
        modalStore.showModalError(error);
      });
  }

  render() {
    const {
      i18n,
      rulesPaisStore,
    } = this.context;

    return (
      <div>
        <Anamnese>
          <Title type={1} as="h2" subTitleColor="#fff">
            {i18n.t('size_training_title')}
            <SubTitle fontWeight="500">{i18n.t('size_training_subtitle')}</SubTitle>
          </Title>

          {rulesPaisStore.trainingDurations.map((sizeTraining) => (
            <Button
              id={sizeTraining.duration}
              key={sizeTraining.duration}
              secondary="true"
              large="true"
              block="true"
              className={`questiion-${sizeTraining.duration} button-position`}
              onClick={() => {
                this.selectedSizeTraing(sizeTraining);
              }}
            >
              {sizeTraining.duration}
            </Button>
          ))}
          <InfoSizeTraing />
        </Anamnese>
      </div>
    )
  }
}

export default observer(TrainingSize);
