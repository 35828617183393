import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Text, Button, Flex,
} from 'rebass';
import Context from '../../stores/context';
import ModalWrapper from './modal-wrapper';

function ModalError({ onClickClose, onClickLeave }) {
  const context = useContext(Context);
  const { modalStore, i18n } = context;

  const closeCallback = (e) => {
    modalStore.toggleModal('modalAnamnesis', false);
    if (onClickClose) {
      onClickClose(e);
    }
  };

  const leaveCallback = (e) => {
    modalStore.toggleModal('modalAnamnesis', false);
    if (onClickLeave) {
      onClickLeave(e);
    }
  };

  return (
    <ModalWrapper onClick={closeCallback}>
      <Heading my={[3]}>{modalStore.title}</Heading>
      <Text mb={[3]}>{modalStore.description}</Text>
      <Flex justifyContent="center">
        <Button
          variant="primary"
          onClick={leaveCallback}
        >
          {i18n.t('abandon')}
        </Button>
        <Button
          ml={[3]}
          variant="cancel"
          onClick={closeCallback}
        >
          {i18n.t('cancel')}
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

ModalError.defaultProps = {
  onClickClose: () => {},
  onClickLeave: () => {},
};

ModalError.propTypes = {
  onClickClose: PropTypes.func,
  onClickLeave: PropTypes.func,
};

export default ModalError;
