import React, { useContext } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Text, Flex } from 'rebass';
import {
  Button, Fieldset, FormGroup, Input, Label, ButtonSquare,
} from '../../../../components';
import Context from '../../../../stores/context';

const StyledFormEditExercise = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 544px) {
    max-width: 480px;
  }

  legend {
    color: #000;
    font-family: 'Gotham';
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
    text-align: center;
    margin-bottom: 34px;

    @media screen and (min-width: 544px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .w-training-main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    > div {
      display: inline-flex;
      width: auto;

      &:first-child {
        @media screen and (min-width: 544px) {
          width: 60px;
        }
      }

      @media screen and (min-width: 544px) {
        max-width: 105px;
      }
    }

    label {
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      color: #808080;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
    }
  }

  label {
    font-size: 13px;
    line-height: 16px;
  }
`;

export const FormEditExercise = ({ updateList }) => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    modalStore,
    i18n,
    appStore,
  } = context;

  const reloadPostData = () => {
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;

    // eslint-disable-next-line no-use-before-define
    updateSeries(values, setSubmitting);
  };

  const updateSeries = (values, setSubmitting) => {
    appStore.clearObjTemp();

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    setTimeout(() => {
      buildTrainingStore.clickEditAllItemsSelected([{
        series: values.series,
      }]);

      if (buildTrainingStore.hasDiff) {
        buildTrainingStore.doRequestPatchTrainingExercises(
          buildTrainingStore.getExercisesUpdated,
          'series',
        ).then(() => {
          buildTrainingStore.diff = [];
          buildTrainingStore.editAllItemsSelected = [];
          appStore.clearObjTemp();
          modalStore.closeModalError();
          updateList();
          modalStore.toggleModal('modalEditAllExercises', false);
        }).catch((error) => {
          window.scroll(0, 0);
          modalStore.setModalNetworkCallback(reloadPostData);
          modalStore.showModalError(error);
        });
      }


      setSubmitting(false);
    }, 400);
  };

  return (
    <StyledFormEditExercise>
      <Formik
        initialValues={{
          series: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.series) {
            errors.series = i18n.t('warning_required');
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateSeries(values, setSubmitting);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset>
                <Flex flexWrap="wrap" alignItems="center" flexDirection="column" m={[2]}>
                  <Text color="red">
                    {errors.series && touched.series && errors.series}
                  </Text>
                </Flex>
                <div className="w-training-main-info">
                  <FormGroup justifyContent="center" noMarginBottom="true">
                    <Label htmlFor="series">{i18n.t('series')}</Label>
                    <Input
                      type="number"
                      id="series"
                      square="true"
                      small="true"
                      width="48px"
                      textAlign="center"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="0"
                      value={values.series}
                    />
                  </FormGroup>
                </div>

                <FormGroup formActions="true" className="w-form-actions" noMarginBottom="true">
                  <Button
                    tertiary="true"
                    color="#d52b1e"
                    onClick={() => {
                      buildTrainingStore.editAllItemsSelected = [];
                      modalStore.toggleModal('modalEditAllExercises', false);
                    }}
                  >
                    {i18n.t('cancel')}
                  </Button>
                  <ButtonSquare
                    type="submit"
                    variant={Object.keys(errors).length ? 'cancel' : 'primary'}
                  >
                    {isSubmitting && i18n.t('shipping')}
                    {!isSubmitting && i18n.t('saved_update')}
                  </ButtonSquare>
                </FormGroup>
              </Fieldset>
            </form>
          );
        }}
      </Formik>
    </StyledFormEditExercise>
  );
};

export default FormEditExercise;
