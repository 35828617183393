import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Text,
  Box,
  Flex,
} from 'rebass';
import Context from 'stores/context';
import {
  ItemList,
  Incomplete,
  AliasTeacher,
  Button,
  ButtonDropDown,
} from 'components';
import ListExercises from 'views/Suggestions/Component/ListExercises';
import { PostGaAnalytics, ConcatUnitInUrl } from 'utils';

const ItemExerciseTeacher = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    appStore,
    teacherTrainingStore,
    modalStore,
    trainingCreationStore,
    programStore,
    buildTrainingStore,
    cronometerStore,
    lesionAndRestrictionStore,
    toggleStore,
    walletUnityStore,
    rulesPaisStore,
  } = context;

  const {
    exercise,
    history,
    editExercise,
    duplicateExercise,
    deleteButton,
    tabSelected,
  } = props;

  const [reqTecher, setReqTeacher] = useState(null);
  const [exerciseDelete, setExerciseDelete] = useState(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'colapse',
    });
  };

  useEffect(() => {
    const removeExercise = async () => {
      appStore.toggleloading(true);

      try {
        await teacherTrainingStore.doRequestRemoveExercise({ id: exerciseDelete });
        setReqTeacher(null);
        if (toggleStore.allowRenderWalletUnit()) {
          walletUnityStore.setReloadListExercise(true);
        } else {
          teacherTrainingStore.setReloadListExercise(true);
        }


        modalStore.closeModalError();
      } catch (err) {
        const msgTitle = i18n.t('error_loading');

        window.scroll(0, 0);
        modalStore.setTitle(msgTitle);
        modalStore.setModalNetworkCallback(removeExercise);
        setReqTeacher(null);
        modalStore.showModalError(err);
      }

      appStore.toggleloading(false);
    };


    if (reqTecher !== null && exerciseDelete !== null) {
      removeExercise();
    }
  }, [
    reqTecher,
    exerciseDelete,
    teacherTrainingStore,
    appStore,
    modalStore,
    walletUnityStore,
    toggleStore,
    i18n,
  ]);

  const removeItem = () => {
    setReqTeacher(true);
    setExerciseDelete(teacherTrainingStore.idExercise);
  };

  const duplicateItem = ({ id }) => {
    teacherTrainingStore.setTeacherDuplicateTraining(true);
    teacherTrainingStore.setWeightTrainingId(id);
    window.scroll(0, 0);
    modalStore.toggleModal('modalFormTeacher', true);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'duplicate_saved_workout',
    });
  };


  const callRulesPais = async() => {
    const url = ConcatUnitInUrl('/build-training/build-training-list');

    trainingCreationStore.setPaisPremisses({
      pais_premisses: true,
    });

    const objParams = {
      programId: teacherTrainingStore.programId,
      levelId: programStore.levelId,
      profileId: trainingCreationStore.profileSelected,
      paisPremisses: trainingCreationStore.paisPremisses,
    };

    try {
      await rulesPaisStore.doGetRules(objParams);
      history.push(url);
    } catch (error) {
      trainingCreationStore.setPaisPremisses({
        pais_premisses: false,
      });
      history.push(url);
    }
  };

  // eslint-disable-next-line camelcase
  const editItem = ({ id, level_id, weight_training_profile_id }) => {
    const rulesPremissas = toggleStore.validateToggle({
      toggleUnitBlock: 'toogleUnitPaisBlock',
      toggleUnitAllow: 'toogleUnitPaisAllow',
      toggleCountry: 'allowPaisCountry',
    });

    lesionAndRestrictionStore.resetHealthRestrictionEdit();
    programStore.setLevelId(level_id);
    teacherTrainingStore.setTeacherDuplicateTraining(false);
    trainingCreationStore.setWeightTrainingId(id);
    trainingCreationStore.setProfileSelected({
      id: weight_training_profile_id,
    });
    teacherTrainingStore.setTeacherInteface(true);

    if(rulesPremissas) {
      callRulesPais();
    } else {
      rulesPaisStore.reset();

      trainingCreationStore.setPaisPremisses({
        pais_premisses: false,
      });

      const url = ConcatUnitInUrl('/build-training/build-training-list');
      history.push(url);
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'edit_saved_workout',
    });
  };

  const sendExercise = (training) => {
    cronometerStore.start();
    buildTrainingStore.setSuggestionWorkoutBuild(training);
    buildTrainingStore.setToggleAllowEditDateTraining(true);
    buildTrainingStore.setTemplateSuggestionSend(true);
    teacherTrainingStore.setTeacherInteface(true);
    buildTrainingStore.setAnalyticsTypeTrainingSuggestion(tabSelected);
    const url = ConcatUnitInUrl('/wallet-send-training');
    history.push(url);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'button_send_wallet',
    });
  };

  const showModalDelete = ({ id }) => {
    const msgTitle = i18n.t('question_delete_training');

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'trash_saved_workout',
    });

    window.scroll(0, 0);
    teacherTrainingStore.setIdExercise(id);
    modalStore.setTitle(msgTitle);
    modalStore.setModalDeleteCallBack(removeItem);
    modalStore.setModalAnalytics({
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'trash_saved_workout_confirm',
    });
    modalStore.modalDelete = true;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'modal_message',
      eventLabel: 'trash_saved_workout_modal',
    });
  };

  const renderInfoIncomplete = (exerciseComplete) => {
    if (exerciseComplete) {
      return true;
    }

    return (
      <Incomplete>{i18n.t('draft')}</Incomplete>
    );
  };

  const renderButtonEdit = () => {
    if (editExercise) {
      return (
        <li
          onClick={() => { editItem(exercise); }}
          role="presentation"
          data-level="2300"
        >
          {i18n.t('edit')}
        </li>
      );
    }

    return false;
  };

  const renderDuplicate = () => {
    if (exercise.complete && duplicateExercise) {
      return (
        <li
          onClick={() => { duplicateItem(exercise); }}
          role="presentation"
        >
          {i18n.t('duplicate_item')}
        </li>
      );
    }

    return false;
  };

  const rendetButtonDelete = () => {
    if (deleteButton) {
      return (
        <li
          onClick={() => { showModalDelete(exercise); }}
          role="presentation"
          className="color-red"
        >
          {i18n.t('delete')}
        </li>
      );
    }

    return false;
  };

  const listExercises = open && <ListExercises id={exercise.id} />;

  const hasWallet = () => (toggleStore.allowRenderWalletUnit());

  const colorArrow = () => {
    if (hasWallet()) {
      return '#ffb612';
    }

    return '#fff';
  };

  const rendetNameTeacher = () => {
    if (exercise.teacher_name) {
      return (
        <Text color="white" fontSize="14px" className="name-teacher">
          {i18n.t('name_teacher')}
          <strong>{exercise.teacher_name}</strong>
        </Text>
      );
    }

    return '';
  };

  return (
    <ItemList
      borderBottom="1px solid #404040"
      wallet={hasWallet()}
    >
      <Flex justifyContent="space-between" pt="34px" pb="34px">
        <Box pl="12px">
          <Flex mb={2} className="wrapper-info-training">
            <AliasTeacher color="white" pt={1} fontSize="20">
              {exercise.alias}
            </AliasTeacher>
            {renderInfoIncomplete(exercise.complete)}
          </Flex>
          <Text color="white" fontSize="14px">
            {exercise.description}
          </Text>
          {rendetNameTeacher()}
        </Box>

        <div className="wrapper-buttons">
          <ButtonDropDown>
            {renderButtonEdit()}
            {renderDuplicate()}
            {rendetButtonDelete()}
          </ButtonDropDown>

          <Button
            primary="true"
            minWidth="initial"
            className="button-send"
            disabled={!exercise.complete}
            onClick={() => { sendExercise(exercise); }}
          >
            {i18n.t('send')}
          </Button>

          <Button
            className={`w-collapse-button ${open ? 'w-collapse-opened' : ''}`}
            secondary="true"
            rounded="true"
            minWidth="initial"
            onClick={() => toggle(exercise)}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
                fill={colorArrow()}
              />
            </svg>
          </Button>

        </div>

      </Flex>
      {listExercises}
    </ItemList>
  );
};

ItemExerciseTeacher.defaultProps = {
  history: {},
  exercise: {},
  editExercise: false,
  duplicateExercise: false,
  deleteButton: false,
  tabSelected: '',
};

ItemExerciseTeacher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  exercise: PropTypes.shape({
    id: PropTypes.number,
    alias: PropTypes.string,
    complete: PropTypes.bool,
    name: PropTypes.string,
  }),
  editExercise: PropTypes.bool,
  duplicateExercise: PropTypes.bool,
  deleteButton: PropTypes.bool,
  tabSelected: PropTypes.string,
};

export default observer(ItemExerciseTeacher);
