import styled from 'styled-components';

const SimpleList = styled.div`
  .w-title {
    color: #808080;
    font-family: 'Gotham';
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    padding: 0 16px;
    margin-bottom: 24px;
  }

  ul {
    display: block;

    li {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #dedede;
      padding: 0 12px;

      @media screen and (min-width: 544px) {
        padding: 0;
      }
    }

    .w-list-name {
      display: inherit;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: 64px;
      color: #000;
      font-family: 'Gotham';
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      padding: 15px 0 14px;

      @media screen and (min-width: 544px) {
        padding: 15px 24px 14px;
      }
    }

    button {
      padding: 0;
      background-color: transparent;
    }
  }
`;

export { SimpleList };
export default SimpleList;
