import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  ContainerStyle,
  ProfileInfo,
  Text,
} from 'components';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import { ModalInfoHeader } from './ModalnfoHeader';
import { Restriction } from './Restriction';


const AnamnesisStyle = styled.div`
  @media(${props => props.theme.mediaScreen.cellphone}) {
    .container {
      min-width: auto;
      font-size: 12px;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media(${props => props.theme.mediaScreen.desktop}) {
    .space-desktop {
      width: 35%;
    }
  }
`;

const Anamnesis = ({ history }) => {
  const context = useContext(Context);
  const {
    peopleStore,
    i18n,
  } = context;

  const profileInfoLevel = peopleStore.level && (
    <ProfileInfo widthValue="30%" mobile widthValueMobile="45%">
      <small>{i18n.t('profile_level')}</small>
      <span>{peopleStore.level}</span>
    </ProfileInfo>
  );

  const profileInfoFrequency = peopleStore.frequency && (
    <ProfileInfo widthValue="40%" mobile widthValueMobile="auto" className="space-desktop">
      <small>{i18n.t('profile_frequecy')}</small>
      <span>{peopleStore.frequency}</span>
    </ProfileInfo>
  );

  const profileObjective = peopleStore.objective && (
    <ProfileInfo widthValue="60%" mobile widthValueMobile="auto">
      <small>{i18n.t('profile_goal')}</small>
      <span>{i18n.t(peopleStore.objective)}</span>
    </ProfileInfo>
  );

  if (peopleStore.hasAnamnesis === false) {
    return (
      <ContainerStyle>
        <Text>
          {i18n.t('anamnese_empty')}
        </Text>
      </ContainerStyle>
    );
  }

  return (
    <AnamnesisStyle>
      <ContainerStyle justifyContent="space-between">
        <ModalInfoHeader />

        <ProfileInfo widthValue="30%">
          <small>{i18n.t('profile_age')}</small>
          <span>{`${peopleStore.age} ${i18n.t('years_old')}`}</span>
        </ProfileInfo>

        <ProfileInfo widthValue="30%">
          <small>{i18n.t('profile_location_name')}</small>
          <span>{peopleStore.locationName}</span>
        </ProfileInfo>

        {profileInfoLevel}

        <div className="container">
          {profileInfoFrequency}

          {profileObjective}
        </div>



        <Restriction history={history} />
      </ContainerStyle>
    </AnamnesisStyle>
  );
};

Anamnesis.defaultProps = {
  history: {},
};

Anamnesis.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default Anamnesis;
