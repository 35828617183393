import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  unionBy, remove, orderBy,
} from 'lodash';
import {
  createTrainingExercises as createTrainingExercisesApi,
  deleteTrainingExercises as deleteTrainingExercisesApi,
  getSearchExercises as getSearchExercisesApi,
} from '../../services/api';
import {
  TypeExerciseData,
  TypeExerciseDataAlert,
  TypeIdExercises,
  IaddExercisesStore,
  TypeKeyAndValue,
} from './IaddExercisesStore';

import {
  TypePostExercise,
  WeightTrainingExercises,
} from '../IgenericTypes';

import { RenderNameExercise } from '../../utils';

class AddExercisesStore implements IaddExercisesStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    modalStore: {
      setTitle: (e: string) => {},
      setDescription: (e: string) => {},
      toggleModal: (x: string, y: boolean) => {},
      showModalError: (e: any) => {},
      setModalNetworkCallback: (e: Function) => {},
      closeModalError: () => {},
    },
    buildTrainingStore: {
      currentTrainingExercises: [],
      setHasDiffWorkout: (e: boolean) => {},
      editAllItemsSelected: [],
    },
    appStore: {
      toggleloading: (e: boolean) => {},
    },
    i18n: {
      t: (e: string): string => '',
    },
    programStore: {
      levelId: 0,
    },
    trainingCreationStore: {
      weightTrainingId: 0,
    },
    toggleStore: {
      allowRender: (x: string) => false,
    },
  };

  allExercises: TypeExerciseData[] = [];

  exercisesSelected: TypeExerciseData[] = [];

  filterSelected = '';

  filterExecutionExercise = '';

  currentExercise = 0;

  listExerciseView: TypeExerciseData[] = [];

  limitRender = 100;

  limitLoop = 1;

  searchValue = '';

  finishListExercise = false;

  cardioListExerciseValue = [
    'running_machine',
    'elliptic',
    'bike',
    'rowing',
    'ladder',
    'heating',
  ];

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  getRoot() {
    return this.rootStore;
  }

  /**
 * Backend nao envia a lista de cardio, ela e montada manualmente
 * seguindo a logica do array
 * @makeObjectToExerciseCardio
 * @param {string} namExercise - Nome do exercicio.
 * @param {number} index - Posicao no array para nao da error no valor do casdastro.
 * @param {object} - Retorna um objeto do tipo cardio.
 */
  makeObjectToExerciseCardio(namExercise: string, index: number): TypeExerciseData {
    return {
      id: `cardio-${index}`,
      name: namExercise,
      muscle_group: 'cardio',
      category: 'cardio',
      cardio_equipment: this.cardioListExerciseValue[index],
      equipamentWithExercise: namExercise,
    };
  }

  createListExerciseCardio(cardioExerciseList: string[]): TypeExerciseData[] {
    const listCardio: TypeExerciseData[] = [];
    cardioExerciseList.forEach((namExercise, index) => {
      const exercise = this.makeObjectToExerciseCardio(namExercise, index);
      listCardio.push(exercise);
    });

    return listCardio;
  }

  /**
   * Inseri todos exercicios em uma unica lista
   * @setAllExercises
   * @param {array} exercises - Lista de exercicios de musculacao.
   * @param {array} cardioExerciseString - Lista com o nome do exercicio.
   * @return {array} - Retorna lista com todos exercicios.
   */
  setAllExercises(exercises: TypeExerciseData[], cardioExerciseString: string): void {
    const auxAllExercise: TypeExerciseData[] = [];
    this.allExercises = [];
    const cardioExerciseList = cardioExerciseString.split(',');
    const listExerciseCardio = this.createListExerciseCardio(cardioExerciseList);

    exercises.forEach((training) => {
      const objTraining = training;
      const equipamentWithExercise = RenderNameExercise(training);
      objTraining.equipamentWithExercise = equipamentWithExercise;
      objTraining.category = 'weightTraining';
      auxAllExercise.push(objTraining);
    });

    listExerciseCardio.forEach((objCardio) => {
      auxAllExercise.push(objCardio);
    });

    this.allExercises = auxAllExercise;
    this.getListExerciseView();
  }

  clearExercisesSelected(): void {
    this.exercisesSelected = [];
  }

  setListExercisesSelected(newListExerciseSelected: TypeExerciseData[]): void {
    this.exercisesSelected = newListExerciseSelected;
  }

  trimmedName({ name }: TypeExerciseData): string | null {
    return (typeof name === 'string') ? name.trim() : null;
  }

  hasItemInCurrentTrainingExercises(exercise: TypeExerciseData): boolean {
    const hasItemCurrentTraining = this.rootStore
      .buildTrainingStore
      .currentTrainingExercises
      .filter((item: WeightTrainingExercises) => {
        const nameExercise = this.trimmedName(exercise);
        return (item.title === nameExercise);
      });

    return (!!hasItemCurrentTraining.length);
  }

  hasItemInExercisesSelected(exercise: TypeExerciseData): boolean {
    const hasItemExerciseSelected = this.exercisesSelected
      .filter(item => (item.name === exercise.name));

    return (!hasItemExerciseSelected.length);
  }

  setExercisesSelected(exercise: TypeExerciseData): void {
    const hasCurrentTraining = this.hasItemInCurrentTrainingExercises(exercise);
    const listExerciseSelected = this.hasItemInExercisesSelected(exercise);
    const addExercise = ((hasCurrentTraining === false) && listExerciseSelected);

    if (addExercise) {
      this.exercisesSelected.push(exercise);
    } else {
      const trainingSelected = this.rootStore.i18n.t('training_selected');
      this.rootStore.modalStore.setTitle('');
      this.rootStore.modalStore.setDescription(trainingSelected);
      this.rootStore.modalStore.toggleModal('modalError', true);
    }
  }

  alertExerciseSelected(): TypeExerciseData[] {
    if (this.exercisesSelected.length === 0) return [];

    const listExerciseSelected = this.exercisesSelected
      .map((exercise) => {
        const objExercise: TypeExerciseDataAlert = exercise;
        delete objExercise.hasList;
        const currentTraining = this.hasItemInCurrentTrainingExercises(objExercise);

        if (currentTraining) {
          objExercise.hasList = true;
          return objExercise;
        }

        return objExercise;
      });

    return listExerciseSelected;
  }

  removeExercisesSelected(exercise: TypeExerciseData): void {
    remove(this.exercisesSelected, item => item.id === exercise.id);
  }

  makeRequestFilter() {
    const listParams: TypeKeyAndValue[] = [];
    let request = '?';
    const filter = this.filterSelected === 'allExercise' ? '' : this.filterSelected;

    const paramsQuery = {
      search_term: this.searchValue,
      muscle_group: filter,
      exercise_material: this.filterExecutionExercise,
    };

    Object.entries(paramsQuery).find(([key, value]) => {
      if (value !== '') {
        const obj = { key, value };
        listParams.push(obj);
      }
      return false;
    });

    listParams.forEach((obj) => {
      request += `&${obj.key}=${obj.value}`;
    });

    return request.replace(/&/, '');
  }

  // v2 - novo endpoint de busca de exercicio em fase de teste
  doRequestGetAllExercisesNew() {
    this.rootStore.appStore.toggleloading(true);
    this.resetListExerciseView();

    const cardioExerciseList = `${this.rootStore.i18n.t('cardio_exercise_list')}`;
    const queryParams = this.makeRequestFilter();

    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await getSearchExercisesApi(
          {
            levelId: this.rootStore.programStore.levelId,
            queryParams,
          },
          this.rootStore.sessionStore.token,
        );
        this.setAllExercises(res.data.weight_exercises, cardioExerciseList);
        resolve(res.data.training_groups);
      } catch (err) {
        reject(err);
      }

      this.rootStore.appStore.toggleloading(false);
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  /**
   * Busca todos exercicios que sao diferentes da categoria
   * que esta sendo enviada para a api.
   * @removeExerciseCategory
   * @param {string} category - Categoria de exercicio exemplo cardio, weight training.
   * @return {array} [] - Retorna uma nova lista sem os exercicios da categoria.
   */
  removeExerciseCategory(category: string): TypeExerciseData[] {
    return this.exercisesSelected.filter(exercise => (exercise.category !== category));
  }

  /**
   * Cadastra os exercicios selecionados
   * @doRequestCreateAllExercises
   * @param {array} items - Lista de exercicio configurados.
   * @param {string} category - Categoria de exercicio exemplo cardio, weight training.
   */
  doRequestCreateAllExercises(items: TypePostExercise[], category: string) {
    const data = {
      weight_training_exercises: items,
    };
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await createTrainingExercisesApi(
          {
            current_weight_training_id: this.rootStore.trainingCreationStore.weightTrainingId,
            body: data,
          },
          this.rootStore.sessionStore.token,
        );

        this.exercisesSelected = this.removeExerciseCategory(category);
        this.rootStore.buildTrainingStore.setHasDiffWorkout(true);
        resolve(res.data.training_groups);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  doRequestDeleteTrainingExercises() {
    const itemsSelectedToDelete = this.rootStore
      .buildTrainingStore
      .editAllItemsSelected
      .map((item: TypeIdExercises) => item.id.toString());

    const data = {
      weight_training_exercises: itemsSelectedToDelete,
    };

    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await deleteTrainingExercisesApi(
          {
            current_weight_training_id: this.rootStore.trainingCreationStore.weightTrainingId,
            body: data,
          },
          this.rootStore.sessionStore.token,
        );
        this.rootStore.buildTrainingStore.currentTrainingExercises = [];
        this.rootStore.buildTrainingStore.setHasDiffWorkout(true);
        resolve(res.data.training_groups);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  getHasExercisesSave(): boolean {
    return this.exercisesSelected.length > 0;
  }

  /**
   * Verifica se existe exercicios de cardio
   * @hasExerciseCardio
   * @return {Booelan} .
   */
  hasExerciseCardio(): boolean {
    return this.exercisesSelected.some(exercise => exercise.muscle_group === 'cardio');
  }

  /**
   * Verifica se existe exercicios de musculacao
   * @hasExerciseWeightTraining
   * @return {Booelan} .
   */
  hasExerciseWeightTraining(): boolean {
    return this.exercisesSelected.some(exercise => exercise.muscle_group !== 'cardio');
  }

  resetValueListExercise(): void {
    this.searchValue = '';
    this.allExercises = [];
    this.listExerciseView = [];
    this.currentExercise = 0;
    this.filterSelected = '';
    this.filterExecutionExercise = '';
  }

  resetListExerciseView() {
    this.listExerciseView = [];
  }

  filterSearch(searchValue: string): void {
    this.searchValue = searchValue;
    this.listExerciseView = [];
    this.currentExercise = 0;
    this.limitLoop = 1;
  }

  setFilterSelected(muscleGroup: string): void {
    this.filterSelected = muscleGroup;
    this.listExerciseView = [];
    this.currentExercise = 0;
    this.limitLoop = 1;
  }

  setfFilterExecutionExercise(execution: string): void {
    this.filterExecutionExercise = execution;
    this.listExerciseView = [];
    this.currentExercise = 0;
    this.limitLoop = 1;
  }

  plusExercise(): void {
    this.getListExerciseView();
  }

  setListView(exercise: TypeExerciseData): void {
    if (exercise !== undefined) {
      this.listExerciseView.push(exercise);
    }
  }

  statusFinishListExercise(exercises: TypeExerciseData[]): void {
    const hasPlusExercise = this.allowRenderExercise(exercises);
    this.finishListExercise = !hasPlusExercise;
  }

  renderListExercise(exercises: TypeExerciseData[]): void {
    const lenghtExercise = exercises.length - 1;
    const limit = (exercises.length < this.limitRender) ? lenghtExercise : this.limitRender;

    for (let i = 0; i <= limit; i += 1) {
      if (lenghtExercise >= this.currentExercise) {
        this.listExerciseView.push(exercises[this.currentExercise]);
        this.currentExercise += 1;
        this.limitLoop += 1;
      }
    }

    this.statusFinishListExercise(exercises);
  }

  allowRenderExercise(exercises: TypeExerciseData[]): boolean {
    return (exercises.length > 0 && (this.limitLoop <= exercises.length));
  }

  get getAllExercisesFiltered() {
    const exercises = orderBy(this.allExercises, ['name'], ['asc']);
    if (this.filterSelected === '' || this.filterSelected === 'allExercise') {
      return exercises;
    }

    return exercises.filter(item => item.muscle_group === this.filterSelected);
  }

  renderListExerciseEmpty(): void {
    this.renderListExercise([]);
  }

  renderListWithExercise(): void {
    const exercises = this.getAllExercisesFiltered;

    if (this.allowRenderExercise(exercises)) {
      this.renderListExercise(exercises);
    }
  }

  // removendo as funcionalidades da lib fuse.js
  renderListExerciseBackEnd(): void {
    const exercises = this.allExercises;
    if (this.allowRenderExercise(exercises)) {
      this.renderListExercise(exercises);
    }
  }

  getListExecise(): void {
    this.renderListExerciseBackEnd();
  }

  getListExerciseView(): void {
    this.getListExecise();
  }

  /**
   * Retorna lista somente com os exercicios de cardio
   * @getExercisesSelectedCardio
   * @return {array} .
   */
  get getExercisesSelectedCardio() {
    return this.exercisesSelected.filter(exercise => exercise.muscle_group === 'cardio');
  }

  /**
   * Retorna lista somente com os exercicios de musculacao
   * @getExercisesWeightTraining
   * @return {array} .
   */
  get getExercisesWeightTraining() {
    return this.exercisesSelected.filter(exercise => exercise.muscle_group !== 'cardio');
  }

  /**
   * Retorna lista somente com todos exercicios
   * @getExercisesSelected
   * @return {array} .
   */
  get getExercisesSelected() {
    return unionBy(this.exercisesSelected, 'id');
  }

  get hasHitOrKlass() {
    const hit = this.exercisesSelected.filter(exercise => exercise.muscle_group === 'hit');
    const klass = this.exercisesSelected.filter(exercise => exercise.muscle_group === 'klass');
    const result = hit.length + klass.length;
    return (result === this.exercisesSelected.length);
  }
}

decorate(AddExercisesStore, {
  searchValue: observable,
  filterSelected: observable,
  filterExecutionExercise: observable,
  allExercises: observable,
  exercisesSelected: observable,
  listExerciseView: observable,
  finishListExercise: observable,
  setAllExercises: action,
  makeObjectToExerciseCardio: action,
  doRequestGetAllExercisesNew: action,
  setExercisesSelected: action,
  getHasExercisesSave: observable,
  hasExerciseCardio: action,
  hasExerciseWeightTraining: action,
  removeExercisesSelected: action,
  clearExercisesSelected: action,
  createListExerciseCardio: action,
  plusExercise: action,
  getListExerciseView: action,
  filterSearch: action,
  trimmedName: action,
  resetValueListExercise: action,
  setListExercisesSelected: action,
  setfFilterExecutionExercise: action,
  getExercisesSelected: computed,
  getExercisesSelectedCardio: computed,
  getExercisesWeightTraining: computed,
  getAllExercisesFiltered: computed,
  hasHitOrKlass: computed,
});

export const AddExercisesSchema = {
  exercisesSelected: {
    type: 'list',
    schema: {
      id: true,
      name: true,
      muscle_group: true,
      cardio_equipment: true,
    },
  },
};

export default AddExercisesStore;
