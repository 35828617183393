/* eslint-disable camelcase */
import React, { useContext } from 'react';
import {
  Button,
  StyledDataExercise,
} from '../../../../components';
import Context from '../../../../stores/context';
import { ValidateHasHit } from '../../../../utils';

const WeightTraining = (props) => {
  const context = useContext(Context);
  const { i18n } = context;
  const { exercise, handleEventEdit } = props;
  const {
    series,
    min_repetitions,
    max_repetitions,
    min_interval,
    max_interval,
    isometry,
    duration,
    rest,
  } = exercise;

  const renderInfoExercise = () => {
    if (ValidateHasHit(exercise)) {
      return '';
    }

    if (isometry) {
      return (
        <span>
          <p className="w-series">
            <span>{i18n.t('series')}</span>
            { series }
          </p>

          <p className="w-time-execution">
            <span>{i18n.t('duration')}</span>
            {`${duration}`}
          </p>

          <p className="w-interval">
            <span>{i18n.t('interval')}</span>
            {`${rest}`}
          </p>
        </span>
      );
    }

    return (
      <span>
        <p className="w-series">
          <span>{i18n.t('series')}</span>
          { series }
        </p>

        <p className="w-repetitions">
          <span>{i18n.t('repeats')}</span>
          {`${min_repetitions}-${max_repetitions}`}
        </p>

        <p className="w-interval">
          <span>{i18n.t('interval')}</span>
          {`${min_interval}-${max_interval}`}
        </p>
      </span>
    );
  };

  const renderIntensification_method = () => {
    if (ValidateHasHit(exercise)) {
      return '';
    }

    return (
      <Button
        secondary="true"
        rounded="true"
        className={(exercise.intensification_method !== '') && 'w-active'}
        data-button="intensification_method"
        onClick={handleEventEdit}
      >
        <span>{i18n.t('intensification_method')}</span>
        <svg
          width="21"
          height="28"
          viewBox="0 0 21 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.68917 16.9989H1.99986C1.16151 16.9989 0.695424 16.0293 1.21914 15.3748L13.2169 0.380457C13.8948 -0.466823 15.2477 0.221118 14.9622 1.26793L12.3072 11.0011H19.9965C20.8348 11.0011 21.3009 11.9707 20.7772 12.6252L8.77945 27.6195C8.1015 28.4668 6.7486 27.7789 7.03415 26.7321L9.68917 16.9989ZM4.08009 14.9996H10.9982C11.6574 14.9996 12.1362 15.6264 11.9628 16.2623L10.2334 22.6022L17.9162 13.0004H10.9982C10.3389 13.0004 9.86013 12.3736 10.0336 11.7377L11.763 5.39784L4.08009 14.9996Z" />
        </svg>
      </Button>
    );
  };

  const renderComment = () => (
    <Button
      secondary="true"
      rounded="true"
      className={exercise.observation && 'w-active'}
      data-button="comment"
      onClick={handleEventEdit}
    >
      <span>{i18n.t('comment_training')}</span>
      <svg
        width="25"
        height="21"
        viewBox="0 0 25 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.26317 19.9648C1.49262 20.1749 0.798803 19.4415 1.05137 18.6838L2.3869 14.6772C1.48329 13.2535 1.00006 11.657 1.00006 10C1.00006 4.4279 6.41194 0 13.0001 0C19.5882 0 25.0001 4.4279 25.0001 10C25.0001 15.5721 19.5882 20 13.0001 20C10.8683 20 8.8154 19.5368 7.01 18.6702L2.26317 19.9648ZM6.8519 16.6402C7.09431 16.5741 7.35273 16.6017 7.57575 16.7175C9.1787 17.5496 11.0459 18 13.0001 18C18.5622 18 23.0001 14.369 23.0001 10C23.0001 5.63097 18.5622 2 13.0001 2C7.43791 2 3.00006 5.63097 3.00006 10C3.00006 11.4001 3.45192 12.7495 4.30531 13.9468C4.49079 14.2071 4.54072 14.5403 4.43967 14.8434L3.53962 17.5436L6.8519 16.6402ZM13.0001 11.5C12.1716 11.5 11.5001 10.8284 11.5001 10C11.5001 9.17157 12.1716 8.5 13.0001 8.5C13.8285 8.5 14.5001 9.17157 14.5001 10C14.5001 10.8284 13.8285 11.5 13.0001 11.5ZM8.00006 11.5C7.17163 11.5 6.50006 10.8284 6.50006 10C6.50006 9.17157 7.17163 8.5 8.00006 8.5C8.82848 8.5 9.50006 9.17157 9.50006 10C9.50006 10.8284 8.82848 11.5 8.00006 11.5ZM18.0001 11.5C17.1716 11.5 16.5001 10.8284 16.5001 10C16.5001 9.17157 17.1716 8.5 18.0001 8.5C18.8285 8.5 19.5001 9.17157 19.5001 10C19.5001 10.8284 18.8285 11.5 18.0001 11.5Z" />
      </svg>
    </Button>
  );

  return (
    <StyledDataExercise>
      <div className="w-actions">
        {renderIntensification_method()}
        {renderComment()}
      </div>

      <div className="w-exercise-details">
        {renderInfoExercise()}
      </div>
    </StyledDataExercise>
  );
};

export default WeightTraining;
