import styled from 'styled-components';
import IconPrograms from './icon/programs-smart.svg';
import IconHipertrofia from './icon/programs-hipertrofia.svg';
import IconCondicionamento from './icon/programs-condicionamento.svg';
import IconEmagrecimento from './icon/programs-emagrecimento.svg';

const ProgramsIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  width: ${props => props.width || '90x'}px;
  height: ${props => props.height || '60'}px;
  margin: 0 auto;

  ${({ hipertrofia }) => hipertrofia && `
    background-image: url(${IconHipertrofia});
  `}

  ${({ iconCondicionamento }) => iconCondicionamento && `
    background-image: url(${IconCondicionamento});
  `}

  ${({ iconEmagrecimento }) => iconEmagrecimento && `
    background-image: url(${IconEmagrecimento});
  `}

  ${({ smart }) => smart && `
    background-image: url(${IconPrograms});
  `}
`;

export default ProgramsIcon;
