/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import Context from 'stores/context';
import {
  ContainerStyle,
  ProfileInfo,
  Title,
  Button,
} from 'components';
import { PostGaAnalytics } from 'utils';
import { ProgramsStyle } from '../ProgramStyle';
import ProgramsIcon from '../ProgramsIcon';
import ShowDate from '../utils/ShowDate';

const ProgramSmart = () => {
  const context = useContext(Context);
  const {
    i18n,
    appStore,
    peopleStore,
    bookingsStore,
    locationStore,
    sessionStore,
    modalStore,
  } = context;

  const {
    completed_sessions,
    step,
    started_at,
    remains_days,
  } = peopleStore.programs;

  const [reqBooking, setReqBooking] = useState(true);

  useEffect(() => {
    const call = async () => {
      appStore.toggleloading(true);

      const acronym = locationStore.acronymSelected || sessionStore.acronym;
      if (acronym === '') {
        appStore.toggleloading(false);
        setReqBooking(false);
      } else {
        const objParams = {
          personId: peopleStore.peopleId,
          acronym,
        };

        try {
          await bookingsStore.doRequestDateBookings(objParams);
          appStore.toggleloading(false);
          setReqBooking(false);
        } catch (err) {
          appStore.toggleloading(false);
          setReqBooking(false);
        }
      }
    };

    if (reqBooking) {
      call();
    }
  }, [
    i18n,
    modalStore,
    sessionStore,
    reqBooking,
    locationStore,
    bookingsStore,
    peopleStore,
    appStore,
  ]);

  const postConfirmedPresence = async () => {
    appStore.toggleloading(true);

    const objParams = {
      idBooking: bookingsStore.idPeopleBooking,
    };

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:presence',
      eventLabel: 'program_smart_presence',
      timeConfirmation: `${bookingsStore.getTimeSendConfirmation()}`,
    });

    try {
      await bookingsStore.doRequestConfirmedTraining(objParams);
      appStore.toggleloading(false);
    } catch (err) {
      appStore.toggleloading(false);
    }
  };

  const renderButtonConfirmed = () => {
    const showButton = !!(bookingsStore.showButtonConfirmed);

    if (showButton) {
      return (
        <ProfileInfo widthValue="auto" className="profile button-presence">
          <Button
            primary
            className="button-confirmed"
            onClick={postConfirmedPresence}
          >
            {i18n.t('confirmed_presence')}
          </Button>
        </ProfileInfo>
      );
    }

    return false;
  };

  const renderNexDate = () => {
    const hasId = !!(bookingsStore.idPeopleBooking !== null);
    const hasDate = !!(bookingsStore.datePeopleMeet !== null);

    if (hasId && hasDate) {
      return (
        <ContainerStyle baseLine="center" justifyContent="start" className="container presence mg-top">
          <ProfileInfo widthValue="auto" className="profile date-presence">
            <small>{i18n.t('next_presence')}</small>
            <span className="date-meet">{bookingsStore.datePeopleMeet}</span>
          </ProfileInfo>
          {renderButtonConfirmed()}
        </ContainerStyle>
      );
    }

    return false;
  };

  return (
    <ProgramsStyle>
      <div className="wrapper-title">
        <ProgramsIcon
          smart
          height={76}
          width={120}
          className="icon"
        />
        <Title
          type={4}
          fontSize={20}
          align="initial"
          className="title"
        >
          {i18n.t('smart_program')}
        </Title>
      </div>

      <div className="wrapper-info">
        <ContainerStyle baseLine="center" justifyContent="start" className="container">
          <ProfileInfo widthValue="auto" className="profile">
            <small>{i18n.t('initial')}</small>
            <span>{ShowDate(started_at)}</span>
          </ProfileInfo>

          <ProfileInfo widthValue="auto" className="profile">
            <small>{i18n.t('remains_day')}</small>
            <span>
              {i18n.t('days', { days: remains_days })}
            </span>
          </ProfileInfo>

        </ContainerStyle>


        <ContainerStyle baseLine="center" justifyContent="start" className="container">
          <ProfileInfo widthValue="auto" className="profile adjust-margin">
            <small>{i18n.t('trainings_finish')}</small>
            <span>{completed_sessions}</span>
          </ProfileInfo>

          <ProfileInfo widthValue="auto" className="profile remove-mr">
            <small>{i18n.t('title_stage')}</small>
            <span>{step}</span>
          </ProfileInfo>

        </ContainerStyle>

        {renderNexDate()}
      </div>
    </ProgramsStyle>
  );
};

export default ProgramSmart;
