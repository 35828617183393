import React, { useState, useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import {
  Flex, Box, Button, Text,
} from 'rebass';
import VideoExercise from './VideoExercise';
import ListAllExercises from './ListAllExercises';
import ListExercisesSelected from './ListExercisesSelected';
import HeaderList from './HeaderLengthExercise';
import PremissaAmountExercise from './PremissasAmountExercise';

const WrapperList = styled(Box)`
  flex-basis: 50%;
  display: none;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;

  &.all-exercise {
    margin-right: 16px;
  }

  &.active {
    display: block;
  }

  .text-header {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 10px 10px;
    color: ${(props) => props.theme.colors.grayLight};
    background-color: ${(props) => props.theme.colors.white};
  }

  @media screen and (min-width: 425px) {
    display: block;
  }
`;

const WrapperContent = styled(Flex)`
  display: block;

  @media screen and (min-width: 425px) {
    display: block;
  }
`;

const WrapperHeaderList = styled(Box)`
   display: block;

   @media screen and (min-width: 425px) {
    width: 50%;
  }
`;

const WrapperButtonContentMobile = styled(Flex)`
  justify-content: center;
  margin-bottom: 20px;

  @media screen and (min-width: 425px) {
    display: none;
  }
`;

const WrapperScrollListSelected = styled.div`
  overflow: scroll;
  height: 517px;
  margin-top: 10px;
`;

const WrapperBoxList = styled.div`
  display: block;

  @media screen and (min-width: 425px) {
    display: flex;
  }
`;

function Content() {
  const context = useContext(Context);
  const { i18n, rulesPaisStore } = context;
  const [columnSelected, setColumnSelected] = useState(false);

  const renderPremissasAmountExercise = () => {
    const hasRules = rulesPaisStore
      .listToggleShowRulesPremissas.includes('number_of_exercise');

    if (rulesPaisStore.showRulesFormExercise && hasRules) {
      return (
        <PremissaAmountExercise />
      );
    }

    return false;
  };

  return (
    <div>
      <WrapperContent>
        <WrapperHeaderList>
          <WrapperButtonContentMobile>
            <Box>
              {
                columnSelected
                  ? (
                    <Button
                      mr={[2]}
                      fontSize={[1]}
                      fontWeight="400"
                      color="#999"
                      variant="text"
                      onClick={() => {
                        setColumnSelected(false);
                      }}
                    >
                      {i18n.t('exercise')}
                    </Button>
                  )
                  : (
                    <Button
                      mr={[2]}
                      fontSize={[1]}
                      variant="primary"
                      onClick={() => {
                        setColumnSelected(false);
                      }}
                    >
                      {i18n.t('exercises')}
                    </Button>
                  )
              }
              {
                !columnSelected
                  ? (
                    <Button
                      mr={[2]}
                      fontSize={[1]}
                      fontWeight="400"
                      color="#999"
                      variant="text"
                      onClick={() => {
                        setColumnSelected(true);
                      }}
                    >
                      {i18n.t('selected')}
                    </Button>
                  ) : (
                    <Button
                      mr={[2]}
                      fontSize={[1]}
                      variant="primary"
                      onClick={() => {
                        setColumnSelected(true);
                      }}
                    >
                      {i18n.t('selected')}
                    </Button>
                  )
              }
            </Box>
          </WrapperButtonContentMobile>
        </WrapperHeaderList>

        <WrapperBoxList>
          <WrapperList className={`all-exercise ${columnSelected === false ? 'active' : ''}`}>
            <Text color="#999" textAlign="left" pl={[4]} className="text-header">
              {i18n.t('exercises')}
            </Text>
            <ListAllExercises />
          </WrapperList>
          <WrapperList className={`${columnSelected ? 'active' : ''}`}>
            <HeaderList />
            <WrapperScrollListSelected>
              <ListExercisesSelected />
            </WrapperScrollListSelected>
            {renderPremissasAmountExercise()}
          </WrapperList>
        </WrapperBoxList>
      </WrapperContent>
      <VideoExercise />
    </div>
  );
}

export default Content;
