import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import ItemPais from './ItemPais';

function NumberRepetition(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
  } = context;

  const {
    hasError,
  } = props;

  const initial = '';
  const listRepetition = rulesPaisStore
    .dataRules.exercise_repetitions
    .map(((repetition) => i18n.t('dropdown_pais_number_max_min_repetition', {
      min: repetition.min,
      max: repetition.max,
    })));

  const listTextRepetition = listRepetition
    .reduce((initial, nextValue) => {
      const initialValue = initial;
      const value = nextValue;
      return `${initialValue} / ${value}`;
    }, initial);

  const labelRepetition = listTextRepetition
    .replace(/[\\/]/, '').trim().replace(/\s+/g, ' ');

  return (
    <ItemPais
      rule={i18n.t('dropdown_pais_title_number_max_min_repetition')}
      checked={hasError}
      expect={labelRepetition}
    />
  );
}

NumberRepetition.defaultProps = {
  hasError: false,
};

NumberRepetition.propTypes = {
  hasError: PropTypes.bool,
};

export default NumberRepetition;
