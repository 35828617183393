import styled from 'styled-components';

const Recommend = styled.span`
  background: ${(props) => props.theme.colors.greenDark};
  display: block;
  position: absolute;
  top: -5px;
  left: 20px;
  font-family: ${(props) => props.theme.fonts.Gotham};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: lowercase;
  padding: 2px 8px;
  border-radius: 10px;
`;

export { Recommend };
export default Recommend;
