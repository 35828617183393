import React, { useContext } from 'react';
import Context from 'stores/context';

import { observer } from 'mobx-react';
import ModalWrapper from 'components/modal/modal-wrapper';
import HeaderModalLocation from './HeaderModalLocation';
import ListLocation from './ListLocation';


const ModalLocation = () => {
  const context = useContext(Context);
  const {
    locationStore,
  } = context;

  const styleModal = {
    titleColor: 'black',
    widthWrapper: '544px',
    px: '0px',
    py: '0px',
  };

  if (locationStore.showModal === false) return false;

  return (
    <ModalWrapper styleCss={styleModal}>
      <HeaderModalLocation />
      <ListLocation />
    </ModalWrapper>
  );
};

export default observer(ModalLocation);
