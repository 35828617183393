import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import i18n from './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import * as connection from './connection';
import rootStore from './stores/rootStore';
import Context from './stores/context';
import { GlobalStyle, theme } from './style';
import './assets/stylesheets/app.scss';

rootStore.i18n = i18n;

setTimeout(() => {
  const root = (
    <Context.Provider value={rootStore}>
      <Router>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </Context.Provider>
  );

  ReactDOM.render(root, document.getElementById('root'));
  // connection.default();
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PW
  serviceWorker.register();
}, 300);
