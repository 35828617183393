import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  NewGridContainer as Container,
  HeaderSession,
  TabNavigatorStyle,
  Title,
} from 'components';

import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import ListWorkout from './ListWorkout';


const SuggestionStyle = styled.div`
  margin-top: -28px;

  .title-unit-suggestion {
    margin-top: 30px;
    text-align: initial;
    padding: 0 27px;
  }

  .wrapper-header {
    padding-left: 24px;
    padding-right: 24px;
    background-color: ${props => props.theme.colors.darkgray};
    padding-top: 15px;
    padding-bottom: 16px;
  }

  @media(${props => props.theme.mediaScreen.cellphone}) {
    wrapper-header {
      height: 100px;
    }
  }
`;

class Suggestions extends Component {
  static contextType = Context;

  state = {
    tabSelected: 'my_training',
  };

  constructor(props) {
    super(props);
    this.activeTab = this.activeTab.bind(this);
  }

  componentDidMount() {
    const {
      buildTrainingStore,
      trainingCreationStore,
      toggleStore,
    } = this.context;

    trainingCreationStore.resetDateFinish();

    if (toggleStore.allowRenderWalletUnit()) {
      this.setState({
        tabSelected: 'training_wallet',
      });
      buildTrainingStore.setAnalyticsTypeTrainingSuggestion('training_wallet');
    } else {
      buildTrainingStore.setAnalyticsTypeTrainingSuggestion('my_training');
    }

    if (trainingCreationStore.paisPremisses) {
      this.callRulesPais();
    }
  }

  callRulesPais = async () => {
    const {
      trainingCreationStore,
      rulesPaisStore,
      programStore,
      modalStore,
    } = this.context;

    const objParams = {
      programId: programStore.programId,
      levelId: programStore.levelId,
      profileId: trainingCreationStore.profileSelected,
      paisPremisses: trainingCreationStore.paisPremisses,
    };

    try {
      await rulesPaisStore.doGetRules(objParams);
    } catch (err) {
      modalStore.showModalError(err);
    }
  };

  activeTab(e) {
    const { buildTrainingStore, walletUnityStore } = this.context;
    const itemSelected = e.currentTarget.id;
    buildTrainingStore.setAnalyticsTypeTrainingSuggestion(itemSelected);

    if (itemSelected === 'training_wallet') {
      walletUnityStore.resetView();
      walletUnityStore.setReloadListExercise(true);
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:suggestion',
      eventAction: 'click:table',
      eventLabel: `suggestion_workout_${itemSelected}`,
    });

    this.setState({
      tabSelected: itemSelected,
    });

    return true;
  }

  listButtons() {
    const {
      toggleStore,
    } = this.context;

    const listOptions = ['my_training'];

    if (toggleStore.allowRenderWalletUnit()) {
      listOptions.push('training_wallet');
    } else {
      listOptions.push('suggestion');
    }

    return listOptions;
  }

  renderButton() {
    const listOptions = this.listButtons();
    const { i18n } = this.context;
    const { tabSelected } = this.state;

    const buttonsTabs = listOptions.map(item => (
      <li
        key={item}
        id={item}
        type="button"
        role="presentation"
        className={tabSelected === item ? 'item active' : 'item'}
        onClick={(e) => {
          this.activeTab(e);
        }}
      >
        <span className="tab-link">{`${i18n.t(item)}`}</span>
      </li>
    ));

    return buttonsTabs;
  }

  renderTabOrAddressUnit() {
    const {
      toggleStore,
      i18n,
      sessionStore,
    } = this.context;

    if (toggleStore.allowRenderWalletUnit()) {
      return (
        <Title
          type={4}
          fontSize={20}
          className="title-unit-suggestion"
        >
          {i18n.t('workout_unit', {
            name: sessionStore.session.location.name,
            acronym: sessionStore.acronym,
          })}
        </Title>
      );
    }

    return (
      <TabNavigatorStyle bgBlack>
        <ul className="wrapper-menu">
          {this.renderButton()}
        </ul>
      </TabNavigatorStyle>
    );
  }

  render() {
    const {
      i18n,
      modalStore,
      sessionStore,
      peopleStore,
    } = this.context;
    const { history } = this.props;
    const { tabSelected } = this.state;
    const modalError = modalStore.modalError && (
      <ModalError
        onClick={() => {
          const url = ConcatUnitInUrl('/profile');
          history.push(url);
        }}
      />
    );

    const modalNetwork = modalStore.modalNetwork && (
      <ModalNetwork />
    );

    const eventButtonHeader = () => {
      history.goBack();
    };

    return (
      <SuggestionStyle>
        <header className="wrapper-header">
          <HeaderSession
            statusActionButton={false}
            isBack
            eventButton={eventButtonHeader}
            nameClass="w-btn-back"
            title={sessionStore.renderListNavigator(i18n.t('profile_title'))}
            subTitle={peopleStore.peopleName.split(' ')[0]}
            titlePlan={i18n.t('health_insurance')}
            namePlan={peopleStore.namePlanPeople}
          />
        </header>

        <Container spacing="0px">
          {this.renderTabOrAddressUnit()}

          <ListWorkout history={history} tabSelected={tabSelected} />

          {modalError}
          {modalNetwork}
        </Container>
      </SuggestionStyle>
    );
  }
}

Suggestions.defaultProps = {
  history: {},
};

Suggestions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(Suggestions);
