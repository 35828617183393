const removeCache = () => {
  if ('serviceWorker' in window.navigator) {
    window.navigator.serviceWorker.ready.then((registration) => {
      registration.unregister().then(() => {
        window.location.reload(true); // (shift + cmd + R) equivalent
      });
      window.caches.keys().then((names) => {
        for (const name of names) { caches.delete(name); }
      });
    });
  }
};

export default removeCache;
