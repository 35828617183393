import styled from 'styled-components';

const TrainingEvaluationStyle = styled.div`
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.colors.darkgray};
  border-radius: 7px 7px;
  margin-bottom: 32px;
  padding: 10px 16px;

  .wrapper-menu-evaluation {
    position: relative;
    height: 47px;
  }

  .name-tab {
    line-height: 50px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  .w-collapse-button {
    background-color: transparent;
    transform: rotateZ(0);
    transition: transform 0.2s ease-out;
    margin: 0px;
    width: 20px;
    height: 20px;
    border: none;
    position: absolute;
    right: 0px;
    top: 12px;

    &.w-collapse-opened {
      transform: rotateZ(180deg);
    }
  }

  .tab__wrapper {
    flex-wrap: wrap;
    display: none;

    &.opened {
      display: flex;
    }
  }

  .list-justify {
    list-style-type: none;
    padding-bottom: 8px;

    &__item {
      display: flex;
      margin-bottom: 8px;
      width: 50%;
      align-items: center;
    }

    &__icon-like {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    &__title {
      text-align: initial;
    }

    &__title--small {
      font-size: 15px;

    }
  }

  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    .name-tab {
      font-size: 10px;
    }

    .tab__wrapper {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .list-justify {
      &__item {
      display: flex;
      margin-bottom: 8px;
      width: 100%;
    }
    }
  }
`;

export { TrainingEvaluationStyle };
export default TrainingEvaluationStyle;
