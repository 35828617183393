import styled from 'styled-components';

const Anamnese = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 32px;

  @media screen and (min-width: 768px) {
    max-width: 720px;
    padding-top: 56px;
    margin: 0 auto;
  }

  > button,
  > a {
    margin-bottom: 24px;
  }

  .w-start {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    button,
    a {
      width: 100%;
      margin-bottom: 18px;

      @media screen and (min-width: 544px) {
        width: calc(50% - 12px);
      }
    }
  }

  .next-frequency {
    margin: 0 auto;
  }

  .warning-info {
    text-align: center;
    width: 100%;
    margin-bottom: 24px;
  }

  .toogle-restriction {
    display: none;

    &.active {
      display: block;
    }
  }

  &.w-question-lesion {
    padding-top: 0px;

    .title {
      margin-top: 32px;
    }
  }

  .w-option-lesion {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    button {
      margin: 8px;
    }
  }

  .w-lesion-details {
    width: 100%;
    margin: 0 auto;

    .label {
      font-family: ${(props) => props.theme.fonts.Gotham};
      font-style: normal;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .limit-text {
      color: ${(props) => props.theme.colors.white};
      text-align: end;
      width: 100%;
      margin-top: 10px;
    }
  }

  .button-position {
    position: relative;
  }
`;

export { Anamnese };
export default Anamnese;
