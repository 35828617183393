import React, { useContext, useState } from 'react';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import {
  Button,
  DragButton,
  TrainingListItem,
  StyledCheckBox,
} from 'components';
import Context from 'stores/context';
import { RenderNameExercise, PostGaAnalytics } from 'utils';
import {
  WeightTraining,
  Cardio,
} from './FooterItemExercise';

const DragHandle = sortableHandle(() => <DragButton className="w-drag-button" />);

const Input = ({ id, onChange }) => {
  const [checked, setChecked] = useState(false);

  return (
    <input
      type="checkbox"
      checked={checked}
      id={`item-${id}`}
      onChange={(e) => {
        setChecked(e.target.checked);
        onChange(e.target.checked);
      }}
    />
  );
};

const SortableItem = SortableElement(({ item }) => {
  const context = useContext(Context);
  const { i18n, buildTrainingStore } = context;

  /**
   * Seta o exercicio que sera editado
   * @editExercise
  */
  const editExercise = (e) => {
    const { modalStore } = context;
    buildTrainingStore.setEditItemSelected(item.id);
    modalStore.toggleModal('modalExercise', true);

    const textLabel = e.currentTarget.attributes['data-button'].value;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: textLabel,
    });
  };

  /**
   * Retorna o nome do exercicio
   * @titleExercise
   * @param {object} exercise - Objeto com os dados do exercicio.
   * @return {string} - Retorna o nome do exercicio tratado.
  */
  const titleExercise = (exercise) => {
    if (exercise.cardio) {
      return (exercise.cardio_equipment === null) ? `${i18n.t('heating')}` : `${i18n.t(exercise.cardio_equipment)}`;
    }

    return RenderNameExercise(exercise);
  };

  const renderDataExercise = (exercise) => {
    if (exercise.cardio) {
      return <Cardio exercise={exercise} handleEventEdit={editExercise} />;
    }

    return <WeightTraining exercise={exercise} handleEventEdit={editExercise} />;
  };

  return (
    <TrainingListItem key={item.id}>
      <DragHandle />
      <div className="w-wrap">
        <h4 className="w-title">
          <StyledCheckBox className="w-checkbox">
            <Input
              id={item.id}
              onChange={(checked) => {
                buildTrainingStore.toggleEditAllItemsSelected(item.id, checked);
              }}
            />
            <label htmlFor={`item-${item.id}`}>
              <span className="w-sr-only">{i18n.t('select')}</span>
            </label>
          </StyledCheckBox>

          { titleExercise(item) }

          <Button
            secondary="true"
            rounded="true"
            edit="true"
            data-button="edit"
            onClick={editExercise}
            className="w-edit-button"
          >
            <span>{i18n.t('edit')}</span>
          </Button>
        </h4>

        {renderDataExercise(item)}

      </div>
    </TrainingListItem>
  );
});

export default SortableItem;
