import RulesPaisHelper from './RulesPaisHelper';

import {
  typeDataPremissa,
  TypeRulesFormExercise,
  typeListPaisFormValidate,
} from './IrulesPaisStore';

class RulesPaisFormExercise {
  rootStore = {
    i18n: {
      t: (x: string, y?: any): string => '',
    },
  };

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  hasIsometry(id: string, title: string) {
    return {
      id,
      title,
      warning: {},
      checked: true,
    };
  }

  // Serie minimo e maximo por exercicio
  minAndMaxSeries(currentRules: typeDataPremissa, values: TypeRulesFormExercise) {
    const { series } = values;
    const uniqueValue = RulesPaisHelper.convertToNumber(series);
    const max = currentRules.max_series_by_exercise;
    const min = currentRules.min_series_by_exercise;

    const id = 'min_and_max_series';
    const title = this.rootStore.i18n.t('amount_series');
    const label = this.rootStore.i18n.t('series', { min, max });
    const maxAndMin = this.rootStore.i18n.t('number_of_series', { min, max });

    const warning = RulesPaisHelper.listDefaultWarningComponent(label, maxAndMin);
    const checked = RulesPaisHelper.intervalOneValues({ uniqueValue, min, max });

    return RulesPaisHelper.getTratamentRulesPais({
      id, title, warning, checked,
    });
  }

  minAndmaxInterval(currentRules: typeDataPremissa, values: TypeRulesFormExercise) {
    const label = this.rootStore.i18n.t('interval');
    const id = 'interval';
    const title = this.rootStore.i18n.t('amount_interval');

    const minForm = RulesPaisHelper.convertToNumber(values.min_interval);
    const maxForm = RulesPaisHelper.convertToNumber(values.max_interval);
    const min = currentRules.min_interval;
    const max = currentRules.max_interval;
    const maxAndMin = this.rootStore.i18n.t('interval_max_min_recommend', { min, max });

    if (values.isometry) {
      return this.hasIsometry(id, title);
    }

    const warning = RulesPaisHelper.listDefaultWarningComponent(label, maxAndMin);
    const checked = RulesPaisHelper.intervalTwoValues({
      minForm, maxForm, min, max,
    });

    return RulesPaisHelper.getTratamentRulesPais({
      id, title, warning, checked,
    });
  }

  minAndMaxRepetition(currentRules: typeDataPremissa, values: TypeRulesFormExercise) {
    const label = this.rootStore.i18n.t('repeats');
    const id = 'repeats';
    const title = this.rootStore.i18n.t('amount_repetition');
    const minForm = RulesPaisHelper.convertToNumber(values.min_repetitions);
    const maxForm = RulesPaisHelper.convertToNumber(values.max_repetitions);

    if (values.isometry) {
      return this.hasIsometry(id, title);
    }

    const listWarningRepetition = currentRules.exercise_repetitions.map((repetition) => {
      const min = RulesPaisHelper.convertToNumber(repetition.min);
      const max = RulesPaisHelper.convertToNumber(repetition.max);

      return this.rootStore.i18n.t('number_max_min_repetition', { min, max });
    });

    const warning = RulesPaisHelper.listDefaultWarningComponent(label, listWarningRepetition);
    const matchRepetition = currentRules.exercise_repetitions.filter((repetition) => {
      const min = RulesPaisHelper.convertToNumber(repetition.min);
      const max = RulesPaisHelper.convertToNumber(repetition.max);

      return RulesPaisHelper.intervalTwoValues({
        minForm, maxForm, min, max,
      });
    });

    const checked = !!(matchRepetition.length !== 0);

    return RulesPaisHelper.getTratamentRulesPais({
      id, title, warning, checked,
    });
  }

  getInfoFormWarningPais(data: typeDataPremissa, values: TypeRulesFormExercise) {
    return [
      this.minAndMaxSeries(data, values),
      this.minAndMaxRepetition(data, values),
      this.minAndmaxInterval(data, values),
    ];
  }

  intensificationMethod(data: typeDataPremissa, values: TypeRulesFormExercise) {
    if (values.intensification_method === '') {
      return {
        id: 'intensification_method',
        checked: true,
      };
    }

    const listAllow = data.intensification_methods.not_recommended;
    const result = !listAllow.includes(values.intensification_method);

    return {
      id: 'intensification_method',
      checked: result,
    };
  }

  getInfoFormWarningProfilePais(data: typeDataPremissa, values: TypeRulesFormExercise, listAlertError: string[]) {
    const listRules: typeListPaisFormValidate = {
      min_and_max_series: this.minAndMaxSeries(data, values),
      repeats: this.minAndMaxRepetition(data, values),
      interval: this.minAndmaxInterval(data, values),
      intensification_method: this.intensificationMethod(data, values),
    }

    const listWarning = listAlertError.map((rulesPaisValidate) => {
      return listRules[rulesPaisValidate as keyof typeListPaisFormValidate];
    });

    return listWarning.filter(e => e !== undefined);
  }

  getListSeries(currentRules: typeDataPremissa) {
    return [
      currentRules.min_series_by_exercise,
      currentRules.max_series_by_exercise,
    ];
  }

  getListRepeats(currentRules: typeDataPremissa) {
    return currentRules.exercise_repetitions;
  }

  getListInterval(currentRules: typeDataPremissa) {
    return [
      currentRules.min_interval,
      currentRules.max_interval,
    ];
  }

  getOptionsRulesPais(data: typeDataPremissa) {
    const series = this.getListSeries(data);
    const repeats = this.getListRepeats(data);
    const interval = this.getListInterval(data);

    return {
      series,
      repeats,
      interval,
    };
  }

  getGroupsListIntensification(currentRules?: typeDataPremissa) {
    if (currentRules && currentRules.intensification_methods) {
      return currentRules.intensification_methods;
    }

    return [];
  }
}

export default RulesPaisFormExercise;
