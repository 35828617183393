import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Flex, Text, Box } from 'rebass';
import Context from '../../../stores/context';
import { Modal, Select as BaseSelect } from '../../../components';
import FormSeries from './Forms/Series';
import FormInterval from './Forms/Interval';
import FormRepetitions from './Forms/Repetitions';
import FormIntensificationMethod from './Forms/IntensificationMethod';
import FormDuration from './Forms/Duration';
import FormObservation from './Forms/Observation';
import FormSeconds from './Forms/Seconds';
import FormRest from './Forms/Rest';

const Select = styled(BaseSelect)`
  width: auto;
`;

class ModalEditAllExercises extends Component {
  static contextType = Context;

  render() {
    const { updateList } = this.props;
    const { buildTrainingStore, i18n } = this.context;

    const ChooseForm = () => (
      <Select
        value={buildTrainingStore.chooseForm || ''}
        onChange={(e) => {
          buildTrainingStore.setChooseForm(e.target.value);
        }}
      >
        <option value="">{i18n.t('select')}</option>
        <option value="series">
          {i18n.t('series')}
        </option>
        <option value="interval">
          {i18n.t('interval')}
        </option>
        <option value="repetitions">
          {i18n.t('repeats')}
        </option>
        <option value="intensification_method">
          {i18n.t('intensification_method')}
        </option>
        <option value="duration">
          {i18n.t('minutes')}
        </option>
        <option value="seconds">
          {i18n.t('seconds')}
        </option>
        <option value="rest">
          {i18n.t('rest')}
        </option>
        <option value="observation">
          {i18n.t('profile_observation')}
        </option>
      </Select>
    );

    return (
      <Modal>
        <div className="w-modal-content">
          <div className="w-modal-body">
            {!buildTrainingStore.chooseForm && (
              <>
                <h3 className="w-modal-title">{i18n.t('choice_edit')}</h3>
                <ChooseForm />
              </>
            )}

            {buildTrainingStore.chooseForm === 'series' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_serie')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormSeries updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'interval' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_interval')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormInterval updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'repetitions' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_repetitions')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormRepetitions updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'intensification_method' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_method_intensification')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormIntensificationMethod updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'duration' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_method_duration')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormDuration updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'seconds' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_method_seconds')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormSeconds updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'rest' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_method_rest')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormRest updateList={updateList} />
              </>
            )}

            {buildTrainingStore.chooseForm === 'observation' && (
              <>
                <Flex alignItems="center">
                  <Text mr={[2]}>{i18n.t('edit_method_observation')}</Text>
                  <Box margin={[2]}>
                    <ChooseForm />
                  </Box>
                </Flex>
                <FormObservation updateList={updateList} />
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default observer(ModalEditAllExercises);
