import styled, { css } from 'styled-components';

const ProfileInfo = styled.div`
  position: relative;
  font-family: 'Gotham';
  width:  ${(props) => props.widthValue || '100%'};
  display: block;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: ${(props) => props.mgBottom || '32'}px;

  small {
    display: block;
    color: #808080;
    font-size: ${(props) => props.fontSizeSmall || '13px'};
    font-weight: 500;
    line-height:  ${(props) => props.lineHeight || '2'};
    text-transform: uppercase;
  }

  span {
    font-size: ${(props) => props.fontSizeSpan || '24px'};

    &.lower-letter {
      font-size: 16px;
    }

    &.date-meet {
      font-size: 18px;
    }
  }

  ${(props) => props.highlight
    && css`
      border-radius: 0px 4px 4px 0px;
      display: flex;
      align-items: center;
      padding: ${(props) => props.paddingSpace || '16px 0px 16px 24px'};
      justify-content: space-between;
      background-color: ${(props) => props.theme.colors.darkgray};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        border-radius: 8px;
        background-color: #ffb612;
      }

      small {
        line-height: 25px;
      }
  `};


  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    margin-bottom: 24px;

    ${(props) => props.mobile
      && css`
        width:  ${(props) => props.widthValueMobile || '100%'};
    `};

    small {
      font-size: 12px;
    }

    span {
      font-size: 16px;
    }
  }

  .wrapper-edit-lesion {
    width: 100%;
  }

  .w-edit-button {
    background-color: transparent;
  }
`;

export { ProfileInfo };
export default ProfileInfo;
