import styled, { css } from 'styled-components';

const TrainingTeacherStyle = styled.section`
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 100px;

  .wrapper-buttons {
    display: flex;
    justify-content: center;

    .button-filter-list {
      position: relative;

      .triangle-one {
        display: none;
        position: absolute;
        top: 93px;
        left: 93px;
        z-index: 2;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid ${(props) => props.theme.colors.darkgray};

        &.w-active {
          display: block;
        }
      }
    }
  }

  ${(props) => props.walletUnity
    && css`
      margin-top: 24px;
      padding-left: 0px;
      padding-right: 0px;

      .w-objective {
        padding: 0px 16px;
      }

      .wrapper-buttons {
        justify-content: space-between;
        padding: 0px 16px;

        &.empty {
          justify-content: center;
        }

        &.hidden-create {
          justify-content: center;

          .create-training {
            display: none;
          }
        }
      }

      @media(${(props) => props.theme.mediaScreen.desktop}) {
        .w-objective {
          padding: 0px;
        }

        .wrapper-buttons {
          justify-content: space-around;
        }
      }
    `};

  .wrapper-content-button {
    position: relative;

    &.wallet {
      position: initial;
      display: flex;
    }
  }

  .w-text-status {
    width: 100%;
    color: ${(props) => props.theme.colors.white};
    font-family: 'Gotham';
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 0;
  }

  .w-collapse-button {
    background-color: transparent;
    transform: rotateZ(0);
    transition: transform 0.2s ease-out;
    margin: 0px;

    &.w-collapse-opened {
      transform: rotateZ(180deg);
    }
  }
`;

export default TrainingTeacherStyle;
export { TrainingTeacherStyle };
