import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import {
  Title,
  BoxListInfoTraining,
} from 'components';

const InfoSizeTraingStyle = styled.div`
  width: 100%;
  margin-top: 16px;
`;

function InfoSizeTraing() {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
    programStore,
  } = context;

  const listRulesDivision = () => {
    const list = rulesPaisStore.trainingDurations.map((sizeTraining) => (
      <li className="item" key={sizeTraining.duration}>
        <strong className="item-size">
          {sizeTraining.duration}
          :
          {' '}
        </strong>
        {i18n.t('size_training_info_warning', {
          min: sizeTraining.min,
          max: sizeTraining.max,
        })}
      </li>
    ));

    return list;
  };

  return (
    <InfoSizeTraingStyle>
      <Title
        type={4}
        fontSize="16"
        className="title-info-size"
        mgBottom="8"
        align="left"
      >
        {i18n.t('size_training_info_title')}
      </Title>
      <BoxListInfoTraining>
        <h3 className="title-box-list-info">{programStore.levelName}</h3>
        <ul className="wrapper-list">
          {listRulesDivision()}
        </ul>
      </BoxListInfoTraining>
    </InfoSizeTraingStyle>
  )
}

export default InfoSizeTraing;
