import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  ArrowDown as ArrowDownIcon,
} from './image-country';
import ItemCountry from './ItemCountry';

const CurrentCountryStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  height: 48px;
  justify-content: space-between;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.colors.lightgray};
`;

function CurrentCountry(props) {
  const context = useContext(Context);
  const {
    selectCountryStore,
  } = context;
  const {
    openListCountry,
  } = props;

  return (
    <CurrentCountryStyle onClick={openListCountry}>
      <div className="data-country-current">
        <ItemCountry locale={selectCountryStore.locale} />
      </div>
      <ArrowDownIcon />
    </CurrentCountryStyle>
  );
}

CurrentCountry.defaultProps = {
  openListCountry: () => {},
};

CurrentCountry.propTypes = {
  openListCountry: PropTypes.func,
};

export default observer(CurrentCountry);
