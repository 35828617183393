import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Anamnese, Button, Title, SubTitle, Textarea, Text,
} from '../../../components';
import Context from '../../../stores/context';
import ModalNetwork from '../../../components/modal/modal-network';
import { ConcatUnitInUrl } from '../../../utils';

class EditTrainingObservation extends Component {
  static contextType = Context

  constructor(props) {
    super(props);
    this.sendEditTraining = this.sendEditTraining.bind(this);
    this.setTextObservationExercise = this.setTextObservationExercise.bind(this);
    this.goBackPage = this.goBackPage.bind(this);
    this.getContentText = this.getContentText.bind(this);
  }

  state = {
    trainingComment: false,
  };

  setTextObservationExercise(e) {
    this.setState({ trainingComment: e.currentTarget.value });
  }

  getContentText() {
    const { peopleStore } = this.context;
    const { trainingComment } = this.state;

    if (trainingComment === false) {
      return peopleStore.trainingObservation;
    }

    return trainingComment;
  }

  sendEditTraining = async () => {
    const {
      trainingCreationStore,
      peopleStore,
      modalStore,
      buildTrainingStore,
    } = this.context;
    const { history } = this.props;
    const obj = {
      enrollmentId: peopleStore.getActiveEnrollment.id,
      weightTrainingId: peopleStore.getCurrentWeightTraining.id,
      body: {
        observation: this.getContentText(),
      },
    };

    let result;
    let sync;

    try {
      result = await trainingCreationStore.doRequestSendObservation(obj);
      sync = await buildTrainingStore.doRequestCoachSync(peopleStore.getCurrentWeightTraining.id);
      trainingCreationStore.setEditObservationOrRestriction(false);
      const url = ConcatUnitInUrl('/profile');
      history.push(url);
    } catch (err) {
      modalStore.showModalError(err);
    }

    return {
      result,
      sync,
    };
  }

  goBackPage() {
    const { trainingCreationStore } = this.context;
    const { history } = this.props;
    trainingCreationStore.setEditObservationOrRestriction(false);
    history.goBack();
  }

  render() {
    const { i18n, modalStore, peopleStore } = this.context;

    return (
      <Anamnese>
        <Title type={1} as="h2" fontWeight="358" marginBottom="24">
          {i18n.t('anamnesis_observation_training')}
          <SubTitle fontWeight="325">{i18n.t('anamnesis_observation_training_description')}</SubTitle>
        </Title>

        <Text
          className="warning-info"
          color="#ed7d25"
        >
          {i18n.t('anamnesis_observation_training_warning')}
        </Text>

        <Textarea
          tabIndex="0"
          id="observation-restriction"
          minHeight="169px"
          marginBottom="32px"
          onChange={this.setTextObservationExercise}
          defaultValue={peopleStore.trainingObservation}
        />

        <Button
          backQuestion="true"
          onClick={this.goBackPage}
        >
          {i18n.t('cancel')}
        </Button>
        <Button
          primary
          onClick={this.sendEditTraining}
        >
          {i18n.t('save')}
        </Button>
        {modalStore.modalNetwork && <ModalNetwork tryAgain={this.sendEditTraining} />}
      </Anamnese>
    );
  }
}

EditTrainingObservation.defaultProps = {
  history: {},
};

EditTrainingObservation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};


export default observer(EditTrainingObservation);
