import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  Anamnese, Button, Title, SubTitle, Textarea, Text,
} from '../../../components';
import Context from '../../../stores/context';
import { ConcatUnitInUrl } from '../../../utils';

class NewTrainingObservation extends Component {
  static contextType = Context

  constructor(props) {
    super(props);
    this.setTrainingObservation = this.setTrainingObservation.bind(this);
    this.setTextObservationExercise = this.setTextObservationExercise.bind(this);
    this.goBackPage = this.goBackPage.bind(this);
    this.getContentText = this.getContentText.bind(this);
  }

  state = {
    trainingComment: false,
  };

  setTextObservationExercise(e) {
    this.setState({ trainingComment: e.currentTarget.value });
  }

  getContentText() {
    const { peopleStore } = this.context;
    const { trainingComment } = this.state;

    if (trainingComment === false) {
      return peopleStore.trainingObservation;
    }

    return trainingComment;
  }

  setTrainingObservation() {
    const {
      trainingCreationStore,
    } = this.context;
    const { history } = this.props;
    const route = '/training-creation/restriction';
    const text = this.getContentText();

    trainingCreationStore.setTrainingObservation(text);
    const url = ConcatUnitInUrl(route);
    history.push(url);
  }

  goBackPage() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { i18n, peopleStore } = this.context;

    return (
      <Anamnese>
        <Title type={1} as="h2" fontWeight="358" marginBottom="24">
          {i18n.t('anamnesis_observation_training')}
          <SubTitle fontWeight="325">{i18n.t('anamnesis_observation_training_description')}</SubTitle>
        </Title>

        <Text
          className="warning-info"
          color="#ed7d25"
        >
          {i18n.t('anamnesis_observation_training_warning')}
        </Text>

        <Textarea
          tabIndex="0"
          id="observation-restriction"
          minHeight="169px"
          marginBottom="32px"
          onChange={this.setTextObservationExercise}
          defaultValue={peopleStore.trainingObservation}
        />

        <Button
          backQuestion="true"
          onClick={this.goBackPage}
        >
          {i18n.t('anamnesis_question_back')}
        </Button>
        <Button
          primary
          className="next__comment"
          onClick={this.setTrainingObservation}
        >
          {i18n.t('next')}
        </Button>
      </Anamnese>
    );
  }
}

NewTrainingObservation.defaultProps = {
  history: {},
};

NewTrainingObservation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(NewTrainingObservation);
