import styled from 'styled-components';
import { Box } from 'rebass';
import SearchSVG from '../Button/search.svg';
import DeleteSVG from '../Button/delete.svg';
import PlusSVG from '../Button/plus.svg';
import PlusIconSVG from './icons/plus-icon.svg';
import PlaySVG from '../Button/play.svg';
import FilterSVG from './icons/filter.svg';
import WarningSVG from './icons/warning.svg';
import BelSVG from './icons/icon-bel.svg';
import ProfileSVG from './icons/profile.svg';
import BelNotFoundSvg from './icons/bell-empty.svg';
import CloseWhiteSvg from './icons/close-white.svg';

const BaseIcon = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
`;

export const Search = styled(BaseIcon)`
  background-image: url(${SearchSVG});
`;

export const Delete = styled(BaseIcon)`
  background-image: url(${DeleteSVG});
`;

export const Plus = styled(BaseIcon)`
  background-image: url(${PlusSVG});
`;

export const PlusIcon = styled(BaseIcon)`
  background-image: url(${PlusIconSVG});
`;

export const Play = styled(BaseIcon)`
  background-image: url(${PlaySVG});
`;

export const Filter = styled(BaseIcon)`
  background-image: url(${FilterSVG});
`;

export const Warning = styled(BaseIcon)`
  background-image: url(${WarningSVG});
`;

export const Bel = styled(BaseIcon)`
  background-image: url(${BelSVG});
`;

export const Profile = styled(BaseIcon)`
  background-image: url(${ProfileSVG});
  width: 32px;
  height: 32px;
`;

export const CloseWhite = styled(BaseIcon)`
  background-image: url(${CloseWhiteSvg});
  width: 32px;
  height: 32px;
`;

export const BelNotFound = styled(BaseIcon)`
  background-image: url(${BelNotFoundSvg});
  width: 183px;
  height: 167px;
`;

export default {
  Search,
  Delete,
  Plus,
  Filter,
  Warning,
  BelNotFound,
  Profile,
  CloseWhite,
};
