import styled from 'styled-components';

const SubTitle = styled.small`
  display: block;
  color: #fff;
  font-family: 'Gotham';
  font-size: ${(props) => props.fontSize || '19px'};
  font-weight: ${(props) => props.fontWeight || '400'};
  line-height: 23px;
  padding: 0;
  margin: 0;
  vertical-align: top;
`;

export { SubTitle };
export default SubTitle;
