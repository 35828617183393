import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';

import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import {
  Button,
  FlexDiv,
  Fieldset,
  FormGroup,
  Input,
  Label,
  HeaderSession,
  Column,
  SelectCountry,
} from 'components';
import PlanTotalPass from './PlanTotalPass/PlanTotalPass';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 60px;
  align-items: flex-start;

  .select-company {
    margin-bottom: 42px;

    .title-company {
      font-family: Gotham;
      font-style: normal;
      font-weight: 900;
      font-size: 19px;
      color: #fff;
      margin-bottom: 16px;
    }

    .form-radio {
      display: flex;
    }
  }

  .w-form-search {
    margin-top: 30px;

    .w-form-group {
      @media screen and (min-width: 544px) {
        padding-right: 234px;
      }

      input {
        margin-bottom: 18px;

        @media screen and (min-width: 544px) {
          margin-bottom: 0;
        }
      }

      .w-btn-primary {
        width: 100%;

        @media screen and (min-width: 544px) {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 210px;
          min-width: initial;
        }
      }
    }
  }
`;

class SearchClient extends Component {
  static contextType = Context;

  state = {
    registryNumber: '',
  };

  constructor() {
    super();
    this.onClickToSubmit = this.onClickToSubmit.bind(this);
    this.redirectToProfile = this.redirectToProfile.bind(this);
  }

  componentWillMount() {
    const {
      appStore,
      locationStore,
      bookingsStore,
      rulesPaisStore,
      cronometerStore,
      teacherTrainingStore,
      buildTrainingStore,
    } = this.context;

    appStore.clearALlCache();
    locationStore.setAcronymSelected('');
    bookingsStore.resetDatePeople();
    bookingsStore.reset();
    rulesPaisStore.reset();
    cronometerStore.stop();
    teacherTrainingStore.resetListWorkout();
    buildTrainingStore.reset();
  }

  onClickToSubmit(event) {
    const {
      appStore,
      modalStore,
      peopleStore,
      i18n,
      teacherTrainingStore,
      toggleStore,
      selectCountryStore,
    } = this.context;
    const { registryNumber } = this.state;
    const { locale } = selectCountryStore;

    event.preventDefault();
    modalStore.closeModalError();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:home',
      eventAction: 'click:button',
      eventLabel: 'see_profile',
    });

    appStore.toggleloading(true);

    if(toggleStore.allowRenderCountry('allowRenderSearchSelectCountry')) {
      peopleStore
        .doRequestFindPeopleLocale({ registryNumber, locale })
        .then(() => {
          teacherTrainingStore.setTeacherInteface(false);
          this.validateTotalPass();
        })
        .catch((error) => {
          const msgTitle = i18n.t('student_not_found');
          const msgDescription = i18n.t('validate_cpf');
          const params = {
            hitType: 'event',
            eventCategory: 'workout:home',
            eventAction: 'modal_message',
            eventLabel: 'cpf_not_found',
          };

          modalStore.setModalAnalytics(params);
          appStore.toggleloading(false);
          modalStore.setTitle(msgTitle);
          modalStore.setDescription(msgDescription);
          modalStore.showModalError(error);
        });

    } else {
      peopleStore
        .doRequestFindPeople({ registryNumber })
        .then(() => {
          teacherTrainingStore.setTeacherInteface(false);
          this.validateTotalPass();
        })
        .catch((error) => {
          const msgTitle = i18n.t('student_not_found');
          const msgDescription = i18n.t('validate_cpf');
          const params = {
            hitType: 'event',
            eventCategory: 'workout:home',
            eventAction: 'modal_message',
            eventLabel: 'cpf_not_found',
          };

          modalStore.setModalAnalytics(params);
          appStore.toggleloading(false);
          modalStore.setTitle(msgTitle);
          modalStore.setDescription(msgDescription);
          modalStore.showModalError(error);
        });
    }
  }

  validateTotalPass() {
    const {
      peopleStore,
      modalStore,
      appStore,
    } = this.context;

    if (peopleStore.origin === 'totalpass_smartfit') {
      modalStore.toggleModal('modalTotalPass', true);
      appStore.toggleloading(false);
    } else {
      peopleStore.setPlanSmartFit();
      this.redirectToProfile();
    }
  }

  redirectToProfile() {
    const { history } = this.props;
    const {
      appStore,
    } = this.context;

    const unit = ConcatUnitInUrl('/profile');
    history.push(unit);
    appStore.toggleloading(false);
    appStore.clearObjTemp();
  }

  renderHeader() {
    const { history } = this.props;
    const {
      toggleStore,
      sessionStore,
      i18n,
    } = this.context;

    if (toggleStore.allowRenderWalletUnit()) {
      return (
        <Column sm="12" xs="12">
          <HeaderSession
            statusActionButton={false}
            isBack
            eventButton={() => {
              const url = ConcatUnitInUrl('/select-option-work/list-exercise');
              history.push(url);
            }}
            nameClass="w-btn-back"
            title={sessionStore.renderListNavigator(i18n.t('search_client_title'))}
            subTitle={i18n.t('search_client_title')}
          />
        </Column>

      );
    }

    return false;
  }

  renderOptionSelectCountry() {
    const {
      toggleStore,
    } = this.context;

    if(toggleStore.allowRenderCountry('allowRenderSearchSelectCountry')) {
      return (
        <SelectCountry />
      );
    }

    return '';
  }

  renderTextDocument() {
    const {
      i18n,
      toggleStore,
      selectCountryStore,
    } = this.context;

    if(toggleStore.allowRenderCountry('allowRenderSearchSelectCountry')) {
      selectCountryStore.updateLabelDocument();
      return selectCountryStore.textDocument;
    }

    return i18n.t('search_client_label');
  }

  render() {
    const { i18n, modalStore } = this.context;
    const { history } = this.props;

    return (
      <Fragment>

        {this.renderHeader()}

        <SearchContainer>
          <FlexDiv display="inline-flex" width="auto" minHeight="initial">

            <form className="w-form-search">
              {this.renderOptionSelectCountry()}
              <Fieldset>
                <FormGroup className="w-form-group" noMarginBottom>
                  <Label color="#fff" padding="0px 24px 0px 0px">{this.renderTextDocument()}</Label>
                  <Input
                    type="text"
                    className="search__id"
                    onChange={event => this.setState({ registryNumber: event.target.value })}
                  />
                  <Button
                    primary="true"
                    large="true"
                    className="w-btn-primary"
                    onClick={this.onClickToSubmit}
                  >
                    {i18n.t('search_client_button')}
                  </Button>
                </FormGroup>
              </Fieldset>
            </form>
          </FlexDiv>
        </SearchContainer>
        {modalStore.modalError && <ModalError />}
        {modalStore.modalNetwork && <ModalNetwork tryAgain={this.onClickToSubmit} />}
        {modalStore.modalTotalPass && <PlanTotalPass history={history} />}
      </Fragment>
    );
  }
}

SearchClient.defaultProps = {
  history: {},
};

SearchClient.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(SearchClient);
