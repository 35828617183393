import styled from 'styled-components';

const TrainingListActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 32px;
  padding-bottom: 32px;

  @media screen and (min-width: 544px) {
    flex-wrap: nowrap;
  }

  .w-title {
    width: 100%;
    color: #fff;
    font-family: 'Gotham';
    font-size: 22px;
    font-weight: normal;
    line-height: 29px;
    margin-bottom: 24px;

    @media screen and (min-width: 544px) {
      width: auto;
      margin-bottom: 0;
    }
  }
`;

export { TrainingListActions };
export default TrainingListActions;
