import React, { useContext } from 'react';
import Context from 'stores/context';

function ItemListNotices(props) {
  const context = useContext(Context);
  const {
    notice,
  } = props;
  const {
    noticesStore,
  } = context;

  const showMessage = ({ id, read }) => {
    noticesStore.setNotice(id);
    noticesStore.setShowListNotices(false);
    if (read === false) {
      noticesStore.doRequestPostRead(id)
        .then(() => {})
        .catch(() => {});
    }
  };

  const hasRead = ({ read }) => {
    if (!read) {
      return (
        <div className="list__notices__warning">
          <span className="list__notices__warning--circle" />
        </div>
      );
    }

    return false;
  };

  return (
    <li className="list__notices" onClick={() => showMessage(notice)} aria-hidden="true">
      <div className="list__notices__content">
        <h3 className="list__notices__title">{notice.title}</h3>
        <p className="list__notices__description">{notice.description}</p>
      </div>
      {hasRead(notice)}
    </li>
  );
}

export default ItemListNotices;
