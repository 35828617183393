import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Context from 'stores/context';
import { PostGaAnalytics, ConcatUnitInUrl } from 'utils';

import {
  TabResponsive,
  GridContainer as Container,
  ContainerBox,
  ButtonCircle,
  ButtonFilter,
  Button,
} from 'components';


import FilterListExercise from 'components/FilterListExercise/FilterListExercise';
import FilterListNameTeacher from 'components/FilterListNameTeacher/FilterListNameTeacher';

import {
  Plus as BaseIconPlus,
  PlusIcon as BaseIconPlusWallet,
} from 'components/icons';

import ListWorkoutTeacher from './WorkoutTeacher/ListWorkoutTeacher';
import ListWorkoutSuggestion from './WorkoutSuggetion/ListWorkoutSuggestion';
import ListWorkoutWallet from './WorkoutWallet/ListWorkoutWallet';

const ListWorkoutStyle = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 20px;

  .wrapper-buttons-suggestion {
    display: flex;
    justify-content: center;

    .suggestion {
      display: none;

      &.w-active {
        display: block
      }
    }
  }

  .wrapper-content-button {
    position: relative;
    align-items: center;
    display: flex;
  }

  ${props => props.wallet
    && css`
      .wrapper-buttons-suggestion {
        justify-content: space-between;
        padding: 0px 16px;

        &.hidden-create {
          justify-content: center;

          .create-training {
            display: none;
          }
        }
      }

      .list-item-workout {
        .title {
          padding-left: 18px;
        }
      }

      .wrapper-buttons {
        padding-right: 10px;
      }

      @media(${props => props.theme.mediaScreen.desktop}) {
        .wrapper-buttons-suggestion {
          justify-content: space-around;

          &.hidden-create {
            justify-content: center;

            .create-training {
              display: none;
            }
          }
        }
      }
    `};
`;

const ListWorkout = (props) => {
  const [requestPrograms, SetRequestPrograms] = useState(false);
  const [toggleFilterButton, SetToggleFilterButton] = useState(false);
  const [isActiveButtonFilter, SetIsActiveButtonFilter] = useState(false);
  const [loadingFilterEmphasis, setLoadingFilterEmphasis] = useState(false);
  const [loadingListNameTeacher, setLoadingListNameTeacher] = useState(true);
  const context = useContext(Context);
  const {
    teacherTrainingStore,
    modalStore,
    appStore,
    i18n,
    technicalTrainingStore,
    filterWorkoutStore,
    programStore,
    peopleStore,
    toggleStore,
    walletUnityStore,
    cronometerStore,
    sessionStore,
  } = context;

  const {
    history,
    tabSelected,
  } = props;


  const removeStraing = name => name.replace(/(Coach|Entrenador|Físico)/gm, '');

  const hasAllowRenderEmphasis = () => toggleStore
    .allowRenderCountry('toggleEmphasisTraining');

  useEffect(() => {
    const loadingTabSelected = (result) => {
      if (peopleStore.objective !== null) {
        const name = removeStraing(peopleStore.objective);

        const dataProgram = result
          .allowProgram
          .filter((objProgram) => {
            const nameProgram = removeStraing(objProgram.name);
            return (nameProgram === name);
          });

        const nameProgram = removeStraing(dataProgram[0].name);

        if (name === nameProgram) {
          programStore.setProgramId(dataProgram[0].id);
          teacherTrainingStore.setProgramId(dataProgram[0].id);
          teacherTrainingStore.setProgramName(dataProgram[0].name);
        }
      } else {
        programStore.setProgramId(result.allowProgram[0].id);
      }
    };

    const callListPrograms = async () => {
      appStore.toggleloading(true);
      try {
        const result = await teacherTrainingStore.doRequestListProgramTeacher();
        teacherTrainingStore.setReloadListExercise(true);
        technicalTrainingStore.setReloadListExercise(true);
        walletUnityStore.setReloadListExercise(true);

        if (teacherTrainingStore.teacherInteface === false) {
          loadingTabSelected(result);
        }

        SetRequestPrograms(true);
        appStore.toggleloading(false);
      } catch (err) {
        modalStore.setModalNetworkCallback(callListPrograms.bind(this));
        window.scroll(0, 0);
        modalStore.showModalError(err);
        appStore.toggleloading(false);
      }
    };

    const callListEmphase = async() => {
      try {
        await filterWorkoutStore.doRequestEmphasis();
      } catch(err) {
        modalStore.setModalNetworkCallback(callListEmphase.bind(this));
        window.scroll(0, 0);
        modalStore.showModalError(err);
        appStore.toggleloading(false);
      }
    }

    const callListNameTeacher = async() => {
      try {
        await filterWorkoutStore.doRequestListNameTeacher();
      } catch(err) {
        modalStore.setModalNetworkCallback(callListNameTeacher.bind(this));
        window.scroll(0, 0);
        modalStore.showModalError(err);
        appStore.toggleloading(false);
      }
    }

    const openFilter = () => {
      if (filterWorkoutStore.lengthSelected > 0) {
        SetToggleFilterButton(true);
      }
    };

    if(loadingFilterEmphasis === false && hasAllowRenderEmphasis()) {
      callListEmphase();
      setLoadingFilterEmphasis(true);
    }

    if(loadingListNameTeacher && toggleStore.allowRenderWalletUnit()) {
      callListNameTeacher();
      setLoadingListNameTeacher(false);
    }

    if (requestPrograms === false) {
      callListPrograms();
      openFilter();
    }
  }, [
    teacherTrainingStore,
    modalStore,
    requestPrograms,
    appStore,
    technicalTrainingStore,
    programStore,
    peopleStore,
    filterWorkoutStore,
    walletUnityStore,
    toggleStore,
  ]);

  const hasWallet = () => {
    return toggleStore.allowRenderWalletUnit();
  }

  const hiddenButtonCreate = () => {
    return toggleStore.allowRenderCountry('hiddenButtonCreateTrainingCountry');
  }

  const elementSelected = (id, name) => {
    teacherTrainingStore.setProgramId(id);
    teacherTrainingStore.setProgramName(name);
    teacherTrainingStore.setReloadListExercise(true);
    technicalTrainingStore.setReloadListExercise(true);
    walletUnityStore.resetView();
    walletUnityStore.setReloadListExercise(true);
    programStore.setProgramId(id);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:table',
      eventLabel: `workout_suggestion_${name}`,
    });
  };

  const removeText = (groupExercise) => {
    const text = groupExercise.replace(/(Coach|Entrenador|Físico)/gm, '');
    return text.trim();
  };

  const makeTabLabel = () => {
    const list = teacherTrainingStore.listPrograms.map((item) => {
      const tratamentName = removeText(item.name);

      return {
        id: item.id,
        view: item.id,
        name: tratamentName,
        handleEvent: () => { elementSelected(item.id, tratamentName); },
      };
    });

    return list;
  };

  const renderListExercise = () => {
    if (requestPrograms && tabSelected === 'my_training') {
      return (
        <ListWorkoutTeacher history={history} tabSelected={tabSelected} />
      );
    }

    if (requestPrograms && tabSelected === 'suggestion') {
      return (
        <ListWorkoutSuggestion history={history} tabSelected={tabSelected} />
      );
    }

    if (requestPrograms && tabSelected === 'training_wallet') {
      return (
        <ListWorkoutWallet history={history} tabSelected={tabSelected} />
      );
    }

    return false;
  };

  const createNewTrainig = () => {
    const urlLevelChoose = ConcatUnitInUrl('/anamnesis/level-choose');
    history.push(urlLevelChoose);
    cronometerStore.setFluxAnamnese(true);
  };

  const toggleFilter = () => {
    const toggle = !toggleFilterButton;
    SetToggleFilterButton(toggle);

    if(toggle) {
      SetIsActiveButtonFilter(false);
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:suggestion',
      eventAction: 'click:filter',
      eventLabel: `filter_${toggle}`,
      acronym: window.localStorage.getItem('w-acronym'),
    });
  };

  const clickShowListTeacher = () => {
    const toggle = !isActiveButtonFilter;

    if(toggle) {
      SetToggleFilterButton(false);
    }

    SetIsActiveButtonFilter(toggle);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:suggestion',
      eventAction: 'click:filter',
      eventLabel: `filter_teacher_${toggle}`,
      acronym: window.localStorage.getItem('w-acronym'),
    });
  }

  const renderButtonCreate = () => {
    if(hiddenButtonCreate()) {
      return '';
    }

    if (hasWallet()) {
      return (
        <Button
          primary
          icon
          className="w-btn-primary create-training"
          onClick={() => {
            createNewTrainig();
          }}
        >
          <div className="wrapper-content-button wallet">
            <BaseIconPlusWallet className="icon-plus" />
            <span className="text-button-register">
              {i18n.t('register_create_training')}
            </span>
          </div>
        </Button>
      );
    }

    return (
      <ButtonCircle
        variant="secondSquare"
        className={`w-btn-primary ${tabSelected}`}
        onClick={() => {
          createNewTrainig();
        }}
      >
        <div className="wrapper-content-button">
          <BaseIconPlus className="icon-plus" />
          <span className="text-button-register">
            {i18n.t('register_create_new_training')}
          </span>
        </div>

      </ButtonCircle>
    );
  };

  const showStyleButtonCreateTrainingWallet = () => {
    if(hiddenButtonCreate()) {
      return 'hidden-create';
    }

    return '';
  }

  const renderListFilterExercise = () => {
    const showButton = hiddenButtonCreate();
    if (hasWallet()) {
      return   (
        <FilterListNameTeacher
        clickShowListTeacher={clickShowListTeacher}
        hasButtonCreate={!showButton}
        isActiveButtonFilter={isActiveButtonFilter}
      />
      );
    }
  }

  return (
    <Container>
      <ListWorkoutStyle wallet={hasWallet()}>
        <TabResponsive
          tabList={makeTabLabel()}
          selected={teacherTrainingStore.programId}
          nameLabel={teacherTrainingStore.programName}
          showDropDown={false}
        />
        <ContainerBox padding="32px 0px">
          <div className={`wrapper-buttons-suggestion ${showStyleButtonCreateTrainingWallet()}`}>
            {renderButtonCreate()}

            {renderListFilterExercise()}

            <ButtonFilter
              toggleFilterButton={toggleFilterButton}
              toggleFilter={toggleFilter}
              lengthSelected={filterWorkoutStore.lengthSelected}
            />
          </div>

          <FilterListExercise
            toggleFilterButton={toggleFilterButton}
            tabSelected={tabSelected}
          />

          {renderListExercise()}
        </ContainerBox>
      </ListWorkoutStyle>
    </Container>
  );
};

ListWorkout.defaultProps = {
  history: {},
  tabSelected: 'my_training',
};

ListWorkout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  tabSelected: PropTypes.string,
};

export default observer(ListWorkout);
