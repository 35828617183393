import React, { Component } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';

import LogoutSVG from './logout-header.svg';

const Logout = styled.button`
  background-image: url(${LogoutSVG});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #111;
  padding: 24px 14px;
  border: none;
`;

class ButtonLogout extends Component {
  static contextType = Context;

  constructor() {
    super();
    this.openModalLogout = this.openModalLogout.bind(this);
  }

  openModalLogout() {
    const { modalStore } = this.context;
    modalStore.setTitle('Encerrar sessão?');
    modalStore.setDescription('Deseja sair da sua conta? ');
    modalStore.toggleModal('modalLogout', true);
  }

  render() {
    return (
      <Logout onClick={this.openModalLogout} />
    );
  }
}

export default ButtonLogout;
export { ButtonLogout };
