/* eslint react/prop-types: 0, react/jsx-props-no-spreading: 0 */
import React from 'react';
import { Box, Flex } from 'rebass';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background: rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
  text-align: center;

  .wrapper-button-modal {
    display: flex;
    margin-top: 20px;
  }
`;

const Content = styled(Box)`
  border-radius: 4px;
`;

const setWidthWrapper = (styleCss) => {
  if (styleCss && styleCss.widthWrapper) {
    return styleCss.widthWrapper;
  }

  return ['272px', '400px'];
};

const setPyAndPx = (styleCss, typePadding) => {
  if (typePadding === 'py') {
    return styleCss && styleCss.py ? styleCss.py : [3];
  }

  if (typePadding === 'px') {
    return styleCss && styleCss.py ? styleCss.py : [4];
  }

  return '';
};

const bgColor = (styleCss) => ((styleCss && styleCss.bgColor) ? styleCss.bgColor : 'white');

function ModalWrapper(props) {
  const {
    styleCss,
    children,
  } = props;

  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Content
        bg={bgColor(styleCss)}
        py={setPyAndPx(styleCss, 'py')}
        px={setPyAndPx(styleCss, 'px')}
        width={setWidthWrapper(styleCss)}
      >
        {children}
      </Content>
    </Wrapper>
  );
}

ModalWrapper.defaultProps = {
  styleCss: {},
  children: [],
};

ModalWrapper.propTypes = {
  styleCss: PropTypes.oneOfType([
    PropTypes.shape({
      widthWrapper: PropTypes.string,
      bgColor: PropTypes.string,
    }),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ModalWrapper;
