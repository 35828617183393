import React, { useContext } from 'react';
import Context from 'stores/context';
import {
  ListInfoModalStyle,
} from 'components';
import HeaderNotices from './HeaderNotices';
import ItemListNotices from './ItemListNotices';

const ListNotices = () => {
  const context = useContext(Context);
  const {
    noticesStore,
    i18n,
  } = context;


  return (
    <div>
      <HeaderNotices title={i18n.t('notifications')} />
      <ListInfoModalStyle>
        {noticesStore.getListNotices.map((notice, index) => (
          <ItemListNotices key={`item-${notice.id}`} index={index} notice={notice} />
        ))}
      </ListInfoModalStyle>
    </div>
  );
};

export default ListNotices;
