import React, { useContext, useState, useEffect } from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { Formik } from 'formik';
import {
  Fieldset,
  StyledFormExercise,
} from 'components';
import Context from 'stores/context';
import { ValidateHasHit, PostGaAnalytics } from 'utils';
import FormExercise from '../../../FormExercise/FormExercise';

const FormEditExerciseWeightTraining = ({ updateList }) => {
  const context = useContext(Context);
  const {
    formExercisesStore,
    buildTrainingStore,
    modalStore,
    i18n,
    appStore,
    rulesPaisStore,
  } = context;

  const [checkedIsometry, setCheckedIsometry] = useState(false);
  const [activePostExercise, setActivePostExercise] = useState(true);
  const [selectedPaisOrCustomize, setSelectedPaisOrCustomize] = useState('customize');

  const currentItem = buildTrainingStore.getEditItemSelected || {};

  const initialValues = {
    id: currentItem.id,
    series: currentItem.series,
    min_interval: currentItem.min_interval,
    max_interval: currentItem.max_interval,
    min_repetitions: currentItem.min_repetitions,
    max_repetitions: currentItem.max_repetitions,
    duration: currentItem.duration,
    intensification_method: currentItem.intensification_method,
    observation: currentItem.observation,
    isometry: currentItem.isometry,
    rest: currentItem.rest,
  };

  useEffect(() => {
    const showViewIsometry = !!(currentItem.isometry);
    setCheckedIsometry(showViewIsometry);
  }, [currentItem]);

  const reloadPostData = () => {
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;

    // eslint-disable-next-line no-use-before-define
    handlePostForm(values, setSubmitting);
  };

  const updateExerciseWeightTraining = (values, setSubmitting) => {
    appStore.clearObjTemp();

    setActivePostExercise(false);

    let newValues = cloneDeep(currentItem);

    if (buildTrainingStore.hasDiff) {
      const index = findIndex(buildTrainingStore.diff, { id: values.id });
      newValues = buildTrainingStore.diff[index];
    }

    const obj = formExercisesStore.makeEditWeightTrainingPattern(values, checkedIsometry);
    newValues = {
      ...newValues,
      ...obj,
    };

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    if (!buildTrainingStore.hasDiff) {
      buildTrainingStore.setExercisesToEdit([newValues]);
    }

    if (buildTrainingStore.hasDiff) {
      buildTrainingStore.doRequestPatchTrainingExercises(
        buildTrainingStore.getExercisesUpdated,
      ).then(() => {
        buildTrainingStore.diff = [];
        updateList();
        appStore.clearObjTemp();
        modalStore.toggleModal('modalExercise', false);
        modalStore.closeModalError();
      }).catch((error) => {
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(reloadPostData);
        modalStore.showModalError(error);
        setActivePostExercise(true);
      });
    }

    setSubmitting(false);
  };

  const showErrosPais = (values) => {
    const errors = {};

    if (!values.series) {
      errors.series = i18n.t('warning_series');
    }
    if (!values.interval && checkedIsometry === false) {
      errors.interval = i18n.t('warning_interval');
    }

    if (!values.repeats && checkedIsometry === false) {
      errors.repeats = i18n.t('warning_repetition');
    }

    return errors;
  };

  const showErrosCustomize = (values) => {
    const errors = {};

    if (!values.series) {
      errors.series = i18n.t('warning_series');
    }

    if (!values.min_interval && checkedIsometry === false) {
      errors.min_interval = i18n.t('warning_interval_min');
    }

    if (!values.min_repetitions && checkedIsometry === false) {
      errors.min_repetitions = i18n.t('warning_repetition_min');
    }

    if (!values.duration && checkedIsometry) {
      errors.duration = i18n.t('warning_duration');
    }
    if (!values.rest && checkedIsometry) {
      errors.rest = i18n.t('warning_rest');
    }

    return errors;
  };

  const showErros = (values) => {
    const showPais = rulesPaisStore.showRulesPais('form');
    const hasPremissa = (selectedPaisOrCustomize === 'premissas');

    if (hasPremissa && showPais) {
      return showErrosPais(values, i18n);
    }

    return showErrosCustomize(values);
  };

  const toggleIsometry = () => {
    const toggleChecked = !checkedIsometry;
    setCheckedIsometry(toggleChecked);
  };

  const cancelEdit = () => {
    buildTrainingStore.editAllItemsSelected = [];
    modalStore.toggleModal('modalExercise', false);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workout_details',
      eventAction: 'click:button',
      eventLabel: 'return',
    });
  };

  const handlePostForm = (values, setSubmitting) => {
    if (activePostExercise) {
      updateExerciseWeightTraining(values, setSubmitting);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:workout_details',
        eventAction: 'click:button',
        eventLabel: 'save',
      });
    }
  };

  const selectedTabPaisOrCustomize = (labelSelected) => {
    setSelectedPaisOrCustomize(labelSelected);
  };

  const structForm = (props) => {
    const {
      handleSubmit,
      setErrors,
      values,
    } = props;

    rulesPaisStore.setWarningFormExercise(values);

    return (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <legend>{currentItem.title}</legend>

          <FormExercise
            infoLabel={props}
            checkedIsometry={checkedIsometry}
            toggleIsometry={toggleIsometry}
            setErrors={setErrors}
            listIntensification={buildTrainingStore.intensificationMethods}
            cancelForm={cancelEdit}
            textCancel="cancel"
            isCardio={false}
            isFormEdit
            isHit={ValidateHasHit(currentItem)}
            selectedTabPaisOrCustomize={selectedTabPaisOrCustomize}
            selectedPaisOrCustomize={selectedPaisOrCustomize}
          />

        </Fieldset>
      </form>
    );
  };

  return (
    <StyledFormExercise>
      <Formik
        initialValues={initialValues}
        validate={values => showErros(values)}
        onSubmit={(values, { setSubmitting }) => {
          handlePostForm(values, setSubmitting);
        }}
      >
        {props => structForm(props)}
      </Formik>
    </StyledFormExercise>
  );
};

export default FormEditExerciseWeightTraining;
