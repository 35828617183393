import React, { useContext } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ListNameTeacherStyledd = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  display: none;
  margin-top: 10px;
  position: absolute;
  top: -23px;
  left: 60px;
  margin-top: 21px;
  width: 252px;
  font-size: ${(props) => props.theme.fontSizes[1]};


  &.w-active {
    display: block;
  }

  .wrapper-buttons-filters {
    height: 400px;
    overflow-x: hidden;
    list-style-type: none;
  }

  .item {
    color: ${(props) => props.theme.colors.black};
    padding: 13px 8px;
    font-size: ${(props) => props.theme.fontSizes[1]};
    margin: 4px 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.active {
      background-color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

function ListNameTeacher(props) {
  const context = useContext(Context);
  const {
    walletUnityStore,
    filterWorkoutStore,
  } = context;

  const {
    showListNameTeacher,
  } = props;

  const selectTeacher = (teacher) => {
    filterWorkoutStore.setSelectTeacher(teacher);
    walletUnityStore.resetView();
    walletUnityStore.plusWorkout();
  }

  const hasTeacherSelected = (teacher) => {
    if (filterWorkoutStore.selecedTeacher !== undefined
      && filterWorkoutStore.selecedTeacher.id === teacher.id) {
      return true;
    }

    return false;
  }

  const renderListTeacher = () => filterWorkoutStore.groupTeacher.map((teacher) => (
    <li
      key={teacher.id}
      className={hasTeacherSelected(teacher) ? 'item active' : 'item'}
      role="presentation"
      onClick={() => selectTeacher(teacher)}
    >
      {teacher.name}
    </li>
  ));

  return (
    <ListNameTeacherStyledd
      className={showListNameTeacher ? 'w-active' : ''}
    >
      <ul className="wrapper-buttons-filters">
        {renderListTeacher()}
      </ul>
    </ListNameTeacherStyledd>
  );
}

ListNameTeacher.defaultProps = {
  showListNameTeacher: false,
};

ListNameTeacher.propTypes = {
  showListNameTeacher: PropTypes.bool,
};

export default observer(ListNameTeacher);
