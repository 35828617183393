import React, { Component, Fragment } from 'react';

import { FlexDiv, Title } from '../../../components';

import Context from '../../../stores/context';

export class EmptyTraining extends Component {
  static contextType = Context;

  render() {
    const { i18n } = this.context;

    return (
      <Fragment>
        <FlexDiv minHeight="initial">
          <Title type={1} as="h2">
            {i18n.t('empty_training')}
            <small>{i18n.t('empty_training_description')}</small>
          </Title>
        </FlexDiv>
      </Fragment>
    );
  }
}

export default EmptyTraining;
