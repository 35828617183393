import { observable, action, decorate } from 'mobx';
import { PostGaAnalytics } from 'utils';

class ModalStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.modalError = false;
    this.modalLogout = false;
    this.modalExercise = false;
    this.modalAddExercise = true;
    this.modalEditAllExercises = false;
    this.modalAnamnesis = false;
    this.modalDelete = false;
    this.modalSuccess = false;
    this.modalSync = false;
    this.modalSuccessEdit = false;
    this.modalCreateEnrollment = false;
    this.modalSendAnamnesis = false;
    this.modalFormTeacher = false;
    this.modalFormTeacherSucess = false;
    this.modalNetwork = false;
    this.modalNotify = false;
    this.modalNotices = false;
    this.modalDataProfile = false;
    this.modalVideoExercise = false;
    this.modalTotalPass = false;
    this.modalMessage = false;
    this.title = '';
    this.description = '';
    this.styleCss = '';
    this.modalNetworkCallBack = () => {};
    this.modalDeleteCallBack = () => {};
    this.modalAnalytics = {};
    this.modalErrorPremissas = false;
    this.modalDateTraining = false;
    this.modalSuccessPremissas = false;
    this.modalSuccessSend = false;
    this.modalSuccesUnit = false;
    this.modalSelectedUnit = true;
    this.modalInpuTraining = false;
  }

  reset() {
    this.title = '';
    this.description = '';
    this.styleCss = '';
  }

  setStyleCss(objStyle) {
    this.styleCss = objStyle;
  }

  setTitle(title) {
    this.title = title;
  }

  setDescription(desc) {
    this.description = desc;
  }

  setModalAnalytics(paramsGa) {
    this.modalAnalytics = paramsGa;
  }

  toggleModal(modalTarget, value) {
    this[modalTarget] = value;

    if (value) {
      window.localStorage.setItem('w-modal-opened', modalTarget);
      this.rootStore.feedBackBarStore.hiddenFeedBackBar();
    } else {
      window.localStorage.removeItem('w-modal-opened');
    }
  }

  closeModalError() {
    this.toggleModal('modalNetwork', false);
    this.toggleModal('modalError', false);
    this.resetModalAnalytics();
  }

  setModalDeleteCallBack(callback) {
    this.modalDeleteCallBack = callback;
  }

  setModalNetworkCallback(callback) {
    this.modalNetworkCallBack = callback;
  }

  execModalDeleteCallBack() {
    this.modalDeleteCallBack();
  }

  execModalNetworkCallBack() {
    this.modalNetworkCallBack();
  }

  showModalError(error, view) {
    if (error.message === 'networkoffline') {
      this.toggleModal('modalNetwork', true);
    }

    if (error.message !== 'networkoffline') {
      const hasToken = this.rootStore.sessionStore.validateToken(error);

      if (hasToken || view === 'login') {
        this.toggleModal('modalError', true);
      } else {
        this.rootStore.sessionStore.removeStorageLogin();
      }
    }
  }

  resetModalAnalytics() {
    this.modalAnalytics = {};
  }

  postDataGa() {
    const params = this.modalAnalytics;
    PostGaAnalytics('send', params);
    this.resetModalAnalytics();
  }
}

decorate(ModalStore, {
  title: observable,
  description: observable,
  modalSuccess: observable,
  modalFormTeacher: observable,
  modalFormTeacherSucess: observable,
  modalDelete: observable,
  modalError: observable,
  modalLogout: observable,
  modalExercise: observable,
  modalAddExercise: observable,
  modalEditAllExercises: observable,
  modalAnamnesis: observable,
  modalSync: observable,
  modalSuccessEdit: observable,
  modalCreateEnrollment: observable,
  modalSendAnamnesis: observable,
  modalNetwork: observable,
  modalNetworkCallBack: observable,
  modalDeleteCallBack: observable,
  modalNotify: observable,
  modalNotices: observable,
  modalDataProfile: observable,
  modalVideoExercise: observable,
  modalTotalPass: observable,
  modalErrorPremissas: observable,
  modalDateTraining: observable,
  modalSuccessPremissas: observable,
  modalSuccessSend: observable,
  modalMessage: observable,
  modalSuccesUnit: observable,
  modalSelectedUnit: observable,
  modalInpuTraining: observable,
  execModalNetworkCallBack: action,
  execModalDeleteCallBack: action,
  setModalDeleteCallBack: action,
  setModalNetworkCallback: action,
  toggleModal: action,
  setTitle: action,
  setDescription: action,
  setColorHeader: action,
  setCloseCallback: action,
  showModalError: action,
  postDataGa: action,
  resetModalAnalytics: action,
  setModalAnalytics: action,
});

export const ModalSchema = {
  title: true,
  description: true,
  modalLogout: true,
  modalExercise: true,
  modalAddExercise: true,
  modalEditAllExercises: true,
  modalAnamnesis: true,
  modalDelete: true,
  modalSuccess: true,
  modalSync: true,
  modalSuccessEdit: true,
  modalCreateEnrollment: true,
  modalSendAnamnesis: true,
  modalFormTeacher: true,
  modalFormTeacherSucess: true,
  modalNetwork: false,
  modalMessage: true,
};

export default ModalStore;
