import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import List from './List';

const FilterListExerciseStyle = styled.div`
  background-color: #3B3B40;
  color: ${(props) => props.theme.colors.white};
  display: none;
  margin-top: 10px;

  &.w-active {
    display: block;
  }

  .wrapper-buttons-filters-title {
    padding-left: 20px;
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes[1]};
    line-height: 24px;
    color: #FFFFFF;
  }

  .wrapper-buttons-filters {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 12px 12px;
  }

  .item {
    background: #141415;
    border-radius: 8px;
    color: ${(props) => props.theme.colors.white};
    padding: 8px 16px;
    margin: 4px 4px;
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;

    &.active {
      background: ${(props) => props.theme.colors.yellow};
      color: ${(props) => props.theme.colors.black};
    }
  }

  ${(props) => props.wallet
    && css`
      margin-top: 21px;

      .item {
        margin: 4px 4px;
      }
    `};
`;

function FilterListExercise(props) {
  const context = useContext(Context);
  const {
    filterWorkoutStore,
    teacherTrainingStore,
    technicalTrainingStore,
    walletUnityStore,
    toggleStore,
  } = context;
  const {
    toggleFilterButton,
    tabSelected,
  } = props;

  const getObject = () => {
    if (tabSelected === 'my_training') {
      return teacherTrainingStore;
    }

    if (tabSelected === 'training_wallet') {
      return walletUnityStore;
    }

    return technicalTrainingStore;
  };

  const resetList = () => {
    const obj = getObject();
    obj.resetView();
    obj.plusWorkout();
  };

  const selectedGroupLabel = (label) => {
    filterWorkoutStore.setGroupLabel(label);
    resetList();
  };

  const selectedFrequency = (frequency) => {
    filterWorkoutStore.setFrequency(frequency);
    resetList();
  };

  const setToggleIntensifcation = () => {
    filterWorkoutStore.setIntensificationMethod();
    resetList();
  };

  const selectedLevel = (level, levelId) => {
    filterWorkoutStore.setLevel(level, levelId);
    resetList();
  };

  const selectedRestriction = () => {
    filterWorkoutStore.setRestriction();
    resetList();
  };

  const selectEmphasis = (emphasis) => {
    filterWorkoutStore.setSelecedtBodyEmphasis(emphasis);
    resetList();
  }

  const hasEmphasis = () => filterWorkoutStore.hasEmphasis();

  const selectSizeTraining = (sizeTraining) => {
    filterWorkoutStore.setSizeTraining(sizeTraining);
    resetList();
  }

  return (
    <FilterListExerciseStyle
      className={toggleFilterButton ? 'w-active' : ''}
      wallet={toggleStore.allowRenderWalletUnit()}
    >
      <List
        selectedGroupLabel={selectedGroupLabel}
        selectedFrequency={selectedFrequency}
        setToggleIntensifcation={setToggleIntensifcation}
        selectedLevel={selectedLevel}
        selectedRestriction={selectedRestriction}
        selectEmphasis={selectEmphasis}
        hasEmphasis={hasEmphasis()}
        selectSizeTraining={selectSizeTraining}
      />
    </FilterListExerciseStyle>
  );
}

FilterListExercise.defaultProps = {
  toggleFilterButton: false,
  tabSelected: 'my_training',
};

FilterListExercise.propTypes = {
  toggleFilterButton: PropTypes.bool,
  tabSelected: PropTypes.string,
};

export default observer(FilterListExercise);
