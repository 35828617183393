import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { Formik } from 'formik';
import {
  Button, Fieldset, FormGroup, Textarea as BaseTextarea, Label,
} from '../../../../components';
import Context from '../../../../stores/context';

const Textarea = styled(BaseTextarea)`
  width: auto;
`;

const StyledFormEditExercise = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 544px) {
    max-width: 480px;
  }

  legend {
    color: #000;
    font-family: 'Gotham';
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
    text-align: center;
    margin-bottom: 34px;

    @media screen and (min-width: 544px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .w-training-main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    > div {
      display: inline-flex;
      width: auto;

      &:first-child {
        @media screen and (min-width: 544px) {
          width: 60px;
        }
      }

      @media screen and (min-width: 544px) {
        max-width: 105px;
      }
    }

    label {
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      color: #808080;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
    }
  }

  label {
    font-size: 13px;
    line-height: 16px;
  }
`;

export const FormEditExercise = ({ updateList }) => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    modalStore,
    i18n,
    appStore,
  } = context;

  const reloadPostData = () => {
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;

    // eslint-disable-next-line no-use-before-define
    updateObservation(values, setSubmitting);
  };

  const updateObservation = (values, setSubmitting) => {
    appStore.clearObjTemp();

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    setTimeout(() => {
      buildTrainingStore.clickEditAllItemsSelected([{
        observation: values.observation,
      }]);

      if (buildTrainingStore.hasDiff) {
        buildTrainingStore.doRequestPatchTrainingExercises(
          buildTrainingStore.getExercisesUpdated,
          'observation',
        ).then(() => {
          appStore.clearObjTemp();
          buildTrainingStore.diff = [];
          buildTrainingStore.editAllItemsSelected = [];
          updateList();
          modalStore.closeModalError();
          modalStore.toggleModal('modalEditAllExercises', false);
        }).catch((error) => {
          window.scroll(0, 0);
          modalStore.setModalNetworkCallback(reloadPostData);
          modalStore.showModalError(error);
        });
      }

      setSubmitting(false);
    }, 400);
  };

  return (
    <StyledFormEditExercise>
      <Formik
        initialValues={{
          observation: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateObservation(values, setSubmitting);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
              <Fieldset>
                <div className="w-training-main-info">
                  <FormGroup>
                    <Box m={3}>
                      <Label htmlFor="observation">{i18n.t('profile_observation')}</Label>
                      <Textarea
                        id="observation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.observation}
                      />

                    </Box>
                  </FormGroup>
                </div>

                <FormGroup formActions="true" className="w-form-actions" noMarginBottom="true">
                  <Button
                    tertiary="true"
                    color="#d52b1e"
                    onClick={() => {
                      buildTrainingStore.editAllItemsSelected = [];
                      modalStore.toggleModal('modalEditAllExercises', false);
                    }}
                  >
                    {i18n.t('cancel')}
                  </Button>
                  <Button
                    type="submit"
                    primary="true"
                    disabled={isSubmitting}
                  >
                    {i18n.t('saved_update')}
                  </Button>
                </FormGroup>
              </Fieldset>
            </form>
        )}
      </Formik>
    </StyledFormEditExercise>
  );
};

export default FormEditExercise;
