import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import {
  Fieldset,
  StyledFormExercise,
} from 'components';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import FormExercise from '../../FormExercise/FormExercise';

class FormAddExerciseWeightTraining extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.toggleIsometry = this.toggleIsometry.bind(this);
    this.selectedTabPaisOrCustomize = this.selectedTabPaisOrCustomize.bind(this);
    this.cancelAddExercise = this.cancelAddExercise.bind(this);
    this.state = {
      checkedIsometry: false,
      activePostExercise: true,
      selectedPaisOrCustomize: 'premissas',
    };
  }

  getValidationForm() {
    return {
      series: '',
      min_interval: '',
      max_interval: '',
      min_repetitions: '',
      max_repetitions: '',
      intensification_method: '',
      observation: '',
      duration: '',
      rest: '',
      repeats: '',
      interval: '',
    };
  }

  postWeightTraining(values, setSubmitting, history) {
    const {
      addExercisesStore,
      modalStore,
      i18n,
      appStore,
      formExercisesStore,
      buildTrainingStore,
    } = this.context;

    const {
      checkedIsometry,
    } = this.state;

    this.setState({ activePostExercise: false });

    appStore.clearObjTemp();

    const category = 'weightTraining';
    const items = [];
    let msgTitle;

    addExercisesStore.getExercisesWeightTraining.forEach((item) => {
      const obj = formExercisesStore.makeAddObjectTraining({ item, values, checkedIsometry });
      items.push(obj);
    });

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    addExercisesStore.doRequestCreateAllExercises(items, category).then(() => {
      msgTitle = i18n.t('exercise_add_success');

      modalStore.setTitle(msgTitle);
      modalStore.setDescription('');
      modalStore.toggleModal('modalSuccess', true);
      modalStore.closeModalError();

      appStore.clearObjTemp();
      const url = ConcatUnitInUrl('/build-training/build-training-list');
      history.push(url);
    }).catch((err) => {
      msgTitle = i18n.t('error_post_exercise');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription('');
      modalStore.showModalError(err);

      this.setState({ activePostExercise: true });
    });

    setSubmitting(false);
  }

  handlePostForm(values, setSubmitting, history) {
    const {
      activePostExercise,
    } = this.state;
    if (activePostExercise) {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:workout_details',
        eventAction: 'click:button',
        eventLabel: 'save',
      });

      this.postWeightTraining(values, setSubmitting, history);
    }

    return false;
  }

  reloadPostData(history) {
    const { appStore } = this.context;
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;
    this.handlePostForm(values, setSubmitting, history);
  }

  showErrosCustomize(values, i18n) {
    const errors = {};
    const {
      checkedIsometry,
    } = this.state;

    const {
      addExercisesStore,
    } = this.context;

    if (addExercisesStore.hasHitOrKlass) {
      return {};
    }

    if (!values.series) {
      errors.series = i18n.t('warning_series');
    }

    if (!values.min_interval && checkedIsometry === false) {
      errors.min_interval = i18n.t('warning_interval_min');
    }
    if (!values.min_repetitions && checkedIsometry === false) {
      errors.min_repetitions = i18n.t('warning_repetition_min');
    }

    if (!values.duration && checkedIsometry) {
      errors.duration = i18n.t('warning_duration');
    }

    if (!values.rest && checkedIsometry) {
      errors.rest = i18n.t('warning_rest');
    }

    return errors;
  }

  showErrosPais(values, i18n) {
    const errors = {};
    const {
      checkedIsometry,
    } = this.state;

    const {
      addExercisesStore,
    } = this.context;

    if (addExercisesStore.hasHitOrKlass) {
      return {};
    }

    if (!values.series) {
      errors.series = i18n.t('warning_series');
    }

    if (!values.repeats && checkedIsometry === false) {
      errors.repeats = i18n.t('warning_repetition');
    }

    if (!values.interval && checkedIsometry === false) {
      errors.interval = i18n.t('warning_interval');
    }

    return errors;
  }

  allowRenderRulesPais() {
    const {
      toggleStore,
    } = this.context;

    return toggleStore.validateToggle({
      toggleUnitBlock: 'toogleUnitPaisBlock',
      toggleUnitAllow: 'toogleUnitPaisAllow',
      toggleCountry: 'allowPaisCountry',
      }
    );
  }

  showErros(values, i18n) {
    const {
      rulesPaisStore,
    } = this.context;
    const {
      selectedPaisOrCustomize,
    } = this.state;

    const showPais = rulesPaisStore.showRulesPais('form');
    const hasPremissa = (selectedPaisOrCustomize === 'premissas');
    const hasAllowRenderPremissas = this.allowRenderRulesPais();

    if (showPais && hasAllowRenderPremissas && hasPremissa) {
      return this.showErrosPais(values, i18n);
    }

    return this.showErrosCustomize(values, i18n);
  }

  selectedTabPaisOrCustomize(labelSelected) {
    this.setState({ selectedPaisOrCustomize: labelSelected });
  }

  toggleIsometry() {
    const { checkedIsometry } = this.state;
    const toggleChecked = !checkedIsometry;

    this.setState({ checkedIsometry: toggleChecked });
  }

  cancelAddExercise() {
    const { history } = this.props;
    const { addExercisesStore } = this.context;
    addExercisesStore.resetValueListExercise();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workout_details',
      eventAction: 'click:button',
      eventLabel: 'return',
    });

    const url = ConcatUnitInUrl('/add-exercises/exercises-select');
    history.push(url);
  }

  structForm(props, i18n, label, intensificationMethods, isHit, rulesPaisStore) {
    const {
      handleSubmit,
      setErrors,
    } = props;

    const {
      checkedIsometry,
      selectedPaisOrCustomize,
    } = this.state;

    rulesPaisStore.setWarningFormExercise(props.values);

    return (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <legend>
            {i18n.t('add_exercise_training')}
            {' '}
            {label}
          </legend>

          <FormExercise
            infoLabel={props}
            checkedIsometry={checkedIsometry}
            toggleIsometry={this.toggleIsometry}
            setErrors={setErrors}
            listIntensification={intensificationMethods}
            cancelForm={this.cancelAddExercise}
            textCancel="back_navigate"
            isCardio={false}
            isHit={isHit}
            isFormEdit={false}
            selectedPaisOrCustomize={selectedPaisOrCustomize}
            selectedTabPaisOrCustomize={this.selectedTabPaisOrCustomize}
          />
        </Fieldset>
      </form>
    );
  }

  render() {
    const {
      buildTrainingStore,
      modalStore,
      i18n,
      addExercisesStore,
      rulesPaisStore,
    } = this.context;

    const { history } = this.props;
    const { intensificationMethods } = buildTrainingStore;
    const isHit = addExercisesStore.hasHitOrKlass;
    const label = buildTrainingStore.currentTraining.label || '';
    const setInitialValues = this.getValidationForm();

    return (
      <StyledFormExercise>
        <Formik
          initialValues={setInitialValues}
          validate={values => this.showErros(values, i18n, rulesPaisStore)}
          onSubmit={(values, { setSubmitting }) => {
            this.handlePostForm(values, setSubmitting, history);
          }}
        >
          {props => this.structForm(props, i18n, label, intensificationMethods, isHit, rulesPaisStore)}
        </Formik>
        {modalStore.modalError && (
          <ModalError />
        )}

        {modalStore.modalNetwork && <ModalNetwork tryAgain={() => { this.reloadPostData(history); }} />}
      </StyledFormExercise>
    );
  }
}
export default observer(FormAddExerciseWeightTraining);
