import styled from 'styled-components';
import { Button } from 'rebass';

const ButtonSquare = styled(Button)`
  text-transform: uppercase;
  border-radius: 0px;
  padding: ${(props) => props.padding || '15px 16px 15px 16px'};
  cursor: pointer;
`;

export { ButtonSquare };
export default ButtonSquare;
