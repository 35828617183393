import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Anamnese,
  Button,
  Title,
  SubTitle,
  Recommend,
} from '../../components';
import Context from '../../stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from '../../utils';
import ModalNetwork from '../../components/modal/modal-network';

class Frequency extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.setFrequencySelected = this.setFrequencySelected.bind(this);
    this.getTrainingProfiles = this.getTrainingProfiles.bind(this);
    this.state = {
      frequency: 1,
    };

    this.frequencyInTheWeek = [1, 2, 3, 4, 5];
    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'frequency',
    });
  }

  getTrainingProfiles() {
    const {
      trainingCreationStore,
      programStore,
      modalStore,
      appStore,
    } = this.context;

    const { frequency } = this.state;

    if (!programStore.programId || !programStore.levelId) {
      return false;
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:frequency',
      eventAction: 'click:button',
      eventLabel: `frequency_${frequency}`,
    });

    appStore.toggleloading(true);
    trainingCreationStore.doRequestTrainingProfiles({
      programId: programStore.programId,
      levelId: programStore.levelId,
    })
      .then(() => {
        appStore.toggleloading(false);
        modalStore.closeModalError();
        this.setFrequencySelected();
      })
      .catch((err) => {
        appStore.toggleloading(false);
        modalStore.showModalError(err);
      });

    return true;
  }

  setFrequencySelected() {
    const { trainingCreationStore } = this.context;
    const { history } = this.props;
    const { frequency } = this.state;
    const url = ConcatUnitInUrl('/training-creation/division');
    trainingCreationStore.setFrequencySelected(frequency);
    history.push(url);
  }

  saveFrequency(frequencySelected) {
    this.setState({
      frequency: frequencySelected,
    });

    this.getTrainingProfiles();
  }

  renderRecommend(frequency) {
    const { peopleStore, i18n } = this.context;

    if (frequency === peopleStore.getFrequencyActive()) {
      return (
        <Recommend>{i18n.t('recommended')}</Recommend>
      );
    }

    return false;
  }

  render() {
    const {
      i18n,
      modalStore,
    } = this.context;

    return (
      <div>
        <Anamnese>
          <Title type={1} as="h2" subTitleColor="#fff">
            {i18n.t('training_creation_frequency_title')}
            <SubTitle fontWeight="500">
              {i18n.t('training_creation_frequency_description')}
            </SubTitle>
          </Title>

          {this.frequencyInTheWeek.map(num => (
            <Button
              id={num}
              key={num}
              secondary="true"
              large="true"
              block="true"
              className="button-position"
              onClick={(e) => {
                this.saveFrequency(e.target.id);
              }}
            >
              {this.renderRecommend(num)}
              {i18n.t(`training_creation_frequency_alternative_${num}`)}
            </Button>
          ))}

        </Anamnese>
        {modalStore.modalNetwork && (
          <ModalNetwork
            tryAgain={() => { this.getTrainingProfiles(); }}
          />
        )}
      </div>
    );
  }
}

export default observer(Frequency);
