import styled from 'styled-components';

const StyledFormExercise = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 544px) {
    max-width: 480px;
  }

  legend {
    color: #000;
    font-family: 'Gotham';
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
    text-align: center;
    margin-bottom: 34px;

    @media screen and (min-width: 544px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .w-training-legend-cardio {
    @media screen and (min-width: 544px) {
      font-size: 30px;
      line-height: 38px;
    }
  }

  .w-training-weight-training {
    justify-content: space-between;
  }

  .w-training-cardio {
    justify-content: center;
  }

  .w-isometry {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
    height: 25px;

    label {
      font-size: 16px;
    }
  }

  .w-training-main-info {
    display: flex;
    align-items: center;

    margin-bottom: 48px;
    > div {
      display: inline-flex;
      width: auto;

      @media screen and (min-width: 544px) {
        max-width: 105px;
      }
    }

    label {
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      color: #808080;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .w-training-rulespais {
    display: flex;
    align-items: center;
    margin-bottom: 48px;

    label {
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      color: #808080;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
    }

    .form-group {
      width: 156px;
    }
  }
`;

export { StyledFormExercise };
export default StyledFormExercise;
