/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import PropTypes from 'prop-types';

import {
  Box,
} from 'rebass';

import {
  ButtonCircle,
  TabResponsive,
  ContainerBox,
  ButtonFilter,
  ButtonFilterTeacher,
  ListEmptyBell,
  Button,
  Title,
} from 'components';

import FilterListExercise from 'components/FilterListExercise/FilterListExercise';
import FilterListNameTeacher from 'components/FilterListNameTeacher/FilterListNameTeacher';

import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import {
  Plus as BaseIconPlus,
  PlusIcon as BaseIconPlusWallet,
} from 'components/icons';

import ListWalletUnity from 'views/WalletUnity/ListWalletUnity';
import ListExerciseTeacher from './ListExerciseTeacher';

const NewListWorkoutContainer = styled.div`
  .title-unit {
    text-align: initial;
    padding: 0px 20px;
  }

  @media(${(props) => props.theme.mediaScreen.desktop}) {
    max-width: 1440px;
    margin: 0 auto

    .title-unit {
      text-align: initial;
      padding: 0px;
    }
  }
`;

function NewListWorkout(props) {
  const context = useContext(Context);
  const [toggleFilterButton, SetToggleFilterButton] = useState(false);
  const [isActiveButtonFilter, SetIsActiveButtonFilter] = useState(false);
  const [loadingFilterEmphasis, setlLoaginFilterEmphasis] = useState(true);
  const [loadingListNameTeacher, setLoadingListNameTeacher] = useState(true);
  const [tabSelected, setTabSelected] = useState('');
  const [loadingWorkout, setLoadingWorkout] = useState(true);

  const {
    i18n,
    teacherTrainingStore,
    programStore,
    filterWorkoutStore,
    lesionAndRestrictionStore,
    toggleStore,
    walletUnityStore,
    sessionStore,
    modalStore,
    appStore,
  } = context;

  const {
    history,
    requestPrograms,
    elementSelected,
  } = props;

  const hasWallet = () => toggleStore.allowRenderWalletUnit();

  const hasAllowRenderEmphasis = () => toggleStore
    .allowRenderCountry('toggleEmphasisTraining');

  useEffect(() => {
    const loadingTab = () => {
      const status = toggleStore.allowRenderWalletUnit();
      const result = status ? 'training_wallet' : 'my_training';
      setTabSelected(result);
      setLoadingWorkout(false);
    };

    const callListEmphase = async() => {
      try {
        await filterWorkoutStore.doRequestEmphasis();
      } catch(err) {
        window.scroll(0, 0);
        modalStore.showModalError(err);
        appStore.toggleloading(false);
      }
    }

    const callListNameTeacher = async() => {
      try {
        await filterWorkoutStore.doRequestListNameTeacher();
      } catch(err) {
        modalStore.showModalError(err);
        appStore.toggleloading(false);
      }
    }

    if (loadingFilterEmphasis && hasAllowRenderEmphasis()) {
      callListEmphase();
      setlLoaginFilterEmphasis(false);
    }

    if(loadingListNameTeacher && toggleStore.allowRenderWalletUnit()) {
      callListNameTeacher();
      setLoadingListNameTeacher(false);
    }

    if (loadingWorkout) {
      loadingTab();
    }
  }, [
    toggleStore,
    loadingWorkout,
    filterWorkoutStore,
    modalStore,
    hasAllowRenderEmphasis,
  ]);

  const removeText = (groupExercise) => {
    const text = groupExercise.replace(/(Coach|Entrenador|Físico)/gm, '');
    return text.trim();
  };

  const makeTabLabel = () => {
    const list = teacherTrainingStore.listPrograms.map((item) => {
      const tratamentName = removeText(item.name);

      return {
        id: item.id,
        view: item.id,
        name: tratamentName,
        handleEvent: () => { elementSelected(item.id, tratamentName); },
      };
    });

    return list;
  };

  const createNewTrainig = () => {
    const urlLevelChoose = ConcatUnitInUrl('/teacher-training-creation');
    teacherTrainingStore.setTeacherDuplicateTraining(false);
    teacherTrainingStore.setTeacherInteface(true);
    lesionAndRestrictionStore.resetHealthRestrictionEdit();

    programStore.setProgramId(teacherTrainingStore.programId);
    history.push(urlLevelChoose);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:button',
      eventLabel: 'create_new_workout',
    });
  };

  const toggleFilter = () => {
    const toggle = !toggleFilterButton;

    if(toggle) {
      SetIsActiveButtonFilter(false);
    }

    SetToggleFilterButton(toggle);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:teacher',
      eventAction: 'click:filter',
      eventLabel: `filter_${toggle}`,
      acronym: window.localStorage.getItem('w-acronym'),
    });
  };

  const clickShowListTeacher = () => {
    const toggle = !isActiveButtonFilter;

    if(toggle) {
      SetToggleFilterButton(false);
    }

    SetIsActiveButtonFilter(toggle);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:suggestion',
      eventAction: 'click:filter',
      eventLabel: `filter_teacher_${toggle}`,
      acronym: window.localStorage.getItem('w-acronym'),
    });
  }

  const hasListExercise = () => {
    const hasExerciseTeacher = (teacherTrainingStore.listWorkoutTeacherView.length > 0);
    const hasExerciseWallet = (walletUnityStore.listWorkoutWAlletView.length > 0);
    return (hasExerciseTeacher || hasExerciseWallet);
  };

  const hasFilterActive = () => (filterWorkoutStore.lengthSelected > 0);

  const renderListExercise = () => {
    if (requestPrograms && tabSelected === 'my_training') {
      return (
        <ListExerciseTeacher history={history} tabSelected={tabSelected} />
      );
    }

    if (requestPrograms && tabSelected === 'training_wallet') {
      return (
        <ListWalletUnity history={history} tabSelected={tabSelected} />
      );
    }

    return false;
  };

  const renderButtonCreate = () => {
    if (hasWallet()) {
      return (
        <Button
          primary
          icon
          removePadding
          className="w-btn-primary create-training"
          onClick={() => {
            createNewTrainig();
          }}
        >
          <div className="wrapper-content-button wallet">
            <BaseIconPlusWallet className="icon-plus" />
            <div>
              <span className="text-button-register">
                {i18n.t('register_create_training')}
              </span>
            </div>
          </div>
        </Button>
      );
    }

    return (
      <ButtonCircle
        variant="secondSquare"
        className="w-btn-primary"
        onClick={() => {
          createNewTrainig();
        }}
      >
        <div className="wrapper-content-button">
          <BaseIconPlus className="icon-plus" />
          <span className="text-button-register">
            {i18n.t('register_create_new_training')}
          </span>
        </div>
      </ButtonCircle>
    );
  };

  const showButtonCreateWallet = () => {
    const showButtonCreate = hasWallet();
    const allowWorkoutRender = sessionStore.createTrainingEnabled;

    return showButtonCreate && allowWorkoutRender;
  };

  const renderListEmpty = () => {
    if (hasListExercise() === false && hasFilterActive() === false) {
      const showButtonCreate = showButtonCreateWallet();

      return (
        <>
          <ListEmptyBell
            title={i18n.t('not_found_list')}
            subTitle={i18n.t('suggestion_create', { programName: teacherTrainingStore.programName })}
          />
          <ContainerBox padding="32px 0px">
            <div className={`wrapper-buttons empty ${showButtonCreate ? '' : 'hidden-create'}`}>
              {renderButtonCreate()}
            </div>
          </ContainerBox>
        </>
      );
    }

    if (hasListExercise() === false && hasFilterActive()) {
      return (
        <ListEmptyBell
          title={i18n.t('filter_empty')}
        />
      );
    }

    return false;
  };

  const renderListFilterExercise = (showButtonCreate) => {
    if (hasWallet()) {
      return (
        <FilterListNameTeacher
          clickShowListTeacher={clickShowListTeacher}
          hasButtonCreate={showButtonCreate}
          isActiveButtonFilter={isActiveButtonFilter}
        />
      );
    }
  }

  const renderButtonsEventsList = () => {
    if (hasListExercise() === false && hasFilterActive() === false) return false;
    const showButtonCreate = showButtonCreateWallet();

    return (
      <>
        <div className={`wrapper-buttons ${showButtonCreate ? '' : 'hidden-create'}`}>
          {renderButtonCreate()}

          {renderListFilterExercise(showButtonCreate)}

          <ButtonFilter
            toggleFilterButton={toggleFilterButton}
            toggleFilter={toggleFilter}
            lengthSelected={filterWorkoutStore.lengthSelected}
          />
        </div>

        <FilterListExercise
          toggleFilterButton={toggleFilterButton}
          tabSelected={tabSelected}
        />
      </>
    );
  };

  const renderTitleUnit = () => {
    if (hasWallet()) {
      return (
        <Title
          type={4}
          fontSize={20}
          className="title-unit"
        >
          {i18n.t('workout_unit', {
            name: sessionStore.session.location.name,
            acronym: sessionStore.acronym,
          })}
        </Title>
      );
    }

    return false;
  };

  return (
    <NewListWorkoutContainer>
      {renderTitleUnit()}
      <Box width="auto" minHeight="initial" className="w-objective">
        <TabResponsive
          tabList={makeTabLabel()}
          selected={teacherTrainingStore.programId}
          nameLabel={teacherTrainingStore.programName}
          showDropDown={false}
        />
        <ContainerBox padding="32px 0px">
          {renderButtonsEventsList()}
          {renderListEmpty()}
          {renderListExercise()}
        </ContainerBox>
      </Box>
    </NewListWorkoutContainer>
  );
}

NewListWorkout.defaultProps = {
  history: {},
  requestPrograms: false,
  elementSelected: () => {},
};

NewListWorkout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  requestPrograms: PropTypes.bool,
  elementSelected: PropTypes.func,
};

export default observer(NewListWorkout);
