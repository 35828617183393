import {
  observable, action, decorate, computed,
} from 'mobx';

import {
  getHealthRestrictions as getHealthRestrictionsApi,
  postHealthRestrictions as postHealthRestrictionsApi,
} from 'services/api';

import {
  TypeHealthRestriction,
  IlestionAndRestrictionStore,
  TypePostHealthRestriction,
  TypeHealthRestrictionEdit,
  TypeKinds,
} from './IlesionAndRestrictionStore';

class LesionAndRestrictionStore implements IlestionAndRestrictionStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    trainingCreationStore: {
      weightTrainingId: 0,
    },
  };

  healthRestrictions: TypeHealthRestriction[] = [];

  listNavigation: string[] = [];

  listOptionSelected: TypeHealthRestriction[] = [];

  navigator: string = '';

  currentQuestion: number = 0;

  listDataLesionAndRestriction: TypePostHealthRestriction[] = [];

  propertyLesion = '';

  listKindsEdit: TypeKinds[] = [];

  listKindsEditSelected: string[] = [];

  editDescription = '';

  healthRestrictionEdit: TypeHealthRestrictionEdit[] = [];

  keyLabel = '';

  reloadLesionEdit: TypeHealthRestrictionEdit = {
    label: '',
    value: '',
    health_restriction_kinds: [],
    description: '',
  };

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  resetAll(): void {
    this.healthRestrictions = [];
    this.listNavigation = [];
    this.listOptionSelected = [];
    this.navigator = '';
    this.currentQuestion = 0;
    this.listDataLesionAndRestriction = [];
  }

  resetEdit(): void {
    this.keyLabel = '';
    this.propertyLesion = '';
    this.listKindsEdit = [];
    this.listKindsEditSelected = [];
    this.editDescription = '';
  }

  resetHealthRestrictionEdit(): void {
    this.healthRestrictionEdit = [];
  }

  setHealthRestrictionEdit(dataHealthRestrictionEdit: TypeHealthRestrictionEdit[]) {
    this.healthRestrictionEdit = dataHealthRestrictionEdit;
  }

  clearLesionEditReload() {
    this.reloadLesionEdit = {
      label: '',
      value: '',
      health_restriction_kinds: [],
      description: '',
    };
  }

  setLesionEditReload(training: TypeHealthRestrictionEdit) {
    this.reloadLesionEdit = training;
  }

  setDataEditLesionAndRestriction(training: TypeHealthRestrictionEdit) {
    this.keyLabel = training.value;
    this.propertyLesion = training.label;
    this.editDescription = training.description;
    this.setListKindsEdit();

    training.health_restriction_kinds.map((kind) => {
      this.setKindCurrentEdit(kind.value);
      return false;
    });
  }

  setListKindsEdit() {
    const restriction = this.healthRestrictions
      .filter(lesion => (this.keyLabel === lesion.value));

    if (restriction.length > 0) {
      this.listKindsEdit = restriction[0].kinds;
    } else {
      this.listKindsEdit = [];
    }
  }

  setHealthRestrictions(data: TypeHealthRestriction[]): void {
    this.healthRestrictions = data;
  }

  setListOptionsSelected(selected: TypeHealthRestriction): void {
    const hasListOption = this.listNavigation.includes(selected.value);

    if (hasListOption) {
      const index = this.listOptionSelected.indexOf(selected);
      this.listNavigation.splice(index, 1);
      this.listOptionSelected.splice(index, 1);
    } else {
      this.listNavigation.push(selected.value);
      this.listOptionSelected.push(selected);
    }
  }

  doRequestListHealthRestriction(params: any): any {
    return new Promise((resolve, reject) => this
      .promiseListHealthRestriction(params, resolve, reject));
  }

  promiseListHealthRestriction = async (params: any, resolve: any, reject: any) => {
    try {
      const res = await getHealthRestrictionsApi(params, this.rootStore.sessionStore.token);
      this.resetAll();
      this.setHealthRestrictions(res.data.health_restrictions);
      resolve(res.data.health_restrictions);
    } catch (err) {
      reject(err);
    }
  }

  doRequestSavePrescription(params: any): any {
    return new Promise((resolve, reject) => this
      .promiseSavePrescription(params, resolve, reject));
  }

  promiseSavePrescription = async (params: any, resolve: any, reject: any) => {
    const data = {
      body: {
        health_restrictions: this.listDataLesionAndRestriction,
      },
      weightExerciseId: this.rootStore.trainingCreationStore.weightTrainingId,
    };

    try {
      const res = await postHealthRestrictionsApi(data, this.rootStore.sessionStore.token);
      resolve(res.data.health_restrictions);
    } catch (err) {
      reject(err);
    }
  }

  getHealthRestrictionKinds(lesion: TypeHealthRestrictionEdit) {
    if (lesion.health_restriction_kinds.length <= 0) return [];

    return lesion.health_restriction_kinds.map((kind) => {
      return kind.value;
    });
  }

  makeObjToEditLesion(detailPrescription: string) {
    this.listDataLesionAndRestriction = [];

    const dataLesionEdit: TypePostHealthRestriction = {
      value: this.keyLabel,
      kinds: this.listKindsEditSelected,
      description: detailPrescription,
    };

    this.listDataLesionAndRestriction = this.healthRestrictionEdit
      .map((lesion) => {
        if (lesion.value !== this.keyLabel) {
          return {
            value: lesion.value,
            kinds: this.getHealthRestrictionKinds(lesion),
            description: lesion.description,
          };
        }

        return dataLesionEdit;
      });
  }

  resetValues(): void {
    this.currentQuestion = 0;
    this.listDataLesionAndRestriction = [];
    this.setNavigator();
  }

  makeObjPrescription(): TypePostHealthRestriction {
    return {
      value: this.listOptionSelected[this.currentQuestion].value,
      kinds: [],
      description: '',
    };
  }

  setStartPrescription(): void {
    const detailPrescription = this.makeObjPrescription();
    this.listDataLesionAndRestriction.push(detailPrescription);
  }

  nextCurrent(): void {
    this.currentQuestion += 1;
    this.setNavigator();
  }

  setNavigator(): void {
    this.navigator = this.listOptionSelected[this.currentQuestion].value;
  }

  setKindCurrent(value: string): void {
    const hasTag = this.optionsKindsSelected.includes(value);

    if (hasTag) {
      const index = this.listDataCurrent.kinds.indexOf(value);
      this.listDataLesionAndRestriction[this.currentQuestion].kinds.splice(index, 1);
    } else {
      this.listDataLesionAndRestriction[this.currentQuestion].kinds.push(value);
    }
  }

  setKindCurrentEdit(value: string): void {
    const hasTag = this.listKindsEditSelected.includes(value);

    if (hasTag) {
      const index = this.listKindsEditSelected.indexOf(value);
      this.listKindsEditSelected.splice(index, 1);
    } else {
      this.listKindsEditSelected.push(value);
    }
  }

  saveDetailPrescription(description: string): void {
    this.listDataLesionAndRestriction[this.currentQuestion].description = description;
  }

  backQuestionCurrent(): void {
    const index = this.listDataLesionAndRestriction.length - 1;
    this.listDataLesionAndRestriction.splice(index, 1);
    this.currentQuestion -= 1;

    if (this.currentQuestion >= 0) {
      this.setNavigator();
    }
  }

  getDataDescription() {
    return this.listDataLesionAndRestriction[this.currentQuestion].description;
  }

  get renderPage(): string {
    return this.navigator;
  }

  get optionCurrent(): string[] | any {
    return this.listOptionSelected[this.currentQuestion];
  }

  get optionKinds(): string[] | any {
    return this.listOptionSelected[this.currentQuestion].kinds;
  }

  get limitQuestion(): boolean {
    return this.currentQuestion >= (this.listOptionSelected.length - 1);
  }

  get hasQuestion(): boolean {
    return this.listDataLesionAndRestriction.length > 1;
  }

  get optionsKindsSelected(): string[] {
    return this.listDataLesionAndRestriction[this.currentQuestion].kinds;
  }

  get listDataCurrent(): TypePostHealthRestriction {
    return this.listDataLesionAndRestriction[this.currentQuestion];
  }

  get dataDescription(): string {
    return this.listDataLesionAndRestriction[this.currentQuestion].description;
  }
}

decorate(LesionAndRestrictionStore, {
  healthRestrictions: observable,
  listOptionSelected: observable,
  listNavigation: observable,
  navigator: observable,
  listDataLesionAndRestriction: observable,
  propertyLesion: observable,
  listKindsEdit: observable,
  listKindsEditSelected: observable,
  editDescription: observable,
  healthRestrictionEdit: observable,
  reloadLesionEdit: observable,
  makeObjPrescription: action,
  setKindCurrent: action,
  setKindCurrentEdit: action,
  setStartPrescription: action,
  doRequestListHealthRestriction: action,
  setListOptionsSelected: action,
  resetValues: action,
  nextCurrent: action,
  setNavigator: action,
  setDataEditLesionAndRestriction: action,
  resetEdit: action,
  getDataDescription: action,
  setHealthRestrictionEdit: action,
  makeObjToEditLesion: action,
  clearLesionEditReload: action,
  setLesionEditReload: action,
  resetHealthRestrictionEdit: action,
  renderPage: computed,
  optionCurrent: computed,
  optionKinds: computed,
  limitQuestion: computed,
  hasQuestion: computed,
  optionsKindsSelected: computed,
});

export const LesionAndRestrictionStoreSchema = {
  navigator: true,
  currentQuestion: true,
  listDataLesionAndRestriction: {
    type: 'list',
  },
  healthRestrictions: {
    type: 'list',
  },
  listNavigation: {
    type: 'list',
  },
  listOptionSelected: {
    type: 'list',
  },
  propertyLesion: true,
  listKinds: {
    type: 'list',
  },
  listKindsEdit: {
    type: 'list',
  },
  listKindsEditSelected: {
    type: 'list',
  },
  editDescription: true,
  keyLabelL: true,
  healthRestrictionEdit: {
    type: 'list',
  },
  reloadLesionEdit: {
    type: 'object',
    schema: {
      label: true,
      value: true,
      health_restriction_kinds: {
        type: 'list',
      },
      description: true,
    },
  },
};

export default LesionAndRestrictionStore;
