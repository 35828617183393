import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Textarea,
} from 'components';

const CommentTrainingStyle = styled.div`
  margin-top: 30px;
  color:${props => props.theme.colors.white};

  .w-title {
    margin-bottom: 8px;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`;

const CommentTraining = (props) => {
  const context = useContext(Context);
  const {
    trainingCreationStore,
    i18n,
    buildTrainingStore,
    peopleStore,
  } = context;

  const {
    setTextObservationExercise,
  } = props;


  const renderCommentObervation = () => {
    if (buildTrainingStore.resendTraining) {
      return peopleStore.trainingObservation;
    }

    return trainingCreationStore.trainingObservation;
  };

  return (
    <CommentTrainingStyle>
      <p className="w-title">{i18n.t('comment_not_require')}</p>

      <Textarea
        tabIndex="0"
        id="comment-training"
        minHeight="88px"
        marginBottom="32px"
        onChange={e => setTextObservationExercise(e)}
        defaultValue={renderCommentObervation()}
      />
    </CommentTrainingStyle>
  );
};

CommentTraining.defaultProps = {
  setTextObservationExercise: () => {},
};

CommentTraining.propTypes = {
  setTextObservationExercise: PropTypes.func,
};

export default CommentTraining;
