import React, { Component } from 'react';
import {
  TabGeneric,
} from 'components';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';
import ModalView from 'components/modal/modal-view';
import HistoryTraining from './HistoryTraining/HistoryTraining';
import ProfileGlobal from './ProfileGlobal/ProfileGlobal';

class DataProfile extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.closedModal = this.closedModal.bind(this);
    this.elementSelected = this.elementSelected.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.state = {
      elementSelected: 'profile',
    };

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'modal_message',
      eventLabel: 'profile_workout_summary',
    });
  }

  componentWillMount() {
    const { historyTrainingStore } = this.context;

    if (historyTrainingStore.showHistoryTraining) {
      this.setState({ elementSelected: 'history' });
    }

    historyTrainingStore.setShowHistoryTraining(false);
  }

  closedModal() {
    const {
      modalStore,
    } = this.context;

    modalStore.toggleModal('modalDataProfile', false);
  }

  elementSelected(view) {
    this.setState({ elementSelected: view });
  }

  makeTabLabel() {
    const {
      i18n,
    } = this.context;

    return [
      {
        id: 0,
        view: 'profile',
        name: i18n.t('profile'),
        handleEvent: () => { this.elementSelected('profile'); },
      },
      {
        id: 1,
        view: 'history',
        name: i18n.t('history_training'),
        handleEvent: () => { this.elementSelected('history'); },
      },
    ];
  }

  renderComponent(elementSelected) {
    if (elementSelected === 'profile') {
      return (
        <ProfileGlobal />
      );
    }

    return (
      <HistoryTraining />
    );
  }


  render() {
    const list = this.makeTabLabel();
    const {
      elementSelected,
    } = this.state;

    return (
      <ModalView closedModal={this.closedModal}>
        <TabGeneric tabList={list} selected={elementSelected} />
        {this.renderComponent(elementSelected)}
      </ModalView>
    );
  }
}

export default DataProfile;
