import styled from 'styled-components';

import IntervalSVG from './interval.svg';
import RepetitionsSVG from './repetitions.svg';
import SeriesSVG from './series.svg';
import ExerciseExec from './exercise-exec.svg';

const TrainingList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 1024px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const TrainingListItem = styled.div`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px;

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #404040;
  }

  .w-drag-button {
    position: absolute;
    top: calc(50% - 20px / 2);
    right: 30px;
  }

  .w-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 48px;
  }

  .w-title {
    width: 100%;
    min-height: 34px;
    position: relative;
    color: #fff;
    font-family: 'Gotham';
    font-size: 19px;
    font-weight: normal;
    line-height: 23px;
    padding-left: 44px;
    padding-right: 40px;
    margin-bottom: 24px;

    .w-checkbox {
      position: absolute;
      top: 0;
      left: 0;

      label {
        span {
          display: none;
        }
      }
    }

    .w-edit-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 34px;
      height: 34px;
      background-color: transparent;
      padding: 0;
    }
  }

  .w-actions {
    display: none;

    @media screen and (min-width: 544px) {
      display: inline-flex;
    }

    button {
      svg {
        fill: #fff;
      }

      &.w-active {
        background-color: #ffb612;

        svg {
          fill: #000;
        }
      }
    }
  }

  .w-exercise-details {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;

    p {
      position: relative;
      display: inline-block;
      color: #fff;
      font-family: 'Gotham';
      font-size: 16px;
      font-weight: normal;
      line-height: 29px;
      padding-left: 38px;

      @media screen and (min-width: 544px) {
        font-size: 24px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 32px;
        height: 29px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &.w-series {
        &::before {
          background-image: url(${SeriesSVG});
          background-size: 32px 14px;
        }
      }

      &.w-interval {
        &::before {
          background-image: url(${IntervalSVG});
          background-size: 24px;
        }
      }

      &.w-repetitions {
        &::before {
          background-image: url(${RepetitionsSVG});
          background-size: 28px 20px;
        }
      }

      &.w-time-execution {
        &::before {
          background-image: url(${ExerciseExec});
          background-size: 28px 20px;
        }
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
`;

export { TrainingList, TrainingListItem };
