import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import { PostGaAnalytics } from 'utils';
import ModalAddExercise from './ModalAddExercise';

class ExercisesSelect extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'add_workout',
    });
  }

  render() {
    const { modalStore } = this.context;
    const { history } = this.props;

    return (
      <div>
        {modalStore.modalError && <ModalError />}
        {modalStore.modalAddExercise && <ModalAddExercise history={history} />}
      </div>
    );
  }
}

ExercisesSelect.defaultProps = {
  history: {},
};

ExercisesSelect.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(ExercisesSelect);
