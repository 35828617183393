import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';

import {
  Button,
} from 'components';

const ItemUnitStyle = styled.li`
  display: flex;
  justify-content: space-between;
  font-family: 'Gotham';
  font-style: normal;
  margin-bottom: 36px;

  .name-unity {
    font-weight: 400;
    font-size: 16px;
  }

  .address-unity {
    font-weight: 325;
    font-size: 14px;
    line-height: 25px;
  }

  .button-select {
    width: 130px;
  }
`;

const ItemUnit = (props) => {
  const context = useContext(Context);

  const {
    i18n,
  } = context;

  const {
    onClickToSubmit,
    location,
  } = props;

  return (
    <ItemUnitStyle>
      <div className="wrapper-data-unity">
        <p className="name-unity">
          {location.acronym}
          {' '}
-
          {' '}
          {location.name}
        </p>
      </div>
      <Button
        tertiary="true"
        minWidth="initial"
        className="button-select"
        onClick={() => {
          onClickToSubmit(location.id);
        }}
      >
        {i18n.t('select')}
      </Button>
    </ItemUnitStyle>
  );
};

ItemUnit.defaultProps = {
  location: {
    acronym: '',
    name: '',
    id: 0,
  },
  onClickToSubmit: () => {},
};

ItemUnit.propTypes = {
  location: PropTypes.shape({
    acronym: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number,
  }),
  onClickToSubmit: PropTypes.func,
};

export default ItemUnit;
