import styled, { css } from 'styled-components';

const TabNavigatorStyle = styled.nav`
  width: 100%;

  .wrapper-menu {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0px;
    background-color: #111;

    .item {
      list-style-type: none;
      height: 64px;
      width: 100%;
      text-align: center;
      line-height: 63px;
      color: #808080;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 16px;

      @media screen and (max-width: 650px) {
        font-size: 12px;
      }

      .tab-link {
        text-decoration: none;
        color: #808080;
        height: 100%;
        display: block;
        width: 100%;
      }

      &.active {
        border-bottom: 4px solid #ffb612;
        .tab-link {
          color: #fff;
        }
      }
    }
  }

  ${(props) => props.position
    && css`
      position: absolute;
      top: ${(props) => props.positionTop || '50'}px;

      .wrapper-menu {
        background-color: #000;
      }
    `};

    ${(props) => props.bgBlack
      && css`
        .wrapper-menu {
          background-color: #000;
        }
      `};
`;

export { TabNavigatorStyle };
export default TabNavigatorStyle;
