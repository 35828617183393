import styled from 'styled-components';
import { Button } from 'rebass';

import ScheduleSVG from './schedule.svg';

const ButtonSchedule = styled(Button)`
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.theme.colors.darkgray};
  width: 30px;
  height: 30px;
  padding: 0px;
  margin-left: 10px;
  margin-top: 8px;
  background-image: url(${ScheduleSVG});
`;

export default ButtonSchedule;
export { ButtonSchedule };
