import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import {
  Title,
  BoxListInfoTraining,
} from 'components';

const InfoEmphasisStyle = styled.div`
  width: 100%;
  margin-top: 26px;
`;

function InfoEmphasis() {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  return (
    <InfoEmphasisStyle>
      <Title
        type={4}
        fontSize="16"
        className="title-info-size"
        mgBottom="8"
        align="left"
      >
        {i18n.t('emphasis_info_title')}
      </Title>
      <BoxListInfoTraining listStyleType="disc">
        <ul className="wrapper-list">
          <li className="item">
            {i18n.t('emphasis_info_first')}
          </li>
          <li className="item">
            {i18n.t('emphasis_info_second')}
          </li>
        </ul>
      </BoxListInfoTraining>
    </InfoEmphasisStyle>
  )
}

export default InfoEmphasis;
