import React, { useContext } from 'react';
import {
  FormGroup,
  Label,
  Input,
} from '../../components';
import Context from '../../stores/context';

const LabelExerciseCardio = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    infoLabel,
  } = props;

  const {
    values,
    handleChange,
    handleBlur,
  } = infoLabel;

  return (
    <div className="w-training-main-info w-training-cardio">
      <FormGroup justifyContent="center" noMarginBottom="true">
        <Label htmlFor="duration">{i18n.t('minutes')}</Label>
        <Input
          type="number"
          id="duration"
          square="true"
          small="true"
          width="48px"
          textAlign="center"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.duration}
        />
      </FormGroup>
    </div>
  );
};

export default LabelExerciseCardio;
