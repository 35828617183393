import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Context from 'stores/context';
import { PostGaAnalytics, ConcatUnitInUrl } from 'utils';
import ButtonToLogout from '../Button/ButtonLogout';
import ButtonShowNotices from '../Button/ButtonNotices';
import { ButtonSchedule } from '../Button/ButtonSchedule';

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background-color: #111;
  padding-left: 16px;
  padding-right: 16px;

  p {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding-right: 32px;

    .header-version {
      font-size: 12px;
      color: #ccc;
      padding-right: 10px;
    }

    .header-style {
      display: none;

      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }

    strong {
      display: inline-block;
      font-weight: 600;
      padding-left: 5px;
    }
  }
`;

const NameTeacher = styled.div`
  font-size: 16px;
  color: #ccc;
  padding-right: 10px;

  @media screen and (max-width: 450px) {
    .name-teacher {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 145px;
      display: block;
      padding-right: 9px;
    }
  }
`;

const Menu = styled.nav`
  font-size: 12px;
  color: #ccc;
  padding-right: 10px;

  .menu-header {
    list-style: none;
    display: flex;

    .centra-notices {
      margin: 0px 8px 0px 0px;
    }

    .version {
      line-height: 49px;
    }

    @media(${(props) => props.theme.mediaScreen.cellphone}) {
      .version {
        font-size: 10px;
      }
    }
  }
`;

export default class HeaderTeacher extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.openModalLogout = this.openModalLogout.bind(this);
    this.showListNotices = this.showListNotices.bind(this);
    this.getBookings = this.getBookings.bind(this);
  }

  async getBookings() {
    const {
      bookingsStore,
      appStore,
      i18n,
      modalStore,
      sessionStore,
      locationStore,
    } = this.context;

    bookingsStore.reset();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:button',
      eventLabel: 'schedule',
    });

    appStore.toggleloading(true);
    locationStore.setNameLocation(sessionStore.nameAcronym);
    locationStore.setAcronymSelected(sessionStore.acronym);

    if (sessionStore.acronym === '') {
      appStore.toggleloading(false);
      return false;
    }

    try {
      await bookingsStore.doRequestBookings(sessionStore.acronym);
      appStore.toggleloading(false);
    } catch (err) {
      const msgTitle = i18n.t('error_bookings');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);
      appStore.toggleloading(false);
    }

    return true;
  }

  showListNotices() {
    const { noticesStore, modalStore } = this.context;
    noticesStore.setShowListNotices(true);
    modalStore.toggleModal('modalNotices', true);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:button',
      eventLabel: 'notifications',
    });
  }

  openModalLogout() {
    const { modalStore } = this.context;
    modalStore.setTitle('Encerrar sessão?');
    modalStore.setDescription('Deseja sair da sua conta? ');
    modalStore.toggleModal('modalLogout', true);
    modalStore.setModalAnalytics({
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'modal_message',
      eventLabel: 'exit_confirm',
    });

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:button',
      eventLabel: 'exit',
    });
  }

  renderButtonSchedule() {
    const { sessionStore } = this.context;

    if (sessionStore.toggleUnidadeProgramSmart) {
      const url = ConcatUnitInUrl('/schedule');

      return (
        <li>
          <Link to={url}>
            <ButtonSchedule onClick={this.getBookings} />
          </Link>
        </li>
      );
    }

    return false;
  }

  render() {
    const { sessionStore } = this.context;
    const sessionName = sessionStore.session.name || '';
    const { activeButton } = this.props;

    return (
      <StyledHeader>
        <NameTeacher>
          <strong className="name-teacher">{sessionName}</strong>
        </NameTeacher>

        <Menu>
          <ul className="menu-header">
            <li className="version">{process.env.REACT_APP_VERSION}</li>
            {this.renderButtonSchedule()}
            <li className="centra-notices">
              <ButtonShowNotices
                onClick={this.showListNotices}
                hasNotices={activeButton}
              />
            </li>
            <li><ButtonToLogout /></li>
          </ul>
        </Menu>

      </StyledHeader>
    );
  }
}

HeaderTeacher.defaultProps = {
  activeButton: false,
};

HeaderTeacher.propTypes = {
  activeButton: PropTypes.bool,
};

export { HeaderTeacher };
