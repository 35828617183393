/* eslint-disable camelcase */
import React, { useContext } from 'react';
import {
  FormGroup,
  Label,
  SelectFormExercise,
} from 'components';
import Context from 'stores/context';

const SelectItensificationMethod = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    infoLabel,
    listIntensification,
  } = props;

  const {
    values,
    handleChange,
    handleBlur,
  } = infoLabel;

  return (
    <FormGroup>
      <Label htmlFor="intensification-method">{i18n.t('intensification_method')}</Label>
      <SelectFormExercise
        handleChange={handleChange}
        handleBlur={handleBlur}
        values={values}
        listIntensification={listIntensification}
      />
    </FormGroup>
  );
};

export default SelectItensificationMethod;
