import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { ConcatUnitInUrl } from 'utils';
import { observer } from 'mobx-react';

import {
  ProfileInfo,
  Button,
} from 'components';

const LesionAndRestrictionTrainingStyle = styled.section`
  color: ${(props) => props.theme.colors.white};

  .wrapper-list-lesion {
    list-style-type: none;
  }

  .wrapper-lesion {
    width: 272px;
  }

  .title-action {
    display: flex;
    justify-content: space-between;

    .w-edit-button {
      width: 32px;
      height: 32px;
      background-color: ${(props) => props.theme.colors.white};
      margin: 0px;
    }
  }

  .list-lesion-restriction {
    margin-left: 18px;
    margin-top: 5px;

    .item-lesion {
      margin-bottom: 8px;
    }
  }

  .description-lesion {
    margin-left: 5px;
  }
`;

function LesionAndRestrictionTraining(props) {
  const context = useContext(Context);
  const {
    lesionAndRestrictionStore,
    appStore,
    modalStore,
  } = context;

  const {
    history,
  } = props;

  const relodSetEdit = () => {
    // eslint-disable-next-line no-use-before-define
    setEditTraining(lesionAndRestrictionStore.reloadLesionEdit);
  };

  const setEditTraining = (lesion) => {
    lesionAndRestrictionStore.resetEdit();
    appStore.toggleloading(true);

    lesionAndRestrictionStore
      .doRequestListHealthRestriction()
      .then(() => {
        appStore.toggleloading(false);
        lesionAndRestrictionStore.clearLesionEditReload();
        lesionAndRestrictionStore.setDataEditLesionAndRestriction(lesion);
        const url = ConcatUnitInUrl('/lesion-and-restriction/question-edit');
        history.push(url);
      })
      .catch((error) => {
        lesionAndRestrictionStore.setLesionEditReload(lesion);
        appStore.toggleloading(false);
        appStore.toggleloading(false);
        modalStore.setModalNetworkCallback(relodSetEdit);
        modalStore.showModalError(error);
      });
  };

  const renderRegisterLesion = (kinds) => {
    if (kinds.length <= 0) return false;

    return kinds.map((kind) => (
      <li className="item-lesion" key={kind.value}>
        <span className="lower-letter">
          {kind.label}
        </span>
      </li>
    ));
  };

  const renderDescription = (description) => {
    if (description === '') return '';

    return (
      <div className="item-lesion description-lesion">
        <span className="lower-letter">
          {description}
        </span>
      </div>
    );
  };

  const renderListLesionAndRestriction = () => lesionAndRestrictionStore
    .healthRestrictionEdit.map((lesion) => (
      <li key={lesion.label}>
        <ProfileInfo
          highlight="true"
          paddingSpace="16px 10px"
          className="wrapper-lesion"
        >
          <div className="wrapper-edit-lesion">
            <div className="title-action">
              <small>{lesion.label}</small>
              <Button
                secondary="true"
                rounded="true"
                editBlack="true"
                className="w-edit-button"
                onClick={() => setEditTraining(lesion)}
              />
            </div>

            <ul className="list-lesion-restriction">
              {renderRegisterLesion(lesion.health_restriction_kinds)}
            </ul>
            {renderDescription(lesion.description)}
          </div>
        </ProfileInfo>
      </li>
    ));

  return (
    <LesionAndRestrictionTrainingStyle>
      <ul className="wrapper-list-lesion">
        {renderListLesionAndRestriction()}
      </ul>

    </LesionAndRestrictionTrainingStyle>
  );
}

LesionAndRestrictionTraining.defaultProps = {
  history: {},
};

LesionAndRestrictionTraining.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(LesionAndRestrictionTraining);
