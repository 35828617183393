/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */

import React, { useContext, useEffect, useState } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';

function AuthenticateAutoSeg() {
  const [reqLogin, setReqLogin] = useState(false);
  const context = useContext(Context);
    const {
    appStore,
    sessionStore,
    modalStore,
    walletUnityStore,
    i18n,
  } = context;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('jwt');

  useEffect(() => {
    const prepareRequestToLogin = () => {
      setReqLogin(false);
    };

    const callLogin = async () => {
      appStore.toggleloading(true);
      try {
        walletUnityStore.resetView();
        walletUnityStore.resetListUnity();

        PostGaAnalytics('send', {
          hitType: 'event',
          eventCategory: 'workout:login',
          eventAction: 'click:button',
          eventLabel: 'autoseg',
        });

        const objToken = await sessionStore
          .doRequestAuthenticate(token);

        await sessionStore.doRequestGetDataUser(objToken);

        sessionStore.authenticate();
        setReqLogin(true);
      } catch(error) {
        const title = `${i18n.t('user_not_found')}`;
        const description = `${i18n.t('verify_login')}`;
        modalStore.setTitle(title);
        modalStore.setDescription(description);
        appStore.toggleloading(false);

        modalStore.setModalAnalytics({
          hitType: 'event',
          eventCategory: 'workout:login',
          eventAction: 'modal_message',
          eventLabel: 'user_not_found_auto_seg',
        });

        modalStore.showModalError(error, 'login');
      }
    };

    if (reqLogin === false) {
      callLogin();
    }
  }, [
    token,
    reqLogin,
  ]);

  if(reqLogin) {
    const url = ConcatUnitInUrl("/select-option-work/list-exercise")
    return <Redirect to={url} />;
  }

  return (
    <div>
      {modalStore.modalError && <ModalError onClick={
        () => { window.location.replace(sessionStore.getUrlLogin());}
      }/>}
      {modalStore.modalNetwork && <ModalNetwork tryAgain={
        () => { setReqLogin(false)}
      }/>}
    </div>
  );
}

export default observer(AuthenticateAutoSeg);
