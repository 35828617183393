import request from './request';

const host = () => process.env.REACT_APP_API_HOST;

const apiRoute = 'api/v2';
const path = `${host()}/${apiRoute}`;
const authToken = (token) => (
  { Authorization: `Bearer ${token}` }
);

export const login = (params) => (
  request('POST', `${path}/sessions`, params)
);

export const authenticate = (params) => (
  request(
    'POST',
    `${path}/users`,
    params.body,
    authToken(params.body.token),
  )
);

export const getDataUser = (token) => (
  request(
    'GET',
    `${path}/users`,
    {},
    authToken(token),
  )
);

export const deleteToken = (token) => (
  request(
    'DELETE',
    `${path}/sessions`,
    {},
    authToken(token),
  )
);

export const findPeople = (params, token) => (
  request(
    'GET',
    `${path}/people?registry_number=${params.registryNumber}`,
    {},
    authToken(token),
  )
);

export const findPeopleLocale = (params, token) => (
  request(
    'GET',
    `${path}/people?registry_number=${params.registryNumber}&locale=${params.locale}`,
    {},
    authToken(token),
  )
);

export const people = (params, token) => (
  request(
    'GET',
    `${path}/people/${params.id}`,
    {},
    authToken(token),
  )
);

export const sendObservationRestriction = (params, token) => (
  request(
    'POST',
    `${path}/people/${params.peopleId}/physical_evaluations`,
    params.body,
    authToken(token),
  )
);

export const getQuestionAnamnesis = (params, token) => (
  request(
    'GET',
    `${path}/anamneses/questionnaire`,
    {},
    authToken(token),
  )
);

export const postQuestionAnamnesis = (params, token) => (
  request(
    'POST',
    `${path}/anamneses`,
    params.body,
    authToken(token),
  )
);

export const programRegister = (params, token) => (
  request(
    'POST',
    `${path}/people/${params.peopleId}/enrollments`,
    params.body,
    authToken(token),
  )
);

export const trainingProfiles = (params, token) => (
  request(
    'GET',
    `${path}/levels/${params.levelId}/weight_training_profiles`,
    params.body,
    authToken(token),
  )
);

export const suggestionsTraining = (params, token) => (
  request(
    'GET',
    `${path}/weight_training_profiles/${params.profileId}/weight_trainings`,
    params.body,
    authToken(token),
  )
);

export const listExerciseTechnicalDepartmentStor = (params, token) => (
  request(
    'GET',
    `${path}/programs/${params.program.id}/weight_trainings`,
    params.body,
    authToken(token),
  )
);

export const getSuggestionsTrainingSaveTeacher = (params, token) => request(
  'GET',
  `${path}/weight_training_profiles/${params.profileId}/teacher_weight_trainings`,
  {},
  authToken(token),
);

export const cloneTemplateTraining = (params, token) => {
  const hasTrainingId = params.trainingId;
  const queryTrainingId = hasTrainingId ? `?original_weight_training_id=${params.trainingId}` : '';

  return request(
    'POST',
    `${path}/enrollments/${params.enrollmentId}/weight_trainings${queryTrainingId}`,
    params.body,
    authToken(token),
  );
};

export const createTraining = (params, token) => request(
  'POST',
  `${path}/people/${params.peopleId}/weight_trainings`,
  params.body,
  authToken(token),
);

export const getTrainings = (params, token) => request(
  'GET',
  `${path}/enrollments/${params.enrollmentId}/weight_trainings`,
  params.body,
  authToken(token),
);

export const delTrainings = (params, token) => request(
  'DELETE',
  `${path}/enrollments/${params.enrollmentId}/weight_trainings/${params.weightTrainingId}`,
  params.body,
  authToken(token),
);

export const patchEditTraining = (params, token) => request(
  'PATCH',
  `${path}/enrollments/${params.enrollmentId}/weight_trainings/${params.weightTrainingId}`,
  params.body,
  authToken(token),
);

export const getDataTrainingCurrent = (params, token) => (
  request(
    'GET',
    `${path}/weight_trainings/${params.current_weight_training_id}`,
    params.body,
    authToken(token),
  )
);

export const patchUpdateTraining = (params, token) => request(
  'PUT',
  `${path}/weight_trainings/${params.weightTrainingId}`,
  params.body,
  authToken(token),
);

export const getTrainingExercises = (params, token) => (
  request(
    'GET',
    `${path}/weight_trainings/${params.current_weight_training_id}/weight_training_exercises`,
    params.body,
    authToken(token),
  )
);

export const getTrainingSuggestions = (params, token) => (
  request(
    'GET',
    `${path}/weight_training_suggestions/${params.current_weight_training_id}/weight_training_exercises`,
    params.body,
    authToken(token),
  )
);

export const patchTrainingExercises = (params, token) => (
  request(
    'PATCH',
    `${path}/weight_trainings/${params.current_weight_training_id}/weight_training_exercises`,
    params.body,
    authToken(token),
  )
);

export const deleteTrainingExercises = (params, token) => (
  request(
    'DELETE',
    `${path}/weight_trainings/${params.current_weight_training_id}/weight_training_exercises`,
    params.body,
    authToken(token),
  )
);

export const createTrainingExercises = (params, token) => (
  request(
    'POST',
    `${path}/weight_trainings/${params.current_weight_training_id}/weight_training_exercises`,
    params.body,
    authToken(token),
  )
);

export const getSearchExercises = (params, token) => (
  request(
    'GET',
    `${path}/weight_exercises/search${params.queryParams}`,
    params.body,
    authToken(token),
  )
);

export const coachSync = (params, token) => (
  request(
    'POST',
    `${path}/weight_trainings/${params.current_weight_training_id}/coach_syncs`,
    params.body,
    authToken(token),
  )
);

export const getProgramLevels = (params, token) => (
  request(
    'GET',
    `${path}/programs/${params.programId}/levels`,
    {},
    authToken(token),
  )
);

export const levelChanges = (params, token) => (
  request(
    'POST',
    `${path}/enrollments/${params.enrollmentId}/level_changes`,
    params.body,
    authToken(token),
  )
);

export const levelTraining = (params, token) => (
  request(
    'GET',
    `${path}/levels/${params.levelId}/weight_training_profiles`,
    {},
    authToken(token),
  )
);

export const getListProgramsTeacher = (params, token) => (
  request(
    'GET',
    `${path}/programs`,
    {},
    authToken(token),
  )
);

export const postSaveExerciseTeacher = (params, token) => (
  request(
    'POST',
    `${path}/weight_trainings/${params.current_weight_training_id}/teacher_weight_trainings`,
    params.data,
    authToken(token),
  )
);

export const getListExerciseTeacher = (params, token) => (
  request(
    'GET',
    `${path}/teacher_weight_trainings?program_id=${params.id}${params.queryString}`,
    {},
    authToken(token),
  )
);

export const deleteExerciseTeacher = (params, token) => (
  request(
    'DELETE',
    `${path}/teacher_weight_trainings/${params.id}`,
    {},
    authToken(token),
  )
);

export const createNewTrainingTeacher = (params, token) => request(
  'POST',
  `${path}/teacher_weight_trainings`,
  params.body,
  authToken(token),
);

export const getListExerciseWallet = (params, token) => (
  request(
    'GET',
    `${path}/locations/weight_trainings?program_id=${params.id}${params.queryString}`,
    {},
    authToken(token),
  )
);

export const postTrainingEmail = (params, token) => request(
  'POST',
  `${path}/weight_trainings/${params.current_weight_training_id}/mail_senders`,
  {},
  authToken(token),
);

export const getNotices = (params, token) => request(
  'GET',
  `${path}/notices`,
  {},
  authToken(token),
);

export const postNotices = (params, token) => request(
  'POST',
  `${path}/notices/${params.id}/notice_read_marks`,
  {},
  authToken(token),
);

export const getHistoryTraining = (params, token) => request(
  'GET',
  `${path}/people/${params.peopleId}/weight_trainings`,
  {},
  authToken(token),
);

export const getRulesPais = (params, token) => request(
  'GET',
  `${path}/weight_training_profiles/${params.profileId}`,
  {},
  authToken(token),
);

export const getVideoExercise = (params, token) => request(
  'GET',
  `${path}/weight_exercises/${params.weightExerciseId}/variation_videos`,
  {},
  authToken(token),
);

export const getFollowUp = (params, token) => request(
  'GET',
  `${path}/people/${params.peopleId}/training_programs/${params.slug}/follow_up`,
  {},
  authToken(token),
);

export const getListLocation = (params, token) => request(
  'GET',
  `${path}/locations`,
  {},
  authToken(token),
);

export const getBookings = (params, token) => request(
  'GET',
  `${path}/bookings?location_acronym=${params.acronym}`,
  {},
  authToken(token),
);

export const getPeopleListBookings = (params, token) => request(
  'GET',
  `${path}/bookings?location_acronym=${params.acronym}&person_id=${params.personId}`,
  {},
  authToken(token),
);

export const postPresenceBookings = (params, token) => request(
  'POST',
  `${path}/bookings/${params.idBooking}/checkins`,
  {},
  authToken(token),
);

export const getHealthRestrictions = (params, token) => request(
  'GET',
  `${path}/health_restrictions`,
  {},
  authToken(token),
);

export const postHealthRestrictions = (params, token) => request(
  'POST',
  `${path}/weight_trainings/${params.weightExerciseId}/health_restrictions`,
  params.body,
  authToken(token),
);

export const postConfirmationLocation = (params, token) => request(
  'PUT',
  `${path}/users`,
  params.body,
  authToken(token),
);

export const getListBodyEmphasis = (token) => request(
  'GET',
  `${path}/body_emphasis`,
  {},
  authToken(token),
);

export const getListNameTeacher = (token) => request(
  'GET',
  `${path}/locations/teachers`,
  {},
  authToken(token),
);

export default {};
