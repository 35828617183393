import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Text } from 'rebass';
import { Redirect } from 'react-router-dom';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import { Trans } from 'react-i18next';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import {
  Box,
  Button,
  Fieldset,
  FlexDiv,
  FormGroup,
  GridContainer as Container,
  Input,
  Label,
  SubTitle,
  Title,
  ForgotPassword,
} from 'components';

import removeCache from 'services/remove-cache';

class Login extends Component {
  static contextType = Context;

  state = {
    username: '',
    password: '',
    login: false,
  };

  constructor() {
    super();
    this.login = this.login.bind(this);
    this.onClickShowPassword = this.onClickShowPassword.bind(this);
    this.showModalResetPassword = this.showModalResetPassword.bind(this);
    this.handleKeyDownLogin = this.handleKeyDownLogin.bind(this);
    this.prepareRequestToLogin = this.prepareRequestToLogin.bind(this);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'login',
    });
  }

  // eslint-disable-next-line react/sort-comp
  handleKeyDownLogin(e) {
    if (e.key === 'Enter') {
      this.prepareRequestToLogin();
    }
  }

  showModalResetPassword(event) {
    const { appStore, i18n, modalStore } = this.context;
    const textTitle = `${i18n.t('forgot_password_title')}`;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:login',
      eventAction: 'click:button',
      eventLabel: 'esqueci_senha',
    });

    const eventGaModal = () => {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:login',
        eventAction: 'modal_message',
        eventLabel: 'change_password',
      });
    };

    const textDescription = () => (
      <Trans
        i18nKey="forgot_password_description"
        components={[<a href="https://intranet.bioritmo.com.br/change_password" target="blank" onClick={() => { eventGaModal(); }}>*</a>]}
      />
    );
    const styleModal = {
      titleColor: 'black',
      widthWrapper: '544px',
      padding: '24px',
      py: [3],
    };

    appStore.toggleloading(true);
    event.preventDefault();
    setTimeout(() => {
      modalStore.setTitle(textTitle);
      modalStore.setDescription(textDescription());
      modalStore.setStyleCss(styleModal);
      modalStore.toggleModal('modalError', true);
      appStore.toggleloading(false);
    }, 500);
  }

  makeObjUser() {
    const { username, password } = this.state;

    return {
      username,
      password,
      fieldEmpty: (username === '' && password === ''),
    };
  }

  prepareRequestToLogin() {
    const {
      modalStore,
      walletUnityStore,
    } = this.context;

    const objUser = this.makeObjUser();
    this.setState({ login: true });
    modalStore.closeModalError();
    walletUnityStore.resetView();
    walletUnityStore.resetListUnity();

    if (objUser.fieldEmpty) {
      return true;
    }

    this.login(objUser);

    return true;
  }

  login(objUser) {
    const {
      appStore, sessionStore, modalStore, i18n,
    } = this.context;

    appStore.toggleloading(true);

    modalStore.closeModalError();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:login',
      eventAction: 'click:button',
      eventLabel: 'enter',
    });

    sessionStore
      .doRequestLogin(objUser)
      .then(() => {
        sessionStore.authenticate();
        this.setState({ login: false });
        appStore.toggleloading(false);
        appStore.clearObjTemp();
      })
      .catch((error) => {
        const title = `${i18n.t('user_not_found')}`;
        const description = `${i18n.t('verify_login')}`;
        appStore.toggleloading(false);
        modalStore.setTitle(title);
        modalStore.setDescription(description);

        appStore.setObjTemp(objUser);
        modalStore.setModalAnalytics({
          hitType: 'event',
          eventCategory: 'workout:login',
          eventAction: 'modal_message',
          eventLabel: 'user_not_found',
        });
        modalStore.setModalNetworkCallback(this.prepareRequestToLogin);
        modalStore.showModalError(error, 'login');
      });
  }

  onClickShowPassword() {
    const { appStore } = this.context;

    appStore.toggleShowPassword(!appStore.showPassword);
  }

  urlConcatUnit(location) {
    const { from } = location.state || { from: { pathname: '/select-option-work/list-exercise' } };
    const newURL = ConcatUnitInUrl(from.pathname);

    return newURL;
  }

  render() {
    const {
      appStore, sessionStore, i18n, modalStore,
    } = this.context;

    const { location } = this.props;
    const { login } = this.state;

    const from = this.urlConcatUnit(location);

    let svgButtonShowPassword;

    if (!appStore.showPassword) {
      svgButtonShowPassword = (
        <Fragment>
          <path
            fill="#808080"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16ZM18 16C18 17.1046 17.1046 18 16 18C14.8954 18 14 17.1046 14 16C14 14.8954 14.8954 14 16 14C17.1046 14 18 14.8954 18 16Z"
          />
          <path
            fill="#808080"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.7688 15.3605C30.0765 15.7309 30.0769 16.2685 29.7692 16.639L29 16C29.7692 16.639 29.7682 16.6402 29.7682 16.6402L29.7666 16.6421L29.7619 16.6477L29.7461 16.6665C29.7387 16.6753 29.7295 16.686 29.7186 16.6987C29.7099 16.7088 29.7002 16.7202 29.6893 16.7328C29.6404 16.7893 29.5696 16.87 29.4779 16.9714C29.2947 17.1741 29.0277 17.46 28.6857 17.8015C28.0027 18.4833 27.0148 19.392 25.7915 20.3023C23.3672 22.1062 19.9058 24 16 24C12.0942 24 8.63281 22.1062 6.20849 20.3023C4.9852 19.392 3.99729 18.4833 3.3143 17.8015C2.97227 17.46 2.7053 17.1741 2.52205 16.9714C2.43039 16.87 2.35958 16.7893 2.31069 16.7328C2.28625 16.7045 2.26727 16.6823 2.25391 16.6665L2.23808 16.6477L2.23335 16.6421L2.23076 16.639L3 16C2.23076 15.361 2.23179 15.3598 2.23179 15.3598L2.23335 15.3579L2.23808 15.3523L2.25391 15.3335C2.26727 15.3177 2.28625 15.2955 2.31069 15.2672C2.35958 15.2107 2.43039 15.13 2.52205 15.0286C2.7053 14.8259 2.97227 14.54 3.3143 14.1985C3.99729 13.5167 4.9852 12.608 6.20849 11.6977C8.63281 9.89378 12.0942 8 16 8C19.9058 8 23.3672 9.89378 25.7915 11.6977C27.0148 12.608 28.0027 13.5167 28.6857 14.1985C29.0277 14.54 29.2947 14.8259 29.4779 15.0286C29.5696 15.13 29.6404 15.2107 29.6893 15.2672C29.7138 15.2955 29.7327 15.3177 29.7461 15.3335L29.7619 15.3523L29.7666 15.3579L29.7688 15.3605ZM4.72729 16.386C4.5869 16.2459 4.46076 16.1164 4.34965 16C4.46076 15.8836 4.5869 15.7541 4.72729 15.614C5.35907 14.9833 6.27389 14.142 7.40242 13.3023C9.68173 11.6062 12.7204 10 16 10C19.2796 10 22.3183 11.6062 24.5976 13.3023C25.7261 14.142 26.6409 14.9833 27.2727 15.614C27.4131 15.7541 27.5392 15.8836 27.6503 16C27.5392 16.1164 27.4131 16.2459 27.2727 16.386C26.6409 17.0167 25.7261 17.858 24.5976 18.6977C22.3183 20.3938 19.2796 22 16 22C12.7204 22 9.68173 20.3938 7.40242 18.6977C6.27389 17.858 5.35907 17.0167 4.72729 16.386ZM29 16C29.7692 15.361 29.7688 15.3605 29.7688 15.3605L29 16Z"
          />
          <path
            fill="#808080"
            d="M2.23076 16.639C1.92308 16.2685 1.92308 15.7315 2.23076 15.361L3 16C2.23076 16.639 2.23076 16.639 2.23076 16.639Z"
          />
        </Fragment>
      );
    } else {
      svgButtonShowPassword = (
        <Fragment>
          <path
            fill="#808080"
            d="M6.80761 9.63604C6.41709 9.24551 6.41709 8.61235 6.80761 8.22183C7.19814 7.8313 7.8313 7.8313 8.22183 8.22183L23.7782 23.7782C24.1687 24.1687 24.1687 24.8019 23.7782 25.1924C23.3876 25.5829 22.7545 25.5829 22.364 25.1924L6.80761 9.63604Z"
          />
          <path
            fill="#808080"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.60382 9.60389C8.32962 10.23 7.18493 10.9712 6.20849 11.6977C4.9852 12.608 3.99729 13.5167 3.3143 14.1985C2.97227 14.54 2.7053 14.8259 2.52205 15.0286C2.43039 15.13 2.35958 15.2107 2.31069 15.2672C2.28625 15.2955 2.26727 15.3177 2.25391 15.3335L2.23808 15.3523L2.23335 15.3579L2.23179 15.3598C2.23156 15.3601 2.23076 15.361 3 16L2.23076 15.361C1.92308 15.7315 1.92308 16.2685 2.23076 16.639L2.23335 16.6421L2.23808 16.6477L2.25391 16.6665C2.26727 16.6823 2.28625 16.7045 2.31069 16.7328C2.35958 16.7893 2.43039 16.87 2.52205 16.9714C2.7053 17.1741 2.97227 17.46 3.3143 17.8015C3.99729 18.4833 4.9852 19.392 6.20849 20.3023C8.63281 22.1062 12.0942 24 16 24C18.3326 24 20.5067 23.3245 22.3961 22.3962L20.8912 20.8913C19.3772 21.5573 17.7218 22 16 22C12.7204 22 9.68173 20.3938 7.40242 18.6977C6.27389 17.858 5.35907 17.0167 4.72729 16.386C4.5869 16.2459 4.46076 16.1164 4.34965 16C4.46076 15.8836 4.5869 15.7541 4.72729 15.614C5.35907 14.9833 6.27389 14.142 7.40242 13.3023C8.48506 12.4967 9.739 11.7113 11.1087 11.1088L9.60382 9.60389ZM2.23076 16.639L3 16C2.23076 16.639 2.23053 16.6387 2.23076 16.639Z"
          />
          <path
            fill="#808080"
            d="M13.1715 13.1716C12.4477 13.8955 12 14.8954 12 16C12 18.2091 13.7909 20 16 20C17.1046 20 18.1045 19.5523 18.8284 18.8285L13.1715 13.1716Z"
          />
          <path
            fill="#808080"
            d="M19.8645 17.0361L14.9639 12.1355C15.2943 12.0471 15.6417 12 16 12C18.2091 12 20 13.7909 20 16C20 16.3583 19.9529 16.7057 19.8645 17.0361Z"
          />
          <path
            fill="#808080"
            d="M22.7562 19.9278C23.4119 19.5391 24.0284 19.1212 24.5976 18.6977C25.7261 17.858 26.6409 17.0167 27.2727 16.386C27.4131 16.2459 27.5392 16.1164 27.6503 16C27.5392 15.8836 27.4131 15.7541 27.2727 15.614C26.6409 14.9833 25.7261 14.142 24.5976 13.3023C22.3183 11.6062 19.2796 10 16 10C15.0423 10 14.1052 10.137 13.2015 10.3731L11.5986 8.77022C12.9711 8.29802 14.4506 8 16 8C19.9058 8 23.3672 9.89378 25.7915 11.6977C27.0148 12.608 28.0027 13.5167 28.6857 14.1985C29.0277 14.54 29.2947 14.8259 29.4779 15.0286C29.5696 15.13 29.6404 15.2107 29.6893 15.2672C29.7002 15.2798 29.7099 15.2911 29.7186 15.3013C29.7295 15.314 29.7387 15.3247 29.7461 15.3335L29.7619 15.3523L29.7666 15.3579L29.7688 15.3605L29 16C29.7692 16.639 29.7684 16.6399 29.7682 16.6402L29.7666 16.6421L29.7619 16.6477L29.7461 16.6665L29.7186 16.6987L29.6893 16.7328C29.6404 16.7893 29.5696 16.87 29.4779 16.9714C29.2947 17.1741 29.0277 17.46 28.6857 17.8015C28.0027 18.4833 27.0148 19.392 25.7915 20.3023C25.305 20.6643 24.7767 21.0299 24.2115 21.3831L22.7562 19.9278Z"
          />
          <path
            fill="#808080"
            d="M29 16L29.7692 16.639C30.0769 16.2685 30.0765 15.7309 29.7688 15.3605C29.769 15.3608 29.7692 15.361 29 16Z"
          />
        </Fragment>
      );
    }

    if (!modalStore.modalError) {
      if (login) {
        return <Redirect to="/select-option-work/list-exercise" />;
      }

      if (sessionStore.auth) {
        return <Redirect to={from} />;
      }

      if (appStore.reload) {
        removeCache();
      }
    }

    return (
      <Fragment>
        <Title>
          {i18n.t('login_title')}
          <SubTitle>{i18n.t('login_description')}</SubTitle>
        </Title>

        <Container>
          <FlexDiv width="auto" minHeight="auto">
            <Box>
              <form onSubmit={this.prepareRequestToLogin}>
                <Fieldset>
                  <FormGroup>
                    <Label>{i18n.t('login_user')}</Label>
                    <Input
                      type="text"
                      className="login__user"
                      onChange={(event) => {
                        this.setState({ username: event.target.value });
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>{i18n.t('login_password')}</Label>
                    <div className="w-container-field">
                      <Input
                        type={appStore.showPassword ? 'text' : 'password'}
                        showPassword
                        placeholder="******"
                        onChange={(event) => {
                          this.setState({ password: event.target.value });
                        }}
                        className="login__password"
                        onKeyDown={this.handleKeyDownLogin}
                      />
                      <Button
                        className="w-show-password"
                        secondary="true"
                        minWidth="initial"
                        noBackgroundColor
                        large="true"
                        svg="true"
                        onClick={this.onClickShowPassword}
                        type="button"
                      >
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {svgButtonShowPassword}
                        </svg>
                      </Button>
                    </div>
                  </FormGroup>

                  <ForgotPassword onClick={this.showModalResetPassword}>
                    {i18n.t('forgot_password')}
                  </ForgotPassword>

                  <FormGroup noMarginBottom="true">
                    <Button type="submit" primary="true" large="true" block="true" className="login__submit">
                      {i18n.t('login_button')}
                    </Button>
                  </FormGroup>
                </Fieldset>
              </form>
            </Box>
          </FlexDiv>

          <Text color="#666" p={[3]} textAlign="center">
            {process.env.REACT_APP_VERSION}
          </Text>
        </Container>

        {modalStore.modalError && <ModalError />}
        {modalStore.modalNetwork && <ModalNetwork />}
      </Fragment>
    );
  }
}

export default observer(Login);
