import React from 'react';
import styled from 'styled-components';
import { Delete as BaseIconDelete } from 'components/icons';

const ModalListExerciseFormStyle = styled.li`
  min-height: 49px;
  background: #F4F4F5;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  margin-bottom: 8px

  &:nth-child(odd) {
    margin-right: 8px;
  }

  .title-exercise {
    padding: 10px 10px;
  }
`;

const IconDelete = styled(BaseIconDelete)`
  width: 15px;
  height: 16px;
`;

const ModalItemExerciseForm = (props) => {
  const {
    exercise,
    onClick,
  } = props;

  return (
    <ModalListExerciseFormStyle>
      <div className="title-exercise">{exercise.name}</div>
      <div><IconDelete ml={[2]} onClick={onClick} /></div>
    </ModalListExerciseFormStyle>
  );
};

export default ModalItemExerciseForm;
