import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Button,
} from 'components';

import {
  PaisWarningIconBase as BasePaisWarningIconBase,
} from '../icon';

const DropDownPaisStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .text-and-icon-pais {
    display: flex;
    justify-content: center;
    align-items: self-end;

    .icon-warning {
      margin-right: 18px;
    }
  }

  .title {
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSizes[0]};
  }

  .w-collapse-button {
    background-color: transparent;
    transform: rotateZ(0);
    transition: transform 0.2s ease-out;
    margin-right: 0px;

    &.w-collapse-opened {
      transform: rotateZ(180deg);
    }

    .icon {
      fill: #${(props) => props.fill || 'fff'};
    }
  }
`

function DropDownPais(props) {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    openCollpase,
    toggleCollpase,
  } = props;

  return (
    <DropDownPaisStyle className="header-button">
      <div className="text-and-icon-pais">
        <BasePaisWarningIconBase className="icon-warning" />
        <h3 className="title">{i18n.t('premissas_recommend')}</h3>
      </div>
      <Button
        className={`w-collapse-button ${openCollpase ? 'w-collapse-opened' : ''}`}
        secondary="true"
        rounded="true"
        minWidth="initial"
        onClick={() => toggleCollpase()}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="icon"
            d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
            fill="white"
          />
        </svg>
      </Button>
    </DropDownPaisStyle>
  )
}

DropDownPais.defaultProps = {
  toggleCollpase: () => {},
  openCollpase: false,
};

DropDownPais.propTypes = {
  toggleCollpase: PropTypes.func,
  openCollpase: PropTypes.bool,
};

export default DropDownPais;
