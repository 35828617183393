import styled, { css } from 'styled-components';

const WarningNotices = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 30px;
  position: absolute;
  right: 1px;
  top: 4px;
  display: none;
  ${(props) => props.notify
    && css`
      display: block;
    `};

  ${(props) => !props.notify
    && css`
      display: none;
    `};
`;

export default WarningNotices;
export { WarningNotices };
