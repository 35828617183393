import styled from 'styled-components';

const HistoryTrainingStyle = styled.div`
  color: #000;
  padding: 0px 20px;

  .header-training {
    display: flex;
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    color: #808080;
    text-transform: uppercase;
    padding-bottom: 25px;

    .item {
      margin-right: 24px;

      &.date {
        width: 88px;
        margin-left: 51px;
      }

      &.goal {
        width: 120px;
      }

      &.level {
        width: 96px;
      }
    }
  }

  .wrapper-list-training {
    height: 400px;
    overflow-y: auto;

    &.loading {
      height: 440px;
    }

    .item {
      margin-bottom: 32px;
      &.active {
        margin-bottom: 17px;

        .wrapper-info {
          font-weight: bold;
        }
      }
    }
  }

  .wrapper-info {
    display: flex;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;

    .item-info {
      margin-right: 24px;

      &.date {
        margin-right: 21px;
        width: 88px;
      }
    }

    .objective {
      width: 105px;
    }

    .level {
      width: 86px;

    }

    .w-collapse-button {
      width: 20px;
      height: 13px;
      transform: rotate(267deg);
      transition: transform 0.2s ease-out;
      margin: 0px;

      &.w-collapse-opened {
        transform: rotateZ(360deg);
      }
    }
  }

  .content-training {
    background: #eee;
    border-radius: 8px;
    padding: 8px 8px;
    margin-bottom: 10px;
  }
`;

export default HistoryTrainingStyle;
export { HistoryTrainingStyle };
