import { observable, decorate, action } from 'mobx';
import {
  getHistoryTraining as getHistoryTrainingApi,
} from '../services/api';

class HistoryTrainingStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.listExercise = [];
    this.showHistoryTraining = false;
  }

  setListExercise(list) {
    this.listExercise = list;
  }

  setShowHistoryTraining(toggle) {
    this.showHistoryTraining = toggle;
  }

  getLisTrainingGym() {
    return this.listExercise;
  }

  doRequestGetHistoryTraining() {
    const params = {
      peopleId: this.rootStore.peopleStore.peopleId,
    };

    const promiseHistoryTraining = async (resolve, reject) => {
      try {
        const res = await getHistoryTrainingApi(params, this.rootStore.sessionStore.token);
        this.setListExercise(res.data.weight_trainings);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseHistoryTraining(resolve, reject)
    ));
  }
}

decorate(HistoryTrainingStore, {
  listExercise: observable,
  showHistoryTraining: observable,
  doRequestGetHistoryTraining: action,
  getLisTrainingGym: action,
  setShowHistoryTraining: action,
});

export default HistoryTrainingStore;
