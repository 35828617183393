import {
  action, decorate,
} from 'mobx';

class ListExercisesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  isExercises(data) {
    return data && data.training_groups && data.training_groups.length;
  }

  isExercisesSuggestions(data) {
    return data && data.length;
  }
}

decorate(ListExercisesStore, {
  isExercises: action,
  isExercisesSuggestions: action,
});

export default ListExercisesStore;
