/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {
  Anamnese, Button, Title, SubTitle, Textarea, Text,
} from 'components';
import Context from 'stores/context';
import ModalNetwork from 'components/modal/modal-network';
import { ConcatUnitInUrl } from 'utils';
import ToogleRestriction from './component/ToogleRestriction';

class ObservationAndRestriction extends Component {
  static contextType = Context

  constructor() {
    super();
    this.hasEdit = this.hasEdit.bind(this);
    this.hasTraining = this.hasTraining.bind(this);
    this.postObservationAndRestriction = this.postObservationAndRestriction.bind(this);
    this.setTextObservationRestriction = this.setTextObservationRestriction.bind(this);
    this.getRoute = this.getRoute.bind(this);
    this.goBackPage = this.goBackPage.bind(this);
    this.toogleShowTextArea = this.toogleShowTextArea.bind(this);
  }

  state = {
    textObservationRestriction: '',
    toogleTextArea: false,
  };

  componentWillMount() {
    const { peopleStore } = this.context;

    if (peopleStore.observationAndRestriction) {
      this.setState({
        textObservationRestriction: peopleStore.observationAndRestriction,
        toogleTextArea: true,
      });
    } else {
      this.setState({ textObservationRestriction: peopleStore.observationAndRestriction });
    }
  }

  setTextObservationRestriction(e) {
    this.setState({ textObservationRestriction: e.currentTarget.value });
  }

  getTextObservationRestriction() {
    const { peopleStore } = this.context;
    const { textObservationRestriction } = this.state;
    if (textObservationRestriction !== peopleStore.observationAndRestriction) {
      return textObservationRestriction;
    }

    return false;
  }

  getRenderTextBack() {
    const { i18n } = this.context;
    return (this.hasEdit()) ? i18n.t('cancel') : i18n.t('anamnesis_question_back');
  }

  getRoute() {
    const { trainingCreationStore } = this.context;
    if (this.hasEdit()) {
      trainingCreationStore.setEditObservationOrRestriction(false);
      return ConcatUnitInUrl('/profile');
    }

    return ConcatUnitInUrl('/suggestions');
  }

  getRenderTextButton() {
    const { i18n } = this.context;
    return (this.hasEdit()) ? i18n.t('save') : i18n.t('finish');
  }

  postObservationAndRestriction = async (e) => {
    const {
      trainingCreationStore,
      peopleStore,
      modalStore,
      buildTrainingStore,
    } = this.context;
    const { history } = this.props;
    const text = this.getTextObservationRestriction();
    const data = {
      peopleId: peopleStore.peopleId,
      body: this.makeObjTextObservationRestriction(text),
    };

    let info;
    e.preventDefault();

    if (text !== false) {
      try {
        modalStore.closeModalError();
        info = await trainingCreationStore.doSendObservationRestrictionApi(data);

        if (this.hasEdit() && this.hasTraining()) {
          await buildTrainingStore.doRequestCoachSync(peopleStore.getCurrentWeightTraining.id);
        }

        const url = this.getRoute();
        history.push(url);
      } catch (err) {
        modalStore.showModalError(err);
      }
    } else {
      const url = this.getRoute();
      history.push(url);
    }

    return info;
  }

  makeObjTextObservationRestriction(text) {
    return {
      physical_evaluation: {
        observation: text,
      },
    };
  }

  goBackPage() {
    const { trainingCreationStore } = this.context;
    const { history } = this.props;

    if (this.hasEdit()) {
      trainingCreationStore.setEditObservationOrRestriction(false);
    }

    history.goBack();
  }

  hasTraining() {
    const { trainingCreationStore } = this.context;
    return !!(trainingCreationStore.weightTrainingId);
  }

  hasEdit() {
    const { trainingCreationStore } = this.context;
    return trainingCreationStore.editObservationOrRestriction;
  }

  toogleShowTextArea(status) {
    if (status === false) {
      this.setState({ textObservationRestriction: '' });
      document.querySelector('#observation-restriction').value = '';
    }

    this.setState({ toogleTextArea: status });
  }

  render() {
    const { peopleStore, i18n, modalStore } = this.context;
    const {
      toogleTextArea,
    } = this.state;

    return (
      <Anamnese>
        <Title type={1} as="h2" fontWeight="358" marginBottom="24">
          {i18n.t('anamnesis_observation_restriction')}
          <SubTitle fontWeight="325">{i18n.t('anamnesis_observation_restriction_description')}</SubTitle>
        </Title>

        <ToogleRestriction
          toogle={this.toogleShowTextArea}
          toogleButton={toogleTextArea}
        />

        <div className={`toogle-restriction ${toogleTextArea ? 'active' : ''}`}>
          <Text
            className="warning-info"
            color="#ed7d25"
          >
            {i18n.t('anamnesis_observation_restriction_warning')}
          </Text>

          <Textarea
            tabIndex="0"
            id="observation-restriction"
            minHeight="169px"
            marginBottom="32px"
            onChange={this.setTextObservationRestriction}
            defaultValue={peopleStore.observationAndRestriction}
          />
        </div>

        <Button
          backQuestion="true"
          onClick={this.goBackPage}
        >
          {this.getRenderTextBack()}
        </Button>
        <Button
          primary
          className="submit__restriction"
          onClick={(e) => {
            this.postObservationAndRestriction(e);
          }}
        >
          {this.getRenderTextButton()}
        </Button>
        {modalStore.modalNetwork && <ModalNetwork tryAgain={this.postObservationAndRestriction} />}
      </Anamnese>
    );
  }
}

export default observer(ObservationAndRestriction);
