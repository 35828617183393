import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './locales/es.json';
import esAR from './locales/es-AR.json';
import esCL from './locales/es-CL.json';
import esCO from './locales/es-CO.json';
import esDO from './locales/es-DO.json';
import esEC from './locales/es-EC.json';
import esGT from './locales/es-GT.json';
import esMX from './locales/es-MX.json';
import esPA from './locales/es-PA.json';
import esPE from './locales/es-PE.json';
import esPY from './locales/es-PY.json';
import esSV from './locales/es-SV.json';
import esHN from './locales/es-HN.json';
import esCR from './locales/es-CR.json';
import ptBR from './locales/pt-BR.json';

const variableAmbient = process.env.REACT_APP_LANGUAGE || 'pt-BR';
const language = variableAmbient.replace('-', '');
const genericLanguage = (/pt/).test(language) ? 'ptBR' : 'es';

const resources = {
  es,
  esAR,
  esCL,
  esCO,
  esDO,
  esEC,
  esGT,
  esMX,
  esPA,
  esPE,
  esPY,
  esSV,
  esHN,
  esCR,
  ptBR,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: genericLanguage,
    lng: language,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
