import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import PropTypes from 'prop-types';

import {
  Text,
  Box,
  Flex,
} from 'rebass';

import {
  AliasTeacher,
  Button,
  ButtonDropDown,
} from 'components';

import ListExercises from 'views/Suggestions/Component/ListExercises';

const ItemWorkoutStyle = styled.div`
  border-bottom: 1px solid #404040;

  &:last-child {
    border: 0px;
  }

  @media(${props => props.theme.mediaScreen.minTablet}) and (${props => props.theme.mediaScreen.maxTablet}){
    .name-training {
      width: 310px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .wrapper-buttons {
    display: flex;
    height: 52px;
    padding-right: 12px;

    .wrapper-dropdown {
      margin-right: 16px;
    }

    .button-send {
      height: 40px;
      padding: 13px 20px;
    }

    .w-collapse-button {
      background-color: transparent;
      transform: rotateZ(0);
      transition: transform 0.2s ease-out;
      margin: 0px;

      &.w-collapse-opened {
        transform: rotateZ(180deg);
      }
    }
  }

  .name-teacher {
    margin-top: 12px;
  }
`;

const ItemWorkout = (props) => {
  const [open, setOpen] = useState(false);
  const [typeEvent, setTypeEvent] = useState(false);
  const context = useContext(Context);
  const {
    i18n,
    trainingCreationStore,
    programStore,
    buildTrainingStore,
    modalStore,
    peopleStore,
    toggleStore,
    teacherTrainingStore,
    rulesPaisStore,
  } = context;

  const {
    exercise,
    history,
    tabSelected,
  } = props;

  const editItem = () => {
    setTypeEvent('editData');
  };

  const sendData = () => {
    buildTrainingStore.setSuggestionWorkoutBuild(exercise);
    buildTrainingStore.setToggleAllowEditDateTraining(true);
    buildTrainingStore.setTemplateSuggestionSend(true);
    const url = ConcatUnitInUrl('/build-training-send');
    history.push(url);
  };

  const hasWallet = () => (toggleStore.allowRenderWalletUnit() ? '#ffb612' : '#fff');

  useEffect(() => {
    const callRulesPais = async() => {
      trainingCreationStore.setPaisPremisses({
        pais_premisses: true,
      });

      const objParams = {
        programId: teacherTrainingStore.programId,
        levelId: programStore.levelId,
        profileId: trainingCreationStore.profileSelected,
        paisPremisses: trainingCreationStore.paisPremisses,
      };

      try {
        await rulesPaisStore.doGetRules(objParams);
        sendEdit();
      } catch (error) {
        trainingCreationStore.setPaisPremisses({
          pais_premisses: false,
        });
        sendEdit();
      }
    };

    const sendEdit = async () => {
      let url;
      try {
        await trainingCreationStore.doRequestCreateSuggestionEdit({
          trainingId: exercise.id,
          peopleId: peopleStore.peopleId,
        });
        modalStore.closeModalError();
        buildTrainingStore.setToggleAllowEditDateTraining(true);
        url = ConcatUnitInUrl('/build-edit/build-training-list');
        history.push(url);
      } catch (err) {
        const msgTitle = i18n.t('unexpected_error');
        const msgDescription = i18n.t('redirect_to_profile');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription(msgDescription);
        modalStore.setModalNetworkCallback(editItem);
        modalStore.showModalError(err);
        window.scroll(0, 0);
        setTypeEvent(false);
      }
    };


    if (typeEvent === 'editData') {
      const rulesPremissas = toggleStore.validateToggle({
        toggleUnitBlock: 'toogleUnitPaisBlock',
        toggleUnitAllow: 'toogleUnitPaisAllow',
        toggleCountry: 'allowPaisCountry',
      });

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:suggestion',
        eventAction: 'click:table',
        eventLabel: `edit_${tabSelected}_workout`,
        acronym: window.localStorage.getItem('w-acronym'),
      });

      if(rulesPremissas) {
        callRulesPais();
      } else {
        sendEdit();
      }
    }
  }, [
    trainingCreationStore,
    modalStore,
    programStore,
    buildTrainingStore,
    exercise,
    history,
    i18n,
    typeEvent,
    tabSelected,
    peopleStore,
    teacherTrainingStore,
    rulesPaisStore,
    toggleStore,
  ]);

  const toggle = () => {
    setOpen(!open);
  };

  const rendetNameTeacher = () => {
    if (exercise.teacher_name) {
      return (
        <Text color="white" fontSize="14px" className="name-teacher">
          {i18n.t('name_teacher')}
          <strong>{exercise.teacher_name}</strong>
        </Text>
      );
    }

    return '';
  };

  const listExercises = open && <ListExercises id={exercise.id} />;

  return (
    <ItemWorkoutStyle className="list-item-workout">
      <Flex justifyContent="space-between" pt="34px" pb="34px" alignItems="center">
        <Box pl="12px" className="title">
          <Flex mb={2}>
            <AliasTeacher color="white" pt={1} className="name-training" fontSize="20">
              {exercise.alias || exercise.name}
            </AliasTeacher>
          </Flex>
          <Text color="white" fontSize="14px">
            {exercise.description}
          </Text>
          {rendetNameTeacher()}
        </Box>

        <div className="wrapper-buttons">
          <ButtonDropDown>
            <li
              onClick={() => { editItem('editData'); }}
              role="presentation"
            >
              {i18n.t('edit')}
            </li>
          </ButtonDropDown>

          <Button
            primary="true"
            minWidth="initial"
            className="button-send"
            onClick={() => { sendData(); }}
          >
            {i18n.t('send')}
          </Button>

          <Button
            className={`w-collapse-button ${open ? 'w-collapse-opened' : ''}`}
            secondary="true"
            rounded="true"
            minWidth="initial"
            onClick={() => toggle(exercise)}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
                fill={hasWallet()}
              />
            </svg>
          </Button>

        </div>

      </Flex>
      {listExercises}
    </ItemWorkoutStyle>
  );
};

ItemWorkout.defaultProps = {
  history: {},
  tabSelected: 'my_training',
  exercise: {},
};

ItemWorkout.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  tabSelected: PropTypes.string,
  exercise: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default ItemWorkout;
