import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Text,
} from 'components';

const ProgressBarStyle = styled.div`
  width: 100%;
  padding-top: 10px;

  @media(${props => props.theme.mediaScreen.cellphone}) {
    width: 100%;
    padding-top: 0px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 8px;
  }

  .myProgress {
    width: 100%;
    background-color: #e5e5e5;
    overflow: hidden;
    height: 10px;
    border-radius: 14px;
    position: relative;
  }

  .myBar {
    width: ${props => props.progressBar || '0'}%;
    height: 30px;
    background-color: ${props => props.theme.colors.yellow};
    height: 10px;
    border-radius: 14px
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;

    &.in_recovery {
      background: #e98d35;
    }

    &.closed {
      background: #c92c3f;
    }
  }

  .sub-myBar {
    width: ${props => props.progressExpected || '0'}%;
    height: 30px;
    background-color: #94bfdd;
    height: 10px;
    border-radius: 14px
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .wrapper-info-training {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    color:  ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.Gotham};
    font-size: ${props => props.theme.fontSizes[2]};
  }
`;


const ProgressBar = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;
  const {
    label,
    progressPercent,
    progressExpected,
    completedSessions,
    totalSessions,
    remainsDays,
    hiddenRemainsDays,
    hiddenSubBar,
    status,
  } = props;

  const renderRemainsDays = () => {
    if (hiddenRemainsDays) return false;

    return (
      <div className="wrapper-info-training">
        <div className="value-progress">
          <span>{`${completedSessions} / ${totalSessions}`}</span>
        </div>
        <div className="remains-days">
          <span>{i18n.t('remains_days', { remainsDays })}</span>
        </div>
      </div>
    );
  };

  const renderSubBar = () => {
    if (hiddenSubBar) return false;

    return (
      <div className="sub-myBar" />
    );
  };

  return (
    <ProgressBarStyle
      progressBar={progressPercent}
      progressExpected={progressExpected}
    >
      <Text as="h2" type={4} align="initial">{i18n.t(label)}</Text>

      <div className="myProgress">
        <div className={`myBar ${status}`} />
        {renderSubBar()}
      </div>
      {renderRemainsDays()}
    </ProgressBarStyle>
  );
};

ProgressBar.defaultProps = {
  progressPercent: 0,
  progressExpected: 0,
  label: '',
  completedSessions: 0,
  totalSessions: 0,
  remainsDays: 0,
  hiddenRemainsDays: true,
  hiddenSubBar: true,
  status: 'default',
};

ProgressBar.propTypes = {
  progressPercent: PropTypes.number,
  progressExpected: PropTypes.number,
  label: PropTypes.string,
  completedSessions: PropTypes.number,
  totalSessions: PropTypes.number,
  remainsDays: PropTypes.number,
  hiddenRemainsDays: PropTypes.bool,
  hiddenSubBar: PropTypes.bool,
  status: PropTypes.string,
};


export default ProgressBar;
