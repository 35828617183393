import styled from 'styled-components';

const Textarea = styled.textarea`
  width: 100%;
  min-height: ${(props) => props.minHeight || '96px'};
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: #dedede;
  border-radius: 4px;
  appearance: none;
  color: #808080;
  font-family: 'Gotham';
  font-size: 16px;
  line-height: 19px;
  padding: 14px 64px 11px 20px;
  resize: none;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  &:focus {
    box-shadow: inset 0 0 0 3px #ffb612;
    border-color: #ffb612;
  }

  &::-webkit-input-placeholder {
    color: #808080;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: #808080;
  }

  &:-ms-input-placeholder {
    color: #808080;
  }

  &:-moz-placeholder {
    color: #808080;
  }
`;

export { Textarea };
export default Textarea;
