import styled from 'styled-components';

const Suggestion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border-width: 2px;
  border-style: solid;
  border-color: #404040;
  border-radius: 4px;
  padding: 14px 24px 14px 24px;
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    flex-wrap: wrap;
    min-height: 128px;
  }

  .w-suggestion-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  .w-text-status {
    width: 100%;
    color: #fff;
    font-family: 'Gotham';
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 0;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .w-collapse-button {
      background-color: transparent;
      transform: rotateZ(0);
      transition: transform 0.2s ease-out;
      margin: 0px;

      &.w-collapse-opened {
        transform: rotateZ(180deg);
      }
    }
  }

  .w-title, .description-training {
    color: #fff;
    font-family: 'Gotham';
    font-size: 18px;
    line-height: 24px;
    text-transform: capitalize;

    @media screen and (min-width: 768px) {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      margin-bottom: 0;
    }
  }

  .description-training {
    font-size: 16px;
    font-weight: 300;
    text-transform: initial;
  }

  .w-actions {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 15px;

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }

    button,
    a {
      &:first-child {
        @media screen and (min-width: 768px) {
          margin-bottom: 0;
          margin-right: 22px;
        }
      }
    }
  }
`;

export { Suggestion };
export default Suggestion;
