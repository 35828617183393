import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import NotFound from 'views/NotFound/NotFound';
import {
  Button,
  HeaderSession,
  GridContainer,
  Row,
  Column,
  PrivateRoute,
} from 'components';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalSuccess from 'components/modal/modal-success';
import ModalNetwork from 'components/modal/modal-network';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import BuildTrainingSuccess from './BuildTrainingSuccess';
import BuildTrainingList from './BuildTrainingList';

class BuildingEdit extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);

    this.sendTraining = this.sendTraining.bind(this);
    this.doRequestTrainingToUser = this.doRequestTrainingToUser.bind(this);
    this.reloadPostData = this.reloadPostData.bind(this);
    this.requestToSync = this.requestToSync.bind(this);
    this.getValueAnalytics = this.getValueAnalytics.bind(this);
    this.renderModal = this.renderModal.bind(this);
  }

  reloadPostData() {
    // eslint-disable-next-line no-use-before-define
    this.doRequestTrainingToUser();
  }

  renderModal() {
    const {
      buildTrainingStore,
      modalStore,
      trainingCreationStore,
      i18n,
      appStore,
    } = this.context;

    const msgTitle = i18n.t('updated_with_success');
    const msgDescription = '';
    buildTrainingStore.setHasDiffWorkout(false);

    if (trainingCreationStore.paisPremisses) {
      appStore.toggleloading(false);
      modalStore.toggleModal('modalSuccessPremissas', true);
    } else {
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      appStore.toggleloading(false);
      modalStore.toggleModal('modalSync', true);
    }
  }

  doRequestTrainingToUser() {
    const {
      buildTrainingStore,
      modalStore,
      trainingCreationStore,
      i18n,
      appStore,
    } = this.context;
    let msgTitle;
    let msgDescription;

    modalStore.closeModalError();
    appStore.toggleloading(true);

    buildTrainingStore
      .doRequestCoachSync(trainingCreationStore.weightTrainingId)
      .then(() => {
        this.renderModal();
      })
      .catch((error) => {
        msgTitle = i18n.t('error_sending_to_user');
        msgDescription = i18n.t('verify_training_exercise_and_config');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription(msgDescription);
        modalStore.setModalNetworkCallback(this.reloadPostData);
        appStore.toggleloading(false);
        modalStore.showModalError(error);
      });
  }

  getRulesPremissas() {
    const {
      rulesPaisStore,
      buildTrainingStore,
      modalStore,
    } = this.context;

    const hasErrorSend = buildTrainingStore.hasErrorInSendPremissa();
    const hasWarningPraze = rulesPaisStore.hasErrorCheckedPraze();

    if (hasErrorSend || hasWarningPraze) {
      modalStore.toggleModal('modalErrorPremissas', true);
    } else {
      this.doRequestTrainingToUser();
    }
  }

  requestToSync() {
    const {
      trainingCreationStore,
    } = this.context;
    const hasPremissa = trainingCreationStore.paisPremisses;

    if (hasPremissa) {
      this.getRulesPremissas();
    } else {
      this.doRequestTrainingToUser();
    }
  }

  getValueAnalytics() {
    const { buildTrainingStore } = this.context;

    const tab = buildTrainingStore.analyticsTypeTrainingSuggestion;
    if (tab === 'my_training') {
      return 'send_my_training_workout_without_editing';
    }

    if (tab === 'training_wallet') {
      return 'send_training_wallet_workout_without_editing';
    }

    return 'send_suggestion_workout_with_editing';
  }

  sendTraining() {
    const { history } = this.props;
    const {
      buildTrainingStore,
      modalStore,
      i18n,
    } = this.context;
    let msgTitle;
    let msgDescription;
    const dataEventLabel = this.getValueAnalytics();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:suggestion',
      eventAction: 'click:table',
      eventLabel: dataEventLabel,
      acronym: window.localStorage.getItem('w-acronym'),
      fluxAnamnese: 'remove_anamnese',
    });

    if (!buildTrainingStore.hasMinExercisePerTraining) {
      msgTitle = '';
      msgDescription = i18n.t('verify_training_exercise');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.toggleModal('modalError', true);
      return true;
    }

    buildTrainingStore.setResendTraining(true);
    const url = ConcatUnitInUrl('/build-training-send');
    buildTrainingStore.setEditSuggestionSend(true);
    history.push(url);

    return true;
  }

  render() {
    const { history } = this.props;
    const {
      i18n,
      buildTrainingStore,
      modalStore,
      sessionStore,
    } = this.context;

    const modalSuccessEdit = modalStore.modalSuccessEdit && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalSuccessEdit', false);
          modalStore.toggleModal('modalSuccess', false);
        }}
      />
    );

    const modalSuccessSync = modalStore.modalSync && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalSync', false);
          modalStore.toggleModal('modalSuccess', false);
          const url = ConcatUnitInUrl('/profile');
          buildTrainingStore.setToggleAllowEditDateTraining(false);
          history.push(url);
        }}
      />
    );

    const modalError = modalStore.modalError && <ModalError />;

    const isDisabled = !buildTrainingStore.hasMinExercisePerTraining;

    const eventButtonHeader = () => {
      buildTrainingStore.setToggleAllowEditDateTraining(false);
      const url = ConcatUnitInUrl('/profile');
      history.push(url);
    };

    return (
      <Fragment>
        <GridContainer className="mg-bottom">
          <Row>
            <Column sm="12" xs="12">
              <HeaderSession
                statusActionButton={false}
                isBack={false}
                eventButton={eventButtonHeader}
                nameClass="w-btn-back"
                title={sessionStore.renderListNavigator(i18n.t('profile_button_new_training'))}
                subTitle={i18n.t('profile_mount_training')}
              >

                <Button
                  primary="true"
                  disabledWithClick={isDisabled}
                  className="post-training"
                  minWidth="initial"
                  onClick={this.sendTraining}>
                  {i18n.t('next')}
                </Button>
              </HeaderSession>
            </Column>
          </Row>
        </GridContainer>


        <Switch>
          <PrivateRoute path="/build-edit/build-training-list" component={BuildTrainingList} />
          <PrivateRoute
            path="/build-training/build-training-success"
            component={BuildTrainingSuccess}
          />
          <Route path="*" component={NotFound} />
        </Switch>


        {modalError}
        {modalSuccessSync}
        {modalSuccessEdit}
        {modalStore.modalNetwork && <ModalNetwork />}
        {modalStore.modalSuccess && <ModalSuccess />}
      </Fragment>
    );
  }
}

export default observer(BuildingEdit);
