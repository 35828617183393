import {
  observable,
  decorate,
  action,
} from 'mobx';

import { IfeedBackBarStore } from './IfeedBackBarStore';

class FeedBackBarStore implements IfeedBackBarStore {
  rootStore = {};

  activeBar = false;

  message = '';


  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  showFeebBackToUser(status: boolean, msg: string): void {
    this.hiddenFeedBackBar();
    this.setActive(status);
    this.setMessage(msg);


    setTimeout(() => {
      this.setActive(false);
      this.setMessage('');
    }, 4000);
  }

  setActive(status: boolean): void {
    this.activeBar = status;
  }

  setMessage(msg: string): void {
    this.message = msg;
  }

  hiddenFeedBackBar(): void {
    this.setActive(false);
    this.setMessage('');
  }
}

decorate(FeedBackBarStore, {
  activeBar: observable,
  message: observable,
  showFeebBackToUser: action,
  setActive: action,
  setMessage: action,
  hiddenFeedBackBar: action,
});

export default FeedBackBarStore;
