import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  HeaderSession, GridContainer, Row, Column, PrivateRoute,
} from 'components';
import context from 'stores/context';
import ModalLeave from 'components/modal/modal-leave';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import NotFound from 'views/NotFound/NotFound';
import AnamnesisLevelChoose from './LevelChoose';

class Anamnesis extends Component {
  static contextType = context;

  constructor(props) {
    super(props);
    this.eventButtonHeader = this.eventButtonHeader.bind(this);
  }

  backButton() {
    const { history } = this.props;
    const { teacherTrainingStore } = this.context;

    let url;
    const contextComponent = teacherTrainingStore.teacherInteface;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:button',
      eventLabel: 'previous_page',
    });

    url = contextComponent ? '/select-option-work/list-exercise' : '/profile';
    url = ConcatUnitInUrl(url);
    return history.push(url);
  }

  closeButton() {
    const { i18n, modalStore } = this.context;
    const msgSetDescription = i18n.t('stop_fill_anamnese');
    modalStore.setTitle('');
    modalStore.setDescription(msgSetDescription);
    modalStore.toggleModal('modalAnamnesis', true);
  }

  hasQuestion() {
    const { history } = this.props;
    const hasQuestion = /anamnesis\/question/.test(history.location.pathname);
    return hasQuestion;
  }

  hasClosed() {
    return !(this.hasQuestion());
  }

  eventButtonHeader() {
    if (this.hasQuestion()) {
      return this.closeButton();
    }
    return this.backButton();
  }

  render() {
    const { i18n, modalStore, sessionStore } = this.context;
    const { history } = this.props;
    const hasBack = this.hasClosed();

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'anamnesis',
    });

    return (
      <>
        <GridContainer>
          <Row>
            <Column sm="12" xs="12">
              <HeaderSession
                statusActionButton={false}
                isBack={hasBack}
                eventButton={this.eventButtonHeader}
                nameClass="w-btn-back"
                title={sessionStore.renderListNavigator(i18n.t('profile_button_new_training'))}
                subTitle={i18n.t('anamnesis_title')}
              />
            </Column>

            <Column sm="12" xs="12">
              <Switch>
                <PrivateRoute path="/anamnesis/level-choose" component={AnamnesisLevelChoose} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Column>
          </Row>
        </GridContainer>
        {modalStore.modalAnamnesis && (
          <ModalLeave
            onClickLeave={() => {
              const url = ConcatUnitInUrl('/profile');
              history.push(url);
            }}
          />
        )}
      </>
    );
  }
}

export default observer(Anamnesis);
