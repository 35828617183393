import React, { useEffect, useContext, useState } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import {
  GridContainer,
  TabResponsive,
  HeaderSession,
  ContainerBox,
} from 'components';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import Trainings from './Trainings/Trainings';
import Anamnesis from './Anamnesis/Anamnesis';
import Programs from './Programs/Programs';

const ProfileStyle = styled.div`
  margin-top: -28px;

  .wrapper-header {
    padding-left: 24px;
    padding-right: 24px;
    background-color: ${props => props.theme.colors.darkgray};
    margin-bottom: 24px;
    padding-top: 15px;
    padding-bottom: 16px;
  }

  @media(${props => props.theme.mediaScreen.cellphone}) {
    wrapper-header {
      height: 100px;
    }
  }
`;

const ProfileTab = ({ history }) => {
  const context = useContext(Context);
  const {
    i18n,
    sessionStore,
    peopleStore,
    modalStore,
    addExercisesStore,
    trainingCreationStore,
    appStore,
    profileStore,
    followUpStore,
    cronometerStore,
    teacherTrainingStore,
    buildTrainingStore,
  } = context;

  const [reqPeople, setReqPeople] = useState(null);
  const dropDown = false;

  useEffect(() => {
    cronometerStore.stop();
    cronometerStore.setFluxAnamnese(false);
    buildTrainingStore.setAnalyticsTypeTrainingSuggestion('');

    const reset = () => {
      modalStore.reset();
      modalStore.closeModalError();
      addExercisesStore.clearExercisesSelected();
      sessionStore.resetNavigation();
      trainingCreationStore.setPaisPremisses({});
      trainingCreationStore.resetEmphasis();
      teacherTrainingStore.setTeacherInteface(false);
    };
    let msgTitle;
    let msgDescription;

    const call = async () => {
      appStore.toggleloading(true);
      let data;
      try {
        data = await peopleStore.doRequestPeopleShow({ id: peopleStore.peopleId });
        setReqPeople('done');
        profileStore.setInitialTable();

        if (peopleStore.hasProgramsDefault()) {
          followUpStore.reset();
          await followUpStore.doRequestFollowUp(peopleStore.peopleId, peopleStore.programs.slug);
        }

        appStore.toggleloading(false);
      } catch (err) {
        if (err.status === 404) {
          setReqPeople('404Error');
          appStore.toggleloading(false);
        } else {
          msgTitle = i18n.t('error_show_data_studente');
          modalStore.setTitle(msgTitle);
          modalStore.showModalError(err);
          setReqPeople('error');
          appStore.toggleloading(false);
        }
        data = err;
      }
      return data;
    };

    if (reqPeople === null) {
      reset();
      call();

      PostGaAnalytics('send', {
        hitType: 'pageview',
        page: 'profile',
      });
    }

    if (reqPeople === '404Error') {
      msgTitle = i18n.t('student_not_found');
      msgDescription = i18n.t('studente_not_found_data');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.toggleModal('modalError', true);
    }
  }, [
    reqPeople,
    modalStore,
    addExercisesStore,
    i18n,
    peopleStore,
    sessionStore,
    trainingCreationStore,
    appStore,
    profileStore,
    followUpStore,
    cronometerStore,
    teacherTrainingStore,
    buildTrainingStore,
  ]);

  const elementSelected = (view) => {
    profileStore.selectTab(view);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:tab',
      eventLabel: `profile_${view}`,
    });
  };

  const makeTabLabel = () => {
    const listTab = [
      {
        id: 0,
        view: 'trainings',
        name: i18n.t('trainings'),
        handleEvent: () => { elementSelected('trainings'); },
      },
      {
        id: 1,
        view: 'anamnesis_title',
        name: i18n.t('anamnesis_title'),
        handleEvent: () => { elementSelected('anamnesis_title'); },
      },
    ];

    if (peopleStore.hasProgramsDefault() || peopleStore.hasProgramSmart()) {
      const objProgram = {
        id: 2,
        view: 'programs',
        name: i18n.t('programs'),
        handleEvent: () => { elementSelected('programs'); },
      };

      listTab.unshift(objProgram);
    }

    return listTab;
  };

  const renderComponent = () => {
    if (profileStore.itemSeleted === 'trainings') {
      return (
        <Trainings history={history} />
      );
    }

    if (profileStore.itemSeleted === 'programs') {
      return (
        <Programs history={history} />
      );
    }

    return (
      <Anamnesis history={history} />
    );
  };

  const eventButtonHeader = () => {
    const url = ConcatUnitInUrl('/select-option-work/list-exercise');
    history.push(url);
  };

  const modalError = modalStore.modalError && (
    <ModalError
      onClick={() => {
        const url = ConcatUnitInUrl('/');
        history.push(url);
      }}
    />
  );

  const modalNetwork = modalStore.modalNetwork && (
    <ModalNetwork
      tryAgain={() => {
        setReqPeople(null);
      }}
    />
  );



  const textLabel = i18n.t(profileStore.itemSeleted);

  return (
    <ProfileStyle className="profile">
      <header className="wrapper-header">
        <HeaderSession
          statusActionButton={false}
          isBack
          eventButton={eventButtonHeader}
          nameClass="w-btn-back"
          title={sessionStore.renderListNavigator(i18n.t('profile_title'))}
          subTitle={peopleStore.peopleName.split(' ')[0]}
          titlePlan={i18n.t('health_insurance')}
          namePlan={peopleStore.namePlanPeople}
        />
      </header>
      <GridContainer>
        <TabResponsive
          tabList={makeTabLabel()}
          selected={profileStore.itemSeleted}
          nameLabel={textLabel}
          showDropDown={dropDown}
        />
        <ContainerBox>
          {renderComponent()}
        </ContainerBox>
      </GridContainer>

      {modalError}
      {modalNetwork}

    </ProfileStyle>
  );
};

ProfileTab.defaultProps = {
  history: {},
};

ProfileTab.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(ProfileTab);
