/* eslint comma-dangle: 0 */
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Context from '../../stores/context';

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9;

  .w-btn-close {
    background-color: #dedede;
    float: right;
    margin-bottom: 70px;
  }

  .w-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;

    ${(props) => !props.paddingSide
    && css`
        padding: 16px;

        @media screen and (min-width: 544px) {
          padding: 32px;
        }
      `};

    ${(props) => props.paddingSide
    && css`
        padding: 16px 0;

        @media screen and (min-width: 544px) {
          padding: 32px 0;
        }
      `};

    ${(props) => !props.fullScreen
    && css`
        border-radius: 4px;

        @media screen and (min-width: 544px) {
          width: 562px;
          min-height: initial;
        }
      `};

    ${(props) => props.fullScreen
    && css`
        @media screen and (min-width: 544px) {
          width: 100%;
          min-height: 100vh;
        }
      `};

    .w-modal-body {
      width: 100%;

    }

    .w-modal-body-form {
      height: 730px;
      overflow-y: auto;

      .wrapper-children {
        clear: both;
      }
    }

    .w-modal-title {
      color: #000;
      font-family: 'Gotham';
      font-size: 24px;
      font-weight: normal;
      line-height: 29px;
      margin-bottom: 42px;
    }

    .w-modal-footer {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 155px;

        + button {
          margin-left: 24px;
        }
      }
    }
  }
`;

export class Modal extends Component {
  static contextType = Context;

  constructor() {
    super();

    this.onClickBackDrop = this.onClickBackDrop.bind(this);
  }

  onClickBackDrop(event) {
    const { eventChildren } = this.props;

    if (event.target.classList.contains('w-modal')) {
      const modalTarget = window.localStorage.getItem('w-modal-opened');
      this.closeModal(modalTarget);
    }

    if (eventChildren) {
      eventChildren();
    }
  }

  closeModal(modalTarget) {
    const { modalStore } = this.context;
    modalStore.toggleModal(modalTarget, false);
  }

  render() {
    const { children } = this.props;

    return (
      <StyledModal className="w-modal" onClick={this.onClickBackDrop} {...this.props}>
        {children}
      </StyledModal>
    );
  }
}

Modal.defaultProps = {
  eventChildren: () => {},
  children: <div />
};

Modal.propTypes = {
  eventChildren: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
