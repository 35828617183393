import styled from 'styled-components';

const SliderRange = styled.div`
  width: 100%;
  color: #fff;

  .progressao {
    --colorYellow: ${(props) => props.theme.colors.yellow};
    --val: ${(props) => props.val}
    --min: 0;
    --max: 100;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--val) - var(--min))/var(--range));
    --sx: calc(.5*1.5em + var(--ratio)*(100% - 1.5em));
    --width: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 1.5em;
    background: transparent;
    font: .82em/1 arial, sans-serif;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      box-sizing: border-box;
      border: none;
      width: var(--width);
      height: 0.70em;
      background: #fff;
      border-radius: 40px;
    }

    &::-webkit-slider-thumb {
      margin-top: -0.89em;
      box-sizing: border-box;
      border: 6px solid #000;
      width: 2.2em;
      height: 2.2em;
      border-radius: 50%;
      background: ${(props) => props.theme.colors.yellow};
    }

    &::-webkit-slider-runnable-track {
      background: linear-gradient(var(--colorYellow), var(--colorYellow)) 0/var(--sx) 100% no-repeat #404040;
    }
  }

  .range__list {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: center;
    height: auto;
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 60px;
    color: #fff;
  }

  .range__opt {
    display:none;

    &.active {
      display: block;
    }
  }

  .range__list__opt:before {
    content: '';
    display: block;
    width: 0;
    height: auto;
    padding-left: 3px;
    text-indent: 0;
  }

  &.slider{
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .slider {
    &__list {
      margin: 0px;
      width: 156px;
      margin-left: 10px;
      display: flex;
      border: 1px solid #404040;
      box-sizing: border-box;
      border-radius: 4px;
      height: 48px;
      align-items: center;
    }

    &__info {
      width: 228px;
    }
  }
`;

export { SliderRange };
export default SliderRange;
