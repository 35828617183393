import React, { useContext } from 'react';
import { Formik } from 'formik';
import {
  Button,
  Fieldset,
  FormGroup,
  Input,
  Label,
  FlexDiv,
  Text,
  StyleEditDuration,
} from '../../../../components';
import Context from '../../../../stores/context';

export const FormEditExercise = ({ updateList }) => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    modalStore,
    i18n,
    appStore,
  } = context;
  const setInitialValues = {
    duration: '',
  };

  const reloadPostData = () => {
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;

    // eslint-disable-next-line no-use-before-define
    updateDuration(values, setSubmitting);
  };

  const updateDuration = (values, setSubmitting) => {
    appStore.clearObjTemp();

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    setTimeout(() => {
      buildTrainingStore.clickEditAllItemsSelected([{
        duration: values.duration,
      }], 'minutes');

      if (buildTrainingStore.hasDiff) {
        buildTrainingStore.doRequestPatchTrainingExercises(
          buildTrainingStore.getExercisesUpdated,
          'duration',
        ).then(() => {
          appStore.clearObjTemp();
          buildTrainingStore.diff = [];
          buildTrainingStore.editAllItemsSelected = [];
          modalStore.closeModalError();
          modalStore.toggleModal('modalEditAllExercises', false);
          updateList();
        }).catch((error) => {
          window.scroll(0, 0);
          modalStore.setModalNetworkCallback(reloadPostData);
          modalStore.showModalError(error);
        });
      }

      setSubmitting(false);
    }, 400);
  };

  const showErros = (values) => {
    const errors = {};

    if (!values.duration) {
      errors.duration = i18n.t('warning_required');
    }
    return errors;
  };

  const structForm = (props) => {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
    } = props;

    return (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <FlexDiv minHeight="0" direction="column" marginBottom="32px" alignItems="flex-end">
            <Text color="red">
              {errors.duration && touched.duration && errors.duration}
            </Text>
          </FlexDiv>
          <div className="w-training-main-info">
            <FormGroup justifyContent="center" noMarginBottom="true">
              <Label htmlFor="duration">{i18n.t('minutes')}</Label>
              <Input
                type="number"
                id="duration"
                square="true"
                small="true"
                width="48px"
                textAlign="center"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="0"
                value={values.duration}
              />
            </FormGroup>
          </div>

          <FormGroup formActions="true" className="w-form-actions" noMarginBottom="true">
            <Button
              tertiary="true"
              color="#d52b1e"
              onClick={() => {
                buildTrainingStore.editAllItemsSelected = [];
                modalStore.toggleModal('modalEditAllExercises', false);
              }}
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              type="submit"
              variant={Object.keys(errors).length ? false : 'primary'}
            >
              {isSubmitting && i18n.t('shipping')}
              {!isSubmitting && i18n.t('saved_update')}
            </Button>
          </FormGroup>
        </Fieldset>
      </form>
    );
  };

  return (
    <StyleEditDuration>
      <Formik
        initialValues={setInitialValues}
        validate={values => showErros(values)}
        onSubmit={(values, { setSubmitting }) => {
          updateDuration(values, setSubmitting);
        }}
      >
        {props => structForm(props)}
      </Formik>
    </StyleEditDuration>
  );
};

export default FormEditExercise;
