import React, { useContext } from 'react';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Modal,
  Title,
  Button,
} from 'components';
import ItemError from './ItemError';

const StyledModal = styled.div`
  &.w-modal-body {
    padding: 10px;
  }

  .text-premissas {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 21px;
  }

  .wrapper-error {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
`;

function ModalErrorPremissas(props) {
  const context = useContext(Context);

  const {
    i18n,
    modalStore,
    buildTrainingStore,
    rulesPaisStore,
    cronometerStore,
  } = context;

  const {
    updateTraining,
  } = props;

  const postAnalyticsSuggestion = () => {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:premissas',
      eventAction: 'click:table',
      eventLabel: 'send_premissas_error_workout_without_editing',
      timeFishExercise: `${cronometerStore.getTimeFinishExercise()}`,
      fluxAnamnese: cronometerStore.fluxAnamnese,
      acronym: window.localStorage.getItem('w-acronym'),
    });
  };

  const sendTraining = () => {
    modalStore.toggleModal('modalErrorPremissas', false);
    postAnalyticsSuggestion();
    updateTraining();
  };

  const closeModal = () => {
    modalStore.toggleModal('modalErrorPremissas', false);
  };

  const rulesPraze = () => {
    const hasError = rulesPaisStore.hasErrorCheckedPraze();
    const style = (hasError) ? 'red' : 'green';
    const hasRules = rulesPaisStore.listToggleShowRulesPremissas.includes('dateExpire');
    if (hasRules === false) {
      return false;
    }

    return (
      <ItemError colorStyle={style} label="rules_praze" />
    );
  };

  const listErrorPremissas = () => {
    const listErrorsPremissa = buildTrainingStore.getListErrorPremissas();
    const list = [...rulesPaisStore.listToggleShowRulesPremissas];
    const hasPrazeTraining = rulesPaisStore
      .listToggleShowRulesPremissas.includes('dateExpire');

    if (hasPrazeTraining) {
      const index = rulesPaisStore.listToggleShowRulesPremissas.indexOf('dateExpire');
      if (index >= 0) {
        list.splice(index, 1);
      }
    }

    return list.map((error) => {
      const hasError = listErrorsPremissa.includes(error);
      const style = (hasError) ? 'red' : 'green';

      return (
        <ItemError key={error} colorStyle={style} label={error} />
      );
    });
  };

  return (
    <Modal>
      <div className="w-modal-content">
        <StyledModal className="w-modal-body">
          <Title
            type={4}
            as="h4"
            color="#000"
            align="initial"
            mgBottom="8"
            fontWeight="400"
          >
            {i18n.t('title_modal_premissas_error')}
          </Title>

          <p className="text-premissas">
            {i18n.t('sub_title_modal_premissas_error')}
          </p>

          <ul className="wrapper-error">
            {rulesPraze()}
            {listErrorPremissas()}
          </ul>

          <Title
            type={4}
            as="h5"
            color="#000"
            fontWeight="400"
            fontSize="16"
          >
            {i18n.t('question_send_premissa_error')}
          </Title>
          <div>
            <Button
              fourth
              onClick={closeModal}
            >
              {i18n.t('edit')}
            </Button>
            <Button
              variant
              onClick={sendTraining}
            >
              {i18n.t('send')}
            </Button>
          </div>
        </StyledModal>
      </div>
    </Modal>
  );
}

ModalErrorPremissas.defaultProps = {
  updateTraining: () => {},
};

ModalErrorPremissas.propTypes = {
  updateTraining: PropTypes.func,
};

export default ModalErrorPremissas;
