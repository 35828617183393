import React, { useContext } from 'react';
import Context from 'stores/context';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ItemPais from './ItemPais';

function AmountSeriesByTraining(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
  } = context;

  const {
    hasError,
  } = props;

  const textAmountSerie = () => (
    <Trans
      i18nKey="dropdown_pais_series_by_training"
      count={rulesPaisStore.dropDownPais.amountSerie}
      components={[<span className="color-white">*</span>]}
    />
  );

  return (
    <ItemPais
      rule={textAmountSerie()}
      checked={hasError}
      expect={i18n.t('dropdown_pais_series_by_training_expect', {
        min: rulesPaisStore.dataRules.min_series_by_training,
        max: rulesPaisStore.dataRules.max_series_by_training,
      })}
    />
  );
}

AmountSeriesByTraining.defaultProps = {
  hasError: false,
};

AmountSeriesByTraining.propTypes = {
  hasError: PropTypes.bool,
};

export default AmountSeriesByTraining;
