import React, { useContext, useState } from 'react';
import Context from 'stores/context';
import { Button, Loader } from 'components';
import TableExercises from './TableExercises';

const ItemTraining = (props) => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    i18n,
  } = context;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infoTraining, setinfoTraining] = useState(null);

  const {
    training,
  } = props;

  const toggle = () => {
    setOpen(!open);
  };

  const removeText = () => {
    const text = training.program_name.replace(/(Coach|Entrenador|Físico)/gm, '');
    return text.trim();
  };

  const formatDate = () => {
    const date = new Date(training.created_at);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const showListExercise = async (id) => {
    setLoading(true);
    try {
      const response = await buildTrainingStore.doRequestGetTrainingExercises(id);
      setLoading(false);
      setinfoTraining(response);
      toggle();
    } catch (err) {
      setLoading(false);
    }
  };

  const loadingTraining = () => {
    if (loading) {
      return (
        <Loader text={i18n.t('loading')} />
      );
    }

    return '';
  };

  const listExercises = open && <TableExercises exercises={infoTraining} />;

  return (
    <li className={`item ${open ? 'active' : ''}`}>
      <div
        className="wrapper-info"
        role="menu"
        tabIndex={0}
        onKeyPress={() => {}}
        onClick={() => { showListExercise(training.id); }}
      >
        <p className="item-info show-exercise">

          <Button
            className={`w-collapse-button ${open ? 'w-collapse-opened' : ''}`}
            rounded="true"
            noBackgroundColor="true"
            minWidth="initial"
          >
            <svg
              width="14px"
              height="14px"
              viewBox="0 0 32 32"
              fill="#fff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
                fill="#000"
              />
            </svg>
          </Button>

        </p>
        <p className="item-info date">{formatDate()}</p>
        <p className="item-info objective">{removeText()}</p>
        <p className="item-info level">{training.level_name}</p>
        <p className="item-info group-label">{training.training_groups_label}</p>
      </div>

      <div className="wrapper-training">
        {listExercises}
        {loadingTraining()}
      </div>
    </li>
  );
};

export default ItemTraining;
