import styled from 'styled-components';

const HeaderModal = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  align-items: center;
  padding-top: 39px;
  padding-bottom: 20px;

  .w-btn-back {
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-left: 24px;
  }

  .w-btn-close {
    margin-right: 24px;
  }
`;

export { HeaderModal };
export default HeaderModal;
