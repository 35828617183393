import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from '../../../stores/context';
import ModalExercise from '../../BuildTraining/BuildingTrainingList/ModalExercise';
import {
  FormAddExerciseCardio,
} from '../Forms';

class ExercisesSettingsCardio extends Component {
  static contextType = Context;

  componentWillMount() {
    const { modalStore } = this.context;
    modalStore.toggleModal('modalExercise', true);
  }

  render() {
    const { modalStore } = this.context;
    const { history } = this.props;
    const renderModalExercise = modalStore.modalExercise && (
      <ModalExercise history={history}>
        <FormAddExerciseCardio history={history} />
      </ModalExercise>
    );

    return (
      <div className="modal__add modal__add--cardio">
        { renderModalExercise }
      </div>
    );
  }
}

export default observer(ExercisesSettingsCardio);
