import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  ISelectCountryStore,
  typeListDocumentCountry,
} from './IselectCountryStore';

class SelectCountryStore implements ISelectCountryStore {
  rootStore = {};

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  locale: string = process.env.REACT_APP_LANGUAGE || 'pt-BR';
  listCountry: string[] = [
    "es-AR",
    "pt-BR",
    "es-CL",
    "es-CO",
    "es-SV",
    "es-EC",
    "es-GT",
    "es-HN",
    "es-MX",
    "es-PA",
    "es-PY",
    "es-PE",
    "es-DO",
    "es-UY",
    "es-CR"
  ];

  listDocumentCountry: typeListDocumentCountry = {
    'pt-BR': 'Documento do aluno',
    'es-AR': 'DNI / Pasaporte del alumno',
    'es-CL': 'RUT / Pasaporte del alumno',
    'es-CO': 'CC / Pasaporte del alumno',
    'es-DO': 'CIE / Pasaporte del alumno',
    'es-EC': 'Cédula / Pasaporte del alumno',
    'es-GT': 'DPI / Pasaporte del alumno',
    'es-MX': 'CURP / Pasaporte del alumno',
    'es-PE': 'DNI / CE / Pasaporte del alumno',
    'es-PY': 'CI / Pasaporte del alumno',
    'es-SV': 'DUI / Pasaporte del alumno',
    'es-HN': 'Tarjeta de Identidad / Pasaporte del alumno',
    'es-PA': 'Cedula / Pasaporte del alumno',
    'es-UY': 'CI / Pasaporte del alumno',
    "es-CR": 'Tarjeta de Identidad / Pasaporte del alumno',
    'default': 'Documento do aluno'
  };

  textDocument: string = '';


  setCountrySelect(country: string) {
    this.locale = country;
  }

  updateLabelDocument() {
    const nameLocale: string = this.locale || 'default';
    this.textDocument = this.listDocumentCountry[nameLocale as keyof typeListDocumentCountry]
  }

  get getListCountry() {
    return this.listCountry;
  }
}

decorate(SelectCountryStore, {
  locale: observable,
  listCountry: observable,
  listDocumentCountry: observable,
  textDocument: observable,
  setCountrySelect: action,
  updateLabelDocument: action,
  getListCountry: computed,
});

export default SelectCountryStore;
