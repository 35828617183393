import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import ModalDelete from 'components/modal/modal-delete';
import ModalNotify from 'views/CentralBoard/ModalNotify';

import {
  TrainingTeacherStyle,
} from 'components';

import { PostGaAnalytics } from 'utils';

import ModalFormTeacherTitle from './ModalFormTeacherTitle';
import NewListWorkout from './NewListWorkout';

class TrainingTeacher extends Component {
  static contextType = Context;

  constructor() {
    super();
    this.elementSelected = this.elementSelected.bind(this);
    this.state = {
      requestPrograms: false,
    };

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'workouts',
    });
  }

  componentWillMount() {
    const {
      appStore,
      modalStore,
      sessionStore,
      filterWorkoutStore,
      rulesPaisStore,
      trainingCreationStore,
    } = this.context;

    trainingCreationStore.resetDateFinish();
    modalStore.toggleModal('modalFormTeacher', false);
    sessionStore.resetNavigation();
    appStore.clearALlCache();
    this.callListPrograms();
    filterWorkoutStore.resetFilter();
    rulesPaisStore.reset();
  }

  callListPrograms = async () => {
    const {
      modalStore,
      teacherTrainingStore,
    } = this.context;

    try {
      await teacherTrainingStore.doRequestListProgramTeacher();
      this.setState({ requestPrograms: true });
    } catch (err) {
      modalStore.setModalNetworkCallback(this.callListPrograms.bind(this));
      window.scroll(0, 0);
      modalStore.showModalError(err);
    }
  }

  elementSelected(id, name) {
    const {
      teacherTrainingStore,
      walletUnityStore,
    } = this.context;

    teacherTrainingStore.setProgramId(id);
    teacherTrainingStore.setProgramName(name);
    teacherTrainingStore.setReloadListExercise(true);
    walletUnityStore.setReloadListExercise(true);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workouts',
      eventAction: 'click:table',
      eventLabel: `workout_${name}`,
    });
  }

  hasWallet() {
    const {
      toggleStore,
    } = this.context;
    return (toggleStore.allowRenderWalletUnit());
  }

  render() {
    const { modalStore } = this.context;
    const { history } = this.props;
    const { requestPrograms } = this.state;

    const modalFormTeacher = modalStore.modalFormTeacher && (
      <ModalFormTeacherTitle />
    );

    const modalError = modalStore.modalError && (
      <ModalError />
    );

    const modalNetwork = modalStore.modalNetwork && (
      <ModalNetwork />
    );

    const modalDelete = modalStore.modalDelete && (
      <ModalDelete />
    );

    return (
      <TrainingTeacherStyle walletUnity={this.hasWallet()}>
        <NewListWorkout
          history={history}
          requestPrograms={requestPrograms}
          elementSelected={this.elementSelected}
        />

        {modalFormTeacher}
        {modalError}
        {modalNetwork}
        {modalDelete}
        {modalStore.modalNotify && <ModalNotify />}
      </TrainingTeacherStyle>
    );
  }
}

export default observer(TrainingTeacher);
