import React, { useContext } from 'react';
import {
  FormGroup,
  Label,
  Textarea,
} from '../../components';
import Context from '../../stores/context';

const ObservationExercise = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    infoLabel,
  } = props;

  const {
    values,
    handleChange,
    handleBlur,
  } = infoLabel;

  return (
    <FormGroup>
      <Label htmlFor="observation">{i18n.t('profile_observation')}</Label>
      <Textarea
        id="observation"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.observation || ''}
      />
    </FormGroup>
  );
};

export default ObservationExercise;
