import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonFilterTeacher from './ButtonFilterTeacher';
import ListNameTeacher from './ListNameTeacher';

const FilterListExerciseTeacherStyle = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
`;

function FilterListNameTeacher(props) {
  const {
    clickShowListTeacher,
    hasButtonCreate,
    isActiveButtonFilter,

  } = props;

  return (
    <FilterListExerciseTeacherStyle>
      <ButtonFilterTeacher
        className="button-fitler-teacher"
        clickShowListTeacher={clickShowListTeacher}
        positionButton={hasButtonCreate}
        isActiveButtonFilter={isActiveButtonFilter}
      />

      <ListNameTeacher
        showListNameTeacher={isActiveButtonFilter}
      />

    </FilterListExerciseTeacherStyle>
  );
}

FilterListNameTeacher.defaultProps = {
  clickShowListTeacher: () => {},
  hasButtonCreate: false,
  isActiveButtonFilter: false,
};

FilterListNameTeacher.propTypes = {
  clickShowListTeacher: PropTypes.func,
  hasButtonCreate: PropTypes.bool,
  isActiveButtonFilter: PropTypes.bool,

};

export default observer(FilterListNameTeacher);
