import React, { useContext } from 'react';
import Context from 'stores/context';

function RedirectAutoSeg() {
  const context = useContext(Context);
  const {
    appStore,
    sessionStore,
  } = context;

  window.location.replace(sessionStore.getUrlLogin());

  appStore.toggleloading(true);
  return (
    <div>empty</div>
  );
}

export default RedirectAutoSeg;
