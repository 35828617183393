import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'components';
import Context from 'stores/context';
import NotFound from 'views/NotFound/NotFound';
import ExercisesSelect from './ExercisesSelect';
import {
  ExercisesSettingsWeightTraining,
  ExercisesSettingsCardio,
} from './ModalSettingExercises';

class AddExercises extends Component {
  static contextType = Context;

  render() {
    return (
      <div>
        <Switch>
          <PrivateRoute path="/add-exercises/exercises-select" component={ExercisesSelect} />
          <PrivateRoute path="/add-exercises/exercises-settings-weight-training" component={ExercisesSettingsWeightTraining} />
          <PrivateRoute path="/add-exercises/exercises-settings-cardio" component={ExercisesSettingsCardio} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default AddExercises;
