import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

import {
  Modal,
  Title,
  Button,
} from 'components';

import {
  Bel as BaseIconBel,
} from 'components/icons';

const ModalChangeUnitStyled = styled.div`
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: ${(props) => props.theme.colors.white};
  margin: 0px 50px;
  border-radius: 4px;

  .text-send-training {
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 325;
    font-size: ${(props) => props.theme.fontSizes[1]};
    line-height: 17px;
    margin-bottom: 21px;
  }

  .icon-bel {
    width: 130px;
    height: 140px;
  }

  .wrapper-button {
    display: flex;
    flex-direction: column;

    .button-save {
      margin-bottom: 10px;
    }
  }
`;

function ModalChangeUnit() {
  const context = useContext(Context);
  const {
    i18n,
    modalStore,
    sessionStore,
  } = context;

  const closeModal = () => {
    modalStore.toggleModal('modaSelectedUnit', false);
    modalStore.toggleModal('modalSuccesUnit', false);
    modalStore.reset();

    if (sessionStore.validateAutoSeg()) {
      sessionStore.doRequestDeleteToken();
    }

    sessionStore.signout();
  };

  return (
    <Modal eventChildren={closeModal}>
      <ModalChangeUnitStyled>
        <BaseIconBel className="icon-bel" />

        <Title
          type={4}
          as="h4"
          color="#1E8654"
          mgBottom="8"
          fontSize="20"
          className="title"
          marginTop="8px"
          subTitleColor="#010114"
        >
          {i18n.t('update_unity')}
          <small className="text-send-training">
            {i18n.t('warning_unity')}
          </small>
        </Title>

        <div className="wrapper-button">
          <Button
            primary
            onClick={closeModal}
          >
            {i18n.t('close')}
          </Button>
        </div>
      </ModalChangeUnitStyled>
    </Modal>
  );
}

export default ModalChangeUnit;
