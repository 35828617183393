import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Select,
} from 'components';

const LabelPaisPatternExercise = (props) => {
  const context = useContext(Context);
  const { i18n } = context;
  const {
    infoLabel,
    optionSelectPais,
  } = props;
  const {
    values,
    handleChange,
    handleBlur,
  } = infoLabel;

  const renderOption = list => list.map(item => (
    <option key={item} value={item}>{item}</option>
  ));

  const renderRepeats = (list, type) => list.map((item, index) => {
    const keyFor = `${type}-${item.min}-${index}`;

    return (
      <option key={keyFor} value={`${item.min} - ${item.max}`}>{`${item.min} à ${item.max}`}</option>
    );
  });

  const setRepeats = (e) => {
    if (e.currentTarget.value !== '') {
      const min = e.currentTarget.value.split('-')[0].trim();
      const max = e.currentTarget.value.split('-')[1].trim();
      values.min_repetitions = min;
      values.max_repetitions = max;
    }

    handleChange(e);
  };

  const setValueInterval = (e) => {
    values.min_interval = e.currentTarget.value;
    values.max_interval = e.currentTarget.value;
    handleChange(e);
  };

  return (
    <div className="wrapper">
      <div className="w-training-rulespais w-training-weight-training">
        <FormGroup justifyContent="center" noMarginBottom="true" className="form-group">
          <Label htmlFor="series">{i18n.t('series')}</Label>
          <Select
            id="series"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.series || ''}
          >
            <option value="">{i18n.t('none')}</option>
            {renderOption(optionSelectPais.series)}
          </Select>
        </FormGroup>

        <FormGroup justifyContent="center" noMarginBottom="true" className="form-group">
          <Label htmlFor="repeats">{i18n.t('repeats')}</Label>
          <Select
            id="repeats"
            onChange={e => setRepeats(e)}
            onBlur={handleBlur}
            value={values.repeats || ''}
          >
            <option value="">{i18n.t('none')}</option>
            {renderRepeats(optionSelectPais.repeats)}
          </Select>
        </FormGroup>

        <FormGroup justifyContent="center" noMarginBottom="true" className="form-group">
          <Label htmlFor="interval">{i18n.t('interval')}</Label>
          <Select
            id="interval"
            onChange={e => setValueInterval(e)}
            onBlur={handleBlur}
            value={values.interval || ''}
          >
            <option value="">{i18n.t('none')}</option>
            {renderOption(optionSelectPais.interval)}
          </Select>
        </FormGroup>
      </div>
    </div>
  );
};

LabelPaisPatternExercise.defaultProps = {
  infoLabel: {
    values: {},
    handleChange: () => {},
    handleBlur: () => {},
  },
  optionSelectPais: {
    series: [],
    repeats: [],
    interval: [],
  },
};

LabelPaisPatternExercise.propTypes = {
  infoLabel: PropTypes.shape({
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
  }),
  optionSelectPais: PropTypes.shape({
    series: PropTypes.array,
    repeats: PropTypes.array,
    interval: PropTypes.array,
  }),
};

export default LabelPaisPatternExercise;
