/* eslint react/prop-types: 0, react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Button } from 'components';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
`;

const Content = styled(Box)`
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 0px 8px;

  @media screen and (min-width: 450px) {
    display: ${(props) => props.display || 'inline-block'};
    width: 506px;
    padding: 0px 0px;
  }

  .w-btn-close {
    display: block;
    color: ${(props) => props.theme.colors.black};
  }

  .wrapper-content {
    background: #fff;
    border-radius: 4px;
    margin-top: 11px;
  }

  .wrapper-loading {
    width: 230px;
    margin: 0 auto;
    text-align: center;
    padding-top: 140px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #808080;
  }

  .wrapper-msg-error {
    padding: 20px 40px 40px 40px;
  }
`;

function ModalView(props) {
  const {
    children,
    closedModal,
    display,
  } = props;

  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Content display={display}>
        <Button
          closeNew="true"
          rounded="true"
          className="w-btn-close"
          onClick={() => closedModal()}
        />
        <div className="wrapper-content">
          {children}
        </div>
      </Content>
    </Wrapper>
  );
}

ModalView.defaultProps = {
  children: [],
  closedModal: () => {},
  display: 'inline-block',
};

ModalView.propTypes = {
  children: PropTypes.node,
  closedModal: PropTypes.func,
  display: PropTypes.string,
};

export { ModalView };
export default ModalView;
