import React, { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ItemExerciseTeacher from '../TrainingTeacher/ItemExerciseTeacher';


const ListWalletUnityStyle = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
  height: 517px;
`;

const ListWalletUnity = (props) => {
  const context = useContext(Context);
  const {
    teacherTrainingStore,
    walletUnityStore,
    appStore,
    modalStore,
    i18n,
    sessionStore,
  } = context;
  const {
    history,
    tabSelected,
  } = props;
  const [reqListExercise, setReqListExercise] = useState(true);

  const reloadListExercise = () => {
    setReqListExercise(true);
  };

  useEffect(() => {
    const getListExercise = async () => {
      appStore.toggleloading(true);
      try {
        setReqListExercise(false);
        await walletUnityStore.doRequestListWorkoutUnity({
          id: teacherTrainingStore.programId,
        });
        modalStore.closeModalError();
      } catch (error) {
        const msgTitle = i18n.t('error_loading');

        window.scroll(0, 0);
        modalStore.setTitle(msgTitle);
        modalStore.setModalNetworkCallback(reloadListExercise);
        modalStore.showModalError(error);
      }

      appStore.toggleloading(false);
    };

    if (reqListExercise) {
      getListExercise();
    }
  }, [
    modalStore,
    i18n,
    appStore,
    reqListExercise,
    teacherTrainingStore,
    walletUnityStore,
  ]);

  const loadingListWorkout = () => {
    appStore.toggleloading(true);
    setReqListExercise(true);
  };

  if (walletUnityStore.reloadListExercise) {
    walletUnityStore.resetView();
    walletUnityStore.setReloadListExercise(false);
    setReqListExercise(true);
  }

  const onScroll = (e) => {
    const calc = e.target.scrollHeight - e.target.scrollTop;
    const heightClient = e.target.clientHeight + 1;
    const finish = walletUnityStore.finishListSuggestionWallet;
    const result = (calc <= heightClient && finish === false);
    const finishReq = walletUnityStore.finishRequest;

    if (result && reqListExercise === false && finishReq) {
      loadingListWorkout();
    }
  };

  const allowTeacherEdit = (workout) => {
    if (workout && workout.teacher_id) {
      return sessionStore.id === workout.teacher_id;
    }

    return false;
  };

  const listItems = walletUnityStore.listWorkoutWAlletView.map(exercise => (
    <ItemExerciseTeacher
      key={exercise.id}
      exercise={exercise}
      history={history}
      duplicateExercise
      editExercise={allowTeacherEdit(exercise)}
      deleteButton={allowTeacherEdit(exercise)}
      tabSelected={tabSelected}
    />
  ));

  return (
    <ListWalletUnityStyle onScroll={onScroll} className="wrapper-scroll">
      {listItems}
    </ListWalletUnityStyle>
  );
};

ListWalletUnity.defaultProps = {
  history: {},
  tabSelected: 'training_wallet',
};

ListWalletUnity.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  tabSelected: PropTypes.string,
};

export default observer(ListWalletUnity);
