import styled from 'styled-components';
import CheckedSVG from './checked.svg';

const StyledCheckBox = styled.div`
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    opacity: 0;

    &:checked {
      + label {
        &::before {
          border-color: #ffb612;
          background-color: #ffb612;
        }

        &::after {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }

  label {
    display: inline-block;
    position: relative;
    line-height: 24px;
    padding-left: 44px;
    vertical-align: top;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
    }

    &::before {
      border-width: 2px;
      border-style: solid;
      border-color: #404040;
      border-radius: 2px;
      transition: background-color 0.2s ease-out;
    }

    &::after {
      background-image: url(${CheckedSVG});
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(0.96);
      opacity: 0;
      transition: transform 0.2s 0.2s ease-out, opacity 0.2s 0.2s ease-out;
    }
  }
`;

export { StyledCheckBox };
export default StyledCheckBox;
