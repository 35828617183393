import React, { Component } from 'react';
import { Flex } from 'rebass';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import {
  Button,
  Tabs,
  GridContainer as Container,
  TrainingListActions,
} from 'components';
import ModalDelete from 'components/modal/modal-delete';
import ModalError from 'components/modal/modal-error';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import { EmptyTraining } from './EmptyTraining';
import ModalDateTraining from './DateTraining/ModalDateTraining';
import ProfileTraining from './ProfileTraining/ProfileTraining';
import WarningRulesPaisPremisass from './WarningRulesPremissas/WarningRulesPaisPremissas';

class Header extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.saveExercise = this.saveExercise.bind(this);
    this.deleteExercise = this.deleteExercise.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.renderButtonTabs = this.renderButtonTabs.bind(this);
    this.edit = this.edit.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
  }

  componentWillMount() {
    const { modalStore, rulesPaisStore } = this.context;
    modalStore.toggleModal('modalError', false);
    modalStore.setModalDeleteCallBack(this.deleteExercise);

    const printPaisGa = rulesPaisStore.showRulesPais('list');
    if (printPaisGa) {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:current',
        eventAction: 'print:rules_pais_list',
        eventLabel: 'loading_premissas_pais_list',
      });
    }
  }

  tryAgain() {
    const { buildTrainingStore, appStore } = this.context;
    buildTrainingStore.editAllItemsSelected = appStore.objTemp;
    this.deleteExercise();
  }

  deleteExercise() {
    const {
      i18n,
      buildTrainingStore,
      modalStore,
      addExercisesStore,
      appStore,
    } = this.context;
    const { updateItems } = this.props;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'trash_exercise_confirm',
    });

    let msgTitle;
    addExercisesStore
      .doRequestDeleteTrainingExercises()
      .then(() => {
        updateItems();
        buildTrainingStore.diff = [];
        buildTrainingStore.editAllItemsSelected = [];
        msgTitle = i18n.t('remove_exercise_success');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription('');
        appStore.clearObjTemp();
        modalStore.closeModalError();
        modalStore.toggleModal('modalSuccess', true);
      })
      .catch((error) => {
        appStore.setObjTemp(buildTrainingStore.editAllItemsSelected);
        msgTitle = i18n.t('remove_exercise_error');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription('');
        modalStore.setModalNetworkCallback(this.tryAgain);
        modalStore.showModalError(error);
      });
  }

  saveExercise() {
    const {
      buildTrainingStore,
      modalStore,
    } = this.context;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'save_exercise_database',
    });

    buildTrainingStore.doRequestPatchTrainingExercises(
      buildTrainingStore.getExercisesUpdated,
    ).then(() => {
      modalStore.closeModalError();
    }).catch((error) => {
      window.scroll(0, 0);
      modalStore.setModalNetworkCallback(this.saveExercise);
      modalStore.showModalError(error);
    });
  }

  allowRenderPais() {
    const {
      toggleStore,
    } = this.context;

    return toggleStore.validateToggle({
      toggleUnitBlock: 'toogleUnitPaisBlock',
      toggleUnitAllow: 'toogleUnitPaisAllow',
      toggleCountry: 'allowPaisCountry',
    });
  }

  showCheckTabPremissas(training) {
    const {
      trainingCreationStore,
      i18n,
    } = this.context;

    if (trainingCreationStore.paisPremisses && this.allowRenderPais()) {
      return (
        <p className={`validate-premissas premissas premissas__${training.checked}`}>
          <span className="icon" />
          <span className="label-text">{i18n.t('premissas')}</span>
        </p>
      );
    }

    return false;
  }

  renderButtonTabs(updateItems) {
    const {
      i18n,
      buildTrainingStore,
      rulesPaisStore,
    } = this.context;

    rulesPaisStore.setDropDownPais(buildTrainingStore.currentTraining);

    return buildTrainingStore.trainings.map((training, index) => (
      <button
        id={`tab-index-${index}`}
        key={training.id}
        type="button"
        className={buildTrainingStore.currentTraining.label === training.label ? 'w-active' : ''}
        onClick={() => {
          buildTrainingStore.prepareStateCurrentTraining(buildTrainingStore.trainings[index]);
          updateItems();
          buildTrainingStore.diff = [];
          buildTrainingStore.editAllItemsSelected = [];

          PostGaAnalytics('send', {
            hitType: 'event',
            eventCategory: 'workout:current',
            eventAction: 'click:button',
            eventLabel: `workout_${index}`,
          });
        }}
      >
        <div className="wrapper-premissa">
          <p className="label">{`${i18n.t('training')} ${training.label}`}</p>
          {this.showCheckTabPremissas(training)}
        </div>
      </button>
    ));
  }

  edit() {
    const {
      modalStore,
    } = this.context;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'edit',
    });

    modalStore.toggleModal('modalEditAllExercises', true);
  }

  openModalDelete() {
    const {
      i18n, modalStore,
    } = this.context;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'trash_exercise',
    });

    const msgTitle = i18n.t('remove_exercise_selected');
    const msgDescription = i18n.t('question_remove_exercise');
    modalStore.setTitle(msgTitle);
    modalStore.setDescription(msgDescription);
    modalStore.toggleModal('modalDelete', true);
    window.scroll(0, 0);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'modal_message',
      eventLabel: 'trash_exercise_modal',
    });
  }

  getParamsPaisGA() {
    return {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'closed_premissas_pais_list',
    };
  }

  renderWarningRulesPaisPremisass() {
    const {
      trainingCreationStore
    } = this.context;

    if (trainingCreationStore.paisPremisses && this.allowRenderPais()) {
      return (
        <WarningRulesPaisPremisass />
      );
    }

    return false;
  }

  render() {
    const {
      i18n,
      buildTrainingStore,
      modalStore,
      addExercisesStore,
      rulesPaisStore,
      trainingCreationStore,
    } = this.context;
    const {
      updateItems,
      history,
    } = this.props;

    const buttonsTabs = this.renderButtonTabs(updateItems);
    const showButtonSave = (buildTrainingStore.hasDiff);

    if (trainingCreationStore.paisPremisses && this.allowRenderPais()) {
      rulesPaisStore.setWarningListExercise();
    }

    return (
      <>
        <Container>
          <ProfileTraining history={history} />
          {buildTrainingStore.isEmptyTraining && <EmptyTraining />}

          <Tabs className="w-tabs">{buttonsTabs}</Tabs>

          {this.renderWarningRulesPaisPremisass()}

          <TrainingListActions>
            <h3 className="w-title">{i18n.t('exercise_weight_training')}</h3>
            <Flex justifyContent="flex-end" flexWrap="nowrap">
              {buildTrainingStore.hasItemsSelected && (
                <>
                  <Button
                    tertiary="true"
                    delete="true"
                    rounded="true"
                    onClick={this.openModalDelete}
                  >
                    <span>{i18n.t('remove_exercise')}</span>
                  </Button>
                  <Button
                    tertiary="true"
                    minWidth="initial"
                    marginButton="0px 0px 0px 16px"
                    onClick={this.edit}
                  >
                    {i18n.t('edit')}
                  </Button>
                </>
              )}
              {showButtonSave && (
                <Button
                  primary="true"
                  minWidth="initial"
                  marginButton="0px 8px 0px 16px"
                  className="save-exercise"
                  onClick={this.saveExercise}
                >
                  {i18n.t('saved_update')}
                </Button>
              )}
              {!buildTrainingStore.hasItemsSelected && (
                <Button
                  tertiary="true"
                  minWidth="initial"
                  className="add_exercise-select"
                  onClick={() => {
                    modalStore.toggleModal('modalAddExercise', true);
                    addExercisesStore.resetValueListExercise();
                    const url = ConcatUnitInUrl('/add-exercises/exercises-select');
                    history.push(url);

                    PostGaAnalytics('send', {
                      hitType: 'event',
                      eventCategory: 'workout:current',
                      eventAction: 'click:button',
                      eventLabel: 'add_exercise',
                    });
                  }}
                >
                  {i18n.t('add')}
                </Button>
              )}
            </Flex>
          </TrainingListActions>
        </Container>
        {modalStore.modalDelete && (
          <ModalDelete />
        )}
        {modalStore.modalError && <ModalError />}
        {modalStore.modalDateTraining && <ModalDateTraining />}
      </>
    );
  }
}

Header.defaultProps = {
  history: {},
  updateItems: () => {},
};

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  updateItems: PropTypes.func,
};

export default observer(Header);
