import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import {
  Fieldset,
  StyledFormExercise,
} from 'components';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import FormExercise from '../../FormExercise/FormExercise';

class FormAddExerciseCardio extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.cancelAddExercise = this.cancelAddExercise.bind(this);
    this.state = {
      activePostExercise: true,
    };
  }

  settingUrl(addExercisesStore) {
    this.url = '/build-training/build-training-list';
    if (addExercisesStore.hasExerciseWeightTraining()) {
      this.url = '/add-exercises/exercises-settings-weight-training';
    }
  }

  postEmptyHeating(cardioEquipment) {
    if (cardioEquipment === 'heating') {
      return null;
    }

    return cardioEquipment;
  }

  postExerciseCardio(values, setSubmitting, history) {
    const {
      formExercisesStore,
      addExercisesStore,
      modalStore,
      i18n,
      appStore,
      buildTrainingStore,
    } = this.context;

    this.setState({ activePostExercise: false });

    this.settingUrl(addExercisesStore);

    const items = [];
    let msgTitle;
    const category = 'cardio';

    addExercisesStore.getExercisesSelectedCardio.forEach((item) => {
      const obj = formExercisesStore.makeAddObjCardio(item, values);
      items.push(obj);
    });

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    addExercisesStore.doRequestCreateAllExercises(items, category).then(() => {
      msgTitle = i18n.t('exercise_add_success');

      modalStore.setTitle(msgTitle);
      modalStore.setDescription('');
      modalStore.toggleModal('modalSuccess', true);
      modalStore.closeModalError();

      appStore.clearObjTemp();
      const redirect = ConcatUnitInUrl(this.url);
      history.push(redirect);
    }).catch((err) => {
      msgTitle = i18n.t('error_post_exercise');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription('');
      modalStore.showModalError(err);

      this.setState({ activePostExercise: true });
    });

    setSubmitting(false);
  }

  handlePostForm(values, setSubmitting, history) {
    const {
      activePostExercise,
    } = this.state;
    if (activePostExercise) {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:workout_details',
        eventAction: 'click:button',
        eventLabel: 'save',
      });

      this.postExerciseCardio(values, setSubmitting, history);
    }

    return false;
  }

  reloadPostData(history) {
    const { appStore } = this.context;
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;
    this.handlePostForm(values, setSubmitting, history);
  }

  showErros(values, i18n) {
    const errors = {};
    if (!values.duration) {
      errors.duration = i18n.t('warning_duration');
    }

    return errors;
  }

  cancelAddExercise() {
    const {
      history,
    } = this.props;
    const { addExercisesStore } = this.context;
    addExercisesStore.resetValueListExercise();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workout_details',
      eventAction: 'click:button',
      eventLabel: 'return',
    });

    const url = ConcatUnitInUrl('/add-exercises/exercises-select');
    history.push(url);
  }

  structForm(props, i18n, label) {
    const {
      handleSubmit,
    } = props;

    return (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <legend className="w-training-legend-cardio">
            {i18n.t('add_exercises_cardio')}
            {' '}
            {label}
          </legend>

          <FormExercise
            infoLabel={props}
            checkedIsometry={false}
            cancelForm={this.cancelAddExercise}
            textCancel="back_navigate"
            isFormEdit={false}
            isCardio
          />
        </Fieldset>
      </form>
    );
  }

  render() {
    const {
      buildTrainingStore, modalStore, i18n,
    } = this.context;
    const { history } = this.props;
    const label = buildTrainingStore.currentTraining.label || '';
    const setInitialValues = {
      duration: '',
      observation: '',
    };

    return (
      <StyledFormExercise>
        <Formik
          initialValues={setInitialValues}
          validate={values => this.showErros(values, i18n)}
          onSubmit={(values, { setSubmitting }) => {
            this.handlePostForm(values, setSubmitting, history);
          }}
        >
          {props => this.structForm(props, i18n, label, history)}
        </Formik>
        {modalStore.modalError && (
          <ModalError />
        )}

        {modalStore.modalNetwork && <ModalNetwork tryAgain={() => { this.reloadPostData(history); }} />}
      </StyledFormExercise>
    );
  }
}

export default observer(FormAddExerciseCardio);
