import styled, { css } from 'styled-components';

import ArrowLeftSVG from './arrow-left.svg';
import CloseSVG from './close.svg';
import CloseNewSVG from './closeNew.svg';
import DeleteSVG from './delete.svg';
import PencilSVG from './pencil.svg';
import PencilBlackSVG from './pencil-black.svg';
import SearchSVG from './search.svg';

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-radius: 4px;
  font-family: 'Gotham';
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transition: transform 0.2s ease-out;

  &:hover,
  &:focus {
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    background-color: gray;
  }

  &.yellow {
    background-color: ${(props) => props.theme.colors.yellow};
  }

  /* Normal button */
  ${(props) => !props.large
    && css`
      font-size: 16px;
      padding: 13px 30px;
    `};

  /* Large button */
  ${(props) => props.large
    && css`
      font-size: 16px;
      padding: 13px 30px;

      @media screen and (min-width: 768px) {
        font-size: 24px;
        padding: 19.5px 30px;
      }
    `};

  /* remove padding */
  ${(props) => props.removePadding
    && css`
      padding: 0px 0px;
    `};


  ${(props) => props.svg
    && props.large
    && css`
      padding: 8.5px 18px;

      @media screen and (min-width: 768px) {
        font-size: 24px;
        padding: 19.5px 30px;
      }
    `};

  ${(props) => props.primary
    && css`
      background-color: #ffb612;
      color: #000;
      margin: ${(props) => props.marginButton || '0px'};
    `};

  ${(props) => props.secondary
    && css`
      background-color: #202020;
      color: #fff;
      padding: ${(props) => props.padding || '13px 30px'};
      margin: ${(props) => props.marginButton || '0px 15px 0px 0px'};
    `};

  ${(props) => props.noBackgroundColor
    && css`
      background: transparent;
    `};

  ${(props) => props.tertiary
    && css`
      background-color: #fff;
      color: ${(props) => props.color || '#000'};
      margin: ${(props) => props.marginButton || '0px 0px 0px 0px'};
    `};

  ${(props) => props.fourth
    && css`
      background-color: #fff;
      color: #000;
      padding: ${(props) => props.padding || '13px 30px'};
      margin: ${(props) => props.marginButton || '0px 15px 0px 0px'};
      border: 1px solid #ccc;
    `};

  ${(props) => !props.rounded
    && css`
      min-width: ${(props) => props.minWidth || '180px'};

      @media screen and (min-width: 544px) {
        min-width: ${(props) => props.minWidth || '230px'};
      }
    `};

  ${(props) => props.back
    && css`
      background-image: url(${ArrowLeftSVG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
    `};

  ${(props) => props.close
    && css`
      background-image: url(${CloseSVG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
    `};

    ${(props) => props.closeNew
      && css`
        background-image: url(${CloseNewSVG});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
      `};

  ${(props) => props.search
    && css`
      background-image: url(${SearchSVG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
    `};

  ${(props) => props.delete
    && css`
      background-image: url(${DeleteSVG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px 26px;
    `};

  ${(props) => props.edit
    && css`
      background-image: url(${PencilSVG});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
    `};

    ${(props) => props.editBlack
      && css`
        background-image: url(${PencilBlackSVG});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
      `};

  ${(props) => props.rounded
    && css`
      position: relative;
      width: 48px;
      height: 48px;
      padding: 0;

      span {
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    `};

  ${(props) => props.block
    && css`
      width: 100%;
      white-space: initial;
    `};

  ${(props) => props.disabledWithClick
    && css`
      background-color: gray;
      color: #000;
    `};


  ${(props) => props.variant
    && css`
      background-color: #ffb612;
      color: #000;
    `};

  ${(props) => props.formatText
      && css`
        box-sizing: border-box;
        margin: 0;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: inherit;
        color: #333;
        appearance: none;
        display: inline-block;
        text-align: center;
        line-height: inherit;
        text-decoration: none;
        font-weight: bold;
        border: 0;
        background-color: transparent;
        text-transform: uppercase;
        cursor: pointer;
      `};

  ${(props) => props.backQuestion
    && css`
      background-color: #000;
      color: #fff;
      border-width: 2px;
      border-style: solid;
      border-color: #202020;
    `};


  ${(props) => props.variant
    && css`
      background-color: #ffb612;
      color: #000;
    `};

    ${(props) => props.disabledButton
      && css`
        color: #000;
      `};

  ${(props) => props.reponse
    && css`
      min-width: 128px;

      @media(${(props) => props.theme.mediaScreen.cellphone}) {
        min-width: auto;
        font-size: 12px;
      }

      @media(${(props) => props.theme.mediaScreen.minTablet}) and (${(props) => props.theme.mediaScreen.maxTablet}){
        min-width: 128px;
        font-size: 14px;
      }

      @media(${(props) => props.theme.mediaScreen.desktop}) {
        min-width: 128px;
      }
    `};

  ${(props) => props.setSizes
      && css`
      width: ${(props) => props.setSizes || '48px'};
      height: ${(props) => props.setSizes || '48px'};
    `};

    ${(props) => props.icon
      && css`
        background-color: #ffb612;
        color: #000;
        height: 40px;

        .icon-plus {
          margin-right: 10px;
        }
      `};
`;

export { Button };
export default Button;
