import {
  action,
  decorate,
} from 'mobx';
import {
  IVideoSessionStore,
  TypeWeightExercise,
  TypeDataVideo,
  TypeVideo,
} from './IvideoExerciseStore';
import { getVideoExercise as apiGetVideoExercise } from '../../services/api';

class VideoExerciseStore implements IVideoSessionStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
  };

  videoExerciseError = false;

  weightExercise = {
    id: 0,
    name: '',
  }

  dataVideo = {
    id: 0,
    url: '',
  }

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  validateVideo(data: TypeDataVideo, weightExercise: TypeWeightExercise) {
    this.setExercise(weightExercise);

    if (data.variation_videos && data.variation_videos.length > 0) {
      this.setErrorVideo(false);
      this.setDataVideo(data.variation_videos[0]);
      return true;
    }
    this.setErrorVideo(true);

    return false;
  }

  setDataVideo(item: TypeVideo) {
    this.dataVideo = item;
  }

  setExercise(weightExercise: TypeWeightExercise) {
    this.weightExercise = weightExercise;
  }

  setErrorVideo(status: boolean) {
    this.videoExerciseError = status;
  }

  getStatusError(): boolean {
    return this.videoExerciseError;
  }

  getWeightExerciseId(): number {
    return this.weightExercise.id;
  }

  getNameExercise(): string {
    return this.weightExercise.name || '';
  }

  getIdVideo(): number | string {
    return this.dataVideo.id || '';
  }

  getVideo(): string {
    return this.dataVideo.url || '';
  }

  doRequestVideoExercise(weightExercise: TypeWeightExercise) {
    const data = {
      weightExerciseId: weightExercise.id,
    };

    const promiseGetVideoExercise = async (resolve: any, reject: any) => {
      try {
        const res = await apiGetVideoExercise(data, this.rootStore.sessionStore.token);
        const showModal = this.validateVideo(res.data, weightExercise);
        resolve(showModal);
      } catch (err) {
        this.validateVideo({}, weightExercise);
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseGetVideoExercise(resolve, reject)
    ));
  }
}

decorate(VideoExerciseStore, {
  validateVideo: action,
  getVideo: action,
  getIdVideo: action,
  doRequestVideoExercise: action,
  getStatusError: action,
  getWeightExerciseId: action,
});

export default VideoExerciseStore;
