import React, { useContext } from 'react';
import {
  Heading, Text, Button as TextButton, Flex,
} from 'rebass';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';

import ModalWrapper from './modal-wrapper';
import TextButtonRed from '../Button/text-button-red';

function ModalSignout() {
  const context = useContext(Context);
  const { modalStore, sessionStore, i18n } = context;

  const hiddenModal = () => {
    modalStore.toggleModal('modalLogout', false);
  };

  const logoutSession = () => {
    modalStore.toggleModal('modalLogout', false);
    sessionStore.removeStorageLogin();
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'modal_message',
      eventLabel: 'exit_confirm',
    });
  };

  PostGaAnalytics('send', {
    hitType: 'event',
    eventCategory: `workout:${window.location.pathname}`,
    eventAction: 'modal_message',
    eventLabel: 'exit_confirm_modal',
  });

  return (
    <ModalWrapper
      onClick={hiddenModal}
    >
      <Heading my={[3]}>{modalStore.title}</Heading>
      <Text mb={[3]}>{modalStore.description}</Text>
      <Flex justifyContent="center">
        <TextButtonRed
          variant="text"
          onClick={logoutSession}
        >
          {i18n.t('leave')}
        </TextButtonRed>
        <TextButton
          color="darkgray"
          variant="text"
          onClick={hiddenModal}
        >
          {i18n.t('cancel')}
        </TextButton>
      </Flex>
    </ModalWrapper>
  );
}

export default ModalSignout;
