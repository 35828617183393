import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import NotFound from 'views/NotFound/NotFound';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import {
  HeaderSession,
  GridContainer,
  Row,
  Column,
  PrivateRoute,
  Button,
} from 'components';

import BuildTrainingSuccess from './BuildTrainingSuccess';
import BuildTrainingList from './BuildTrainingList';
import ModalError from '../../components/modal/modal-error';
import ModalNetwork from '../../components/modal/modal-network';
import ModalSuccess from '../../components/modal/modal-success';
import ModalFormTeacherTitle from '../TrainingTeacher/ModalFormTeacherTitle';

class BuildTraining extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.saveTemplateTraining = this.saveTemplateTraining.bind(this);
    this.next = this.next.bind(this);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'current_workout',
    });
  }

  showErrorModal(buildTrainingStore, i18n, modalStore) {
    let msgTitle;
    let msgDescription;

    buildTrainingStore.clickButtonSendToClient();

    modalStore.closeModalError();
    if (!buildTrainingStore.hasDiffWorkout) {
      msgTitle = '';
      msgDescription = i18n.t('no_alteration_detected');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.toggleModal('modalError', true);
      return true;
    }

    if (!buildTrainingStore.hasMinExercisePerTraining) {
      msgTitle = '';
      msgDescription = i18n.t('verify_training_exercise');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.toggleModal('modalError', true);
      return true;
    }

    return false;
  }

  saveTemplateTraining() {
    const {
      buildTrainingStore,
      modalStore,
      i18n,
      teacherTrainingStore,
      trainingCreationStore,
    } = this.context;

    const hasError = this.showErrorModal(buildTrainingStore, i18n, modalStore);

    if (hasError) {
      return false;
    }

    teacherTrainingStore.setWeightTrainingId(trainingCreationStore.weightTrainingId);
    modalStore.toggleModal('modalFormTeacher', true);

    return true;
  }

  urlRoutes() {
    const {
      teacherTrainingStore,
    } = this.context;

    if (teacherTrainingStore.teacherInteface) {
      return '/select-option-work/list-exercise';
    }

    return '/profile';
  }

  next() {
    const {
      buildTrainingStore,
      modalStore,
      i18n,
    } = this.context;
    const { history } = this.props;
    const url = ConcatUnitInUrl('/build-training-send');

    let msgTitle;
    let msgDescription;

    if (!buildTrainingStore.hasMinExercisePerTraining) {
      msgTitle = '';
      msgDescription = i18n.t('verify_training_exercise');
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.toggleModal('modalError', true);
    } else {
      history.push(url);
    }
  }

  renderButtonHeader(renderButton, isDisabled) {
    const {
      i18n,
    } = this.context;

    if (renderButton) {
      return (
        <Button
          primary="true"
          disabledWithClick={isDisabled}
          className="post-training"
          onClick={this.next}
          minWidth="initial"
        >
          {i18n.t('next')}
        </Button>
      );
    }

    return false;
  }

  render() {
    const { history } = this.props;
    const {
      i18n,
      buildTrainingStore,
      modalStore,
      teacherTrainingStore,
      sessionStore,
    } = this.context;

    const modalSuccessEdit = modalStore.modalSuccessEdit && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalSuccessEdit', false);
          modalStore.toggleModal('modalSuccess', false);
        }}
      />
    );

    const modalSuccessSync = modalStore.modalSync && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalSync', false);
          modalStore.toggleModal('modalSuccess', false);
          const url = ConcatUnitInUrl('/profile');

          if (buildTrainingStore.toggleAllowEditDateTraining) {
            buildTrainingStore.setToggleAllowEditDateTraining(false);
          }
          history.push(url);
        }}
      />
    );

    const modalSuccessFormTeacher = modalStore.modalFormTeacherSucess && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalFormTeacherSucess', false);
          modalStore.toggleModal('modalSuccess', false);
        }}
      />
    );

    const modalFormTeacher = modalStore.modalFormTeacher && (
      <ModalFormTeacherTitle history={history} />
    );

    const modalError = modalStore.modalError && <ModalError />;

    const isDisabled = !buildTrainingStore.hasMinExercisePerTraining;
    const renderButton = (teacherTrainingStore.teacherInteface === false);

    const buttonSendTraining = this.renderButtonHeader(renderButton, isDisabled);
    const hasButton = !!(buttonSendTraining !== false);

    const eventButtonHeader = () => {
      buildTrainingStore.setToggleAllowEditDateTraining(false);
      const selectUrl = this.urlRoutes();
      const url = ConcatUnitInUrl(selectUrl);
      history.push(url);
    };

    return (
      <Fragment>
        <GridContainer className="mg-bottom">
          <Row>
            <Column sm="12" xs="12">
              <HeaderSession
                statusActionButton={hasButton}
                isBack={false}
                eventButton={eventButtonHeader}
                nameClass="w-btn-back"
                title={sessionStore.renderListNavigator(i18n.t('profile_button_new_training'))}
                subTitle={i18n.t('profile_mount_training')}
              >
                {buttonSendTraining}
              </HeaderSession>
            </Column>
          </Row>
        </GridContainer>

        <Switch>
          <PrivateRoute path="/build-training/build-training-list" component={BuildTrainingList} />
          <PrivateRoute
            path="/build-training/build-training-success"
            component={BuildTrainingSuccess}
          />
          <Route path="*" component={NotFound} />
        </Switch>

        {modalError}
        {modalStore.modalNetwork && <ModalNetwork />}
        {modalSuccessSync}
        {modalSuccessEdit}
        {modalSuccessFormTeacher}
        {modalStore.modalSuccess && <ModalSuccess />}
        {modalFormTeacher}
      </Fragment>
    );
  }
}

BuildTraining.defaultProps = {
  history: {},
};

BuildTraining.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(BuildTraining);
