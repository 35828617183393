import styled from 'styled-components';

const TitleModal = styled.h1`
  display: block;
  width: 100%;
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  color: #000;
  text-align: initial;
  padding: ${(props) => props.padding || '0px 24px'};
`;

export { TitleModal };
export default TitleModal;
