import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  HeaderSession, GridContainer, Row, Column, PrivateRoute,
} from 'components';

import { ConcatUnitInUrl } from 'utils';

import ModalLeave from 'components/modal/modal-leave';

import NotFound from 'views/NotFound/NotFound';
import LesionAndRestrictionQuestion from './LesionAndRestrictionQuestion';
import OptionsLesionAndRestriction from './OptionsLesionAndRestriction';
import LesionAndRestrictionPrescription from './LesionAndRestrictionPrescription';
import LesionAndRestrictionEdit from './LesionAndRestrictionEdit';

class LesionAndRestriction extends Component {
  static contextType = Context;

  constructor() {
    super();
    this.eventButtonHeader = this.eventButtonHeader.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  eventButtonHeader() {
    const {
      lesionAndRestrictionStore,
      modalStore,
      i18n,
    } = this.context;
    const { history } = this.props;

    const hasOptions = /lesion-and-restriction\/options-lesion-restriction/.test(history.location.pathname);

    if (lesionAndRestrictionStore.hasQuestion) {
      lesionAndRestrictionStore.backQuestionCurrent();
    } else if (hasOptions) {
      history.goBack();
    } else {
      const title = i18n.t('abandon_training');
      modalStore.setTitle(title);
      modalStore.setDescription('');
      modalStore.toggleModal('modalAnamnesis', true);
    }
  }

  headerText() {
    const {
      i18n,
    } = this.context;

    return {
      title: i18n.t('profile_button_new_training'),
      subTitle: i18n.t('training_creation_title'),
    };
  }

  renderHeader() {
    const {
      sessionStore,
    } = this.context;

    const { history } = this.props;
    const contentHeader = this.headerText();

    const hasQuestionn = /lesion-and-restriction\/questions/.test(history.location.pathname);
    const hasUrlLesionInitial = /lesion-and-restriction\/options-lesion-restriction/.test(history.location.pathname);

    if (hasQuestionn === false) {
      return (
        <HeaderSession
          statusActionButton={false}
          isBack={hasUrlLesionInitial}
          eventButton={this.eventButtonHeader}
          nameClass="w-btn-back"
          title={sessionStore.renderListNavigator(contentHeader.title)}
          subTitle={contentHeader.subTitle}
        />
      );
    }

    return false;
  }

  render() {
    const {
      modalStore,
      teacherTrainingStore,
    } = this.context;
    const { history } = this.props;

    return (
      <>
        <GridContainer>
          <Row>
            <Column sm="12" xs="12">
              {this.renderHeader()}

            </Column>

            <Column sm="12" xs="12">
              <Switch>
                <PrivateRoute
                  path="/lesion-and-restriction/question-lesion-restriction"
                  component={LesionAndRestrictionQuestion}
                />

                <PrivateRoute
                  path="/lesion-and-restriction/options-lesion-restriction"
                  component={OptionsLesionAndRestriction}
                />

                <PrivateRoute
                  path="/lesion-and-restriction/questions/:id"
                  component={LesionAndRestrictionPrescription}
                />

                <PrivateRoute
                  path="/lesion-and-restriction/question-edit"
                  component={LesionAndRestrictionEdit}
                />

                <Route path="*" component={NotFound} />
              </Switch>

            </Column>

          </Row>
        </GridContainer>
        {modalStore.modalAnamnesis && (
          <ModalLeave
            onClickLeave={() => {
              let url = teacherTrainingStore.teacherInteface ? '/select-option-work/list-exercise' : '/profile';
              modalStore.toggleModal('modalAnamnesis', false);
              modalStore.reset();
              url = ConcatUnitInUrl(url);
              history.push(url);
            }}
          />
        )}
      </>
    );
  }
}

LesionAndRestriction.defaultProps = {
  history: {},
};

LesionAndRestriction.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(LesionAndRestriction);
