/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TabGenericStyle = styled.nav`
  background: #eee;
  border-radius: 4px;
  margin-bottom: ${(props) => props.marginBotton || '24px'};;

  .wrapper-menu {
    display: flex;
    justify-content: center;
  }

  .item {
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 21px 21px;

    &.active {
      border-bottom: 4px solid #ffb612;
    }
  }

  .text {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
  }
`;

export default class TabGeneric extends Component {
  static activeTab(selected, element) {
    return (selected === element) ? 'item active' : 'item';
  }

  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
  }

  renderList() {
    const {
      tabList,
      selected,
    } = this.props;

    const list = tabList.map((label) => (
      <li
        className={TabGeneric.activeTab(selected, label.view)}
        onClick={label.handleEvent}
        key={label.id}
        role="presentation"
        onKeyDown={this.handleKeyDown}
      >
        <span className="text">{label.name}</span>
      </li>
    ));

    return list;
  }

  render() {
    const { mgb } = this.props;

    return (
      <TabGenericStyle marginBotton={mgb}>
        <ul className="wrapper-menu">
          {this.renderList()}
        </ul>
      </TabGenericStyle>
    );
  }
}

TabGeneric.defaultProps = {
  mgb: '',
  tabList: [],
  selected: '',
};

TabGeneric.propTypes = {
  mgb: PropTypes.string,
  tabList: PropTypes.array,
  selected: PropTypes.string,
};

export { TabGeneric };
