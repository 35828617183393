import React, { Component } from 'react';
import Context from 'stores/context';
import { Button as BaseButton } from 'rebass';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { PostGaAnalytics } from 'utils';

const Button = styled(BaseButton)`
  display: inline-block;
  min-width: 120px;
  border-width: 1px;
  border-style: solid;
  border-color: #dedede;
  border-radius: 4px;
  color: ${props => props.theme.colors.black};
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  padding: 10px 16px 6px;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom:10px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

class ListExecutionExercise extends Component {
  static contextType = Context;

  doRequestListAllExercise = async () => {
    const { addExercisesStore, modalStore } = this.context;
    try {
      await addExercisesStore.doRequestGetAllExercisesNew();
    } catch (err) {
      modalStore.showModalError(err);
    }
  };

  filterExecutionExercise(execution) {
    const { addExercisesStore } = this.context;
    const el = document.querySelector('.wrapper-content-all-exercise');
    el.scrollTo(0, 0);

    if (addExercisesStore.filterExecutionExercise === execution) {
      addExercisesStore.setfFilterExecutionExercise('');
    } else {
      addExercisesStore.setfFilterExecutionExercise(execution);
    }

    this.doRequestListAllExercise();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:add_workout',
      eventAction: 'click:filter_execution',
      eventLabel: `filter_execution_${execution}`,
    });
  }

  render() {
    const { addExercisesStore, i18n } = this.context;

    return (
      <>
        <Button
          bg={addExercisesStore.filterExecutionExercise === 'machine' ? 'yellow' : 'white'}
          onClick={() => { this.filterExecutionExercise('machine'); }}
        >
          {i18n.t('machine')}
        </Button>

        <Button
          bg={addExercisesStore.filterExecutionExercise === 'pulleys' ? 'yellow' : 'white'}
          onClick={() => { this.filterExecutionExercise('pulleys'); }}
        >
          {i18n.t('pulleys')}
        </Button>

        <Button
          bg={addExercisesStore.filterExecutionExercise === 'free_weight' ? 'yellow' : 'white'}
          onClick={() => { this.filterExecutionExercise('free_weight'); }}
        >
          {i18n.t('free_weight')}
        </Button>

        <Button
          bg={addExercisesStore.filterExecutionExercise === 'calisthenics' ? 'yellow' : 'white'}
          onClick={() => { this.filterExecutionExercise('calisthenics'); }}
        >
          {i18n.t('calisthenics')}
        </Button>
      </>
    );
  }
}

export default observer(ListExecutionExercise);
