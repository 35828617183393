import React, { useContext, useState } from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { Formik } from 'formik';
import {
  Fieldset,
  StyledFormExercise,
} from 'components';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';
import FormExercise from '../../../FormExercise/FormExercise';

const FormEditExerciseCardio = ({ updateList }) => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    modalStore,
    i18n,
    appStore,
    formExercisesStore,
  } = context;
  const currentItem = buildTrainingStore.getEditItemSelected || {};
  const cardioEquipment = (currentItem.cardio_equipment === null) ? i18n.t('heating') : i18n.t(currentItem.cardio_equipment);
  const initialValues = {
    id: currentItem.id,
    duration: currentItem.duration,
    observation: currentItem.observation,
  };

  const [activePostExercise, setActivePostExercise] = useState(true);

  const reloadPostData = () => {
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;

    // eslint-disable-next-line no-use-before-define
    handlePostForm(values, setSubmitting);
  };

  const updateExerciseCardio = (values, setSubmitting) => {
    appStore.clearObjTemp();

    let newValues = cloneDeep(currentItem);

    if (buildTrainingStore.hasDiff) {
      const index = findIndex(buildTrainingStore.diff, { id: values.id });
      newValues = buildTrainingStore.diff[index];
    }

    const obj = formExercisesStore.makeEditCardio(values);
    newValues = {
      ...newValues,
      ...obj,
    };

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);
    setActivePostExercise(false);

    if (!buildTrainingStore.hasDiff) {
      buildTrainingStore.setExercisesToEdit([newValues]);
    }

    if (buildTrainingStore.hasDiff) {
      buildTrainingStore.doRequestPatchTrainingExercises(
        buildTrainingStore.getExercisesUpdated,
      ).then(() => {
        buildTrainingStore.diff = [];
        updateList();
        appStore.clearObjTemp();
        modalStore.toggleModal('modalExercise', false);
        modalStore.closeModalError();
      }).catch((error) => {
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(reloadPostData);
        modalStore.showModalError(error);
        setActivePostExercise(true);
      });
    }

    setSubmitting(false);
  };

  const cancelEditExercise = () => {
    buildTrainingStore.editAllItemsSelected = [];
    modalStore.toggleModal('modalExercise', false);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workout_details',
      eventAction: 'click:button',
      eventLabel: 'return',
    });
  };

  const handlePostForm = (values, setSubmitting) => {
    if (activePostExercise) {
      updateExerciseCardio(values, setSubmitting);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:workout_details',
        eventAction: 'click:button',
        eventLabel: 'save',
      });
    }
  };

  // eslint-disable-next-line no-shadow
  const showErros = (values) => {
    const errors = {};
    if (!values.duration) {
      errors.duration = i18n.t('warning_duration');
    }

    return errors;
  };

  const structForm = (props) => {
    const {
      handleSubmit,
    } = props;

    return (
      <form onSubmit={handleSubmit}>
        <Fieldset>
          <legend>{cardioEquipment}</legend>

          <FormExercise
            infoLabel={props}
            checkedIsometry={false}
            cancelForm={cancelEditExercise}
            textCancel="cancel"
            isCardio
            isFormEdit
          />

        </Fieldset>
      </form>
    );
  };

  return (
    <StyledFormExercise>
      <Formik
        initialValues={initialValues}
        validate={values => showErros(values)}
        onSubmit={(values, { setSubmitting }) => {
          handlePostForm(values, setSubmitting);
        }}
      >
        {props => structForm(props)}
      </Formik>
    </StyledFormExercise>
  );
};

export default FormEditExerciseCardio;
