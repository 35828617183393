import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Flex, Box, Button as BaseButton } from 'rebass';
import Context from 'stores/context';
import { Play as IconPlay } from 'components/icons';
import { PostGaAnalytics } from 'utils';

const WrapperListScroll = styled.div`
  overflow: scroll;
  height: 517px;
  margin-top: 10px;

  &.premissa-pais {
    height: 580px;
  }
`;

const Button = styled(BaseButton)`
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize;
`;

const WrapperItem = styled(Box)`
  flex-basis: 100%;
  border-bottom: solid 1px #ccc;
`;

const ListEmpty = styled(Box)`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color:  ${(props) => props.theme.colors.blackGray};
  margin: 0 auto;
  padding-top: 80px;
  max-width: 280px;
`;

function ListAllExercises() {
  const context = useContext(Context);
  const {
    addExercisesStore,
    modalStore,
    videoExerciseStore,
    appStore,
    i18n,
    rulesPaisStore,
  } = context;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const callLoading = () => {
      appStore.toggleloading(false);
      setLoading(false);
    };

    if (loading) {
      callLoading();
    }
  }, [loading, appStore]);

  const selectedExercise = (item) => {
    addExercisesStore.setExercisesSelected(item);
  };

  const openVideoExercise = (item) => {
    appStore.toggleloading(true);

    videoExerciseStore
      .doRequestVideoExercise(item)
      .then(() => {
        appStore.toggleloading(false);
        modalStore.toggleModal('modalVideoExercise', true);
        PostGaAnalytics('send', {
          hitType: 'event',
          eventCategory: 'workout:add_workout',
          eventAction: 'click:video',
          eventLabel: `video_${videoExerciseStore.getIdVideo()}`,
        });
      })
      .catch(() => {
        appStore.toggleloading(false);
        modalStore.toggleModal('modalVideoExercise', true);
      });
  };

  const loadingExercise = () => {
    appStore.toggleloading(true);
    addExercisesStore.plusExercise();
    setLoading(true);
  };

  const onScroll = (e) => {
    const calc = e.target.scrollHeight - e.target.scrollTop;
    const heightClient = e.target.clientHeight + 1;
    const finish = addExercisesStore.finishListExercise;
    const result = (calc <= heightClient && finish === false);

    if (result && loading === false) {
      loadingExercise();
    }
  };

  const checkedAmount = () => (rulesPaisStore.showRulesFormExercise ? 'premissa-pais' : '');

  const renderListEmpty = () => {
    const search = addExercisesStore.searchValue;
    const searchEmpty = (search === '');
    const text = searchEmpty ? i18n.t('list_empty_exercise') : i18n.t('no_exercise', { search });

    return (
      <WrapperListScroll className={`wrapper-content-all-exercise ${checkedAmount()}`}>
        <ListEmpty>{text}</ListEmpty>
      </WrapperListScroll>
    );
  };

  if (addExercisesStore.listExerciseView.length <= 0) {
    return renderListEmpty();
  }

  return (
    <WrapperListScroll onScroll={onScroll} className={`wrapper-content-all-exercise ${checkedAmount()}`}>
      {
        addExercisesStore.listExerciseView.map((item) => (
          <WrapperItem key={item.id} mr={[2]}>
            <Flex justifyContent="space-between" alignItems="center">

              <Button
                color="darklight"
                variant="text"
                fontSize={[1]}
                fontWeight="unset"
                className="add-exercise"
                width={[1]}
                onClick={() => selectedExercise(item)}
              >
                {item.equipamentWithExercise}
              </Button>

              <Button
                color="darklight"
                variant="text"
                fontSize={[1]}
                fontWeight="unset"
                onClick={() => openVideoExercise(item)}
              >
                <IconPlay ml={[1]} />
              </Button>

            </Flex>
          </WrapperItem>
        ))
      }

    </WrapperListScroll>
  );
}

export default observer(ListAllExercises);
