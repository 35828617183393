import styled from 'styled-components';
import { Box } from 'rebass';

import CheckTrueSVG from './check-true.svg';
import ErrorPais from './close.svg';
import PaisWarning from './pais-warning.svg';

const BaseIcon = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

`;

export const CheckTrueIconBase = styled(BaseIcon)`
  background-image: url(${CheckTrueSVG});
  width: 12px;
  height: 12px;
`;

export const ErrorIconBase = styled(BaseIcon)`
  background-image: url(${ErrorPais});
  width: 12px;
  height: 12px;
`;

export const PaisWarningIconBase = styled(BaseIcon)`
  background-image: url(${PaisWarning});
  width: 21px;
  height: 20px;
`;

export default {
  CheckTrueIconBase,
  ErrorIconBase,
  PaisWarningIconBase,
};
