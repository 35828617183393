import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';

import {
  ProfileInfo,
} from 'components';

const DataInfoTrainingStyle = styled.section`
  width: 60%;

  .wrapper-profile-intensification {
    display: flex;
  }
`;

const DataInfoTraining = () => {
  const context = useContext(Context);
  const {
    i18n,
    trainingCreationStore,
  } = context;

  const makeTrainingGroup = () => {
    const list = trainingCreationStore.trainingGroupLabels;

    const initial = '';
    if (list.length <= 0) return '';

    const label = list.reduce((initial, nextValue) => {
      const value = nextValue;
      return initial += `${value}/`;
    }, initial);

    return label.slice(0, label.length - 1);
  };

  return (
    <DataInfoTrainingStyle>
      <div className="wrapper-profile-intensification">
        <ProfileInfo
          fontSizeSmall="11px"
          fontSizeSpan="14px"
          mgBottom="16"
        >
          <small>{i18n.t('type_training')}</small>
          <span>{trainingCreationStore.programName}</span>
        </ProfileInfo>
      </div>
      <ProfileInfo
        fontSizeSmall="11px"
        fontSizeSpan="14px"
        mgBottom="16"
      >
        <small>{i18n.t('level_training')}</small>
        <span>{trainingCreationStore.levelName}</span>
      </ProfileInfo>

      <ProfileInfo
        fontSizeSmall="11px"
        fontSizeSpan="14px"
        mgBottom="16"
      >
        <small>{i18n.t('division_training')}</small>
        <span>{makeTrainingGroup()}</span>
      </ProfileInfo>

      <ProfileInfo
        fontSizeSmall="11px"
        fontSizeSpan="14px"
        mgBottom="16"
      >
        <small>{i18n.t('frequency_training')}</small>
        <span>
          {trainingCreationStore.frequency}
          x
        </span>
      </ProfileInfo>
    </DataInfoTrainingStyle>
  );
};

export default DataInfoTraining;
