import localforage from 'localforage';
import clientPersist from 'client-persist';
import { create, persist } from 'mobx-persist';
import AppStore from './appStore/appStore';
import SessionStore, { SessionSchema } from './sessionStore/sessionStore';
import PeopleStore, { PeopleSchema } from './peopleStore';
import ProgramStore, { ProgramSchema } from './programStore';
import TrainingCreationStore, { TrainingCreationSchema } from './trainingCreationStore';
import BuildTrainingStore, { BuildTrainingSchema } from './buildTrainingStore/buildTrainingStore';
import AddExercisesStore, { AddExercisesSchema } from './addExercisesStore/addExercisesStore';
import ModalStore, { ModalSchema } from './modalStore';
import ListExercisesStore from './Suggestions/ListExercisesStore';
import TeacherTrainingStore, { TeacherTrainingSchema } from './teacherTrainingStore/teacherTrainingStore';
import FormExercisesStore from './formExercisesStore';
import NoticesStore from './noticesStore';
import HistoryTrainingStore from './historyTrainingStore';
import FeedBackBarStore from './feedBackBarStore/feedBackbarStore';
import RulesPaisStore, { rulesPaisStoreSchema } from './RulesPaisStore/rulesPaisStore';
import LocationStore, { LocationSchema } from './locationStorie';
import BookingsStore, { BookingsSchema } from './bookingsStore';
import VideoExerciseStore from './videoExerciseStore/videoExerciseStore';
import ProfileStore from './profileStore';
import FollowUpStore from './followUpStore/followUpStore';
import CronometerStore from './cronometerStore/cronometerStore';
import TechnicalTrainingtStore from './technicalTrainingtStore/technicalTrainingtStore';
import FilterWorkoutStore from './filterWorkoutStore/filterWorkoutStore';
import ToggleStore from './toggleStore/toggleStore';
import LesionAndRestrictionStore, { LesionAndRestrictionStoreSchema } from './lesionAndRestrictionStore/lesionAndRestrictionStore';
import WalletUnityStore from './WalletUnityStore/WalletUnityStore';
import SelectCountryStore from './selectCountryStore/selectCountryStore';

const hydrate = create({
  storage: localforage,
  jsonify: false,
});

clientPersist.setDriver(clientPersist.SESSIONSTORAGE);

const hydrateSessionStorage = create({
  storage: clientPersist,
  jsonify: false,
});

class RootStore {
  appStore = new AppStore(this);

  sessionStore = new SessionStore(this);

  peopleStore = new PeopleStore(this);

  programStore = new ProgramStore(this);

  trainingCreationStore = new TrainingCreationStore(this);

  buildTrainingStore = new BuildTrainingStore(this);

  addExercisesStore = new AddExercisesStore(this);

  modalStore = new ModalStore(this);

  suggestions = {
    listExercises: new ListExercisesStore(this),
  }

  teacherTrainingStore = new TeacherTrainingStore(this);

  formExercisesStore = new FormExercisesStore(this);

  noticesStore = new NoticesStore(this);

  historyTrainingStore = new HistoryTrainingStore(this);

  feedBackBarStore = new FeedBackBarStore(this);

  rulesPaisStore = new RulesPaisStore(this);

  videoExerciseStore = new VideoExerciseStore(this);

  locationStore = new LocationStore(this);

  bookingsStore = new BookingsStore(this);

  profileStore = new ProfileStore(this);

  followUpStore = new FollowUpStore(this);

  cronometerStore = new CronometerStore(this);

  technicalTrainingStore = new TechnicalTrainingtStore(this);

  filterWorkoutStore = new FilterWorkoutStore(this);

  toggleStore = new ToggleStore(this);

  lesionAndRestrictionStore = new LesionAndRestrictionStore(this);

  walletUnityStore = new WalletUnityStore(this);

  selectCountryStore = new SelectCountryStore(this);

  constructor() {
    const sessionStore = persist(SessionSchema)(this.sessionStore);
    const peopleStore = persist(PeopleSchema)(this.peopleStore);
    const programStore = persist(ProgramSchema)(this.programStore);
    const trainingCreationStore = persist(TrainingCreationSchema)(this.trainingCreationStore);
    const buildTrainingStore = persist(BuildTrainingSchema)(this.buildTrainingStore);
    const addExercisesStore = persist(AddExercisesSchema)(this.addExercisesStore);
    const modalStore = persist(ModalSchema)(this.modalStore);
    const teacherTrainingStore = persist(TeacherTrainingSchema)(this.teacherTrainingStore);
    const rulesPaisStore = persist(rulesPaisStoreSchema)(this.rulesPaisStore);
    const locationStore = persist(LocationSchema)(this.locationStore);
    const bookingsStore = persist(BookingsSchema)(this.bookingsStore);
    const lesionAndRestrictionStore = persist(LesionAndRestrictionStoreSchema)(this.lesionAndRestrictionStore);

    hydrate('session', sessionStore);
    hydrateSessionStorage('modal', modalStore);
    hydrateSessionStorage('people', peopleStore);
    hydrateSessionStorage('program', programStore);
    hydrateSessionStorage('training-creation', trainingCreationStore);
    hydrateSessionStorage('build-training', buildTrainingStore);
    hydrateSessionStorage('add-exercises', addExercisesStore);
    hydrateSessionStorage('teacherTrainingStore', teacherTrainingStore);
    hydrateSessionStorage('rulesPaisStore', rulesPaisStore);
    hydrateSessionStorage('locationStore', locationStore);
    hydrateSessionStorage('bookingsStore', bookingsStore);
    hydrateSessionStorage('lesionAndRestrictionStore', lesionAndRestrictionStore);
  }
}

export default new RootStore();
