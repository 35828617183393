import React, { useContext } from 'react';
import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import {
  Button,
} from 'components';

function ButtonSeeTraining(props) {
  const context = useContext(Context);
  const {
    i18n,
    trainingCreationStore,
    buildTrainingStore,
    lesionAndRestrictionStore,
    rulesPaisStore,
    programStore,
    toggleStore,
    peopleStore,
  } = context;

  const {
    history,
  } = props;

  const callRulesPais = async() => {
    const url = ConcatUnitInUrl('/build-training/build-training-list');
    rulesPaisStore.reset();

    trainingCreationStore.setPaisPremisses({
      pais_premisses: true,
    });

    const objParams = {
      programId: programStore.programId,
      levelId: programStore.levelId,
      profileId: trainingCreationStore.profileSelected,
      paisPremisses: trainingCreationStore.paisPremisses,
    };

    try {
      await rulesPaisStore.doGetRules(objParams);
      history.push(url);
    } catch (error) {
      trainingCreationStore.setPaisPremisses({
        pais_premisses: false,
      });
      history.push(url);
    }
  };

  const buildTrainingList = () => {
    const rulesPermissas = toggleStore.validateToggle({
      toggleUnitBlock: 'toogleUnitPaisBlock',
      toggleUnitAllow: 'toogleUnitPaisAllow',
      toggleCountry: 'allowPaisCountry',
    });

    const url = ConcatUnitInUrl('/build-training/build-training-list');
    trainingCreationStore.setWeightTrainingId(peopleStore.getCurrentWeightTraining.id);
    buildTrainingStore.setResendTraining(true);
    buildTrainingStore.clearSuggestionWorkoutBuild();
    lesionAndRestrictionStore.resetHealthRestrictionEdit();

    if (rulesPermissas) {
      callRulesPais();
    } else {
      history.push(url);
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:button',
      eventLabel: 'check_workout',
    });
  };

  if (trainingCreationStore.inputManualTraining) return false;

  return (
    <Button
      primary
      reponse
      onClick={() => buildTrainingList()}
    >
      {i18n.t('see_training')}
    </Button>
  );
}

export { ButtonSeeTraining };
export default ButtonSeeTraining;
