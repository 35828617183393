import React, { useContext } from 'react';
import Context from 'stores/context';
import {
  HeaderModal,
  TitleModal,
  Button,
} from 'components';

function HeaderNotices(props) {
  const context = useContext(Context);
  const {
    modalStore,
    noticesStore,
    appStore,
  } = context;
  const {
    title,
    type,
  } = props;

  // eslint-disable-next-line react/no-unstable-nested-components
  function HeaderMessage() {
    const backListNotices = () => {
      appStore.toggleloading(true);
      noticesStore.doRequestNotices()
        .then(() => {
          appStore.toggleloading(false);
          noticesStore.setShowListNotices(true);
        })
        .catch(() => {
          appStore.toggleloading(false);
          noticesStore.setWarningButtonNotices(false);
          modalStore.toggleModal('modalNotices', false);
        });
    };

    return (
      <HeaderModal>
        <Button
          back="true"
          primary="true"
          noBackgroundColor="true"
          rounded="true"
          className="w-btn-back"
          onClick={() => backListNotices()}
        />
        <TitleModal>
          {title}
        </TitleModal>
      </HeaderModal>

    );
  }

  function HeaderListMessage() {
    return (
      <HeaderModal>
        <TitleModal padding="0px 0px 0px 34px">
          {title}
        </TitleModal>

        <Button
          back="true"
          primary="true"
          noBackgroundColor="true"
          rounded="true"
          close="true"
          className="w-btn-close"
          onClick={() => {
            noticesStore.setHasNotices();
            modalStore.toggleModal('modalNotices', false);
          }}
        />
      </HeaderModal>
    );
  }

  const loadHeaderNotices = () => {
    if (type && type === 'message') {
      return HeaderMessage();
    }
    return HeaderListMessage();
  };

  return (
    <div>
      {loadHeaderNotices()}
    </div>
  );
}

export default HeaderNotices;
