import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  ContainerStyle,
  Title,
} from 'components';
import Context from 'stores/context';
import { ObservationTraining } from 'views/Profile/Trainings/ObservationTraining';
import { DataTraining } from './DataTraining';

function TrainingCurrent({ history }) {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
  } = context;

  const renderTraining = () => {
    const currentTraining = peopleStore.getCurrentWeightTraining;
    const hasRating = (currentTraining.rating);
    const hasScore = (hasRating && currentTraining.rating.satisfaction_score !== null);

    return (
      <DataTraining
        history={history}
        locationTraining="gym"
        hasScore={hasScore}
        evaluation={peopleStore.getCurrentWeightTraining.rating}
      />
    );
  };

  return (
    <>
      <ContainerStyle
        margin="40px 0px 0px 0px"
        alignjustifyContent="flex-start"
      >
        <Title as="h2" type={4} align="initial">
          {i18n.t('profile_current_training')}
        </Title>

        <ObservationTraining history={history} />

      </ContainerStyle>
      {renderTraining()}
    </>
  );
}

TrainingCurrent.defaultProps = {
  history: {},
};

TrainingCurrent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export { TrainingCurrent };
export default TrainingCurrent;
