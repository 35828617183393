import styled from 'styled-components';

const StyledDataExercise = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export { StyledDataExercise };
export default StyledDataExercise;
