import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';

import ListHourSchedule from './ListHourSchedule';

const DateScheduleStyle = styled.ul`
  margin-top: 24px;
  list-style-type: none;

  .date {
    font-family: ${props => props.theme.fonts.Gotham};
    font-size: 19px;
    border-bottom: 1px solid ${props => props.theme.colors.white};
    padding-bottom: 8px;
    margin-bottom: 14px;
    font-weight: bold;
  }

  .list-date {
    color: ${props => props.theme.colors.white};
    margin-bottom: 32px;
  }

  .no-schedule {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    color: ${props => props.theme.colors.white};
    font-size: 19px;
    text-align: center;
  }
`;

const DateSchedule = ({ history }) => {
  const context = useContext(Context);
  const {
    i18n,
    bookingsStore,
  } = context;

  if (bookingsStore.dateBookings.length <= 0) {
    return (
      <DateScheduleStyle>
        <div className="no-schedule">{i18n.t('not_booking')}</div>
      </DateScheduleStyle>
    );
  }

  return (
    <DateScheduleStyle>
      {bookingsStore.dateBookings.map(booking => (
        <li className="list-date" key={booking.dateSchedule}>
          <div className="date">{booking.dateSchedule}</div>
          <ListHourSchedule listSchedule={booking.listSchedule} history={history} />
        </li>
      ))}
    </DateScheduleStyle>
  );
};

DateSchedule.defaultProps = {
  history: {},
};

DateSchedule.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default DateSchedule;
