/* eslint-disable camelcase */
import React, { useContext } from 'react';
import Context from 'stores/context';
import ProgramSmart from './ProgramSmart/ProgramSmart';
import ProgramsDefault from './ProgramsDefault/ProgramsDefault';

const Programs = () => {
  const context = useContext(Context);
  const {
    peopleStore,
  } = context;

  const renderComponent = () => {
    if (peopleStore.hasProgramSmart()) {
      return (
        <ProgramSmart />
      );
    }

    if (peopleStore.hasProgramsDefault()) {
      return (
        <ProgramsDefault />
      );
    }

    return false;
  };

  return (
    <>
      {renderComponent()}
    </>
  );
};

export default Programs;
