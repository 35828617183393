import React, { useContext } from 'react';
import Context from 'stores/context';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { ButtonCircle } from './ButtonCircle';
import { Count } from '../Count/Count';

const ButtonFilterStyle = styled.div`
  position: relative;

  .filter {
    display: flex;
    align-items: center;
  }

  .text-button-filter {
    margin-left: 10px;
  }

  .wrapper-icon-filter {
    fill: ${(props) => props.theme.colors.yellow};

    &.w-active {
      fill: black;
    }
  }

  .triangle-one {
    display: none;
    position: absolute;
    top: 75px;
    left: 85px;
    z-index: 2;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #3B3B40;

    &.w-active {
      display: block;
    }
  }

  ${(props) => props.wallet
    && css`
      .triangle-one {
        top: 52px;
        left: 71px;
      }
    `};
`;

function ButtonFilter(props) {
  const context = useContext(Context);
  const {
    i18n,
    toggleStore,
  } = context;

  const {
    toggleFilterButton,
    toggleFilter,
    lengthSelected,
  } = props;

  const renderActiveButton = () => {
    if (toggleFilterButton || lengthSelected > 0) {
      return true;
    }

    return false;
  };

  return (
    <ButtonFilterStyle wallet={toggleStore.allowRenderWalletUnit()}>
      <ButtonCircle
        variant="secondSquare"
        wallet={toggleStore.allowRenderWalletUnit()}
        className={renderActiveButton() ? 'w-btn-primary w-active' : 'w-btn-primary'}
        onClick={() => {
          toggleFilter();
        }}
      >
        <div className="wrapper-content-button filter">
          <div className="icon">
            <svg
              className={renderActiveButton() ? 'wrapper-icon-filter w-active' : 'wrapper-icon-filter'}
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fillRule="evenodd" clipRule="evenodd" d="M1 4H4.17071C4.58254 5.16519 5.69378 6 7 6C8.30622 6 9.41746 5.16519 9.82929 4H23C23.5523 4 24 3.55228 24 3C24 2.44772 23.5523 2 23 2H9.82929C9.41746 0.834808 8.30622 0 7 0C5.69378 0 4.58254 0.834808 4.17071 2H1C0.447715 2 0 2.44772 0 3C0 3.55228 0.447716 4 1 4ZM7 4C7.55228 4 8 3.55228 8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3C6 3.55228 6.44772 4 7 4Z" />
              <path fillRule="evenodd" clipRule="evenodd" d="M1 11H14.1707C14.5825 12.1652 15.6938 13 17 13C18.3062 13 19.4175 12.1652 19.8293 11H23C23.5523 11 24 10.5523 24 10C24 9.44772 23.5523 9 23 9H19.8293C19.4175 7.83481 18.3062 7 17 7C15.6938 7 14.5825 7.83481 14.1707 9H1C0.447715 9 0 9.44772 0 10C0 10.5523 0.447716 11 1 11ZM17 11C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9C16.4477 9 16 9.44772 16 10C16 10.5523 16.4477 11 17 11Z" />
              <path fillRule="evenodd" clipRule="evenodd" d="M1 18H4.17071C4.58254 19.1652 5.69378 20 7 20C8.30622 20 9.41746 19.1652 9.82929 18H23C23.5523 18 24 17.5523 24 17C24 16.4477 23.5523 16 23 16H9.82929C9.41746 14.8348 8.30622 14 7 14C5.69378 14 4.58254 14.8348 4.17071 16H1C0.447715 16 0 16.4477 0 17C0 17.5523 0.447716 18 1 18ZM7 18C7.55228 18 8 17.5523 8 17C8 16.4477 7.55228 16 7 16C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18Z" />
            </svg>

          </div>

          <span className="text-button-filter">
            {i18n.t('filter')}
          </span>
        </div>

        <Count
          toogle={renderActiveButton()}
          count={lengthSelected}
        />
      </ButtonCircle>

      <div className={toggleFilterButton ? 'triangle-one w-active' : 'triangle-one'} />
    </ButtonFilterStyle>
  );
}

ButtonFilter.defaultProps = {
  toggleFilterButton: false,
  toggleFilter: () => {},
  lengthSelected: 0,
};

ButtonFilter.propTypes = {
  toggleFilterButton: PropTypes.bool,
  toggleFilter: PropTypes.func,
  lengthSelected: PropTypes.number,
};

export default ButtonFilter;
export { ButtonFilter };
