import localforage from 'localforage';
import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  ISessionStore,
  typeAllowSession,
  DataSession,
  ObjToken,
} from './IsessionStore';
import {
  login as apiLoginApi,
  authenticate as apiAuthenticate,
  getDataUser as apiGetDataUser,
  postConfirmationLocation as apiPostConfirmationLocation,
  deleteToken as apiDeleteToken,
} from '../../services/api';

class SessionStore implements ISessionStore {
  rootStore = {};

  auth = false;

  token = '';

  id = 0;

  session = {
    location: {
      id: 0,
      name: '',
      create_training_enabled: false,
    },
  };

  acronym = '';

  tabSelected = '';

  listNavigator: string[] = [];

  toggleUnidadeProgramSmart = false;

  toggleAccessTrainingPrograms = false;

  hiddenModalLocation = true;

  createTrainingEnabled = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  resetNavigation(): void {
    this.listNavigator = [];
  }

  getListNavigator(): string[] {
    return this.listNavigator;
  }

  insertNewItemNavigation(title: string) {
    this.listNavigator.push(title);
  }

  updateListNavigator(title: string) {
    const newListNavigator: string[] = [];
    let toogleInsertTitle: boolean = true;

    this.listNavigator.forEach((titleSave: string) => {
      if (toogleInsertTitle) {
        newListNavigator.push(titleSave);
      }

      if (titleSave === title) {
        toogleInsertTitle = false;
      }
    });

    this.listNavigator = newListNavigator;
  }

  validateListNavigator(title: string): boolean {
    if ((!!title) === false) return false;
    const hasTitle: boolean = this.listNavigator.includes(title);

    if (hasTitle === false) {
      this.insertNewItemNavigation(title);
      return true;
    }

    this.updateListNavigator(title);

    return true;
  }

  renderListNavigator(title: string): string {
    this.validateListNavigator(title);
    const list: string[] = this.getListNavigator();

    if (list.length === 1) return `${list[0]}`;


    return list.join(' / ');
  }

  authenticate(): void {
    this.auth = true;
  }

  signout(): void {
    this.auth = false;
  }

  validateToken(error: any): boolean {
    const hasError: boolean = (error && error.response && error.response.data);
    const message: boolean = (hasError && error.response.data.message);

    if (message && error.response.data.message === 'unauthorized') {
      return false;
    }

    return true;
  }

  setSession(session: DataSession): boolean {
    if (!Object.keys(session).length) return false;
    this.setToken(session.token);
    this.setId(session.id);

    if (session.location && session.location.acronym) {
      this.setAcronym(session.location.acronym);
    }

    if (session.location && session.location.access_smart_program) {
      const status = session.location.access_smart_program;
      this.setToggleUnidadeProgramSmart(status);
    }

    if (session.location && session.location.access_training_programs) {
      const status = session.location.access_training_programs;
      this.setToggleAccessTrainingPrograms(status);
    }

    this.setFirstLocationConfirmation(session);

    if(session.location && session.location.create_training_enabled) {
      this.setCreateTrainingEnabled(session
        .location.create_training_enabled);
    }

    this.session = session;
    return true;
  }

  setFirstLocationConfirmation(session: DataSession) {
    const hiddenModal = session.first_location_confirmation;

    if (hiddenModal || hiddenModal === undefined || hiddenModal === null) {
      this.hiddenModalLocation = true;
    } else {
      this.hiddenModalLocation = hiddenModal;
    }
  }

  setAcronym(acronym: string): boolean {
    if (!acronym) {
      return false;
    }

    this.acronym = acronym;
    window.localStorage.setItem('w-acronym', acronym);
    return false;
  }

  setToken(token: string): boolean {
    if (!token) return false;
    this.token = token;
    return true;
  }

  setId(id: number) {
    this.id = id;
  }

  setTabStyleActive(tabSelected: string): void {
    this.tabSelected = tabSelected;
  }

  setToggleUnidadeProgramSmart(statusProgram: boolean): void {
    this.toggleUnidadeProgramSmart = statusProgram;
  }

  setToggleAccessTrainingPrograms(toogle: boolean): void {
    this.toggleAccessTrainingPrograms = toogle;
  }

  setCreateTrainingEnabled(showButton: boolean) : void {
    this.createTrainingEnabled = showButton;
  }

  doRequestLogin({ username, password }: typeAllowSession): any {
    const data = {
      session: {
        username,
        password,
      },
    };

    const promiseLogin = async (resolve: any, reject: any) => {
      try {
        const res = await apiLoginApi(data);
        window.localStorage.removeItem('w-acronym');
        this.setSession(res.data.session);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promiseLogin(resolve, reject));
  }

  doRequestLocationConfirmation(locationId: string): any {
    const data = {
      body: {
        user: {
          location_id: locationId,
        },
      },
    };

    const promiseLogin = async (resolve: any, reject: any) => {
      try {
        const res = await apiPostConfirmationLocation(data, this.token);
        window.localStorage.removeItem('w-acronym');
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promiseLogin(resolve, reject));
  }

  doRequestAuthenticate(token: string): any {
    const data = {
      body: {
        token: token
      },
    };

    const promiseLogin = async (resolve: any, reject: any) => {
      try {
        const res = await apiAuthenticate(data);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promiseLogin(resolve, reject));
  }

  doRequestGetDataUser(objToken: ObjToken): any {
    const promiseLogin = async (resolve: any, reject: any) => {
      try {
        const res = await apiGetDataUser(objToken.token);
        window.localStorage.removeItem('w-acronym');
        res.data.user.token = objToken.token;
        this.setSession(res.data.user);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promiseLogin(resolve, reject));
  }

  doRequestDeleteToken(): any {
    const promiseLogin = async (resolve: any, reject: any) => {
      try {
        const res = await apiDeleteToken(this.token);
        window.localStorage.removeItem('w-acronym');
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promiseLogin(resolve, reject));
  }


  removeStorageLogin(): void {
    if (this.validateAutoSeg()) {
      const url = this.getUrlLogout();
      this.doRequestDeleteToken();

      localforage.removeItem('session').then(() => {
        window.location.href = url;
      });
    } else {
      localforage.removeItem('session').then(() => {
        window.location.href = '/logout';
      });
    }
  }

  getUrlLogin() {
    const envCountry: string = process.env.REACT_APP_LANGUAGE || 'pt-BR';
    const country = envCountry.replace(/.*-/, '');

    return `${process.env.REACT_APP_REDIRECT_LOGIN}.${country.toLocaleLowerCase()}`;
  }

  getUrlLogout() {
    const envCountry: string = process.env.REACT_APP_LANGUAGE || 'pt-BR';
    const country = envCountry.replace(/.*-/, '');

    return `${process.env.REACT_APP_REDIRECT_LOGOUT}.${country.toLocaleLowerCase()}`;
  }

  validateAutoSeg() {
    const url = window.location.hostname;
    const result = url.split('.')[0].replace(/(https:\/\/|http:\/\/)/, '');
    const list = process.env.REACT_APP_LIST_LOGIN_AUTOSEG || '';
    const listDomain = list.split(',');
    if(result !== null) {
      return listDomain.includes(result);
    }

    return false;
  }

  get nameAcronym() {
    return this.session.location.name;
  }

  get getIdLocation() {
    return this.session.location.id;
  }
}

decorate(SessionStore, {
  acronym: observable,
  auth: observable,
  session: observable,
  listNavigator: observable,
  id: observable,
  hiddenModalLocation: observable,
  createTrainingEnabled: observable,
  setAcronym: action,
  setSession: action,
  setToken: action,
  doRequestLogin: action,
  tabSelected: observable,
  setTabStyleActive: action,
  removeStorageLogin: action,
  renderListNavigator: action,
  insertNewItemNavigation: action,
  getListNavigator: action,
  validateListNavigator: action,
  resetNavigation: action,
  setCreateTrainingEnabled: action,
  doRequestLocationConfirmation: action,
  doRequestAuthenticate: action,
  doRequestGetDataUser: action,
  validateAutoSeg: action,
  getUrlLogin: action,
  getUrlLogout: action,
  doRequestDeleteToken: action,
  nameAcronym: computed,
  getIdLocation: computed,
});

export const SessionSchema = {
  auth: true,
  token: true,
  acronym: true,
  id: true,
  toggleUnidadeProgramSmart: true,
  toggleAccessTrainingPrograms: true,
  firstLocationConfirmation: true,
  listNavigator: {
    type: 'list',
  },
  session: {
    type: 'object',
    schema: {
      name: true,
      id: true,
      location: {
        type: 'object',
        schema: {
          id: true,
          name: true,
        },
      },
    },
  },
  tabSelected: true,
  createTrainingEnabled: true,
};

export default SessionStore;
