import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import ModalNetwork from 'components/modal/modal-network';
import ModalError from 'components/modal/modal-error';

import {
  Anamnese,
  Button,
  Title,
  SubTitle,
  Textarea,
  Text,
  HeaderSession,
} from 'components';

class LesionAndRestrictionPrescription extends Component {
  static contextType = Context;

  state = {
    detailPrescription: '',
  };

  constructor() {
    super();
    this.setOptionSelect = this.setOptionSelect.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.eventButtonHeader = this.eventButtonHeader.bind(this);
    this.postSaveLesionAndRestriction = this.postSaveLesionAndRestriction.bind(this);
    this.loadingDataExercise = this.loadingDataExercise.bind(this);
  }

  setOptionSelect(option) {
    const { lesionAndRestrictionStore } = this.context;
    lesionAndRestrictionStore.setKindCurrent(option.value);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestriction',
      eventAction: 'click:button',
      eventLabel: `lesion_and_restriction_preciption_${option}`,
    });
  }

  loadingDataExercise() {
    const {
      trainingCreationStore,
    } = this.context;

    trainingCreationStore.setWeightTrainingId(trainingCreationStore.weightTrainingId);
  }

  postSaveLesionAndRestriction() {
    const {
      lesionAndRestrictionStore,
      appStore,
      modalStore,
    } = this.context;
    const { history } = this.props;

    appStore.toggleloading(true);

    lesionAndRestrictionStore
      .doRequestSavePrescription()
      .then(() => {
        appStore.toggleloading(false);
        this.loadingDataExercise();
        const url = ConcatUnitInUrl('/build-training/build-training-list');
        history.push(url);
      })
      .catch((error) => {
        appStore.toggleloading(false);
        appStore.toggleloading(false);
        modalStore.showModalError(error);
      });
  }

  nextPage() {
    const { detailPrescription } = this.state;
    const { lesionAndRestrictionStore } = this.context;
    lesionAndRestrictionStore.saveDetailPrescription(detailPrescription);
    this.setState({ detailPrescription: '' });

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestriction',
      eventAction: 'click:button',
      eventLabel: 'lesion_and_restriction_next',
    });

    if (lesionAndRestrictionStore.limitQuestion) {
      this.postSaveLesionAndRestriction();
    } else {
      lesionAndRestrictionStore.nextCurrent();
      lesionAndRestrictionStore.setStartPrescription();
    }
  }

  handleChange(event) {
    this.setState({ detailPrescription: event.currentTarget.value });
  }

  headerText() {
    const {
      i18n,
    } = this.context;

    return {
      title: i18n.t('profile_button_new_training'),
      subTitle: i18n.t('training_creation_title'),
    };
  }

  eventButtonHeader() {
    const {
      lesionAndRestrictionStore,
    } = this.context;
    const { history } = this.props;

    if (lesionAndRestrictionStore.hasQuestion) {
      lesionAndRestrictionStore.backQuestionCurrent();
      this.setState({ detailPrescription: lesionAndRestrictionStore.dataDescription });
    } else {
      history.goBack();
    }
  }

  renderListOption() {
    const { lesionAndRestrictionStore } = this.context;

    return lesionAndRestrictionStore.optionKinds.map(option => (
      <Button
        reponse
        key={option.value}
        variant={lesionAndRestrictionStore.optionsKindsSelected.includes(option.value)}
        onClick={() => this.setOptionSelect(option)}
      >
        {option.label}
      </Button>
    ));
  }

  render() {
    const {
      i18n,
      lesionAndRestrictionStore,
      modalStore,
      sessionStore,
    } = this.context;
    const { detailPrescription } = this.state;
    const { match } = this.props;

    const contentHeader = this.headerText();

    if (lesionAndRestrictionStore.renderPage !== match.params.id) {
      const page = lesionAndRestrictionStore.renderPage;
      const url = ConcatUnitInUrl(`/lesion-and-restriction/questions/${page}`);
      return <Redirect to={url} />;
    }

    return (
      <Anamnese className="w-question-lesion">
        <HeaderSession
          statusActionButton={false}
          isBack
          eventButton={this.eventButtonHeader}
          nameClass="w-btn-back"
          title={sessionStore.renderListNavigator(contentHeader.title)}
          subTitle={contentHeader.subTitle}
        />

        <Title type={1} as="h2" subTitleColor="#fff" className="title">
          {lesionAndRestrictionStore.optionCurrent.label}
          <SubTitle fontWeight="500">
            {i18n.t('anamnesis_lesion_subtitle')}
          </SubTitle>
        </Title>

        <div className="w-option-lesion">
          {this.renderListOption()}
        </div>

        <div className="w-lesion-details">
          <Text
            className="label"
            color="#fff"
            fontSize="16px"
          >
            {i18n.t('but_details')}
            :
          </Text>

          <Textarea
            tabIndex="0"
            id="observation-restriction"
            minHeight="100px"
            maxLength="200"
            onChange={this.handleChange}
            defaultValue={lesionAndRestrictionStore.dataDescription}
          />
          <div className="limit-text">
            {i18n.t('limit_name_training', {
              lenghtInput: detailPrescription.length,
              limit: '200',
            })}
          </div>
        </div>

        <div className="next-frequency">
          <Button
            reponse
            onClick={this.nextPage}
          >
            { i18n.t('anamnesis_question_next')}
          </Button>
        </div>

        {modalStore.modalNetwork && (
          <ModalNetwork
            tryAgain={() => { this.postSaveLesionAndRestriction(); }}
          />
        )}
        {modalStore.modalError && <ModalError />}

      </Anamnese>
    );
  }
}

LesionAndRestrictionPrescription.defaultProps = {
  history: {},
  match: {},
};

LesionAndRestrictionPrescription.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default observer(LesionAndRestrictionPrescription);
