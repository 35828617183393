import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { PostGaAnalytics } from 'utils';
import { Box, Heading } from 'rebass';
import Context from '../../../stores/context';
import Search from './Search';
import FiltersWithExecution from './FiltersWithExecution';


const Header = () => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    i18n,
    addExercisesStore,
  } = context;
  const label = buildTrainingStore.currentTraining.label || '';
  const [statusToogleFilter, setStatusToogleFilter] = useState(false);

  const toggleFilter = () => {
    const status = !statusToogleFilter;

    if (status === false) {
      const el = document.querySelector('.wrapper-content-all-exercise');
      el.scrollTo(0, 0);
      addExercisesStore.setFilterSelected('');
      addExercisesStore.setfFilterExecutionExercise('');
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:add_workout',
      eventAction: 'click:button_filter',
      eventLabel: `${status}_filter_exercise`,
    });

    setStatusToogleFilter(status);
  };

  return (
    <div>
      <Box p={[3]}>
        <Heading fontWeight="300">
          {`${i18n.t('add_exercise_training')} ${label}`}
        </Heading>
      </Box>

      <Search toggleFilter={toggleFilter} />

      <FiltersWithExecution open={statusToogleFilter} />
    </div>
  );
};

export default observer(Header);
