import styled from 'styled-components';

const ProgramsStyle = styled.div`
  display: flex;
  flex-direction: column;

  .mg-top {
    margin-top: 32px;
  }

  .follow-up {
    margin-top: 40px;
  }

  @media(${props => props.theme.mediaScreen.cellphone}) {
    .wrapper-title {
      display: flex;

      .icon {
        margin-right: 20px;
      }

      .title {
        padding-top: 16px
      }
    }

    .button-presence {
      width: 100%;

      .button-confirmed {
        width: 100%;
      }
    }

    .container {
      justify-content: space-between;
    }

    .mg-right {
      margin-right: 25px;
    }

    .wrapper-graphic {
      font-size: 14px;
    }
  }

  @media(${props => props.theme.mediaScreen.minTablet}) and (${props => props.theme.mediaScreen.maxTablet}){
    .wrapper-title {
      display: flex;

      .title {
        padding-left: 30px;
      }
    }

    .wrapper-info {
      margin-top: -30px;
      padding-left: 128px;
    }

    .profile {
      margin-right: 56px;
    }

    .remove-mr {
      margin-right: 0px;
    }

    .adjust-margin {
      margin-right: 40px;
    }

    .mg-right {
      margin-right: 50px;
    }

    .wrapper-graphic {
      width: 350px;
      font-size: 14px;
    }

    .presence {
      justify-content: space-between;
    }

    .date-presence {
      margin-right: 0px;
    }

    .button-presence {
      margin-right: 0px;

      .button-confirmed {
        font-size: 12px;
      }
    }
  }

  @media(${props => props.theme.mediaScreen.desktop}) {
    .wrapper-title {
      display: flex;

      .title {
        padding-left: 30px;
      }
    }

    .wrapper-info {
      margin-top: -30px;
      padding-left: 140px;
    }

    .profile {
      margin-right: 56px;
    }

    .mg-right {
      margin-right: 56px;
    }

    .wrapper-graphic {
      width: 400px;
    }
  }

  .icones {
    background-color: #fff;
  }

  .adjust-margin {
    margin-right: 40px;
  }

  .form-wrap-select {
    width: 256px;
    font-size: 14px;
  }
`;

export { ProgramsStyle };
export default ProgramsStyle;
