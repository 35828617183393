import styled from 'styled-components';
import { Button } from 'rebass';

import DeleteSVG from './delete.svg';

const ButtonDelete = styled(Button)`
  background-image: url(${DeleteSVG});
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.theme.colors.darkgray};
  padding: ${(props) => props.padding || '29px 30px 17px 30px'};
  border-radius: 0px;
`;

export default ButtonDelete;
export { ButtonDelete };
