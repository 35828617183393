import styled, { css } from 'styled-components';

const FormGroup = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  flex-wrap: wrap;
  width: ${(props) => props.width || '100%'};

  .w-container-field {
    position: relative;
    width: 100%;

    .w-show-password {
      position: absolute;
      top: 0;
      right: 0;
      margin: -3px 0px 0px 0px;
    }
  }

  ${(props) => !props.noMarginBottom
    && css`
      margin-bottom: 24px;
    `};

  ${(props) => props.formActions
    && css`
      button {
        width: 100%;

        @media screen and (min-width: 544px) {
          width: auto;
        }
      }

      @media screen and (min-width: 544px) {
        justify-content: space-between;
      }
    `};

  input {
    + input {
      margin-left: 8px;
    }
  }
`;

export { FormGroup };
export default FormGroup;
