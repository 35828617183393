import styled from 'styled-components';

import ArrowDownSVG from './arrow-down.svg';

const SelectNew = styled.select`
  background-image: url(${ArrowDownSVG});
  background-position: calc(100% - 19px) center;
  background-repeat: no-repeat;
  background-size: 20px 10px;
  background-color: #fff;
  border: 1px solid #111;
  border-radius: 4px;
  appearance: none;
  color: #000;
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  padding: 14px 64px 11px 12px;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  &:focus {
    box-shadow: inset 0 0 0 3px #ffb612;
    border-color: #ffb612;
  }
`;

export default SelectNew;
export { SelectNew };
