import {
  observable, action, decorate, computed,
} from 'mobx';

import {
  getListBodyEmphasis as getListBodyEmphasisApi,
  getListNameTeacher as getListNameTeacherApi
} from 'services/api';

import {
  IfilterWorkoutStore, teacher, typeBodyEnfases,
} from './IfilterWorkoutStore';

import {
  SuggetionTraining,
} from '../IgenericTypes';

import PreLoadingRulesFilter from './PreLoadingRulesFilter';
import FilterSearchWorkout from './FilterSearchWorkout';

class FilterWorkoutStore implements IfilterWorkoutStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    peopleStore: {
      getFrequencyActive: (): number => 0,
      level: '',
    },
    teacherTrainingStore: {
      programId: 0,
    },
  };

  groupLabel: string[] = [];

  groupFrequency: number[] = [];

  groupLevel: string[] = [];

  groupLevelId: string[] = [];

  groupSizeTraining: string[] = [];

  groupTeacher: teacher[] = [];

  selecedTeacher: teacher | undefined;

  intensificationMethod = false;

  restriction = false;

  bodyEmphasis: typeBodyEnfases[] = [];

  selecedtBodyEmphasis: string[] = [];

  selecedtBodyEmphasisTranslate: string[] = [];

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  resetFilter(): void {
    this.groupLabel = [];
    this.groupTeacher = [];
    this.groupFrequency = [];
    this.groupLevel = [];
    this.groupLevelId = [];
    this.intensificationMethod = false;
    this.restriction = false;
    this.selecedtBodyEmphasis = [];
  }

  setIntensificationMethod(): void {
    this.intensificationMethod = !this.intensificationMethod;
  }

  setRestriction(): void {
    this.restriction = !this.restriction;
  }

  setBodyEmphasis(data: typeBodyEnfases[]): void {
    this.bodyEmphasis = data;
  }

  compare( a: teacher, b: teacher ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

  setGroupTeacher(data: teacher[]): void {
    this.groupTeacher = data.sort(this.compare);
  }

  hasEmphasis(): boolean {
    return this.bodyEmphasis.length > 0;
  }

  setSelecedtBodyEmphasis(emphasis: typeBodyEnfases) {
    const hasEmphasis = this.selecedtBodyEmphasis.includes(emphasis.value);
    if (hasEmphasis) {
      const index = this.selecedtBodyEmphasis.indexOf(emphasis.value);
      this.selecedtBodyEmphasis.splice(index, 1);
      this.selecedtBodyEmphasisTranslate.splice(index, 1);
    } else {
      this.selecedtBodyEmphasis.push(emphasis.value);
      this.selecedtBodyEmphasisTranslate.push(emphasis.label);
    }
  }

  setSelectTeacher(objTeacher: teacher) {
    const idTeacher = this.selecedTeacher?.id;

    if(idTeacher === objTeacher.id) {
      this.selecedTeacher = undefined
    } else {
      this.selecedTeacher = objTeacher;
    }
  }

  setGroupLabel(label: string): void {
    if(label === '') return;

    const hasLabel = this.groupLabel.includes(label);
    if (hasLabel) {
      const index = this.groupLabel.indexOf(label);
      this.groupLabel.splice(index, 1);
    } else {
      this.groupLabel.push(label);
    }
  }

  setFrequency(frequency: number): void {
    if(frequency === undefined) return;

    const hasFrequency = this.groupFrequency.includes(frequency);

    if (hasFrequency) {
      const index = this.groupFrequency.indexOf(frequency);
      this.groupFrequency.splice(index, 1);
    } else {
      this.groupFrequency.push(frequency);
    }
  }

  setLevel(level: string, levelId: string): void {
    const hasLevel = this.groupLevel.includes(level);

    if (hasLevel) {
      const index = this.groupLevel.indexOf(level);
      this.groupLevel.splice(index, 1);
      this.groupLevelId.splice(index, 1);
    } else {
      this.groupLevel.push(level);
      this.groupLevelId.push(levelId);
    }
  }

  setSizeTraining(sizeTraining: string): void {
    const hasSizeTraining = this.groupSizeTraining.includes(sizeTraining);

    if(hasSizeTraining) {
      const index = this.groupSizeTraining.indexOf(sizeTraining);
      this.groupSizeTraining.splice(index, 1);
    } else {
      this.groupSizeTraining.push(sizeTraining);
    }

  }

  getListConcat(listExercise: any) {
    return listExercise.reduce((list: any, nextList: any) => {
      const listSave = list;
      const listCurrent = nextList;

      return listSave.concat(listCurrent);
    }, []);
  }

  getOptionsGroupLabel(list: SuggetionTraining[]): SuggetionTraining[] {
    if (this.groupLabel.length <= 0) return list;

    const listExercise = this.groupLabel.map((group: string) => {
      const listLetter = group.split('/');

      return list.filter((workout: SuggetionTraining) => {
        const filter = JSON.stringify(listLetter);
        const reverseLettter = listLetter.reverse();
        const filterReverse = JSON.stringify(reverseLettter);
        const orderAlphabetically = workout.training_group_labels.slice().sort();
        const groupLabels = JSON.stringify(orderAlphabetically);
        return filter === groupLabels || filterReverse === groupLabels;
      });
    });

    return this.getListConcat(listExercise);
  }


  getFrequency(list: SuggetionTraining[]): SuggetionTraining[] {
    if (this.groupFrequency.length <= 0) return list;

    const listExercise = this.groupFrequency.map((frequency: number) => list
      .filter((workout: SuggetionTraining) => frequency === workout.frequency));

    return this.getListConcat(listExercise);
  }

  getLevel(list: SuggetionTraining[]): SuggetionTraining[] {
    if (this.groupLevel.length <= 0) return list;

    const listExercise = this.groupLevel.map((level: string) => list
      .filter((workout: SuggetionTraining) => level === workout.level_name));

    return this.getListConcat(listExercise);
  }

  getIntensificationMethod(list: SuggetionTraining[]): SuggetionTraining[] {
    if (this.intensificationMethod === false) return list;

    return list
      .filter((workout: SuggetionTraining) => workout
        .intensification_method === this.intensificationMethod);
  }

  getListRestriction(list: SuggetionTraining[]): SuggetionTraining[] {
    if (this.restriction === false) return list;

    return list
      .filter((workout: SuggetionTraining) => {
        return workout.training_health_restriction === this.restriction;
      });
  }

  getListBodyEmphasis(list: SuggetionTraining[]) {
    if(this.selecedtBodyEmphasisTranslate.length <= 0) return list;

   const listExercise = this.selecedtBodyEmphasisTranslate.map((emphasis: string) => {
    return list.filter((workout: SuggetionTraining) => {
      return workout.body_emphasis === emphasis;
    });
   });

   return this.getListConcat(listExercise);
  }

  getListSizeTraining(list: SuggetionTraining[]) {
    if(this.groupSizeTraining.length <= 0) return list;

    const listExercise = this.groupSizeTraining.map((sizeTraining: string) => {
      return list.filter((workout: SuggetionTraining) => {
        return workout.duration === sizeTraining;
      });
     });

     return this.getListConcat(listExercise);
  }

  getListExercise(list: SuggetionTraining[]) {
    const listGroupLabel = this.getOptionsGroupLabel(list);
    const listFrequency = this.getFrequency(listGroupLabel);
    const listNivel = this.getLevel(listFrequency);
    const listIntensification = this.getIntensificationMethod(listNivel);
    const listRestriction = this.getListRestriction(listIntensification);
    const listBodyEmphasis = this.getListBodyEmphasis(listRestriction);
    const listSizeTraining = this.getListSizeTraining(listBodyEmphasis);
    const resultListFilter = listSizeTraining;
    return resultListFilter;
  }

  getListParams(): string {
    const id = this.rootStore.teacherTrainingStore.programId;
    const groupLabel = FilterSearchWorkout.getGroupLabel(this.groupLabel);
    const frequency = FilterSearchWorkout.getFrequency(this.groupFrequency);
    const level = FilterSearchWorkout.getLevel(this.groupLevelId, id);
    const intensification = FilterSearchWorkout.getIntensification(this.intensificationMethod);
    const restriction = FilterSearchWorkout.getRestriction(this.restriction);
    const bodyEmphasis = FilterSearchWorkout.getBodyEmphasis(this.selecedtBodyEmphasis);
    const duration = FilterSearchWorkout.getDuration(this.groupSizeTraining);
    const nameTeacherId = FilterSearchWorkout.getNameTeacherId(this.selecedTeacher?.id);

    return `${groupLabel}${frequency}${level}${intensification}${restriction}${bodyEmphasis}${duration}${nameTeacherId}`;
  }

  loadingFilterBeforeTratament() {
    const frequency = this.rootStore.peopleStore.getFrequencyActive();
    const { level } = this.rootStore.peopleStore;
    const keyLevel = FilterSearchWorkout.normalizeNameKey(level);

    const division = PreLoadingRulesFilter
      .getFrequency(frequency);

    this.setGroupLabel(division);
    this.setFrequency(frequency);
    this.setLevel(level, keyLevel);
  }

  get lengthSelected() {
    const labels = this.groupLabel.length;
    const frequency = this.groupFrequency.length;
    const level = this.groupLevel.length;
    const intensificaiton = (this.intensificationMethod) ? 1 : 0;
    const restriction = (this.restriction) ? 1 : 0;
    const emphasis = this.selecedtBodyEmphasis.length;
    const sizeTraining = this.groupSizeTraining.length;

    return labels + frequency + level + intensificaiton + restriction + emphasis + sizeTraining;
  }

  doRequestEmphasis() {
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await getListBodyEmphasisApi(
          this.rootStore.sessionStore.token,
        );
        this.setBodyEmphasis(res.data.body_emphasis);
        resolve(res.data.weight_training);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  doRequestListNameTeacher() {
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await getListNameTeacherApi(
          this.rootStore.sessionStore.token,
        );

        this.setGroupTeacher(res.data.teachers);
        resolve(res.data.teachers);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }
}

decorate(FilterWorkoutStore, {
  groupLabel: observable,
  groupTeacher: observable,
  groupFrequency: observable,
  groupLevel: observable,
  groupSizeTraining: observable,
  intensificationMethod: observable,
  restriction: observable,
  bodyEmphasis: observable,
  selecedtBodyEmphasis: observable,
  selecedtBodyEmphasisTranslate: observable,
  selecedTeacher: observable,
  getListExercise: action,
  setGroupLabel: action,
  setFrequency: action,
  setSizeTraining: action,
  setIntensificationMethod: action,
  setRestriction: action,
  loadingFilterBeforeTratament: action,
  setLevel: action,
  getListParams: action,
  getOptionsGroupLabel: action,
  getFrequency: action,
  getIntensificationMethod: action,
  getListRestriction: action,
  hasEmphasis: action,
  doRequestEmphasis: action,
  setSelectTeacher: action,
  setGroupTeacher: action,
  doRequestListNameTeacher: action,
  lengthSelected: computed,
});

export default FilterWorkoutStore;
