import {
  typeDataPremissa,
  typeWarning,
  typeTwoValues,
  typeIntervalOneValues,
  typeListExerciseAdd,
} from './IrulesPaisStore';

import {
  Training,
  WeightTrainingExercises,
} from '../IgenericTypes';

class RulesPaisHelper {
  static convertToNumber(value: number | null | undefined): number {
    return value || 0;
  }

  static intervalOneValues(params: typeIntervalOneValues): boolean {
    return (params.uniqueValue >= params.min && params.uniqueValue <= params.max);
  }

  static intervalTwoValues(params: typeTwoValues): boolean {
    const inputMax = (params.maxForm === 0) ? params.minForm : params.maxForm;
    const resultMin = (params.minForm >= params.min);

    const resultMax = (inputMax <= params.max && inputMax >= params.minForm);
    return (resultMin && resultMax);
  }

  static createList(item: string): string[] {
    return [
      item,
    ];
  }

  static listDefaultWarningComponent(label: string, list: string | string[]) {
    const hasList = Array.isArray(list);
    const listWarning = (hasList) ? list : RulesPaisHelper.createList(list);

    return [
      {
        title: label,
        listRules: listWarning,
      },
    ];
  }

  static getTratamentRulesPais(info: typeWarning): typeWarning {
    return {
      id: info.id,
      title: info.title,
      warning: info.warning,
      checked: info.checked,
    };
  }

  static removeExerciseCardio(data: Training): WeightTrainingExercises[] {
    const weightTrainingExercises = data.weight_training_exercises || [];
    const noListCardio = weightTrainingExercises
      .filter((exercise: WeightTrainingExercises) => (exercise.cardio !== true));
    return noListCardio.filter((exercise: WeightTrainingExercises) => exercise.exercise_material !== 'cardio');
  }

  static removeGroupMuscle(
    listExercise: WeightTrainingExercises[],
    currentRules: typeDataPremissa,
  ): WeightTrainingExercises[] {
    const listGroupMuscle = currentRules.unavailable_muscle_goups || [];
    const list = listExercise.filter((exercise: WeightTrainingExercises) => {
      const nameGroupMuscle = exercise.muscle_group;
      return (listGroupMuscle.includes(nameGroupMuscle) === false);
    });

    return list;
  }

  static removeExercisesRules(
    data: Training,
    currentRules: typeDataPremissa,
  ): WeightTrainingExercises[] {
    const noListCardio = RulesPaisHelper.removeExerciseCardio(data);
    return RulesPaisHelper.removeGroupMuscle(noListCardio, currentRules);
  }

  static hasAmountExercise(data: Training, currentRules: typeDataPremissa): boolean {
    const minExercise = RulesPaisHelper.convertToNumber(currentRules.min_exercises);
    const maxExercise = RulesPaisHelper.convertToNumber(currentRules.max_exercises);
    const listAmountExercise = [
      minExercise,
      maxExercise,
    ];

    const trainingCurrent = RulesPaisHelper.removeExercisesRules(data, currentRules);
    const amountExercise = trainingCurrent.length;

    if (amountExercise >= listAmountExercise[0] && amountExercise <= listAmountExercise[1]) {
      return true;
    }

    return false;
  }

  static getAmountSeriesTraining(training: Training, currentRules: typeDataPremissa): boolean {
    const minSeriesTraining = RulesPaisHelper.convertToNumber(currentRules.min_series_by_training);
    const maxSeriesTraining = RulesPaisHelper.convertToNumber(currentRules.max_series_by_training);

    const listAmountExercise = [
      minSeriesTraining,
      maxSeriesTraining,
    ];

    const trainingCurrent = RulesPaisHelper.removeExercisesRules(training, currentRules);

    const numberSerieAll = trainingCurrent
      .reduce((initial: number, exercise: WeightTrainingExercises) => {
        if (exercise.series) {
          return initial + exercise.series;
        }

        return initial;
      }, 0);

    if (numberSerieAll >= listAmountExercise[0] && numberSerieAll <= listAmountExercise[1]) {
      return true;
    }

    return false;
  }

  static getLengthExercise(listExercise: typeListExerciseAdd[], currentRules: typeDataPremissa) {
    const listGroupMuscle = currentRules.unavailable_muscle_goups;
    const list = listExercise
      .filter((exercise: typeListExerciseAdd) => {
        const hasGroupCardio = exercise.muscle_group !== 'cardio';
        const notCardio = (exercise.cardio === undefined || exercise.cardio === false);
        return (hasGroupCardio && notCardio);
      });

    const result = list.filter((exercise) => {
      const nameGroupMuscle = exercise.muscle_group;
      return (listGroupMuscle.includes(nameGroupMuscle) === false);
    }).length;

    return result;
  }
}

export default RulesPaisHelper;
