import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import IconIntensification from '../assets/power-up.svg';

const ItemExercise = (props) => {
  const context = useContext(Context);
  const { exercise } = props;
  const {
    i18n,
  } = context;

  const renderNameCardio = () => {
    const cardioEquipment = exercise.cardio_equipment;
    return i18n.t(cardioEquipment);
  };

  const renderNameIntensification = (intensificationMethod, name) => (
    <p>
      <span>{name}</span>
      {''}
      <br />
      <img src={IconIntensification} alt="este" className="icon-image" />
      <span className="intensification">
        {' '}
        {i18n.t(intensificationMethod)}
      </span>
    </p>
  );

  const renderNameExercise = () => {
    let name = exercise.title.toLowerCase() || '';
    const intensificationMethod = exercise.intensification_method || '';
    const hasCardio = exercise.cardio;

    if (hasCardio === false && intensificationMethod !== '') {
      return renderNameIntensification(intensificationMethod, name);
    }

    if (hasCardio) {
      name = renderNameCardio();
    }

    return (
      <p>
        <span>{name}</span>
      </p>
    );
  };

  return (
    <li>
      <div className="name-exercise">
        {renderNameExercise()}
      </div>

      <div>
        <p>{exercise.series || '-'}</p>
      </div>

      <div>
        <p>{`${exercise.min_repetitions || 'xx'} - ${exercise.max_repetitions || 'xx'}`}</p>
      </div>

      <div>
        <p>{`${exercise.min_interval || 'xx'} - ${exercise.max_interval || 'xx'}`}</p>
      </div>
    </li>
  );
};

ItemExercise.defaultProps = {
  exercise: {},
};

ItemExercise.propTypes = {
  exercise: PropTypes.shape({
    min_interval: PropTypes.number,
    max_interval: PropTypes.number,
    min_repetitions: PropTypes.number,
    max_repetitions: PropTypes.number,
    series: PropTypes.number,
    intensification_method: PropTypes.string,
    cardio_equipment: PropTypes.string,
  }),
};

export default ItemExercise;
