import React, { useContext } from 'react';
import {
  Heading, Text, Button, Flex,
} from 'rebass';
import Context from '../../stores/context';
import ModalWrapper from './modal-wrapper';

function ModalDelete() {
  const context = useContext(Context);
  const { modalStore, i18n } = context;

  const tryAgainCallback = () => {
    modalStore.execModalDeleteCallBack();
    modalStore.toggleModal('modalDelete', false);
    modalStore.postDataGa();
  };

  const closeCallback = () => {
    modalStore.toggleModal('modalDelete', false);
  };

  return (
    <ModalWrapper onClick={closeCallback}>
      <Heading my={[3]}>{modalStore.title}</Heading>
      <Text mb={[3]}>{modalStore.description}</Text>
      <Flex justifyContent="center">
        <Button
          variant="primary"
          onClick={tryAgainCallback}
        >
          {i18n.t('delete')}
        </Button>
        <Button
          ml={[3]}
          variant="cancel"
          onClick={closeCallback}
        >
          {i18n.t('cancel')}
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

export default ModalDelete;
