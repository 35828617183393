import styled from 'styled-components';

const FormSearch = styled.div`
  padding: 16px;

  input[type='search'] {
    padding: 14px 16px 13px 64px;
  }

  button[type='submit'] {
    position: absolute;
    left: 10px;
    bottom: 0;
    background-color: transparent;
  }
`;

export { FormSearch };
export default FormSearch;
