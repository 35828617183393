import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Text, Flex,
} from 'rebass';
import {
  Button,
} from 'components';
import Context from 'stores/context';
import ModalWrapper from 'components/modal/modal-wrapper';

const styleModal = {
  widthWrapper: '544px',
  px: '24px',
  py: '24px',
  bgColor: 'yellow',
};

function ModalNotify({ onClick }) {
  const context = useContext(Context);
  const {
    modalStore,
    noticesStore,
    i18n,
    appStore,
  } = context;

  const postReadNotices = (e) => {
    appStore.toggleloading(true);

    modalStore.toggleModal('modalNotify', false);
    const notice = noticesStore.getFirstNotices();
    const {
      id,
    } = notice;

    noticesStore.doRequestPostRead(id)
      .then(() => {
        noticesStore.removeWarningTemp(id);
        appStore.toggleloading(false);
      })
      .catch(() => {
        appStore.toggleloading(false);
      });

    if (onClick) {
      onClick(e);
    }
  };

  const closeCallback = (e) => {
    modalStore.toggleModal('modalNotify', false);
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ModalWrapper onClick={closeCallback} styleCss={styleModal}>
      <Heading color="black" my={[3]}>{modalStore.title}</Heading>
      <Text mb={[3]}>{modalStore.description}</Text>
      <Flex justifyContent="center">
        <Button
          secondary
          onClick={postReadNotices}
        >
          {i18n.t('ok_i_got_it')}
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

ModalNotify.defaultProps = {
  onClick: () => {},
};

ModalNotify.propTypes = {
  onClick: PropTypes.func,
};

export default ModalNotify;
