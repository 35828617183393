/* eslint-disable camelcase */

import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import {
  Select,
} from 'components';

function SelectFormExercise(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
  } = context;

  const {
    listIntensification,
    values,
    handleChange,
    handleBlur,
  } = props;

  const listPattern = () => listIntensification.map((item) => (
    <option key={item} value={item}>{i18n.t(item)}</option>
  ));

  const listPais = () => {
    const {
      recommended,
      not_recommended,
    } = listIntensification;

    const hasList = !!(recommended || not_recommended);

    if (hasList === false) return '';

    const recommend = recommended.map((item) => (
      <option key={item} value={item}>{i18n.t(item)}</option>
    ));

    const notRecommend = not_recommended.map((item) => (
      <option key={item} value={item}>{i18n.t(item)}</option>
    ));

    return (
      <>
        <optgroup label={i18n.t('recommended')}>
          { recommend }
        </optgroup>
        <optgroup label={i18n.t('notRecommended')}>
          { notRecommend }
        </optgroup>
      </>
    );
  };

  const renderList = () => {
    const hasPais = rulesPaisStore.hasIntensificationMethod();

    if (hasPais) {
      return listPais();
    }

    return listPattern();
  };

  return (
    <Select
      id="intensification_method"
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.intensification_method || ''}
    >
      <option value="">{i18n.t('none')}</option>
      { renderList() }
    </Select>
  );
}

SelectFormExercise.defaultProps = {
  values: {
    intensification_method: '',
  },
  handleChange: () => {},
  handleBlur: () => {},
  listIntensification: {
    recommended: [],
    not_recommended: [],
  },
};

SelectFormExercise.propTypes = {
  values: PropTypes.shape({
    intensification_method: PropTypes.string,
  }),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  listIntensification: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export { SelectFormExercise };
export default SelectFormExercise;
