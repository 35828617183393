import { createGlobalStyle } from 'styled-components';

const green = '#008a1f';
const greenLight = '#26e88a';
const greenDark = '#25A769';
const yellow = '#ffb614';
const red = '#D52B1E';
const darkgray = '#111111';
const darkgraySecondary = '#202020';
const lightgray = '#efefef';
const black = 'black';
const white = '#fff';
const bordeDarkLight = '#4d4d4b';
const textDarkLight = '#666';
const blackGray = '#808080';
const grayLight = '#999';

export const theme = {
  fontSizes: [
    '12px', '14px', '16px', '20px', 24, 32, 48, 64,
  ],
  fonts: {
    Gotham: 'Gotham',
  },
  breakpoints: ['320px', '602px', '1336px'],
  buttons: {
    primarySquare: {
      color: black,
      backgroundColor: yellow,
      fontWeight: '500',
    },
    secondSquare: {
      backgroundColor: darkgray,
      color: white,
      fontWeight: '500',
    },
    primary: {
      color: black,
      backgroundColor: yellow,
      textTransform: 'uppercase',
      borderRadius: '30px',
      padding: '13px 25px 11px',
      cursor: 'pointer',
    },
    cancel: {
      color: black,
      backgroundColor: lightgray,
      textTransform: 'uppercase',
      borderRadius: '30px',
      padding: '13px 25px 11px',
      cursor: 'pointer',
    },
    disabled: {
      color: black,
      backgroundColor: lightgray,
      textTransform: 'uppercase',
      borderRadius: '30px',
      padding: '13px 25px 11px',
      pointerEvents: 'none',
      cursor: 'pointer',
    },
    outline: {
      color: lightgray,
      border: `2px solid ${darkgraySecondary}`,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      padding: '15px 25px',
      borderRadius: '4px',
      textTransform: 'uppercase',
    },
    text: {
      backgroundColor: 'transparent',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
  },
  colors: {
    yellow,
    lightgray,
    darkgray,
    red,
    black,
    green,
    greenLight,
    white,
    bordeDarkLight,
    textDarkLight,
    blackGray,
    grayLight,
    greenDark,
  },
  mediaScreen: {
    cellphone: 'max-width: 599px',
    minTablet: 'min-width: 600px',
    maxTablet: 'max-width: 800px',
    desktop: 'min-width: 801px',
  },
};

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Gotham';

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  html, body {
    min-height: 100vh;
  }

  body {
    -webkit-font-smoothing: antialiased;
    moz-osx-font-smothing: grayscale;
    text-rendering: optimizeLegibility
    background-color: #000;
    overscroll-behavior-y: contain;
    user-select: none;
    padding-bottom: 50px;
  }
`;
