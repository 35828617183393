import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import {
  ProfileInfo,
} from 'components';
import ProgressBar from '../components/ProgressBar';

const ShowProgressTrainingStyle = styled.div`
  border: 1px solid ${props => props.theme.colors.bordeDarkLight};
  padding: 24px 24px;
  border-radius: 8px;
  margin-top: 30px;

  .wrapper-subtitle {
    color: ${props => props.theme.colors.white};
    margin-top: 24px;

    &__item {
      display: flex;
      list-style-type: none;
    }

    .block {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      margin-right: 16px;

      &.yellow {
        background: ${props => props.theme.colors.yellow};
        margin-bottom: 16px;
      }

      &.blue-light {
        background: #94bfdd;
      }
    }

    p {
      font-size: ${props => props.theme.fontSizes[2]};
    }
  }
`;

const ShowProgressTraining = () => {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
  } = context;

  if (peopleStore.hasStaticsTraining === false) {
    return false;
  }

  const porcentProgress = peopleStore.getStatisticTraining.progress_percentage;
  const completedSessions = peopleStore.getStatisticTraining.completed_sessions;
  const totalSessions = peopleStore.getStatisticTraining.planned_sessions;
  const progressExpected = peopleStore.getStatisticTraining.expected_progress_percentage;

  return (
    <ShowProgressTrainingStyle>
      <ProfileInfo mgBottom="24">
        <small>{i18n.t('trainings_finish')}</small>
        <span>{i18n.t('progress_training', { min: completedSessions, max: totalSessions, progress: porcentProgress })}</span>
      </ProfileInfo>

      <ProgressBar
        label="progress_training_bar"
        progressPercent={porcentProgress}
        completedSessions={completedSessions}
        totalSessions={totalSessions}
        progressExpected={progressExpected}
        hiddenSubBar={false}
        hiddenRemainsDays
        status="default"
      />

      <ul className="wrapper-subtitle">
        <li className="wrapper-subtitle__item">
          <div className="block yellow" />
          <p>{i18n.t('progress_complete')}</p>
        </li>
        <li className="wrapper-subtitle__item">
          <div className="block blue-light" />
          <p>{i18n.t('progress_goal')}</p>
        </li>
      </ul>
    </ShowProgressTrainingStyle>
  );
};

export default ShowProgressTraining;
