import React, {
  Fragment, useContext, useEffect, useState,
} from 'react';

import Context from 'stores/context';

import DescriptionWorkout from './DescriptionWorkout';

const ListExercises = (props) => {
  const { id } = props;
  const context = useContext(Context);
  const { suggestions, buildTrainingStore, i18n, modalStore } = context;
  const { listExercises } = suggestions;
  const [reqStatus, setReqStatus] = useState(null);
  const [exercises, setExercises] = useState(null);

  const sendList = () => {
    setReqStatus(null);
  };

  useEffect(() => {
    const call = async () => {
      let response;
      try {
        response = await buildTrainingStore.doRequestGetTrainingSuggestions(id);
        setReqStatus('success');
        modalStore.closeModalError();
        setExercises(response);
      } catch (error) {
        modalStore.setModalNetworkCallback(sendList);
        modalStore.showModalError(error);
        window.scroll(0, 0);
        setReqStatus('error');
      }

      return response;
    };

    if (reqStatus === null) {
      call();
    }
  }, [reqStatus, buildTrainingStore, exercises, id, modalStore]);

  return (
    <Fragment>
      <p className="w-text-status">{exercises === null ? i18n.t('loading') : ''}</p>
      <p className="w-text-status">{reqStatus === 'error' && i18n.t('error_get_data')}</p>

      {listExercises.isExercisesSuggestions(exercises) && <DescriptionWorkout exercises={exercises} />}
    </Fragment>
  );
};

export default ListExercises;
