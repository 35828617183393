import React, { Component } from 'react';
import { Button, FlexDiv, Title } from '../../components';
import Context from '../../stores/context';
import { ConcatUnitInUrl } from '../../utils';

class BuildTrainingSuccess extends Component {
  static contextType = Context;

  render() {
    const { i18n } = this.context;
    const { history } = this.props;

    return (
      <>
        <FlexDiv minHeight="initial">
          <Title type={1} as="h2">
            <p>{i18n.t('build_training_success_title_1')}</p>
            <p>{i18n.t('build_training_success_title_2')}</p>
            <small>{i18n.t('build_training_success_description')}</small>
          </Title>

          <Button
            tertiary="true"
            minWidth="initial"
            onClick={() => {
              const url = ConcatUnitInUrl('/profile');
              history.push(url);
            }}
          >
            {i18n.t('back_navigate')}
          </Button>
        </FlexDiv>
      </>
    );
  }
}

export default BuildTrainingSuccess;
