import React, { Component } from 'react';
import Context from 'stores/context';
import { Button, Modal } from 'components';
import { ConcatUnitInUrl } from 'utils';

class ModalExercise extends Component {
  static contextType = Context;

  constructor() {
    super();

    this.closeModalExercise = this.closeModalExercise.bind(this);
  }

  closeModalExercise() {
    const { modalStore } = this.context;
    const { history } = this.props;
    const url = ConcatUnitInUrl('/build-training/build-training-list');
    history.push(url);
    modalStore.toggleModal('modalExercise', false);
  }

  render() {
    const { i18n } = this.context;
    const { children } = this.props;

    return (
      <Modal fullScreen="true">
        <div className="w-modal-content">
          <div className="w-modal-body w-modal-body-form">
            <Button
              rounded="true"
              tertiary="true"
              close="true"
              className="w-btn-close"
              onClick={this.closeModalExercise}
            >
              <span>{i18n.t('close')}</span>
            </Button>
            <div className="wrapper-children">
              {children}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalExercise;
