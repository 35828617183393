import {
  observable, action, decorate, computed,
} from 'mobx';

import Fuse from 'fuse.js';

import { orderBy } from 'lodash';

import {
  getListLocation as locationListApi,
} from '../services/api';

class LocationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.showModal = false;
    this.listLocation = [];
    this.nameLocation = '';
    this.acronymSelected = null;
    this.searchValue = '';
  }

  reset() {
    this.listLocation = [];
  }

  setNameLocation(name) {
    this.nameLocation = name;
  }

  setShowModalLocation(toggle) {
    this.showModal = toggle;
  }

  setListLocation(data) {
    this.listLocation = orderBy(data, ['name'], ['asc']);
  }

  setAcronymSelected(acronym) {
    this.acronymSelected = acronym;
  }

  doRequestListLocation(status = true) {
    const promiseListLocation = async (resolve, reject) => {
      try {
        const res = await locationListApi({}, this.rootStore.sessionStore.token);
        this.setListLocation(res.data.locations);
        this.setShowModalLocation(status);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseListLocation(resolve, reject)
    ));
  }

  get getListLocation() {
    return this.listLocation;
  }

  get getAllListLocation() {
    const location = this.listLocation;

    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [
        'name',
        'acronym',
      ],
    };

    if (this.searchValue !== '') {
      const fuse = new Fuse(location, options);
      const results = fuse.search(this.searchValue);

      return results;
    }

    return location;
  }
}

decorate(LocationStore, {
  searchValue: observable,
  listLocation: observable,
  nameLocation: observable,
  showModal: observable,
  acronymSelected: observable,
  doRequestListLocation: action,
  setShowModalLocation: action,
  reset: action,
  setNameLocation: action,
  setAcronymSelected: action,
  getListLocation: computed,
  getAllListLocation: computed,
});

export const LocationSchema = {
  nameLocation: true,
  acronymSelected: true,
  listLocation: {
    type: 'list',
  },
};

export default LocationStore;
