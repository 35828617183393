import {
  action, decorate, computed, observable,
} from 'mobx';
import {
  orderBy,
} from 'lodash';
import {
  getNotices as getNoticesApi,
  postNotices as postNoticesApi,
} from 'services/api';

class NoticesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.listNotices = [];
    this.notice = {};
    this.showListNotices = false;
    this.warningButtonNotices = false;
  }

  setListNotices(data) {
    this.listNotices = orderBy(data, ['id'], ['asc']);
  }

  setShowListNotices(status) {
    this.showListNotices = status;
  }

  setWarningButtonNotices(status) {
    this.warningButtonNotices = status;
  }

  setNotice(id) {
    const index = 0;
    const objNotice = this.listNotices.filter((itemNotice) => itemNotice.id === id);
    this.notice = objNotice[index];
  }

  doRequestNotices() {
    const notices = async (resolve, reject) => {
      try {
        const res = await getNoticesApi({}, this.rootStore.sessionStore.token);
        this.setListNotices(res.data.notices);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => {
      notices(resolve, reject);
    });
  }

  doRequestPostRead(id) {
    const data = {
      id,
    };

    const notices = async (resolve, reject) => {
      try {
        const res = await postNoticesApi(data, this.rootStore.sessionStore.token);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => {
      notices(resolve, reject);
    });
  }

  setHasNotices() {
    const status = this.listNotices.some((itemNotice) => itemNotice.read === false);
    this.setWarningButtonNotices(status);
  }

  removeWarningTemp(idNotices) {
    const updatelList = this.listNotices.map((notice) => {
      const objNotice = notice;

      if (objNotice.id === idNotices) {
        objNotice.read = true;
      }

      return objNotice;
    });

    this.setListNotices(updatelList);
    this.setHasNotices();
  }

  get hasNotices() {
    return this.listNotices.some((itemNotice) => itemNotice.read === false);
  }

  get getListNotices() {
    return this.listNotices;
  }

  getFirstNotices() {
    const index = 0;
    const notices = this.listNotices.filter((notice) => notice.read === false);
    return notices[index];
  }
}

decorate(NoticesStore, {
  showListNotices: observable,
  notice: observable,
  warningButtonNotices: observable,
  listNotices: observable,
  setShowListNotices: action,
  doRequestNotices: action,
  doRequestPostRead: action,
  setNotice: action,
  getFirstNotices: action,
  setWarningButtonNotices: action,
  setHasNotices: action,
  removeWarningTemp: action,
  hasNotices: computed,
  getListNotices: computed,
});

export default NoticesStore;
