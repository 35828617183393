import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TabResponsiveStyle = styled.div`
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  height: auto;
  background: ${(props) => props.theme.colors.darkgray};
  border-radius: 7px 7px 0px 0px;
  border-top: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
  border-left: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
  border-right: 1px solid ${(props) => props.theme.colors.bordeDarkLight};

  .tab__wrapper {
    display: flex;
    list-style-type: none;
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.textDarkLight};

    &.active {
      border-bottom: 2px solid ${(props) => props.theme.colors.yellow};
      color: ${(props) => props.theme.colors.white};
    }
  }

  .tab__wrapper {
    justify-content: space-around;
    flex-direction: row;
    height: 49px;
  }

  .text {
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 900;
    font-size: ${(props) => props.theme.fontSizes[1]};
    text-align: center;
    text-transform: uppercase;
  }


  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    .tab__wrapper {
      height: 100%;
      justify-content: flex-start;
      flex-direction: column;
    }

    .tab__nav {
      .collapse {
        display: none;
      }
    }

    .tab__nav.active {
      .collapse {
        display: block;
      }
    }

    .text {
      line-height: 50px;
    }

    .wrapper-menu-mobile {
      text-align: center;
      position: relative;
      height: 47px;
      border-bottom: 1px solid ${(props) => props.theme.colors.yellow};
    }

    .name-tab {
      line-height: 50px;
    }

    .w-collapse-button {
      background-color: transparent;
      transform: rotateZ(0);
      transition: transform 0.2s ease-out;
      margin: 0px;
      width: 34px;
      height: 30px;
      border: none;
      position: absolute;
      right: 10px;
      top: 8px;


      &.w-collapse-opened {
        transform: rotateZ(180deg);
      }
    }

    .item.active {
      border-bottom: none;
    }
  }

  @media(${(props) => props.theme.mediaScreen.minTablet}) and (${(props) => props.theme.mediaScreen.maxTablet}){
    .wrapper-menu-mobile {
      display: none;
    }
  }

  @media(${(props) => props.theme.mediaScreen.desktop}) {
    .wrapper-menu-mobile {
      display: none;
    }

    .tab__wrapper {
      flex-direction: row;
    }
  }
`;

export default class TabResponsive extends Component {
  static activeTab(selected, label) {
    return (selected === label.view) ? 'item active' : 'item';
  }

  constructor(props) {
    super(props);
    this.renderList = this.renderList.bind(this);
    this.toogleMenu = this.toogleMenu.bind(this);
    this.showMenuMobile = this.showMenuMobile.bind(this);
    this.state = {
      showMobile: false,
    };
  }

  toogleMenu() {
    const {
      showMobile,
    } = this.state;
    const toogle = !showMobile;

    this.setState({ showMobile: toogle });
  }

  showMenuMobile() {
    const {
      showMobile,
    } = this.state;

    return (showMobile) ? 'tab__nav active' : 'tab__nav';
  }

  renderList() {
    const {
      tabList,
      selected,
    } = this.props;

    const list = tabList.map((label) => (
      <li
        className={TabResponsive.activeTab(selected, label)}
        onClick={label.handleEvent}
        key={label.id}
        role="presentation"
        onKeyDown={this.handleKeyDown}
      >
        <span className="text">{label.name}</span>
      </li>
    ));

    return list;
  }

  render() {
    const {
      nameLabel,
    } = this.props;
    const {
      showMobile,
    } = this.state;

    return (
      <TabResponsiveStyle>
        <nav className={this.showMenuMobile()}>
          <div
            className="wrapper-menu-mobile"
            onClick={this.toogleMenu}
            role="button"
            aria-hidden="true"
          >
            <span className="name-tab text">{nameLabel}</span>
            <button className={`w-collapse-button ${showMobile ? 'w-collapse-opened' : ''}`} type="button">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
                  fill="#fff"
                />
              </svg>
            </button>
          </div>

          <div className="collapse">
            <ul className="tab__wrapper">
              {this.renderList()}
            </ul>
          </div>
        </nav>
      </TabResponsiveStyle>
    );
  }
}

TabResponsive.defaultProps = {
  nameLabel: '',
  tabList: [],
  selected: 0,
};

TabResponsive.propTypes = {
  nameLabel: PropTypes.string,
  tabList: PropTypes.arrayOf(PropTypes.shape({
    handleEvent: PropTypes.func,
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export { TabResponsive };
