import { action, decorate } from 'mobx';

import {
  IcronometerStore,
} from './IcronometerStore';

class CronometerStore implements IcronometerStore {
  rootStore = {}

  hour = 0;

  minute = 0;

  second = 0;

  millisecond = 0;

  cron = setInterval(() => { this.timer(); }, 10);

  startCronometer = false;

  fluxAnamnese = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    clearInterval(this.cron);
  }


  reset() {
    this.hour = 0;
    this.minute = 0;
    this.second = 0;
    this.millisecond = 0;
    this.startCronometer = false;
  }

  increment() {
    this.millisecond += 10;
  }

  timer(): void {
    this.increment();

    if (this.millisecond === 1000) {
      this.millisecond = 0;
      this.second += 1;
    }

    if (this.second === 60) {
      this.second = 0;
      this.minute += 1;
    }
    if (this.minute === 60) {
      this.minute = 0;
      this.hour += 1;
    }
  }

  returnData(input: number) {
    return input >= 10 ? `${input}` : `0${input}`;
  }

  stop() {
    this.reset();
    this.startCronometer = false;
    clearInterval(this.cron);
  }

  getTimeFinishExercise(): string {
    if (this.startCronometer === false) {
      return 'error_time';
    }

    if (this.hour > 0) {
      return 'faixa_5';
    }

    if ((this.minute >= 0 && this.minute < 2) || (this.minute === 2 && this.second <= 30)) {
      return 'faixa_1';
    }

    if (this.minute >= 2 && this.minute < 5) {
      return 'faixa_2';
    }

    if (this.minute >= 5 && this.minute < 10) {
      return 'faixa_3';
    }

    if (this.minute >= 10) {
      return 'faixa_4';
    }

    return 'error_time';
  }

  start() {
    this.stop();
    this.startCronometer = true;
    this.cron = setInterval(() => { this.timer(); }, 10);
  }

  setFluxAnamnese(status: boolean) {
    this.fluxAnamnese = status;
  }
}

decorate(CronometerStore, {
  start: action,
  stop: action,
  getTimeFinishExercise: action,
  setFluxAnamnese: action,
});

export default CronometerStore;
