import React, { useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ItemExerciseTeacher from './ItemExerciseTeacher';


const ListExerciseTeacherStyle = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
  height: 517px;
`;

const ListExerciseTeacher = (props) => {
  const context = useContext(Context);
  const {
    teacherTrainingStore,
    appStore,
    modalStore,
    i18n,
  } = context;
  const {
    history,
    tabSelected,
  } = props;
  const [reqListExercise, setReqListExercise] = useState(null);
  const [loading, setLoading] = useState(false);

  const reloadListExercise = () => {
    setReqListExercise(null);
  };

  useEffect(() => {
    const callLoading = () => {
      appStore.toggleloading(false);
      setLoading(false);
    };

    const getListExercise = async () => {
      appStore.toggleloading(true);
      try {
        await teacherTrainingStore.doRequestListExerciseTeacher({
          id: teacherTrainingStore.programId,
          queryString: '',
        });
        setReqListExercise(true);
        modalStore.closeModalError();
      } catch (error) {
        const msgTitle = i18n.t('error_loading');

        window.scroll(0, 0);
        modalStore.setTitle(msgTitle);
        modalStore.setModalNetworkCallback(reloadListExercise);
        modalStore.showModalError(error);
      }

      appStore.toggleloading(false);
    };

    if (reqListExercise === null) {
      getListExercise();
    }

    if (loading) {
      callLoading();
    }

  }, [
    modalStore,
    i18n,
    appStore,
    reqListExercise,
    teacherTrainingStore,
    loading,
  ]);

  if (teacherTrainingStore.reloadListExercise) {
    teacherTrainingStore.setReloadListExercise(false);
    reloadListExercise();
  }

  const loadingListWorkout = () => {
    appStore.toggleloading(true);
    teacherTrainingStore.plusWorkout();
    setLoading(true);
  };

  const onScroll = (e) => {
    const calc = e.target.scrollHeight - e.target.scrollTop;
    const heightClient = e.target.clientHeight + 1;
    const finish = teacherTrainingStore.finishListSuggestionTeacher;
    const result = (calc <= heightClient && finish === false);

    if (result && loading === false) {
      loadingListWorkout();
    }
  };

  const listItems = teacherTrainingStore.listWorkoutTeacherView.map(exercise => (
    <ItemExerciseTeacher
      key={exercise.id}
      exercise={exercise}
      history={history}
      editExercise
      duplicateExercise
      deleteButton
      tabSelected={tabSelected}
    />
  ));

  return (
    <ListExerciseTeacherStyle onScroll={onScroll} className="wrapper-scroll">
      {listItems}
    </ListExerciseTeacherStyle>
  );
};

ListExerciseTeacher.defaultProps = {
  history: {},
  tabSelected: 'my_training',
};

ListExerciseTeacher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  tabSelected: PropTypes.string,
};

export default observer(ListExerciseTeacher);
