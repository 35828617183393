import React, { Fragment, Component } from 'react';
import { observer } from 'mobx-react';
import arrayMove from 'array-move';
import { SortableContainer } from 'react-sortable-hoc';
import ModalExercise from './BuildingTrainingList/ModalExercise';
import ModalEditAllExercises from './BuildingTrainingList/ModalEditAllExercises';
import ModalSuccessPremissas from './BuildingTrainingList/ModalSuccessPremissas/ModalSuccessPremissas';
import Context from '../../stores/context';
import List from './BuildingTrainingList/List';
import Header from './BuildingTrainingList/Header';
import {
  FormEditExerciseWeightTraining,
  FormEditExerciseCardio,
} from './BuildingTrainingList/Forms';

class BuildTrainingList extends Component {
  static contextType = Context;

  state = {
    request: false,
    items: [],
  };

  constructor() {
    super();
    this.updateList = this.updateList.bind(this);
    this.loadingBuildTraining = this.loadingBuildTraining.bind(this);
  }

  componentWillMount() {
    const { buildTrainingStore, modalStore } = this.context;
    buildTrainingStore.reset();
    modalStore.toggleModal('modalExercise', false);
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { buildTrainingStore } = this.context;

    buildTrainingStore.editAllItemsSelected = [];

    this.setState(({ items }) => ({
      items: buildTrainingStore.newPositions(arrayMove(items, oldIndex, newIndex)),
    }));
  };

  loadingBuildTraining(res) {
    const {
      buildTrainingStore,
      trainingCreationStore,
      rulesPaisStore,
    } = this.context;

    if (trainingCreationStore.paisPremisses) {
      const data = rulesPaisStore.getWarningTabExercises(res);
      buildTrainingStore.setTrainingList(data);
    } else {
      buildTrainingStore.setTrainingList(res);
    }
  }

  /**
    * Atualiza a lista de exercicios a partir dos formularios
    * que muda varios exercicios ao mesmo tempo
   * @updateList
  */
  updateList() {
    const {
      buildTrainingStore,
      trainingCreationStore,
    } = this.context;

    buildTrainingStore.doRequestGetTrainingExercises(
      trainingCreationStore.weightTrainingId,
    ).then((res) => {
      this.loadingBuildTraining(res);

      this.setState({
        items: buildTrainingStore.getCurrentTrainingExercises,
      });
    });
  }


  /**
   * Carrega a lista de exercios sempre pela navegação
   * @loadingTrainingListy
  */
  loadingTrainingListy() {
    const {
      buildTrainingStore,
      modalStore,
      trainingCreationStore,
    } = this.context;
    const { request } = this.state;

    if (!request && !buildTrainingStore.getCurrentTrainingExercises.length) {
      buildTrainingStore.doRequestGetTrainingExercises(
        trainingCreationStore.weightTrainingId,
      ).then((res) => {
        this.loadingBuildTraining(res);
        this.setState({
          request: true,
          items: buildTrainingStore.getCurrentTrainingExercises,
        });
        modalStore.closeModalError();
      }).catch((error) => {
        modalStore.setModalNetworkCallback(this.loadingTrainingListy.bind(this));
        modalStore.showModalError(error);
      });
    }
  }

  /**
   * Renderiza o formulario de musculacao ou cardio
   * @renderModalExercise
   * @param {object} history - Redireciona a navegacao.
   * @param {object} buildTrainingStore - Objeto com os estado do exercicios.
   * @return {object} - Retorna um elemento que sera rendizado na view.
  */
  renderModalExercise(history, buildTrainingStore) {
    const exercise = buildTrainingStore.getEditItemSelected;

    if (exercise.cardio) {
      return (
        <ModalExercise history={history}>
          <FormEditExerciseCardio
            updateList={this.updateList}
          />
        </ModalExercise>
      );
    }

    return (
      <ModalExercise history={history}>
        <FormEditExerciseWeightTraining
          updateList={this.updateList}
        />
      </ModalExercise>
    );
  }

  render() {
    const {
      buildTrainingStore, modalStore, trainingCreationStore,
    } = this.context;

    const { items } = this.state;
    const { history } = this.props;

    const SortableList = SortableContainer(() => <List items={items} />);

    if (!trainingCreationStore.weightTrainingId) {
      return false;
    }

    this.loadingTrainingListy();

    if (!modalStore.modalEditAllExercises) {
      buildTrainingStore.editAllItemsSelected = [];
    }

    return (
      <Fragment>
        <Header
          updateItems={() => {
            this.setState({
              request: false,
              items: buildTrainingStore.getCurrentTrainingExercises,
            });
          }}
          history={history}
        />

        <SortableList onSortEnd={this.onSortEnd} useDragHandle />

        {modalStore.modalExercise && (this.renderModalExercise(history, buildTrainingStore))}
        {modalStore.modalEditAllExercises && <ModalEditAllExercises updateList={this.updateList} />}

        {modalStore.modalSuccessPremissas && <ModalSuccessPremissas history={history} />}
      </Fragment>
    );
  }
}

export default observer(BuildTrainingList);
