import styled from 'styled-components';

const ModalProgram = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px;
  justify-content: center;
  margin-top: 32px;

  left: 32px;
  top: 573px;
  border-radius: 4px;

  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;

  &.in_progress {
    background: #25A769;
  }

  &.in_recovery {
    background: #e98d35;
  }

  &.finished {
    background: #25A769;
  }

  &.closed {
    background: #c92c3f;
  }
`;

export default ModalProgram;
