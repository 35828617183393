import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import {
  Title,
} from 'components';
import PropTypes from 'prop-types';
import ListFilter from './ListFilter';
import ListExecutionExercise from './ListExecutionExercise';

const StyleFiltersWithExecution = styled.div`
  background-color:${(props) => props.theme.colors.lightgray};
  padding: 20px 20px;
  display: none;

  &.active {
    display: flex;
  }

  .wrapper-filter {
    border-right: 1px solid #CACACE;
    margin-right: 20px;
  }

  .list-filter {
    display: flex;
    flex-wrap: wrap;
  }

  .list-execution {
    display: flex;
    flex-wrap: wrap;
  }

  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    flex-wrap: wrap;

    .wrapper-filter {
      border-right: 0px solid #CACACE;
      overflow-y: hidden;
      margin-bottom: 20px;
    }


    .list-filter {
      width: 410px;
    }
  }

  @media(${(props) => props.theme.mediaScreen.minTablet}) and (${(props) => props.theme.mediaScreen.maxTablet}){
    .list-filter {
      width: 410px;
    }

    .list-execution {
      width: 140px;
    }
  }
`;

function FiltersWithEquipament(props) {
  const context = useContext(Context);
  const {
    i18n,
  } = context;
  const {
    open,
  } = props;

  return (
    <StyleFiltersWithExecution className={`${open ? 'active' : ''}`}>
      <div className="wrapper-filter">
        <Title
          type={4}
          as="h4"
          color="#000"
          align="initial"
          mgBottom="8"
          fontSize="16"
        >

          {i18n.t('muscle_group')}
        </Title>
        <div className="list-filter">
          <ListFilter />
        </div>
      </div>

      <div className="wrapper-execution">
        <Title
          type={4}
          as="h4"
          color="#000"
          align="initial"
          mgBottom="8"
          fontSize="16"
        >
          {i18n.t('execution')}
        </Title>
        <div className="list-execution">
          <ListExecutionExercise />
        </div>
      </div>
    </StyleFiltersWithExecution>
  );
}

FiltersWithEquipament.defaultProps = {
  open: false,
};

FiltersWithEquipament.propTypes = {
  open: PropTypes.bool,
};

export default FiltersWithEquipament;
