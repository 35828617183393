import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Warning as BaseIconWarning,
} from 'components/icons';

const LabelErrorStyle = styled.div`
  color: ${(props) => props.theme.colors.black};
  width: 100%;
  background: #FDF5ED;
  border-radius: 8px;
  display: flex;
  padding: 16px 16px;
  align-items: center;
  display: none;

  .icon-warning {
    margin-right: 10px;
  }

  .text {
    margin: 0px
  }
`;

function LabelError(props) {
  const {
    text,
  } = props;

  return (
    <LabelErrorStyle>
      <BaseIconWarning className="icon-warning" />
      <p className="text">{text}</p>
    </LabelErrorStyle>
  );
}

LabelError.defaultProps = {
  text: '',
};

LabelError.propTypes = {
  text: PropTypes.string,
};

export default LabelError;
export { LabelError };
