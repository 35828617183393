import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { ConcatUnitInUrl } from 'utils';
import {
  ProfileInfo,
  Button,
} from 'components';

const ObservationTraining = ({ history }) => {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
  } = context;

  const editComment = () => {
    const url = ConcatUnitInUrl('/training-creation/edit-observation');
    history.push(url);
  };

  const labelObservationTraining = peopleStore.trainingObservation && (
    <ProfileInfo highlight="true">
      <div>
        <small>{i18n.t('comment_title')}</small>
        <span className="lower-letter">
          {peopleStore.trainingObservation}
        </span>
      </div>
      <div>
        <Button
          secondary="true"
          rounded="true"
          edit="true"
          className="w-edit-button"
          onClick={() => editComment('/training-creation/edit-observation')}
        />
      </div>
    </ProfileInfo>
  );

  return (
    <>
      {labelObservationTraining}
    </>
  );
};

ObservationTraining.defaultProps = {
  history: {},
};

ObservationTraining.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export { ObservationTraining };
export default { ObservationTraining };
