import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  CheckTrueIconBase as CheckTrueIcon,
  ErrorIconBase as ErrorIconBaseIcon,
} from '../icon';

const ItemPaisStyle = styled.div`
  display: flex;
  margin-bottom: 16px;

  .validate-rules-pais {
    margin-left: 18px;
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSizes[0]};
    color: #2CC87E;

    .color-white {
      color:  ${(props) => props.theme.colors.white};
    }
  }

  &.error {
    .validate-rules-pais {
      color: #C92C3F;
    }
  }
`;

function ItemPais(props) {
  const {
    rule,
    expect,
    checked,
  } = props;

  const renderIcon = () => {
    if (checked) {
      return (
        <ErrorIconBaseIcon className="icon" />
      );
    }

    return (
      <CheckTrueIcon className="icon" />
    );
  }

  return (
    <ItemPaisStyle className={`item-pais ${checked ? 'error' : ''}`}>
      {renderIcon()}
      <div className="validate-rules-pais">
        <p>
          {rule}
        </p>
        <p>{expect}</p>
      </div>
    </ItemPaisStyle>
  );
}

ItemPais.defaultProps = {
  rule: '',
  expect: '',
  checked: false,
};

ItemPais.propTypes = {
  rule: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
  ]),
  expect: PropTypes.string,
  checked: PropTypes.bool,
};

export default ItemPais;
