import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import SucessSVG from './icon/sucess.svg';
import ErrorSVG from './icon/error.svg';

const ItemStyleError = styled.li`
  list-style-type: none;
  display: inline-flex;
  align-items: center;
  padding: 13px 13px;
  white-space: nowrap;
  width: 230px;
  margin-bottom: 10px;
  font-family: ${props => props.theme.fonts.Gotham};
  font-style: normal;
  font-weight: 400;
  font-size: ${props => props.theme.fontSizes[1]};

  .text-label {
    padding-top: 3px;
  }

  &.green {
    background: rgba(37, 167, 105, 0.15);

    .icon {
      background-image: url(${SucessSVG});
    }

    .text-label {
      color: #1E8654;
    }
  }

  &.red {
    background: rgba(201, 44, 63, 0.2);

    .icon {
      background-image: url(${ErrorSVG});
    }

    .text-label {
      color: #A72534;
    }
  }

  .icon {
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    width: 20px;
    height: 20px;
  }
`;

const ItemError = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    colorStyle,
    label,
  } = props;

  return (
    <ItemStyleError className={colorStyle}>
      <div className="icon" />
      <div className="text-label">{i18n.t(label)}</div>
    </ItemStyleError>
  );
};

export default ItemError;
