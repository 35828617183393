import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SearchClient from 'views/SearchClient/SearchClient';
import TrainingTeacher from 'views/TrainingTeacher/TrainingTeacher';
import NotFound from 'views/NotFound/NotFound';

import {
  PrivateRoute,
  Button,
} from 'components';
import ModalSelectedUnit from 'views/ChangeUnit/ModalSelectedUnit';
import ModalChangeUnit from 'views/ChangeUnit/ModalChangeUnit';

import { PostGaAnalytics, ConcatUnitInUrl } from 'utils';
import TabNavigator from './TabNavigator';

const WrapperButtonSearch = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  margin-bottom: 13px;

  .button-search {
    background: no-repeat;
    color: ${props => props.theme.colors.yellow};
    width: 88px;
    padding: 0px;
    border-radius: 0px;
    font-family: ${props => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 325;
    font-size: ${props => props.theme.fontSizes[1]};
    text-transform: inherit;
    text-decoration: underline;
  }

  @media(${props => props.theme.mediaScreen.desktop}) {
    max-width: 1440px;
    margin: 0 auto
  }
`;

const SelectTheOptionWork = (props) => {
  const context = useContext(Context);
  const {
    modalStore,
    noticesStore,
    sessionStore,
    toggleStore,
    i18n,
    walletUnityStore,
  } = context;
  const {
    history,
  } = props;

  const [reqNotices, setReqNotices] = useState(false);

  useEffect(() => {
    const showModalNotify = () => {
      if (noticesStore.hasNotices) {
        const notice = noticesStore.getFirstNotices();
        modalStore.setTitle(notice.title);
        modalStore.setDescription(notice.description);
        modalStore.toggleModal('modalNotify', true);
      }
    };

    const getNotices = async () => {
      setReqNotices(true);

      try {
        await noticesStore.doRequestNotices();
        showModalNotify();
        const status = noticesStore.hasNotices;
        noticesStore.setWarningButtonNotices(status);
      } catch (err) {}
    };

    if (reqNotices === false) {
      PostGaAnalytics('send', {
        hitType: 'pageview',
        page: 'home',
      });

      getNotices();
    }
  }, [
    modalStore,
    noticesStore,
    sessionStore,
    reqNotices,
  ]);

  const renderButtonSearch = () => {
    if (toggleStore.allowRenderWalletUnit()) {
      return (
        <WrapperButtonSearch>
          <Button
            tertiary
            minWidth="initial"
            className="button-search"
            onClick={() => {
              const url = ConcatUnitInUrl('/search-client-smart');
              history.push(url);
              walletUnityStore.resetView();
            }}
          >
            {i18n.t('search_client_title')}
          </Button>
        </WrapperButtonSearch>
      );
    }
  };


  const renderModal = () => {
    if (sessionStore.hiddenModalLocation === false && modalStore.modalSelectedUnit) {
      return (
        <ModalSelectedUnit history={history} />
      );
    }

    return false;
  };

  return (
    <div>
      {renderButtonSearch()}

      <TabNavigator />

      <div>
        <Switch>
          <PrivateRoute path="/select-option-work/search-client-smart" component={SearchClient} />
          <PrivateRoute path="/select-option-work/list-exercise" component={TrainingTeacher} />
          <Route component={NotFound} />
        </Switch>
      </div>

      {renderModal()}
      {modalStore.modalSuccesUnit && <ModalChangeUnit />}
    </div>
  );
};

SelectTheOptionWork.defaultProps = {
  history: {},
};

SelectTheOptionWork.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(SelectTheOptionWork);
