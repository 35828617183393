import React, { useState, useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

import {
  Label,
} from 'components';
import CurrentCountry from './CurrentCountry';
import ListCountry from './ListCountry';

const SelectCountryStyled = styled.div`
  margin-bottom: 28px;
  width: 240px;
  position: relative;
`;

function SelectCountry() {
  const [showListCountry, setShowListCountry] = useState(false);
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const openListCountry = () => {
    setShowListCountry(!showListCountry);
  };

  return (
    <SelectCountryStyled>
      <Label color="#fff" padding="0px 24px 0px 0px">{i18n.t('country')}</Label>
      <CurrentCountry openListCountry={openListCountry} />
      <ListCountry showListCountry={showListCountry} openListCountry={openListCountry} />
    </SelectCountryStyled>
  );
}

export default SelectCountry;
export { SelectCountry };
