import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabNavigatorStyle } from 'components';
import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

class TabNavigator extends Component {
  static contextType = Context;

  constructor() {
    super();
    this.optionSelected = this.optionSelected.bind(this);
    this.renderTab = this.renderTab.bind(this);
  }

  componentWillMount() {
    this.urlSelected();
  }

  urlSelected() {
    const { sessionStore } = this.context;
    const url = window.location.pathname;
    if (/select-option-work\/search-client-smart/.test(url)) {
      sessionStore.setTabStyleActive('client-smart');
    } else if (/select-option-work\/list-wallet/.test(url)) {
      sessionStore.setTabStyleActive('tab-wallet');
    } else {
      sessionStore.setTabStyleActive('tab-training');
    }
  }

  activeTab(selected, element) {
    return (selected === element) ? 'item active' : 'item';
  }

  urlConcatUnit(url) {
    return ConcatUnitInUrl(url);
  }


  optionSelected(e) {
    const { sessionStore } = this.context;
    const label = e.currentTarget.id === 'client-smart' ? 'search_member' : 'workouts';

    sessionStore.setTabStyleActive(e.currentTarget.id);
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: `workout:${window.location.pathname}`,
      eventAction: 'click:table',
      eventLabel: label,
    });
  }

  hasPosition() {
    return (this.renderNewWallet() === false);
  }

  renderTab() {
    const {
      sessionStore,
      i18n,
    } = this.context;

    if (this.renderNewWallet()) return false;

    return (
      <TabNavigatorStyle position>
        <ul className="wrapper-menu">
          <li className={this.activeTab(sessionStore.tabSelected, 'tab-training')}>
            <Link
              to={this.urlConcatUnit('/select-option-work/list-exercise')}
              id="tab-training"
              className="tab-link"
              onClick={this.optionSelected}
            >
              {i18n.t('register_training_save')}
            </Link>
          </li>
          <li className={this.activeTab(sessionStore.tabSelected, 'client-smart')}>
            <Link
              to={this.urlConcatUnit('/select-option-work/search-client-smart')}
              id="client-smart"
              className="tab-link"
              onClick={this.optionSelected}
            >
              {i18n.t('search_client_title', { company: '' })}
            </Link>
          </li>
        </ul>
      </TabNavigatorStyle>
    );
  }

  renderNewWallet() {
    const {
      toggleStore,
    } = this.context;

    return toggleStore.allowRenderWalletUnit();
  }

  render() {
    return (
      <>
        {this.renderTab()}
      </>
    );
  }
}

export default TabNavigator;
