import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../Button/Button';

const HeaderCloseStyle = styled.header`
  background: ${(props) => props.theme.colors.lightgray};
  padding: 24px 24px;
  display:flex;
  justify-content: space-between;
  align-items: center;


  .text-header {
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes[2]};
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.black};
  }
`;

function HeaderClose({ onClick, title }) {
  const closedModal = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <HeaderCloseStyle>
      <p className="text-header">{title}</p>
      <Button
        closeNew="true"
        rounded="true"
        className="w-btn-close"
        noBackgroundColor="true"
        setSizes="18px"
        onClick={closedModal}
      />
    </HeaderCloseStyle>
  );
}

HeaderClose.defaultProps = {
  title: '',
  onClick: () => {},
};

HeaderClose.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export { HeaderClose };
export default HeaderClose;
