import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DataInfoTraining from './DataInfoTraining';
import LesionAndRestrictionTraining from './LesionAndRestrictionTraining';


const ProfileTrainingStyle = styled.section`
  color: ${props => props.theme.colors.white};
  display: flex;
`;

const ProfileTraining = (props) => {
  const {
    history,
  } = props;

  return (
    <ProfileTrainingStyle>
      <DataInfoTraining />
      <LesionAndRestrictionTraining history={history} />
    </ProfileTrainingStyle>
  );
};

ProfileTraining.defaultProps = {
  history: {},
};

ProfileTraining.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ProfileTraining;
