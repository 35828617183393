/* eslint comma-dangle: 0 */
import {
  action, decorate,
} from 'mobx';

class FormExercisesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  IsExerciseIsometry(value, isometry) {
    return (isometry) ? value : '';
  }

  validateMin(min, isometry) {
    return (isometry) ? '' : min;
  }

  validateMax(max, min, isometry) {
    if (isometry) {
      return '';
    }

    if (max === '') {
      return min;
    }

    return (parseInt(min, 10) > parseInt(max, 10)) ? min : max;
  }

  postEmptyHeating(cardioEquipment) {
    if (cardioEquipment === 'heating') {
      return null;
    }

    return cardioEquipment;
  }

  makeEditCardio(values) {
    return {
      duration: values.duration,
      observation: values.observation,
    };
  }

  makeAddObjCardio(item, values) {
    return {
      training_group_id: this.rootStore.buildTrainingStore.currentTraining.id,
      series: 1,
      cardio: true,
      cardio_equipment: this.postEmptyHeating(item.cardio_equipment),
      duration: values.duration,
      observation: values.observation,
      duration_measurement_unit: 'minutes',
    };
  }

  makeAddObjectTraining(exercise) {
    if (exercise.item.muscle_group === 'hit' || exercise.item.muscle_group === 'klass') {
      return this.makeAddTrainingHit(exercise);
    }

    return this.makeAddWeightTrainingPattern(exercise);
  }

  makeAddTrainingHit({ item, values }) {
    return {
      weight_exercise_id: item.id,
      training_group_id: this.rootStore.buildTrainingStore.currentTraining.id,
      series: 30,
      min_repetitions: 30,
      max_repetitions: 30,
      min_interval: 30,
      max_interval: 30,
      intensification_method: '',
      observation: values.observation,
    };
  }

  makeObjTrainingPattern(values, checkedIsometry) {
    return {
      series: values.series,
      min_interval: this.validateMin(values.min_interval, checkedIsometry),
      max_interval: this.validateMax(values.max_interval, values.min_interval, checkedIsometry),
      min_repetitions: this.validateMin(values.min_repetitions, checkedIsometry),
      max_repetitions: this.validateMax(
        values.max_repetitions,
        values.min_repetitions,
        checkedIsometry
      ),
      intensification_method: values.intensification_method,
      observation: values.observation,
      duration: this.IsExerciseIsometry(values.duration, checkedIsometry),
      isometry: checkedIsometry,
      rest: this.IsExerciseIsometry(values.rest, checkedIsometry),
      duration_measurement_unit: 'seconds',
      rest_measurement_unit: 'seconds',
    };
  }

  makeEditWeightTrainingPattern(values, checkedIsometry) {
    return this.makeObjTrainingPattern(values, checkedIsometry);
  }

  makeAddWeightTrainingPattern({ item, values, checkedIsometry }) {
    const valuesExercise = this.makeObjTrainingPattern(values, checkedIsometry);

    return {
      weight_exercise_id: item.id,
      training_group_id: this.rootStore.buildTrainingStore.currentTraining.id,
      ...valuesExercise,
    };
  }
}

decorate(FormExercisesStore, {
  makeAddObjectTraining: action,
  makeAddTrainingHit: action,
  makeAddWeightTrainingPattern: action,
  IsExerciseIsometry: action,
  validateMin: action,
  validateMax: action,
});

export default FormExercisesStore;
