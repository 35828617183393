import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import {
  Modal,
  Title,
  Button,
} from 'components';

import {
  Bel as BaseIconBel,
} from 'components/icons';

const ModalSuccessSendStyle = styled.div`
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: ${props => props.theme.colors.white};
  margin: 0px 50px;
  border-radius: 4px;

  .text-send-training {
    font-family: ${props => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 325;
    font-size: ${props => props.theme.fontSizes[1]};
    line-height: 17px;
    margin-bottom: 21px;
  }

  .icon-bel {
    width: 130px;
    height: 140px;
  }

  .wrapper-button {
    display: flex;
    flex-direction: column;

    .button-save {
      margin-bottom: 10px;
    }
  }
`;

const ModalSuccessSend = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    teacherTrainingStore,
    buildTrainingStore,
  } = context;

  const {
    closeModal,
    redirectToSaveTraining,
  } = props;

  const renderButtonSaveTraining = () => {
    const template = buildTrainingStore.templateSuggestionSend;
    if (teacherTrainingStore.teacherInteface === false && template === false) {
      return (
        <Button
          fourth
          variant
          onClick={redirectToSaveTraining}
          className="button-save"
        >
          {i18n.t('save_training')}
        </Button>
      );
    }

    return false;
  };

  const title = () => {
    if (buildTrainingStore.resendEmail) {
      return i18n.t('post_email_success');
    }

    return i18n.t('post_training_success');
  };

  const description = () => {
    if (buildTrainingStore.resendEmail) {
      return '';
    }
    return i18n.t('open_app');
  };

  return (
    <Modal>
      <ModalSuccessSendStyle>
        <BaseIconBel className="icon-bel" />

        <Title
          type={4}
          as="h4"
          color="#1E8654"
          mgBottom="8"
          fontSize="20"
          className="title"
          marginTop="8px"
          subTitleColor="#010114"
        >
          {title()}
          <small className="text-send-training">
            {description()}
          </small>
        </Title>

        <div className="wrapper-button">
          {renderButtonSaveTraining()}

          <Button
            fourth
            secondary
            onClick={closeModal}
          >
            {i18n.t('close')}
          </Button>
        </div>
      </ModalSuccessSendStyle>
    </Modal>
  );
};

ModalSuccessSend.defaultProps = {
  closeModal: () => {},
  redirectToSaveTraining: () => {},
};

ModalSuccessSend.propTypes = {
  closeModal: PropTypes.func,
  redirectToSaveTraining: PropTypes.func,
};

export default ModalSuccessSend;
