import React, { useState, useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

import List from './List';

const DescriptionWorkoutStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .w-tabs {
    margin-bottom: 16px;
  }

  button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    background-color: #111;
    border-width: 0;
    color: ${props => props.theme.colors.white};
    font-family: 'Gotham';
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 16px 15px 13px;
    cursor: pointer;
    transition: color 0.2s ease-out;

    @media screen and (min-width: 544px) {
      min-width: 140px;
    }

    @media screen and (min-width: 768px) {
      min-width: 240px;
      font-size: 16px;
      padding: 16px 15px 13px;
    }

    span {
      display: inline-block;
      z-index: 2;
      pointer-events: none;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${props => props.theme.colors.white};
        border-radius: 4px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }
    }

    &.w-active {
      color: ${props => props.theme.colors.black};

      span {
        &::before {
          opacity: 1;
        }
      }

      .wrapper-premissa {
        &::before {
          opacity: 1;
        }

        .label {
          color: ${props => props.theme.colors.black};
        }
      }

    }
  }
`;

const DescriptionWorkout = (props) => {
  const context = useContext(Context);
  const { i18n } = context;
  const { exercises } = props;
  const [kind, setKind] = useState(0);

  const choose = (index) => {
    setKind(index);
  };

  const buttonsTabs = exercises.map((training, index) => (
    <button
      type="button"
      className={kind === index ? 'w-active' : ''}
      key={training.id}
      id={training.id}
      onClick={() => choose(index)}
    >
      <span>{`${i18n.t('training')} ${training.label}`}</span>
    </button>
  ));

  const listTrainingExercises = exercises.map((training, index) => (
    <List
      key={training.id}
      id={training.id}
      exercises={training.weight_training_exercises}
      show={kind === index}
    />
  ));

  return (
    <DescriptionWorkoutStyle>
      <div className="w-tabs">{buttonsTabs}</div>
      {listTrainingExercises}
    </DescriptionWorkoutStyle>
  );
};

export default DescriptionWorkout;
