import styled from 'styled-components';
import { Text } from 'rebass';

const AliasTeacher = styled(Text)`
  text-transform: capitalize;
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '24'}px;
  overflow: hidden;

  @media(${(props) => props.theme.mediaScreen.minTablet}) and (${(props) => props.theme.mediaScreen.maxTablet}){
    width: 319px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media(${(props) => props.theme.mediaScreen.desktop}) {
    width: auto;
  }
`;

export default AliasTeacher;
export { AliasTeacher };
