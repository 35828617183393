/* eslint-disable camelcase */
import React, { useContext } from 'react';
import Context from 'stores/context';
import {
  ContainerStyle,
  ProfileInfo,
  Title,
} from 'components';
import { ProgramsStyle } from '../ProgramStyle';

import ProgressBar from '../../components/ProgressBar';
import ProgramsIcon from '../ProgramsIcon';
import ShowDate from '../utils/ShowDate';
import ModalProgram from './components/ModalProgram';
import ProgressResultExercise from './components/ProgressResultExercise';

const ProgramDefault = () => {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
  } = context;

  const {
    progress_in_percent,
    total_sessions,
    completed_sessions,
    level,
    slug,
    started_at,
    current_round,
    total_rounds,
    remains_days,
    status,
  } = peopleStore.programs;

  const renderIcon = (typeIcon) => {
    if (typeIcon === slug) {
      return true;
    }
    return false;
  };

  const renderTitle = () => {
    if (slug === null || slug === undefined) {
      return '';
    }

    return i18n.t(`program_${slug}`);
  };

  const hasAdvanced = () => (peopleStore.level === 'Avançado' || peopleStore.level === 'Avanzado');

  const renderProgressBar = () => {
    if (hasAdvanced()) {
      return false;
    }

    return (
      <ProgressBar
        label="evolution_session"
        progressPercent={progress_in_percent}
        completedSessions={completed_sessions}
        totalSessions={total_sessions}
        remainsDays={remains_days}
        hiddenRemainsDays={false}
        status={status}
      />
    );
  };

  const renderModal = () => {
    if (status === 'in_progress' || hasAdvanced()) {
      return false;
    }

    return (
      <ModalProgram className={status}>
        {i18n.t(`program_${status}`)}
      </ModalProgram>
    );
  };

  const renderInfoCicle = () => {
    if (status === 'in_recovery' || status === 'closed') {
      return (
        <span>{i18n.t('recovery')}</span>
      );
    }

    return (
      <span>{i18n.t('step_by_step', { min: current_round, max: total_rounds })}</span>
    );
  };

  return (
    <ProgramsStyle>
      <div className="wrapper-title">
        <ProgramsIcon
          smart={renderIcon('smart')}
          hipertrofia={renderIcon('coach_hypertrophy')}
          iconCondicionamento={renderIcon('coach_physical_fitness')}
          iconEmagrecimento={renderIcon('coach_slimming')}
          height={76}
          width={120}
        />
        <Title
          type={4}
          fontSize={20}
          align="initial"
          className="title"
        >
          {renderTitle()}
        </Title>
      </div>

      <div className="wrapper-info">
        <ContainerStyle baseLine="center" justifyContent="start" className="container">
          <ProfileInfo widthValue="auto" className="mg-right">
            <small>{i18n.t('profile_level')}</small>
            <span>{level}</span>
          </ProfileInfo>
          <ProfileInfo widthValue="auto" className="mg-right">
            <small>{i18n.t('initial_level')}</small>
            <span>{ShowDate(started_at)}</span>
          </ProfileInfo>
          <ProfileInfo widthValue="auto">
            <small>{i18n.t('cycle')}</small>
            {renderInfoCicle()}
          </ProfileInfo>
        </ContainerStyle>

        {renderProgressBar()}

        {renderModal()}

        <ProgressResultExercise />

      </div>
    </ProgramsStyle>
  );
};

export default ProgramDefault;
