import React, { useContext } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Anamnese, Button, Title, SubTitle,
} from 'components';

import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

function OptionsLesionAndRestriction({ history }) {
  const context = useContext(Context);
  const {
    i18n,
    lesionAndRestrictionStore,
  } = context;

  const setOptionSelect = (value) => {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestriction',
      eventAction: 'click:button',
      eventLabel: `lesion_and_restriction_option_${value}`,
    });

    lesionAndRestrictionStore.setListOptionsSelected(value);
  };

  const nextPage = () => {
    const hasSelectes = lesionAndRestrictionStore.listNavigation.length > 0;

    if (hasSelectes) {
      lesionAndRestrictionStore.resetValues();
      lesionAndRestrictionStore.setStartPrescription();
      const page = lesionAndRestrictionStore.renderPage;
      const url = ConcatUnitInUrl(`/lesion-and-restriction/questions/${page}`);
      history.push(url);
    }
  };

  const renderListOption = () => lesionAndRestrictionStore.healthRestrictions.map((option) => (
    <Button
      reponse
      key={option.value}
      variant={lesionAndRestrictionStore.listNavigation.includes(option.value)}
      onClick={() => setOptionSelect(option)}
    >
      {option.label}
    </Button>
  ));

  const disabledButton = () => !(lesionAndRestrictionStore.listNavigation.length > 0);

  return (
    <Anamnese>
      <Title type={1} as="h2" subTitleColor="#fff">
        {i18n.t('anamnesis_observation_restriction')}
        <SubTitle fontWeight="500">{i18n.t('anamnesis_observation_restriction_subtile')}</SubTitle>
      </Title>

      <div className="w-option-lesion">
        {renderListOption()}
      </div>

      <div className="next-frequency">
        <Button
          reponse
          onClick={() => nextPage()}
          disabled={disabledButton()}
        >
          { i18n.t('anamnesis_question_next')}
        </Button>
      </div>
    </Anamnese>
  );
}

OptionsLesionAndRestriction.defaultProps = {
  history: {},
};

OptionsLesionAndRestriction.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(OptionsLesionAndRestriction);
