/* eslint-disable camelcase */
import localforage from 'localforage';
import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  findPeople as findPeopleApi,
  findPeopleLocale as findPeopleLocaleApi,
  people as peopleShowApi,
} from '../services/api';
import { ConcatUnitInUrl } from '../utils';

const clearALlCache = () => {
  localforage.removeItem('program');
  localforage.removeItem('people');
  localforage.removeItem('training-creation');
  localforage.removeItem('build-training');
  localforage.removeItem('add-exercises');
};

const clearTrainingCache = () => {
  localforage.removeItem('training-creation');
  localforage.removeItem('build-training');
  localforage.removeItem('add-exercises');
};

class PeopleStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.data = null;
    this.people = null;
    this.peopleId = null;
    this.peopleName = '';
    this.locationName = null;
    this.level = null;
    this.goal = null;
    this.frequency = null;
    this.frequencyValue = null;
    this.objective = null;
    this.trainingObservation = null;
    this.observationAndRestriction = null;
    this.age = null;
    this.trainingCreatedAt = null;
    this.origin = null;
  }

  reset() {
    this.people = null;
    this.peopleId = null;
    this.peopleName = '';
    this.locationName = '';
    this.level = '';
    this.goal = '';
    this.frequency = null;
    this.frequencyValue = null;
    this.objective = null;
    this.trainingObservation = null;
    this.observationAndRestriction = null;
    this.age = '';
    this.trainingCreatedAt = null;
    this.getProgramStore.anamnesisStarted = false;
    this.origin = null;
  }

  setId() {
    this.peopleId = this.getPeople.id;
  }

  setName() {
    this.peopleName = (
      this.getPeople
        ? this.getPeople.name
        : ''
    );
  }

  setLocationName() {
    this.locationName = (
      (this.getPeople && this.getPeople.location)
        ? this.getPeople.location.name
        : null
    );
  }

  setLevel() {
    const levelName = (
      this.getActiveEnrollment.level
        ? this.getActiveEnrollment.level.name
        : null
    );
    const levelId = (
      this.getActiveEnrollment.level
        ? this.getActiveEnrollment.level.id
        : null
    );
    this.level = levelName;
    this.getProgramStore.setLevelId(levelId);
  }

  setAge() {
    this.age = this.getPeople.age;
  }

  loadFrequency() {
    const activeEnrollment = this.getActiveEnrollment;
    const hasAnamnesis = activeEnrollment.anamnesis || false;
    const hasFrequency = (hasAnamnesis) ? hasAnamnesis.frequency : false;
    const frequency = (hasFrequency) ? activeEnrollment.anamnesis.frequency : null;
    this.setFrequency(frequency);
  }

  setFrequency(frequency) {
    this.frequency = this.getFrequencyText(frequency);
    this.frequencyValue = frequency;
  }

  setTrainingObservation() {
    const { observation } = this.getCurrentWeightTraining;
    this.trainingObservation = observation;
  }

  getLastRestriction() {
    const initialValue = this.people.physical_evaluations[0];
    const listRestriction = this.people.physical_evaluations;
    const lastRestriction = listRestriction.reduce((previousValue, currentValue) => {
      if (previousValue.id < currentValue.id) {
        return currentValue;
      }

      return previousValue;
    }, initialValue);

    return lastRestriction.observation;
  }

  setObservationAndRestriction() {
    const hasPhysicalEvaluations = (this.people && this.people.physical_evaluations);

    if (hasPhysicalEvaluations && this.people.physical_evaluations.length !== 0) {
      const restriction = this.getLastRestriction();
      this.observationAndRestriction = restriction || null;
    }
  }

  setObjective() {
    const { program } = this.getActiveEnrollment;
    this.objective = program ? program.name : null;
  }

  setGoal() {
    const { program } = this.getActiveEnrollment;
    this.goal = program ? program.name : null;

    if (program && program.id) {
      this.getProgramStore.setProgramId(program.id);
    }
  }

  setProgramStore() {
    this.getProgramStore.setPrograms(this.getLocationPrograms);
    this.getProgramStore.setEnrollmentId(this.getActiveEnrollment.id);
  }

  setCurrentWeightTraining(updateIdTraining) {
    if (this.getCurrentWeightTraining.id && updateIdTraining) {
      this.getTrainingCreationStore.setWeightTrainingId(this.getCurrentWeightTraining.id);
    }
  }

  setOrigin() {
    this.origin = this.getPeople.origin || false;
  }

  setData(data) {
    this.data = data;
  }

  setPlanTotalPass() {
    const data = this.data.filter((item) => item.origin === 'totalpass_smartfit')[0];
    this.setPeople(data);
  }

  setPlanSmartFit() {
    let dataPeople = this.data.filter((item) => item.origin === 'smartsystem')[0];
    const index = 0;

    if (dataPeople === undefined) {
      dataPeople = this.data[index];
    }

    this.setPeople(dataPeople);
  }

  setPeople(people, updateIdTraining = true) {
    this.people = people;
    this.setId();
    this.setName();
    this.setLocationName();
    this.setLevel();
    this.setAge();
    this.loadFrequency();
    this.setTrainingObservation();
    this.setObservationAndRestriction();
    this.setGoal();
    this.setObjective();
    this.setProgramStore();
    this.setCurrentWeightTraining(updateIdTraining);
    this.setOrigin();
  }

  setNewEnrollmentId(id) {
    if (this.people.active_enrollment === null) {
      this.people.active_enrollment = {
        id,
      };
    } else {
      this.people.active_enrollment.id = id;
    }
  }

  hasPlanTotalPass() {
    if (this.data.length > 1) {
      const list = this.data.filter((item) => item.origin === 'totalpass_smartfit');
      return !!(list.length >= 1);
    }

    return false;
  }

  planPeople() {
    const hasTotalPass = this.hasPlanTotalPass();

    if (hasTotalPass) {
      this.origin = 'totalpass_smartfit';
    } else {
      this.origin = 'smartsystem';
    }
  }

  doRequestFindPeople(params) {
    clearALlCache();
    this.reset();

    const data = {
      ...params,
    };

    const promiseFindPeople = async (resolve, reject) => {
      try {
        const res = await findPeopleApi(data, this.rootStore.sessionStore.token);
        this.setData(res.data.people);
        this.planPeople();
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseFindPeople(resolve, reject)
    ));
  }

  doRequestFindPeopleLocale(params) {
    clearALlCache();
    this.reset();

    const data = {
      ...params,
    };

    const promiseFindPeople = async (resolve, reject) => {
      try {
        const res = await findPeopleLocaleApi(data, this.rootStore.sessionStore.token);
        this.setData(res.data.people);
        this.planPeople();
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseFindPeople(resolve, reject)
    ));
  }

  doRequestPeopleShow(params) {
    clearTrainingCache();
    const promisePeopleShow = async (resolve, reject) => {
      try {
        const res = await peopleShowApi(params, this.rootStore.sessionStore.token);
        this.setPeople(res.data.person, params.updateIdTraining);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promisePeopleShow(resolve, reject)
    ));
  }

  get hasPeopleId() {
    return this.peopleId;
  }

  get getPeople() {
    return this.people ? this.people : {};
  }

  get getProgramStore() {
    if (this.rootStore && this.rootStore.programStore) {
      return this.rootStore.programStore;
    }

    return {
      setLevelId: () => null,
      setPrograms: () => null,
      setEnrollmentId: () => null,
    };
  }

  get getTrainingCreationStore() {
    if (this.rootStore && this.rootStore.trainingCreationStore) {
      return this.rootStore.trainingCreationStore;
    }

    return {
      setWeightTrainingId: () => null,
    };
  }

  get getActiveEnrollment() {
    if (this.people && this.getPeople.active_enrollment) {
      return this.getPeople.active_enrollment;
    }

    return {};
  }

  get hasTrainingAutomatic() {
    const hasCurrent = this.getCurrentWeightTraining;
    const hasTrainingAutomatic = (hasCurrent) ? hasCurrent.automatically_generated : false;
    return !!(hasTrainingAutomatic);
  }

  get hasAnamnesisRepliedByStudent() {
    const activeEnrollment = this.getActiveEnrollment;
    if (activeEnrollment.anamnesis === null || activeEnrollment.anamnesis === undefined) {
      return false;
    }

    if (activeEnrollment.anamnesis.replied_by_teacher) {
      return false;
    }

    return true;
  }

  get hasAnamnesis() {
    const activeEnrollment = this.getActiveEnrollment;

    if (activeEnrollment.program === null || activeEnrollment.program === undefined) {
      return false;
    }

    return true;
  }

  get getUrlNewTraining() {
    const params = this.rootStore.programStore.navigator;
    const urlLevelaChange = ConcatUnitInUrl('/anamnesis/level-change');
    const urlGoal = ConcatUnitInUrl(`/anamnesis/question/${params}`);

    return (
      this.hasCurrentTraining
        ? urlLevelaChange
        : urlGoal
    );
  }

  get getActiveWeightTrainings() {
    const enrollment = this.getActiveEnrollment;
    const activeWeightTrainings = (enrollment && enrollment.active_weight_trainings);

    if (activeWeightTrainings && enrollment.active_weight_trainings.length !== 0) {
      return this.getActiveEnrollment.active_weight_trainings;
    }

    return false;
  }

  get getCurrentWeightTraining() {
    if (this.getActiveEnrollment && this.getActiveEnrollment.current_weight_training) {
      return this.getActiveEnrollment.current_weight_training;
    }

    return {};
  }

  hasProgramsDefault() {
    const enrollment = this.getActiveEnrollment;
    const hasProgram = enrollment && enrollment.training_program;
    const programDefault = this.rootStore.sessionStore.toggleAccessTrainingPrograms;

    if (hasProgram && programDefault) {
      return enrollment.training_program.slug !== null && enrollment.training_program.slug !== 'smart';
    }

    return false;
  }

  hasProgramSmart() {
    const enrollment = this.getActiveEnrollment;
    const hasProgram = enrollment && enrollment.training_program;
    const teacherProgramSmart = this.rootStore.sessionStore.toggleUnidadeProgramSmart;

    if (hasProgram && teacherProgramSmart) {
      return enrollment.training_program.slug === 'smart';
    }

    return false;
  }

  getFrequencyText(frequency) {
    if (frequency === null) return false;

    if (frequency === 1) {
      return `${frequency}${this.rootStore.i18n.t('frequency_one')}`;
    }

    if (frequency === 2) {
      return `${frequency}${this.rootStore.i18n.t('frequency_variable')}`;
    }

    return `${frequency}${this.rootStore.i18n.t('frequency_limit')}`;
  }

  getFrequencyActive() {
    return this.frequencyValue || this.getCurrentWeightTraining.frequency;
  }

  get getLocation() {
    return (
      this.getPeople.location
        ? this.getPeople.location
        : {}
    );
  }

  get getLocationPrograms() {
    return (
      this.getLocation.programs
        ? this.getLocation.programs
        : []
    );
  }

  get hasCurrentTraining() {
    return this.getActiveEnrollment.id && this.getCurrentWeightTraining.id;
  }

  get showButtonCurrentTraining() {
    return this.hasCurrentTraining;
  }

  get showButtonMountTraining() {
    return this.getActiveEnrollment.id && !this.getCurrentWeightTraining.id;
  }

  get showButtonWithoutTraining() {
    return !this.getActiveEnrollment.id;
  }

  get showButtonNewTraining() {
    return !this.getActiveEnrollment.id && !this.getCurrentWeightTraining.id;
  }

  get programs() {
    return this.getActiveEnrollment.training_program || {};
  }

  get namePlanPeople() {
    if (this.origin === 'totalpass_smartfit') {
      return 'TotalPass';
    }

    return 'Smart Fit';
  }

  get hasStaticsTraining() {
    const WeightTrainingStatistic = this.getCurrentWeightTraining.weight_training_statistic;
    return !!(WeightTrainingStatistic);
  }

  get getStatisticTraining() {
    return this.getCurrentWeightTraining.weight_training_statistic;
  }
}

decorate(PeopleStore, {
  trainingCreatedAt: observable,
  data: observable,
  people: observable,
  peopleId: observable,
  peopleName: observable,
  locationName: observable,
  level: observable,
  goal: observable,
  frequency: observable,
  frequencyValue: observable,
  observationAndRestriction: observable,
  objective: observable,
  age: observable,
  setPeople: action,
  setName: action,
  setLocationName: action,
  doRequestFindPeople: action,
  peopleShow: action,
  setObjective: action,
  setNewEnrollmentId: action,
  setTrainingObservation: action,
  hasProgramSmart: action,
  setPlanSmartFit: action,
  setPlanTotalPass: action,
  hasProgramsDefault: action,
  getFrequencyActive: action,
  doRequestFindPeopleLocale: action,
  hasCurrentTraining: computed,
  getUrlNewTraining: computed,
  showButtonMountTraining: computed,
  showButtonWithoutTraining: computed,
  showButtonCurrentTraining: computed,
  showButtonNewTraining: computed,
  hasTrainingAutomatic: computed,
  hasAnamnesisRepliedByStudent: computed,
  hasAnamnesis: computed,
  namePlanPeople: computed,
  hasStaticsTraining: computed,
  getStatisticTraining: computed,
});

export const PeopleSchema = {
  trainingCreatedAt: true,
  peopleId: true,
  peopleName: true,
  locationName: true,
  level: true,
  goal: true,
  frequency: true,
  frequencyValue: true,
  observation: true,
  objective: true,
  observationAndRestriction: true,
  trainingObservation: true,
  origin: true,
  age: true,
  people: {
    type: 'object',
    schema: {
      name: true,
      location: {
        type: 'object',
        schema: {
          name: true,
        },
      },
      active_enrollment: {
        type: 'object',
        schema: {
          id: true,
          level: {
            type: 'object',
            schema: {
              id: true,
              name: true,
            },
          },
          current_weight_training: {
            type: 'object',
            schema: {
              id: true,
              tags: {
                type: 'list',
              },
              frequency: true,
              observation: true,
              finish_date: true,
            },
          },
        },
      },
    },
  },
};

export default PeopleStore;
