import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  Box, Flex,
} from 'rebass';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import IconButtonFilter from './icons/button-filter.svg';
import IconButtonSearch from './icons/button-search.svg';

const Input = styled.input`
  padding: 10px;
  border: 0;
  flex-grow: 1;
  width: 100%;
`;

const Field = styled(Flex)`
  border: solid 2px ${props => props.theme.colors.lightgray};
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grayLight};
  border-radius: 8px 0px 0px 8px
`;

const WrapperSearch = styled.div`
  @media(${props => props.theme.mediaScreen.cellphone}) {
    margin-bottom: 10px;
  }
`;

const ButtonFilter = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  background-image: url(${IconButtonFilter});
`;

const ButtonSearch = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  background-image: url(${IconButtonSearch});
`;

const Wrapper = styled(Box)`
  height: 110px;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper-button-search  {
    height: 56px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 64px;
    background: #cacace;
    border: 1px solid ${props => props.theme.colors.grayLight};
    border-left: 0px;
    border-radius: 0px 8px 8px 0px;

  }

  wrapper-search {
    display: flex;
  }

  @media(${props => props.theme.mediaScreen.cellphone}) {
    display: flex;

    .wrapper-search {
      width: 100%;
    }

    .button-find {
      width: 100%;
    }
  }
`;

const Search = (props) => {
  const context = useContext(Context);
  const {
    addExercisesStore,
    i18n,
    modalStore,
  } = context;

  const [searchValue, setSearchValue] = useState('');

  const {
    toggleFilter,
  } = props;

  const doRequestListAllExercise = async () => {
    try {
      await addExercisesStore.doRequestGetAllExercisesNew();
    } catch (err) {
      modalStore.showModalError(err);
    }
  };

  const filterSearch = () => {
    const el = document.querySelector('.wrapper-content-all-exercise');
    const labelSearch = searchValue;
    el.scrollTo(0, 0);

    addExercisesStore.filterSearch(labelSearch);
    doRequestListAllExercise();
  };

  const setValue = (text) => {
    setSearchValue(text);

    if (text === '') {
      addExercisesStore.filterSearch('');
      doRequestListAllExercise();
    }
  };

  const hiddenKeyBoard = (element) => {
    element.setAttribute('readonly', 'readonly');
    element.setAttribute('disabled', 'true');

    setTimeout(() => {
      element.blur();
      element.removeAttribute('readonly');
      element.removeAttribute('disabled');
    }, 50);
  };

  const handleKeySearch = (e) => {
    if (e.key === 'Enter') {
      const element = e.currentTarget;
      hiddenKeyBoard(element);
      filterSearch();
    }
  };

  return (
    <WrapperSearch>
      <Wrapper p={[3]} width={['100%']}>
        <Box width={['70%']} className="wrapper-search">
          <Field
            alignItems="center"
            px={[3]}
            py={[2]}
            width={['100%']}
          >
            <Input
              type="text"
              placeholder={i18n.t('find')}
              id="input-search"
              value={searchValue}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              onKeyDown={handleKeySearch}
            />

            <Box width={['30px']}>
              <ButtonFilter onClick={toggleFilter} />
            </Box>
          </Field>
        </Box>
        <Box className="wrapper-button-search">
          <ButtonSearch onClick={filterSearch} />
        </Box>
      </Wrapper>
    </WrapperSearch>
  );
};

Search.defaultProps = {
  toggleFilter: () => {},
};

Search.propTypes = {
  toggleFilter: PropTypes.func,
};

export default Search;
