import React from 'react';
import { Error } from 'components';

function NotFound(props) {
  const {
    history,
  } = props;

  return (
    <div>
      <Error page="notFound" history={history} />
    </div>
  );
}

export default NotFound;
