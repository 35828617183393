import React from 'react';
import { TrainingList } from '../../../components';
import SortableItem from './Item';

const SortableList = ({ items }) => (
  <TrainingList>
    {items.map((item, index) => (
      <SortableItem key={`item-${index}`} index={index} item={item} />
    ))}
  </TrainingList>
);

export default SortableList;
