import React, { useState, useContext } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import { PostGaAnalytics } from 'utils';
import { ModalView } from 'components/modal/modal-view';
import urlImageVideo from 'components/Error/assets/video.svg';

import {
  TabGeneric,
  ErrorMessage,
} from 'components';

const VideoExercise = () => {
  const context = useContext(Context);
  const { videoExerciseStore, modalStore, i18n } = context;
  const [tabSelected, setTabSelected] = useState('video');

  const closedModal = () => {
    modalStore.toggleModal('modalVideoExercise', false);
  };

  const elSelected = (view) => {
    setTabSelected({ tabSelected: view });
  };

  const makeTabLabel = () => [
    {
      id: 0,
      view: 'video',
      name: videoExerciseStore.getNameExercise(),
      handleEvent: () => { elSelected('video'); },
    },
  ];

  const erroInLoadingVideo = () => {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:add_workout',
      eventAction: 'click:video_error',
      eventLabel: `video_${videoExerciseStore.getWeightExerciseId()}`,
    });

    closedModal();
  };

  if (modalStore.modalVideoExercise === false) return '';

  if (videoExerciseStore.getStatusError()) {
    const title = i18n.t('error_loading_video');
    const textButton = i18n.t('close');

    return (
      <ModalView closedModal={() => erroInLoadingVideo()} display="grid">
        <TabGeneric tabList={makeTabLabel()} selected={tabSelected} mgb="0" />
        <ErrorMessage
          icon={urlImageVideo}
          title={title}
          eventFunction={erroInLoadingVideo}
          textButton={textButton}
        />
      </ModalView>
    );
  }

  return (
    <ModalView closedModal={() => closedModal()} display="grid">
      <TabGeneric tabList={makeTabLabel()} selected={tabSelected} />
      <video controls muted loop autoPlay>
        <source src={videoExerciseStore.getVideo()} type="video/mp4" />
      </video>
    </ModalView>
  );
};

export default observer(VideoExercise);
