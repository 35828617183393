import styled from 'styled-components';

const ProfileInfoAnamnese = styled.p`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  color: #fff;
  font-family: 'Gotham';
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px 14px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    line-height: 29px;
    padding-top: 16px;
    margin-top: 32px;
  }

  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    margin-bottom: 24px;
  }
`;

export { ProfileInfoAnamnese };
export default ProfileInfoAnamnese;
