import React, { Component } from 'react';
import Context from 'stores/context';
import { Button as BaseButton } from 'rebass';
import styled from 'styled-components';
import { observer } from 'mobx-react';

import { PostGaAnalytics } from 'utils';

const Button = styled(BaseButton)`
  display: inline-block;
  min-width: 120px;
  border-width: 1px;
  border-style: solid;
  border-color: #dedede;
  border-radius: 4px;
  color: ${props => props.theme.colors.black};
  font-family: 'Gotham';
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  padding: 10px 16px 6px;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom:10px;

  &:not(:last-of-type) {
    margin-right: 16px;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 16px;
  }
`;

class ListFilter extends Component {
  static contextType = Context;

  doRequestListAllExercise = async () => {
    const { addExercisesStore, modalStore } = this.context;

    try {
      await addExercisesStore.doRequestGetAllExercisesNew();
    } catch (err) {
      modalStore.showModalError(err);
    }
  };

  filterSelected(muscleGroup) {
    const { addExercisesStore } = this.context;
    const el = document.querySelector('.wrapper-content-all-exercise');
    el.scrollTo(0, 0);

    if (addExercisesStore.filterSelected === muscleGroup) {
      addExercisesStore.setFilterSelected('');
    } else {
      addExercisesStore.setFilterSelected(muscleGroup);
    }

    this.doRequestListAllExercise();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:add_workout',
      eventAction: 'click:filter',
      eventLabel: `filter_${muscleGroup}`,
    });
  }

  render() {
    const { addExercisesStore, i18n } = this.context;

    return (
      <>
        <Button
          bg={addExercisesStore.filterSelected === 'allExercise' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('allExercise'); }}
        >
          {i18n.t('all')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'lumbar' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('lumbar'); }}
        >
          {i18n.t('low_back')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'abdominal' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('abdominal'); }}
        >
          {i18n.t('abdomen')}
        </Button>
        <Button
          bg={addExercisesStore.filterSelected === 'legs' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('legs'); }}
        >
          {i18n.t('legs')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'biceps' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('biceps'); }}
        >
          {i18n.t('biceps')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'triceps' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('triceps'); }}
        >
          {i18n.t('triceps')}
        </Button>
        <Button
          bg={addExercisesStore.filterSelected === 'shoulder' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('shoulder'); }}
        >
          {i18n.t('shoulder')}
        </Button>
        <Button
          bg={addExercisesStore.filterSelected === 'breastplate' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('breastplate'); }}
        >
          {i18n.t('breastplate')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'dorsal' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('dorsal'); }}
        >
          {i18n.t('dorsal')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'functional' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('functional'); }}
        >
          {i18n.t('functional')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'hit' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('hit'); }}
        >
          {i18n.t('hit')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'cardio' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('cardio'); }}
        >
          {i18n.t('cardio')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'klass' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('klass'); }}
        >
          {i18n.t('klass')}
        </Button>

        <Button
          bg={addExercisesStore.filterSelected === 'stretching' ? 'yellow' : 'white'}
          onClick={() => { this.filterSelected('stretching'); }}
        >
          {i18n.t('stretching')}
        </Button>
      </>
    );
  }
}

export default observer(ListFilter);
