import styled from 'styled-components';

const ForgotPassword = styled.button`
    border: none;
    margin-bottom: 26px;
    font-family: Gotham;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    width: 100%;
    background: none;
  `;

export { ForgotPassword };
export default ForgotPassword;
