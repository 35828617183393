import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import { PostGaAnalytics } from 'utils';
import PropTypes from 'prop-types';
import ModalItemExerciseForm from './ModalItemExerciseForm';


const ModalListExerciseFormStyle = styled.ul`
  list-style-type: none;
  grid-template-columns: 50% 50%;
  display: none;

  &.open {
    display: grid;
  }
`;

const ModalListExerciseForm = (props) => {
  const context = useContext(Context);
  const { addExercisesStore } = context;
  const {
    openCollpase,
    list,
  } = props;


  const removeExercise = (exercise) => {
    addExercisesStore.removeExercisesSelected(exercise);
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:add_workout',
      eventAction: 'click:button',
      eventLabel: 'trash_exercise',
    });
  };

  const renderList = () => {
    const listExercise = list.map(exercise => (
      <ModalItemExerciseForm
        exercise={exercise}
        key={exercise.id}
        onClick={() => removeExercise(exercise)}
      />
    ));

    return listExercise;
  };

  return (
    <ModalListExerciseFormStyle className={`${openCollpase ? 'open' : ''}`}>
      {renderList()}
    </ModalListExerciseFormStyle>
  );
};

ModalListExerciseForm.defaultProps = {
  openCollpase: false,
  list: [],
};

ModalListExerciseForm.propTypes = {
  openCollpase: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
  })),
};

export default observer(ModalListExerciseForm);
