import styled from 'styled-components';

const BoxInfo = styled.div`
  color: #CFCDC9;
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export { BoxInfo };
export default BoxInfo;
