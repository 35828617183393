import React, { useContext } from 'react';
import Context from 'stores/context';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ItemPais from './ItemPais';

function SizeTraining(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
  } = context;

  const {
    hasError,
  } = props;

  const textAmountSerie = () => (
    <Trans
      i18nKey="dropdown_pais_size_training"
      count={rulesPaisStore.dropDownPais.sizeTraining}
      components={[<span className="color-white">*</span>]}
    />
  );

  return (
    <ItemPais
      rule={textAmountSerie()}
      checked={hasError}
      expect={i18n.t('dropdown_pais_size_training_expect', {
        min: rulesPaisStore.rulesSizeTraing.min,
        max: rulesPaisStore.rulesSizeTraing.max,
      })}
    />
  );
}

SizeTraining.defaultProps = {
  hasError: false,
};

SizeTraining.propTypes = {
  hasError: PropTypes.bool,
};

export default SizeTraining;
