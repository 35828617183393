import React, { useContext } from 'react';
import localforage from 'localforage';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, Button, Box } from 'rebass';
import { ConcatUnitInUrl } from 'utils';

import Context from 'stores/context';
import urlImage from './assets/error.svg';

const ErrorStyle = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19;
  text-align: center;
  background-color: #000;

  .wrapper-msg-error {
    padding-bottom: 76px;
  }

  .wrapper-image {
    text-align: center;
  }

  .title {
    color: #fff;
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .subtitle {
    color: #eee;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    margin-bottom: 24px;
  }
`;

function Error(props) {
  const context = useContext(Context);
  const {
    i18n,
    sessionStore,
  } = context;

  const {
    page,
    history,
  } = props;

  const infoError = () => {
    let title;
    let subtitle;
    let textButton;
    let eventFunction;

    const reloadPage = () => {
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    };

    const notFound = () => {
      const url = ConcatUnitInUrl('/');
      history.push(url);
      reloadPage();
    };

    const logout = () => {
      if (sessionStore.validateAutoSeg()) {
        const url = sessionStore.getUrlLogout();
        sessionStore.doRequestDeleteToken();
        localforage.removeItem('session').then(() => {
          window.location.href = url;
        });
      } else {
        sessionStore.signout();
        const url = ConcatUnitInUrl('/');
        history.push(url);
        reloadPage();
      }
    };

    if (page === 'notFound') {
      title = i18n.t('title_page_not_found');
      subtitle = i18n.t('sub_title_page_not_found');
      textButton = i18n.t('back_navigate');
      eventFunction = notFound;
    }

    if (page === 'errorGeneric') {
      title = i18n.t('title_error_generic');
      subtitle = i18n.t('sub_title_error_generic');
      textButton = i18n.t('button_back_initial');
      eventFunction = () => {
        reloadPage();
      };
    }

    if (page === 'logout') {
      title = i18n.t('title_logout');
      subtitle = i18n.t('sub_title_logout');
      textButton = i18n.t('button_back_initial');
      eventFunction = logout;
    }

    return (
      <div className="wrapper-handle-error">
        <h2 className="title">{title}</h2>
        <p className="subtitle">{subtitle}</p>

        <Button
          variant="primarySquare"
          fontSize={[1]}
          fontWeight="unset"
          width={['100%']}
          className="exercise"
          onClick={() => { eventFunction(); }}
        >
          {textButton}
        </Button>
      </div>
    );
  };

  return (
    <ErrorStyle alignItems="center" justifyContent="center">
      <Box className="wrapper-msg-error">
        <figure className="wrapper-image">
          <img src={urlImage} alt="este" />
        </figure>
        {infoError()}
      </Box>
    </ErrorStyle>
  );
}

Error.defaultProps = {
  history: {},
  page: '',
};

Error.propTypes = {
  page: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export { Error };
export default Error;
