import React, { useContext } from 'react';
import {
  Heading, Text, Button as TextButton, Flex,
} from 'rebass';
import PropTypes from 'prop-types';
import Context from '../../stores/context';
import ModalWrapper from './modal-wrapper';

function ModalSuccess(props) {
  const context = useContext(Context);
  const { modalStore, i18n } = context;
  const {
    onClick,
  } = props;

  const closeCallback = (e) => {
    modalStore.toggleModal('modalSuccess', false);
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <ModalWrapper onClick={closeCallback}>
      <Heading color="green" my={[3]}>{modalStore.title}</Heading>
      <Text mb={[3]}>{modalStore.description}</Text>
      <Flex justifyContent="center">
        <TextButton
          color="darkgray"
          variant="text"
          className="closed-modal__success"
          onClick={closeCallback}
        >
          {i18n.t('close')}
        </TextButton>
      </Flex>
    </ModalWrapper>
  );
}

ModalSuccess.defaultProps = {
  onClick: () => {},
};

ModalSuccess.propTypes = {
  onClick: PropTypes.func,
};

export default ModalSuccess;
