import styled, { css } from 'styled-components';
import { Button } from 'rebass';

import NewNoticesSVG from './notices-on.svg';
import ReadNoticesSVG from './notices-off.svg';

const ButtonNotices = styled(Button)`
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.theme.colors.darkgray};
  width: 30px;
  height: 30px;
  padding: 0px;
  margin-left: 10px;
  margin-top: 8px;
  ${(props) => props.hasNotices
    && css`
      background-image: url(${NewNoticesSVG});
    `};

  ${(props) => !props.hasNotices
    && css`
      background-image: url(${ReadNoticesSVG});
    `};
`;

export default ButtonNotices;
export { ButtonNotices };
