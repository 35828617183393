import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { PostGaAnalytics } from 'utils';
import ModalListExerciseForm from './ModalListExerciseForm';

const ModalExerciseFormStyle = styled.div`
  box-sizing: border-box;
  border-radius: 2px
  color: ${props => props.theme.colors.black};
  margin-bottom: 36px;
  background-color: ${props => props.theme.colors.white};
  height: 50px;
  overflow: hidden;

  &.open {
    height: auto;
    overflow: initial;
  }

  .header-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    background-color: #F4F4F5;
    border: 1px solid #CACACE;
    border-radius: 4px;

    .title {
      font-family: ${props => props.theme.fonts.Gotham};
      font-size:  ${props => props.theme.fontSizes[3]};
      font-weight: 325;
      color: ${props => props.theme.colors.black};
      margin-left: 24px;
    }

    .w-collapse-button {
      background-color: transparent;
      transform: rotateZ(0);
      transition: transform 0.2s ease-out;
      margin-right: 0px;

      &.w-collapse-opened {
        transform: rotateZ(180deg);
      }

      .icon {
        fill: ${props => props.theme.colors.black};
      }
    }
  }
`;

const ModalExerciseForm = (props) => {
  const context = useContext(Context);
  const { addExercisesStore, i18n } = context;
  const [openCollpase, setOpenCollpase] = useState(false);
  const {
    isCardio,
  } = props;

  const toggleCollpase = (event) => {
    event.preventDefault();
    setOpenCollpase(!openCollpase);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:workout_details',
      eventAction: 'click:collapse',
      eventLabel: `list_exercise_form_${!openCollpase}`,
    });
  };

  const renderLengthExercise = () => {
    if (isCardio) return addExercisesStore.getExercisesSelectedCardio.length;
    return addExercisesStore.getExercisesWeightTraining.length;
  };

  const renderListExercise = () => {
    if (isCardio) return addExercisesStore.getExercisesSelectedCardio;
    return addExercisesStore.getExercisesWeightTraining;
  };

  return (
    <ModalExerciseFormStyle className="open">
      <div className="header-button">
        <h3 className="title">
          {i18n.t('exercise_selected_form', {
            lenght: renderLengthExercise(),
          })}
        </h3>
        <Button
          className={`w-collapse-button ${openCollpase ? 'w-collapse-opened' : ''}`}
          secondary="true"
          rounded="true"
          minWidth="initial"
          onClick={e => toggleCollpase(e)}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="icon"
              d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
              fill="white"
            />
          </svg>
        </Button>
      </div>
      <ModalListExerciseForm openCollpase={openCollpase} list={renderListExercise()} />
    </ModalExerciseFormStyle>
  );
};

ModalExerciseForm.defaultProps = {
  isCardio: false,
};

ModalExerciseForm.propTypes = {
  isCardio: PropTypes.bool,
};

export default observer(ModalExerciseForm);
