import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import HeaderNotices from './HeaderNotices';

const DescriptionMessage = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  margin-top: 32px;
  margin-bottom: 20px;
  text-align: initial;
  padding: 0px 48px;
`;

const MessageNotices = () => {
  const context = useContext(Context);
  const {
    noticesStore,
  } = context;

  return (
    <div>
      <HeaderNotices title={noticesStore.notice.title} type="message" />
      <DescriptionMessage>{noticesStore.notice.description}</DescriptionMessage>
    </div>
  );
};

export default MessageNotices;
