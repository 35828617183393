import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Text, Button, Flex,
} from 'rebass';
import Context from '../../stores/context';
import ModalWrapper from './modal-wrapper';

function ModalNetwork(props) {
  const context = useContext(Context);
  const {
    modalStore,
    i18n,
    appStore,
  } = context;
  const { tryAgain, doubleConfirmationToClosed } = props;

  const tryAgainCallback = () => {
    modalStore.execModalNetworkCallBack();
  };

  const closeCallback = () => {
    appStore.clearObjTemp(false);
    modalStore.toggleModal('modalNetwork', false);
    modalStore.reset();
  };

  const styleModal = {
    titleColor: 'black',
    widthWrapper: '544px',
    px: '24px',
    py: '24px',
  };

  const [textTitle, setTextTitle] = useState(null);
  const [textSubTitle, setTextSubTitle] = useState(null);
  const [textShow, setTextShow] = useState(null);
  const [colorClosed, setColorClosed] = useState(null);
  const [textClosed, setTextClosed] = useState(null);
  const [textTryAgain, setTextTrayAgain] = useState(null);

  useEffect(() => {
    const showOffline = () => {
      setTextTitle(i18n.t('connection_network'));
      setTextSubTitle(i18n.t('verify_network'));
      setTextClosed(i18n.t('close'));
      setTextTrayAgain(i18n.t('try_again'));
      setColorClosed('darkgray');
      if (doubleConfirmationToClosed) {
        setTextShow('alertCaseClosed');
      }
    };

    const showMessageClosed = () => {
      setTextTitle(i18n.t('exit_form'));
      setTextSubTitle(i18n.t('warning_exit'));
      setTextClosed(i18n.t('button_exit_modal'));
      setTextTrayAgain(i18n.t('try_again_post'));
      setColorClosed('red');
    };

    if (textShow === null) {
      setTextShow('loadingModalNetwork');
      showOffline();
    }

    if (textShow === 'closed') {
      showMessageClosed();
    }
  }, [
    i18n,
    textShow,
    doubleConfirmationToClosed,
  ]);

  const showInfoCaseExit = () => {
    if (textShow === 'loadingModalNetwork' || textShow === 'closed') {
      closeCallback();
    }

    if (textShow === 'alertCaseClosed') {
      setTextShow('closed');
    }
  };

  return (
    <ModalWrapper styleCss={styleModal}>
      <Heading my={[3]}>{textTitle}</Heading>
      <Text mb={[3]}>{textSubTitle}</Text>
      <Flex justifyContent="space-around">
        <Button
          ml={[3]}
          variant="text"
          color={colorClosed}
          onClick={showInfoCaseExit}
        >
          {textClosed}
        </Button>
        <Button
          variant="text"
          color="darkgray"
          onClick={tryAgain || tryAgainCallback}
        >
          {textTryAgain}
        </Button>
      </Flex>
    </ModalWrapper>
  );
}

ModalNetwork.defaultProps = {
  tryAgain: null,
  doubleConfirmationToClosed: false,
};

ModalNetwork.propTypes = {
  tryAgain: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  doubleConfirmationToClosed: PropTypes.bool,
};

export default ModalNetwork;
