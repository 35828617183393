import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RadioButtonStyle = styled.label`
  display: block;
  padding: 24px 24px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  border: 2px solid;
  border-color: #ccc;
  border-radius: 4px;
  background: ${(props) => props.theme.colors.lightgray};
  margin-top: 24px;

  ${({ isActive }) => isActive && `
    border-color: #FFB612;
  `}

  .wrapper-content-radio {
    position: relative;
    background: ${(props) => props.theme.colors.lightgray};

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #FFB612;
  }

  .&:hover input ~ .checkmark {
    background-color: #fff;
  }

  input:checked ~ .checkmark {
    background-color: #fff;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.black};
  }

  .title, .sub-title {
    font-family: ${(props) => props.theme.fonts.Gotham};
    color: ${(props) => props.theme.colors.black};
    padding-left: 48px;
  }

  .title {
    font-size: ${(props) => props.theme.fontSizes[2]};
    font-weight: bold;
    margin-bottom: 8px;
  }

  .sub-title {
    font-size: ${(props) => props.theme.fontSizes[1]};
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class RadioButton extends Component {
  render() {
    const {
      title,
      subTitle,
      name,
      value,
      status,
      isActive,
    } = this.props;

    const active = !!(isActive === value);
    return (
      <RadioButtonStyle isActive={active}>
        <div className="wrapper-content-radio">
          <input type="radio" name={name} value={value} defaultChecked={status} />
          <span className="checkmark" />
          <p className="title">{title}</p>
          <p className="sub-title">{subTitle}</p>
        </div>
      </RadioButtonStyle>
    );
  }
}

RadioButton.defaultProps = {
  title: '',
  subTitle: '',
  name: '',
  value: '',
  status: false,
  isActive: '',
};

RadioButton.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  status: PropTypes.bool,
  isActive: PropTypes.string,
};

export { RadioButton };
