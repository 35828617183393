import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Flex, Box, Button as BaseButton } from 'rebass';
import Context from 'stores/context';
import { Delete as BaseIconDelete } from 'components/icons';
import { RenderNameExercise, PostGaAnalytics } from 'utils';

const Button = styled(BaseButton)`
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  text-transform: capitalize;
`;

const WrapperItem = styled(Box)`
  flex-basis: 100%;
  border-bottom: solid 1px #ccc;

  .warning_exercise {
    color: red;
    text-transform: initial;
    font-size: 10px;
  }

  .name-exercise {
    width: 200px;
  }
`;

const IconDelete = styled(BaseIconDelete)`
  width: 15px;
  height: 16px;
`;

const ListExercisesSelected = () => {
  const context = useContext(Context);
  const { addExercisesStore, i18n } = context;
  const [loadListExercise, setLoadListExercise] = useState(null);

  useEffect(() => {
    const validateList = () => {
      const cloneListExercise = addExercisesStore.alertExerciseSelected();
      addExercisesStore.clearExercisesSelected();
      addExercisesStore.setListExercisesSelected(cloneListExercise);

      setLoadListExercise('done');
    };

    if (loadListExercise === null) {
      validateList();
    }
  });

  const renderTitleExercise = (item) => {
    if (item.hasList) {
      const nameExercise = RenderNameExercise(item);
      return (
        <div>
          <span>{nameExercise}</span>
          <p className="warning_exercise">{i18n.t('training_selected')}</p>
        </div>
      );
    }

    return RenderNameExercise(item);
  };

  return (
    <div>
      {
        addExercisesStore.getExercisesSelected.map(item => (
          <WrapperItem key={item.id} ml={[2]}>
            <Button
              color="darklight"
              variant="text"
              fontSize={[1]}
              fontWeight="unset"
              width={['100%']}
              onClick={() => {
                addExercisesStore.removeExercisesSelected(item);
                PostGaAnalytics('send', {
                  hitType: 'event',
                  eventCategory: 'workout:add_workout',
                  eventAction: 'click:button',
                  eventLabel: 'trash_exercise',
                });
              }}
            >
              <Flex justifyContent="space-between" alignItems="center">
                <span className="name-exercise">{renderTitleExercise(item)}</span>
                <IconDelete ml={[2]} />
              </Flex>
            </Button>
          </WrapperItem>
        ))
      }
    </div>
  );
};

export default observer(ListExercisesSelected);
