import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Text, Box, Flex,
} from 'rebass';
import { Formik } from 'formik';
import {
  Button, Fieldset, FormGroup, Input, Label, ButtonSquare,
} from '../../../../components';
import Context from '../../../../stores/context';

const StyledFormEditExercise = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 544px) {
    max-width: 480px;
  }

  legend {
    color: #000;
    font-family: 'Gotham';
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
    text-align: center;
    margin-bottom: 34px;

    @media screen and (min-width: 544px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .w-training-main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    > div {
      display: inline-flex;
      width: auto;

      &:first-child {
        @media screen and (min-width: 544px) {
          width: 60px;
        }
      }

      @media screen and (min-width: 544px) {
        max-width: 105px;
      }
    }

    label {
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      color: #808080;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
    }
  }

  label {
    font-size: 13px;
    line-height: 16px;
  }
`;

export const FormEditExercise = ({ updateList }) => {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    modalStore,
    i18n,
    appStore,
  } = context;

  const reloadPostData = () => {
    const {
      values,
      setSubmitting,
    } = appStore.objTemp;

    // eslint-disable-next-line no-use-before-define
    updateRepetition(values, setSubmitting);
  };

  const updateRepetition = (values, setSubmitting ) => {
    appStore.clearObjTemp();

    const dataCaseTheErroNetwork = {
      values,
      setSubmitting,
    };

    appStore.setObjTemp(dataCaseTheErroNetwork);

    setTimeout(() => {
      buildTrainingStore.clickEditAllItemsSelected([
        { min_repetitions: values.min_repetitions },
        { max_repetitions: values.max_repetitions },
      ]);

      if (buildTrainingStore.hasDiff) {
        buildTrainingStore.doRequestPatchTrainingExercises(
          buildTrainingStore.getExercisesUpdated,
          'repetition',
        ).then(() => {
          appStore.clearObjTemp();
          buildTrainingStore.diff = [];
          buildTrainingStore.editAllItemsSelected = [];
          updateList();
          modalStore.closeModalError();
          modalStore.toggleModal('modalEditAllExercises', false);
        }).catch((error) => {
          window.scroll(0, 0);
          modalStore.setModalNetworkCallback(reloadPostData);
          modalStore.showModalError(error);
        });
      }

      setSubmitting(false);
    }, 400);
  };

  return (
    <StyledFormEditExercise>
      <Formik
        initialValues={{
          min_repetitions: '',
          max_repetitions: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.min_repetitions) {
            errors.min_repetitions = i18n.t('warning_repetition_min');
          }
          if (!values.max_repetitions) {
            errors.max_repetitions = i18n.t('warning_repetition_max');
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateRepetition(values, setSubmitting);
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset>
                <Flex flexWrap="wrap" alignItems="center" flexDirection="column" mb={[4]}>
                  <Text color="red">
                    {errors.min_repetitions && touched.min_repetitions && errors.min_repetitions}
                  </Text>
                  <Text color="red">
                    {errors.max_repetitions && touched.max_repetitions && errors.max_repetitions}
                  </Text>
                </Flex>

                <div className="w-training-main-info">
                  <FormGroup justifyContent="center" noMarginBottom="true">
                    <Box ml={5}>
                      <Label htmlFor="min_repetitions">{i18n.t('repeats')}</Label>
                      <Flex m={2}>
                        <Input
                          type="number"
                          id="min_repetitions"
                          square="true"
                          small="true"
                          width="48px"
                          textAlign="center"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Min"
                          value={values.min_repetitions}
                        />

                        <Input
                          type="number"
                          id="max_repetitions"
                          square="true"
                          small="true"
                          width="48px"
                          textAlign="center"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Max"
                          value={values.max_repetitions}
                        />
                      </Flex>
                    </Box>
                  </FormGroup>
                </div>

                <FormGroup formActions="true" className="w-form-actions" noMarginBottom="true">
                  <Button
                    tertiary="true"
                    color="#d52b1e"
                    onClick={() => {
                      buildTrainingStore.editAllItemsSelected = [];
                      modalStore.toggleModal('modalEditAllExercises', false);
                    }}
                  >
                    {i18n.t('cancel')}
                  </Button>
                  <ButtonSquare
                    type="submit"
                    variant={Object.keys(errors).length ? 'cancel' : 'primary'}
                  >
                    {isSubmitting && i18n.t('shipping')}
                    {!isSubmitting && i18n.t('saved_update')}
                  </ButtonSquare>
                </FormGroup>
              </Fieldset>
            </form>
          );
        }}
      </Formik>
    </StyledFormEditExercise>
  );
};

export default FormEditExercise;
