import React from 'react';
import PropTypes from 'prop-types';
import {
  FlexDiv,
  Text,
} from 'components';

const ShowErros = (props) => {
  const {
    infoLabel,
  } = props;
  const {
    errors,
    touched,
  } = infoLabel;

  return (
    <FlexDiv minHeight="0" direction="column" marginBottom="32px">
      <Text color="red">
        {errors.series && touched.series && errors.series}
      </Text>
      <Text color="red">
        {errors.repeats && touched.repeats && errors.repeats}
      </Text>
      <Text color="red">
        {errors.min_repetitions && touched.min_repetitions && errors.min_repetitions}
      </Text>
      <Text color="red">
        {errors.max_repetitions && touched.max_repetitions && errors.max_repetitions}
      </Text>
      <Text color="red">
        {errors.interval && touched.interval && errors.interval}
      </Text>
      <Text color="red">
        {errors.min_interval && touched.min_interval && errors.min_interval}
      </Text>
      <Text color="red">
        {errors.max_interval && touched.max_interval && errors.max_interval}
      </Text>
      <Text color="red">
        {errors.duration && touched.duration && errors.duration}
      </Text>
      <Text color="red">
        {errors.rest && touched.rest && errors.rest}
      </Text>
    </FlexDiv>
  );
};

ShowErros.defaultProps = {
  infoLabel: {
    errors: {},
    touched: {},
  },
};

ShowErros.propTypes = {
  infoLabel: PropTypes.shape({
    errors: PropTypes.object,
    touched: PropTypes.object,
  }),
};

export default ShowErros;
