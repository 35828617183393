import React, { useEffect, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';

import {
  ListEmptyBell,
} from 'components';

import ItemWorkout from '../Component/ItemWorkout';

const ListWorkoutTeacherStyle = styled.div`
  color: ${props => props.theme.colors.white};
  margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
  height: 517px;
`;

const ListWorkoutTeacher = (props) => {
  const [reqListExercise, setReqListExercise] = useState(false);
  const [loading, setLoading] = useState(false);
  const context = useContext(Context);
  const {
    teacherTrainingStore,
    appStore,
    modalStore,
    i18n,
    filterWorkoutStore,
  } = context;

  const {
    history,
  } = props;

  const reloadListExercise = () => {
    setReqListExercise(true);
  };

  useEffect(() => {
    const callLoading = () => {
      appStore.toggleloading(false);
      setLoading(false);
    };

    const getListExercise = async () => {
      appStore.toggleloading(true);
      try {
        await teacherTrainingStore.doRequestListExerciseTeacher({
          id: teacherTrainingStore.programId,
          queryString: '&complete=true',
        });
        setReqListExercise(false);
        modalStore.closeModalError();
        window.scroll(0, 0);
        const el = document.querySelector('.wrapper-scroll');
        el.scroll(0, 0);
        appStore.toggleloading(false);
      } catch (error) {
        const msgTitle = i18n.t('error_loading');
        window.scroll(0, 0);
        modalStore.setTitle(msgTitle);
        modalStore.setModalNetworkCallback(reloadListExercise);
        modalStore.showModalError(error);
        appStore.toggleloading(false);
      }
    };

    if (reqListExercise) {
      getListExercise();
    }

    if (loading) {
      callLoading();
    }
  }, [
    modalStore,
    i18n,
    appStore,
    reqListExercise,
    teacherTrainingStore,
    filterWorkoutStore,
    loading]);

  if (teacherTrainingStore.reloadListExercise) {
    teacherTrainingStore.setReloadListExercise(false);
    reloadListExercise();
  }

  const loadingListWorkout = () => {
    appStore.toggleloading(true);
    teacherTrainingStore.plusWorkout();
    setLoading(true);
  };

  const onScroll = (e) => {
    const calc = e.target.scrollHeight - e.target.scrollTop;
    const heightClient = e.target.clientHeight + 1;
    const finish = teacherTrainingStore.finishListSuggestionTeacher;
    const result = (calc <= heightClient && finish === false);

    if (result && loading === false) {
      loadingListWorkout();
    }
  };

  const renderList = () => {
    const listItems = teacherTrainingStore.listWorkoutTeacherView.map(exercise => (
      <ItemWorkout
        key={exercise.id}
        exercise={exercise}
        tabSelected="my_training"
        history={history}
      />
    ));

    return listItems;
  };

  const renderListEmpty = () => {
    const hasListExercise = (teacherTrainingStore.listWorkoutTeacherView.length > 0);
    const hasFilterSelected = (filterWorkoutStore.lengthSelected > 0);

    if (hasListExercise === false && hasFilterSelected) {
      return (
        <ListEmptyBell
          title={i18n.t('filter_empty')}
        />
      );
    }

    if (hasListExercise === false && hasFilterSelected === false) {
      return (
        <ListEmptyBell
          title={i18n.t('not_found_list')}
        />
      );
    }

    return false;
  };


  return (
    <ListWorkoutTeacherStyle onScroll={onScroll} className="wrapper-scroll">
      {renderListEmpty()}
      {renderList()}
    </ListWorkoutTeacherStyle>
  );
};

ListWorkoutTeacher.defaultProps = {
  history: {},
};

ListWorkoutTeacher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(ListWorkoutTeacher);
