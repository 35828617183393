import styled from 'styled-components';

function getWidthString(span) {
  if (!span) return '';
  const width = (span / 12) * 100;
  return `
    flex: 0 0 ${width}%;
    max-width: ${width}%;
  `;
}

const GridContainer = styled.div`
  max-width: ${(props) => (props.fluid ? 'initial' : '1440px')};
  width: 100%;
  padding-left: ${(props) => props.spacing || '24px'};
  padding-right: ${(props) => props.spacing || '24px'};
  margin-left: auto;
  margin-right: auto;

  &.mg-bottom{
    margin-bottom: 30px;
  }
`;

const NewGridContainer = styled.div`
  max-width:  ${(props) => props.fluid || '1440'}px;
  width: 100%;
  padding-left: ${(props) => props.spacing || '24px'};
  padding-right: ${(props) => props.spacing || '24px'};
  margin-left: auto;
  margin-right: auto;
  postion: relative;

  &.mg-bottom{
    margin-bottom: 30px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${(props) => props.spacing || '-24px'};
  margin-right: ${(props) => props.spacing || '-24px'};
`;

const Column = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  box-sizing: border-box;
  padding-left: ${(props) => props.spacing || '24px'};
  padding-right: ${(props) => props.spacing || '24px'};

  ${(props) => props.xs && getWidthString(props.xs)}

  @media screen and (min-width: 768px) {
    ${(props) => props.sm && getWidthString(props.sm)}
  }

  @media screen and (min-width: 992px) {
    ${(props) => props.md && getWidthString(props.md)}
  }

  @media screen and (min-width: 1200px) {
    ${(props) => props.lg && getWidthString(props.lg)}
  }
`;

export {
  NewGridContainer,
  GridContainer,
  Row,
  Column,
};
