import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import {
  Anamnese, Button, Title, SubTitle,
} from 'components';
import ModalNetwork from 'components/modal/modal-network';
import ModalError from 'components/modal/modal-error';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

function LesionAndRestrictionQuestion({ history }) {
  const context = useContext(Context);
  const {
    i18n,
    lesionAndRestrictionStore,
    appStore,
    modalStore,
  } = context;

  const renderOptionsRestriction = () => {
    appStore.toggleloading(true);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestriction',
      eventAction: 'click:button',
      eventLabel: 'lesion_and_restriction_yes',
    });

    lesionAndRestrictionStore
      .doRequestListHealthRestriction()
      .then(() => {
        appStore.toggleloading(false);
        const url = ConcatUnitInUrl('/lesion-and-restriction/options-lesion-restriction');
        history.push(url);
      })
      .catch((error) => {
        appStore.toggleloading(false);
        appStore.toggleloading(false);
        modalStore.showModalError(error);
      });
  };

  const renderListExercise = () => {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestriction',
      eventAction: 'click:button',
      eventLabel: 'lesion_and_restriction_no',
    });

    const url = ConcatUnitInUrl('/build-training/build-training-list');
    history.push(url);
  };

  return (
    <Anamnese>
      <Title type={1} as="h2" subTitleColor="#fff">
        {i18n.t('anamnesis_observation_restriction')}
        <SubTitle fontWeight="500">{i18n.t('anamnesis_observation_restriction_subtile')}</SubTitle>
      </Title>

      <Button
        secondary="true"
        large="true"
        block="true"
        onClick={renderOptionsRestriction}
      >
        {i18n.t('yes')}
      </Button>
      <Button
        secondary="true"
        large="true"
        block="true"
        onClick={renderListExercise}
      >
        {i18n.t('no')}
      </Button>
      {modalStore.modalNetwork && (
      <ModalNetwork
        tryAgain={() => { renderOptionsRestriction(); }}
      />
      )}
      {modalStore.modalError && <ModalError />}
    </Anamnese>
  );
}

LesionAndRestrictionQuestion.defaultProps = {
  history: {},
};

LesionAndRestrictionQuestion.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default LesionAndRestrictionQuestion;
