import styled, { css } from 'styled-components';

const ProfileGlobalStyle = styled.div`
  display: grid;
  padding: 0px 20px;
  grid-template-columns: 250px 230px;
  grid-auto-rows: 80px;
  height: 440px;

  ${(props) => props.noPadding
    && css`
    padding: 0px;
  `};

  .no-column {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export { ProfileGlobalStyle };
export default ProfileGlobalStyle;
