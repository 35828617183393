import styled, { css } from 'styled-components';

const Input = styled.input`
  display: flex;
  width: ${(props) => props.width || '100%'};
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #c0c0c0;
  caret-color: #808080;
  color: #000;
  font-family: 'Gotham';
  font-size: 16px;
  line-height: 1;
  padding-top: 14px;
  padding-right: ${(props) => (props.showPassword ? '108px' : '16px')};
  padding-bottom: 14px;
  padding-left: 16px;
  text-align: ${(props) => props.textAlign || 'left'};
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;

  ${(props) => !props.small
    && css`
      @media screen and (min-width: 768px) {
        padding-top: 24px;
        padding-right: ${(props) => (props.showPassword ? '108px' : '24px')}
        padding-bottom: 23px;
        padding-left: 24px;
      }
    `};

  ${(props) => props.small
    && css`
      padding: 12px 7px 11px;
      @media screen and (min-width: 768px) {
        padding: 12px 7px 11px;
      }
    `};

  ${(props) => props.type === 'number'
    && css`
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `};

  ${(props) => !props.square
    && css`
      border-radius: 4px;
    `};

  ${(props) => props.square
    && css`
      border-radius: 4px;
    `};

  @media screen and (min-width: 768px) {
    font-size: 19px;
    line-height: 23px;
  }

  &:focus {
    box-shadow: inset 0 0 0 3px #ffb612;
    border-color: #ffb612;
  }

  &::-webkit-input-placeholder {
    color: #808080;
    opacity: 1;
  }

  &::-moz-placeholder {
    color: #808080;
  }

  &:-ms-input-placeholder {
    color: #808080;
  }

  &:-moz-placeholder {
    color: #808080;
  }
`;

export { Input };
export default Input;
