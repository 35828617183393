
import PremissasToggle from './toggleRulesPais/toggleRulesPais.json';
import {
  typeListRulesPaisAllow,
} from './IrulesPaisStore';

class RulesPaisToggle {
  static getRulesAllow(): string[] {
    const country: string = process.env.REACT_APP_LANGUAGE || 'pt-BR';
    return PremissasToggle[country as keyof typeListRulesPaisAllow];
  }
}

export default RulesPaisToggle;