import styled, { css } from 'styled-components';

const ContainerBox = styled.div`
  border-radius: 0px 0px 7px 7px;
  border-bottom: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
  border-left: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
  border-right: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
  padding: ${(props) => props.padding || '32px 24px'};

  ${(props) => props.bordeTop
    && css`
      border-left: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
    `};
`;

export { ContainerBox };
export default ContainerBox;
