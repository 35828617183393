import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

import {
  LabelError,
} from 'components';

const DataPeopleWorkoutStyle = styled.div`
  margin-top: 32px;
  color: ${(props) => props.theme.colors.white};

  p {
    margin-bottom: 8px;
  }

  .name {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`;

function DataPeopleWorkout() {
  const context = useContext(Context);
  const {
    peopleStore,
    i18n,
    buildTrainingStore,
  } = context;

  const validateAnamnese = () => {
    try {
      const { level } = peopleStore.getProgramStore.levelId;
      const { frequency } = peopleStore.frequencyValue;

      const trainingLevel = buildTrainingStore
        .suggestionWorkoutBuild.level_id;

      const trainingFrequency = buildTrainingStore
        .suggestionWorkoutBuild.frequency;

      const statusLevel = (level === trainingLevel);
      const statusFrequency = (frequency === trainingFrequency);

      if (statusLevel && statusFrequency) return '';

      const text = i18n.t('training_not_validate');

      return (
        <LabelError text={text} />
      );
    } catch (err) {
      return false;
    }
  };

  const renderdataTraining = () => {
    if (peopleStore.level !== null && peopleStore.frequency) {
      return (
        <>
          <p>
            {i18n.t('profile_current_training')}
            :
            {' '}
            {peopleStore.objective}
          </p>
          <p>{peopleStore.level}</p>
        </>
      );
    }

    return false;
  };

  if (buildTrainingStore.templateSuggestionSend === false) {
    return false;
  }

  return (
    <DataPeopleWorkoutStyle>
      <p className="name">{peopleStore.peopleName}</p>
      {renderdataTraining()}
      <div>
        {validateAnamnese()}
      </div>
    </DataPeopleWorkoutStyle>
  );
}

export default DataPeopleWorkout;
export { DataPeopleWorkout };
