import {
  observable, action, computed, decorate,
} from 'mobx';
import {
  orderBy, differenceWith, isEqual, cloneDeep, remove, find, unionBy,
} from 'lodash';
import {
  getTrainingExercises as getTrainingExercisesApi,
  patchTrainingExercises as patchTrainingExercisesApi,
  getTrainingSuggestions as getTrainingSuggestionsApi,
  coachSync as coachSyncApi,
  postTrainingEmail as postTrainingEmailApi,
} from 'services/api';
import { ValidateHasHit, PostGaAnalytics } from 'utils';

import {
  ListIdExercise,
  IbuildTrainingStore,
  WorkoutSuggestion,
} from './IbuildTrainingStore';

import {
  Training,
  WeightTrainingExercises,
} from '../IgenericTypes';

class BuildTrainingStore implements IbuildTrainingStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    trainingCreationStore: {
      isEmptyDateFinish: false,
      weightTrainingId: 0,
      doRequestFinishDate: () => {},
      doRequestGetTrainings: () => {},
      setWeightTrainingId: (e: number) => {},
    },
    teacherTrainingStore: {
      teacherInteface: false,
    },
    modalStore: {
      setTitle: (e: string) => {},
      setDescription: (e: string) => {},
      toggleModal: (x: string, y: boolean) => {},
      showModalError: (e: any) => {},
      setModalNetworkCallback: (e: Function) => {},
      closeModalError: () => {},
    },
    i18n: {
      t: (e: string): string => '',
    },
    programStore: {
      enrollmentId: 0,
    },
    rulesPaisStore: {
      hasIntensificationMethod: () => false,
      getMethodIntensification: () => {},
    },
  };

  trainings: Training[] = [];

  currentTraining: Training = {
    id: 0,
    label: '',
    min_exercises: 0,
    max_exercises: 0,
    min_series_by_exercise: 0,
    max_series_by_exercise: 0,
    min_series_by_training: 0,
    max_series_by_training: 0,
    min_exercise_repetitions: 0,
    max_exercise_repetitions: 0,
    min_interval: 0,
    max_interval: 0,
    speed: null,
    intensification_methods: [''],
    checked: true,
    errorPremissas: [],
    weight_training_exercises: [],
  }

  currentTrainingExercises: WeightTrainingExercises[] = [];

  sendToClient = false;

  exercisesToEdit: WeightTrainingExercises[] = [];

  groupLabel = '';

  diff: WeightTrainingExercises[] = [];

  editItemSelected = 0;

  editAllItemsSelected: ListIdExercise[] = [];

  chooseForm = '';

  hasDiffWorkout = false;

  hasIncompleteTraining = false;

  listErrorPremissa = [''];

  analyticsTypeTrainingSuggestion = '';

  toggleAllowEditDateTraining = false;

  suggestionWorkoutBuild = {
    alias: '',
    complete: false,
    id: 0,
    level_id: 0,
    name: '',
    frequency: 0,
    description: '',
  };

  editSuggestionSend = false;

  templateSuggestionSend = false;

  resendTraining = false;

  resendEmail = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  reset(): void {
    this.editAllItemsSelected = [];
    this.editItemSelected = 0;
    this.diff = [];
    this.trainings = [];
    this.suggestionWorkoutBuild = {
      alias: '',
      complete: false,
      id: 0,
      level_id: 0,
      name: '',
      frequency: 0,
      description: '',
    };
    this.currentTraining = {
      id: 0,
      label: '',
      min_exercises: 0,
      max_exercises: 0,
      min_series_by_exercise: 0,
      max_series_by_exercise: 0,
      min_series_by_training: 0,
      max_series_by_training: 0,
      min_exercise_repetitions: 0,
      max_exercise_repetitions: 0,
      min_interval: 0,
      max_interval: 0,
      speed: null,
      intensification_methods: [],
      checked: true,
      errorPremissas: [''],
      weight_training_exercises: [],
    };
    this.currentTrainingExercises = [];
    this.editSuggestionSend = false;
    this.templateSuggestionSend = false;
    this.resendEmail = false;
  }

  prepareStateCurrentTraining(training: Training) {
    this.setCurrentTraining(training);
    this.setCurrentTrainingExercises(training.weight_training_exercises);
    this.setGroupLabel(training.label);
  }

  get getTrainingByGroup() {
    return (
      this.trainings.filter(training => training.label === this.groupLabel)[0] || this.trainings[0]
    );
  }

  clearSuggestionWorkoutBuild() {
    this.suggestionWorkoutBuild = {
      alias: '',
      complete: false,
      id: 0,
      level_id: 0,
      name: '',
      frequency: 0,
      description: '',
    };
  }

  setSuggestionWorkoutBuild(workout: WorkoutSuggestion) {
    this.suggestionWorkoutBuild = workout;
  }

  setAnalyticsTypeTrainingSuggestion(tab: string) {
    this.analyticsTypeTrainingSuggestion = tab;
  }

  setToggleAllowEditDateTraining(toggle: boolean) {
    this.toggleAllowEditDateTraining = toggle;
  }

  setCurrentTrainingExercises(training: WeightTrainingExercises[]) {
    this.currentTrainingExercises = training;
  }

  setCurrentTraining(training: Training) {
    this.currentTraining = training;
  }

  setGroupLabel(training: string) {
    this.groupLabel = training;
  }

  setEditSuggestionSend(toggle: boolean) {
    this.editSuggestionSend = toggle;
  }

  setTemplateSuggestionSend(toggle: boolean) {
    this.templateSuggestionSend = toggle;
  }

  setResendTraining(toggle: boolean) {
    this.resendTraining = toggle;
  }

  setResendEmail(toggle: boolean) {
    this.resendEmail = toggle;
  }

  updatePositionExercise(data: any) {
    const listUpdate = this.getCurrentTrainingExercises.map((item) => {
      const exercise = item;
      const exerciseUpdate = data.weight_training_exercises[exercise.id];
      const hasNewPosition = (exerciseUpdate && exerciseUpdate.position);

      if (hasNewPosition) {
        exercise.position = exerciseUpdate.position;
        return exercise;
      }

      return exercise;
    });

    this.setCurrentTrainingExercises(listUpdate);
  }

  setTrainingList(trainings: Training[]) {
    this.trainings = trainings;

    if (this.trainings.length) {
      this.prepareStateCurrentTraining(this.getTrainingByGroup);
    }
  }

  doRequestGetTrainingExercises(weightTrainingId: number) {
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await getTrainingExercisesApi(
          {
            current_weight_training_id: weightTrainingId,
          },
          this.rootStore.sessionStore.token,
        );

        resolve(res.data.training_groups);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  doRequestGetTrainingSuggestions(weightTrainingId: number) {
    const promise = async (resolve: any, reject: any) => {
      try {
        const response = await getTrainingSuggestionsApi(
          {
            current_weight_training_id: weightTrainingId,
          },
          this.rootStore.sessionStore.token,
        );

        resolve(response.data.training_groups);
      } catch (error) {
        reject(error);
      }
    };

    return new Promise((response, reject) => promise(response, reject));
  }

  setExercisesToEdit(exercises: WeightTrainingExercises[]) {
    this.exercisesToEdit = exercises;
    this.diff = differenceWith(this.exercisesToEdit, this.getCurrentTrainingExercises, isEqual);
  }


  doRequestPatchTrainingExercises(data: any, typeLabel = '') {
    let contentTitle;
    let contentDescription;

    const promise = async (resolve: any, reject: any) => {
      try {
        let res = {
          data: {
            training_groups: [],
          },
        };

        if (this.diff.length) {
          res = await patchTrainingExercisesApi(
            {
              current_weight_training_id: this.getTrainingCreationStore.weightTrainingId,
              body: data,
            },
            this.rootStore.sessionStore.token,
          );

          this.diff = [];
          this.updatePositionExercise(data);
        }

        contentTitle = this.rootStore.i18n.t('update_save');
        contentDescription = '';
        this.rootStore.modalStore.setTitle(contentTitle);
        this.rootStore.modalStore.setDescription(contentDescription);
        this.rootStore.modalStore.toggleModal('modalSuccessEdit', true);

        if (typeLabel !== '') {
          PostGaAnalytics('send', {
            hitType: 'event',
            eventCategory: 'workout:current',
            eventAction: 'click:button',
            eventLabel: `edit_${typeLabel}`,
          });
        }

        this.setHasDiffWorkout(true);
        window.scroll(0, 0);
        resolve(res.data.training_groups);
      } catch (err) {
        let msgError;

        if (err instanceof Error) {
          msgError = err.message;
        }

        this.diff = [];
        contentTitle = this.rootStore.i18n.t('edit_error');
        contentDescription = this.rootStore.i18n.t('message_erro_edit');
        this.rootStore.modalStore.setTitle(contentTitle);
        this.rootStore.modalStore.setDescription(msgError || contentDescription);
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  doRequestCoachSync(weightTrainingId: number) {
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await coachSyncApi(
          {
            current_weight_training_id: weightTrainingId,
          },
          this.rootStore.sessionStore.token,
        );
        this.diff = [];
        resolve(res.data.training_groups);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  doRequestTrainingEmail(weightTrainingId: number) {
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await postTrainingEmailApi(
          {
            current_weight_training_id: weightTrainingId,
          },
          this.rootStore.sessionStore.token,
        );
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  clickButtonSendToClient() {
    this.sendToClient = true;
    setTimeout(() => {
      this.sendToClient = false;
    }, 500);
  }

  orderListExerciseSuggestion(exercises: WeightTrainingExercises[]) {
    return orderBy(exercises, ['position'], ['asc']);
  }

  get getTrainingCreationStore() {
    return this.rootStore.trainingCreationStore;
  }

  get isEmptyTraining() {
    return !this.currentTrainingExercises.length;
  }

  get getCurrentTrainingExercises() {
    return orderBy(this.currentTrainingExercises, ['position'], ['asc']);
  }

  get hasDiff() {
    return this.diff.length;
  }

  get getExercisesUpdated() {
    const data: any = {
      weight_training_exercises: {},
    };

    this.diff.map((item: any) => {
      const filtered: any = {};
      const allKeys = Object.keys(item).filter(key => key !== 'id');

      allKeys.map((key: string) => {
        filtered[key] = item[key];
        return undefined;
      });

      data.weight_training_exercises[item.id] = filtered;

      return undefined;
    });

    return data;
  }

  newPositions(items: WeightTrainingExercises[]) {
    const clone = cloneDeep(items);

    const newItems = clone.map((item: any, index: number) => {
      const obj = item;
      obj.position = index + 1;
      return item;
    });

    this.setExercisesToEdit(newItems);

    return newItems;
  }

  setEditItemSelected(itemId: number) {
    this.editAllItemsSelected = [];
    this.editItemSelected = itemId;
  }

  get getEditItemSelected() {
    return this.getCurrentTrainingExercises.filter(item => item.id === this.editItemSelected)[0];
  }

  toggleEditAllItemsSelected(itemId: number, checked: boolean) {
    if (checked) {
      this.editAllItemsSelected.push({ id: itemId });
    } else {
      remove(this.editAllItemsSelected, item => item.id === itemId);
    }
  }

  updateFields = (itemFound: any, fields: any) => {
    const item = itemFound;

    fields.map((field: any) => {
      const key: string = Object.keys(field)[0];
      item[key] = field[key];
      return true;
    });

    return item;
  };

  getAllExerciseExceptionHit(items: any) {
    return items.filter((item: any) => (!ValidateHasHit(item)));
  }

  getFilterExerciseSelected(fields: any, typeTime: string) {
    const items = cloneDeep(this.getCurrentTrainingExercises);

    if (typeTime === 'minutes') {
      return items.filter(item => item.cardio && item.cardio === true);
    }

    if (typeTime === 'seconds') {
      return items.filter(item => item.isometry && item.isometry === true);
    }

    if (fields[0].observation === undefined) {
      return this.getAllExerciseExceptionHit(items);
    }

    return items;
  }

  removeItemsEditAllItemsSelected(items: any) {
    const listId = this.editAllItemsSelected.filter(item => find(items, { id: item.id }));
    this.editAllItemsSelected = listId;
  }

  clickEditAllItemsSelected(fields: any, typeTime: string) {
    let itemsDiff;
    const items = this.getFilterExerciseSelected(fields, typeTime);
    this.removeItemsEditAllItemsSelected(items);

    const itemsFound = this.editAllItemsSelected.map((item) => {
      const itemFound = find(items, { id: item.id });
      return this.updateFields(itemFound, fields);
    });

    try {
      itemsDiff = this.editAllItemsSelected.map((item) => {
        const itemFound = find(this.diff, { id: item.id });
        return this.updateFields(itemFound, fields);
      });
    } catch (err) {
      itemsDiff = [];
    }

    const unionItemsDiff = unionBy(itemsDiff, itemsFound, 'id');
    const unionDiff = unionBy(unionItemsDiff, this.diff, 'id');

    this.setExercisesToEdit(unionDiff);
  }

  get hasItemsSelected() {
    return this.editAllItemsSelected.length;
  }

  setChooseForm(value: string) {
    this.chooseForm = value;
  }

  setHasDiffWorkout(hasDiffWorkout: boolean) {
    this.hasDiffWorkout = hasDiffWorkout;
  }

  get hasMinExercisePerTraining() {
    const temp = [];
    this.trainings.map((items) => {
      if (!items.weight_training_exercises) {
        return false;
      }
      if (items.weight_training_exercises.length) {
        temp.push(items.label);
      }
      return true;
    });
    return temp.length === this.trainings.length;
  }

  verifyIncompleteTraining() {
    const call = async () => {
      try {
        const result: any = await this.rootStore.trainingCreationStore.doRequestGetTrainings();
        if (result && result.data && result.data.weight_trainings.length) {
          const data = result.data.weight_trainings;
          const trainings = data.filter((item: any) => item.complete);
          const hasCompleteTraining = trainings.length;

          if (!hasCompleteTraining) {
            this.hasIncompleteTraining = true;
            this.rootStore.trainingCreationStore.setWeightTrainingId(data[0].id);
          } else {
            this.hasIncompleteTraining = false;
          }
        }
      } catch (error) {
        this.rootStore.modalStore.setModalNetworkCallback(call);
        this.rootStore.modalStore.showModalError(error);
      }
    };

    if (this.rootStore.programStore.enrollmentId) {
      call();
    }
  }

  showErrorModalActivities() {
    let msgTitle;
    let msgDescription;

    this.clickButtonSendToClient();

    this.rootStore.modalStore.closeModalError();
    if (!this.hasDiffWorkout) {
      msgTitle = '';
      msgDescription = this.rootStore.i18n.t('no_alteration_detected');
      this.rootStore.modalStore.setTitle(msgTitle);
      this.rootStore.modalStore.setDescription(msgDescription);
      this.rootStore.modalStore.toggleModal('modalError', true);
      return true;
    }

    if (!this.hasMinExercisePerTraining) {
      msgTitle = '';
      msgDescription = this.rootStore.i18n.t('verify_training_exercise');
      this.rootStore.modalStore.setTitle(msgTitle);
      this.rootStore.modalStore.setDescription(msgDescription);
      this.rootStore.modalStore.toggleModal('modalError', true);
      return true;
    }

    return false;
  }

  hasErrorInSendPremissa() {
    const list: boolean[] = this.trainings.map(training => training.checked);

    if (list.length > 0) {
      return list.includes(false);
    }

    return false;
  }

  validateHasError(listPremissa: string[]) {
    listPremissa.forEach((premissa: string) => {
      if (this.listErrorPremissa.includes(premissa) === false) {
        this.listErrorPremissa.push(premissa);
      }
    });
  }

  getListErrorPremissas() {
    this.listErrorPremissa = [];

    this.trainings.forEach((training) => {
      if (training.errorPremissas && training.errorPremissas.length > 0) {
        this.validateHasError(training.errorPremissas);
      }
    });

    return this.listErrorPremissa;
  }

  get intensificationMethods() {
    const hasPais = this.rootStore.rulesPaisStore.hasIntensificationMethod();

    if (hasPais) {
      return this.rootStore.rulesPaisStore.getMethodIntensification();
    }

    return this.currentTraining.intensification_methods || [];
  }
}

decorate(BuildTrainingStore, {
  hasIncompleteTraining: observable,
  groupLabel: observable,
  hasDiffWorkout: observable,
  editAllItemsSelected: observable,
  chooseForm: observable,
  editItemSelected: observable,
  diff: observable,
  exercisesToEdit: observable,
  sendToClient: observable,
  currentTraining: observable,
  currentTrainingExercises: observable,
  trainings: observable,
  analyticsTypeTrainingSuggestion: observable,
  toggleAllowEditDateTraining: observable,
  suggestionWorkoutBuild: observable,
  editSuggestionSend: observable,
  templateSuggestionSend: observable,
  resendTraining: observable,
  resendEmail: observable,
  clickButtonSendToClient: action,
  setTrainingList: action,
  setExercisesToEdit: action,
  newPositions: action,
  setEditItemSelected: action,
  toggleEditAllItemsSelected: action,
  clickEditAllItemsSelected: action,
  setChooseForm: action,
  setHasDiffWorkout: action,
  verifyIncompleteTraining: action,
  showErrorModalActivities: action,
  doRequestTrainingEmail: action,
  orderListExerciseSuggestion: action,
  updatePositionExercise: action,
  setCurrentTraining: action,
  setGroupLabel: action,
  prepareStateCurrentTraining: action,
  hasErrorInSendPremissa: action,
  getListErrorPremissas: action,
  setAnalyticsTypeTrainingSuggestion: action,
  setToggleAllowEditDateTraining: action,
  setSuggestionWorkoutBuild: action,
  clearSuggestionWorkoutBuild: action,
  setEditSuggestionSend: action,
  setTemplateSuggestionSend: action,
  setResendTraining: action,
  setResendEmail: action,
  getTrainingCreationStore: computed,
  isEmptyTraining: computed,
  getCurrentTrainingExercises: computed,
  getExercisesUpdated: computed,
  hasDiff: computed,
  getEditItemSelected: computed,
  hasMinExercisePerTraining: computed,
  intensificationMethods: computed,
});

export const BuildTrainingSchema = {
  groupLabel: true,
  hasDiffWorkout: true,
  editItemSelected: true,
  suggestionWorkoutBuild: {
    type: 'object',
    schema: {
      alias: true,
      complete: true,
      id: true,
      level_id: true,
      name: true,
    },
  },
  currentTraining: {
    type: 'object',
    schema: {
      id: true,
      label: true,
      intensification_methods: {
        type: 'list',
      },
    },
  },
  currentTrainingExercises: {
    type: 'list',
    schema: {
      id: true,
      title: true,
      series: true,
      min_interval: true,
      max_interval: true,
      min_repetitions: true,
      max_repetitions: true,
      muscle_group: true,
      cardio: true,
    },
  },
  trainings: {
    type: 'list',
    schema: {
      id: true,
      label: true,
    },
  },
  toggleAllowEditDateTraining: true,
  editSuggestionSend: true,
  templateSuggestionSend: true,
  resendTraining: true,
  resendEmail: true,
};

export default BuildTrainingStore;
