/* eslint no-restricted-syntax: "off", guard-for-in: "off" */

import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  getProgramLevels as getProgramLevelsApi,
  levelChanges as levelChangesApi,
  levelTraining as levelTrainingApi,
  getQuestionAnamnesis as getQuestionAnamnesisApi,
  postQuestionAnamnesis as postQuestionAnamnesisApi,
} from '../services/api';
import { ConcatUnitInUrl } from '../utils';

class ProgramStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.programId = null;
    this.programs = [];
    this.enrollmentId = null;
    this.levelId = null;
    this.anamnesisStarted = false;
    this.idProgramConditioning = 16;
    this.anamnese = null;
    this.questionCurrent = null;
    this.answerList = null;
    this.listResponse = [];
    this.navigator = null;
    this.finishQuestion = false;
    this.title = false;
    this.subTitle = false;
    this.levelName = '';
  }

  resetNewAnamnese() {
    this.anamnese = null;
    this.questionCurrent = null;
    this.navigator = null;
    this.listResponse = [];
    this.finishQuestion = false;
    this.title = false;
    this.subTitle = false;
    this.levelName = '';
  }

  setAnamnese(data) {
    this.resetNewAnamnese();
    this.anamnese = data;
    this.resetQuestionCurrent();
    this.setHeaderQuestion();
    this.setNavigator();
  }

  updateQuestionCurrent(list) {
    this.questionCurrent = list;
  }

  resetQuestionCurrent() {
    this.questionCurrent = this.anamnese;
  }

  getQuestionCurrent() {
    const question = this.questionCurrent;

    return (question.child) ? question.child : question;
  }

  getAnswerList() {
    const question = this.questionCurrent;
    const listChild = question.child;

    return (listChild) ? listChild.questionary_items : question.questionary_items;
  }

  findNewQuestion(objResponse) {
    const listQuestion = this.getAnswerList();
    const index = 0;
    const propriety = Object.keys(objResponse)[index];
    const result = listQuestion.filter((item) => (item.value === objResponse[propriety]));

    this.updateQuestionCurrent(result[index]);
  }

  reloadListQuestion() {
    this.resetQuestionCurrent();
    this.listResponse.forEach((response) => {
      this.findNewQuestion(response);
    });

    this.redirectPage();
  }

  setNavigator() {
    const question = this.getQuestionCurrent();
    this.navigator = question.question_slug;
  }

  setHeaderQuestion() {
    const question = this.getQuestionCurrent();
    this.title = question.title;
    this.subTitle = question.subtitle;
  }

  redirectPage() {
    if (this.questionCurrent.child === null) {
      this.anamnesisStarted = false;
      this.finishQuestion = true;
    } else {
      this.setHeaderQuestion();
      this.setNavigator();
    }
  }

  deleteLastResponse() {
    this.listResponse.splice(-1, 1);
    if (this.listResponse.length < 1) {
      this.anamnesisStarted = false;
    }
  }

  setResponse(value) {
    const question = this.getQuestionCurrent();
    const data = {
      [question.question_slug]: value,
    };

    this.listResponse.push(data);
  }

  doRequestGetQuestionAnamnesis(params) {
    const promiseGetQuestion = async (resolve, reject) => {
      try {
        const res = await getQuestionAnamnesisApi(params, this.rootStore.sessionStore.token);
        this.setAnamnese(res.data.anamnesis);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseGetQuestion(resolve, reject)
    ));
  }

  makeObjAnamnesis(peopleId) {
    const objAnamnesis = {
      person_id: peopleId.peopleId,
      anamnesis: {},
    };

    this.listResponse.forEach((obj) => {
      const cloneObj = obj;

      for (const key in cloneObj) {
        objAnamnesis.anamnesis[key] = cloneObj[key];
      }
    });

    return objAnamnesis;
  }

  doRequestPostQuestionAnamnesis(peopleId) {
    const data = {
      body: this.makeObjAnamnesis(peopleId),
    };

    const promisePostQuestionAnamnesis = async (resolve, reject) => {
      try {
        const res = await postQuestionAnamnesisApi(data, this.rootStore.sessionStore.token);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promisePostQuestionAnamnesis(resolve, reject)
    ));
  }

  duplicateProgramConditioning(programs) {
    const programsList = programs;
    const conditioning = programs.filter((program) => program.id === this.idProgramConditioning);

    programsList.push({
      ...conditioning[0],
      name: 'Coach Condicionamento Físico 2',
    });

    return programsList;
  }

  setPrograms(programs) {
    const programsList = this.duplicateProgramConditioning(programs);
    this.programs = programsList;
  }

  setLevelId(levelId) {
    this.levelId = levelId;
  }

  setLevelName(levelName) {
    this.levelName = levelName;
  }

  setEnrollmentId(enrollmentId) {
    this.enrollmentId = enrollmentId;
  }

  setProgramId(programId) {
    this.programId = programId;
  }

  get headerQuestion() {
    return {
      title: this.title,
      subTitle: this.subTitle,
    };
  }

  get questionRoute() {
    const url = ConcatUnitInUrl(`/anamnesis/question/${this.navigator}`);
    return url;
  }

  getOptionLevel(item) {
    const { name } = item;
    const nameTratament = name.toLocaleLowerCase();

    if (nameTratament === 'iniciante' || nameTratament === 'principiante') return item;
    if (nameTratament === 'intermediário' || nameTratament === 'intermedio') return item;
    if (nameTratament === 'avançado' || nameTratament === 'avanzado') return item;

    return false;
  }

  listOfAllowedLevels(data) {
    if (data.length > 0) {
      return data.filter((level) => this.getOptionLevel(level));
    }

    return data;
  }

  doRequestGetProgramLevels = async () => {
    let result;
    try {
      result = await getProgramLevelsApi({
        programId: this.programId,
      }, this.rootStore.sessionStore.token);

      result.data.levels = this.listOfAllowedLevels(result.data.levels);
    } catch (err) {
      result = err;
    }

    return result;
  }

  // metodo antigo
  doRequestLevelChange = async (id) => {
    let result;

    try {
      result = await levelChangesApi({
        enrollmentId: this.enrollmentId,
        body: {
          level_change: {
            level_id: id,
          },
        },
      }, this.rootStore.sessionStore.token);
    } catch (err) {
      result = err;
    }
    return result;
  }

  doRequestLevelTraining = async (id) => {
    let result;

    try {
      result = await levelTrainingApi({
        levelId: id,
      }, this.rootStore.sessionStore.token);
    } catch (err) {
      result = err;
    }
    return result;
  }
}

decorate(ProgramStore, {
  anamnesisStarted: observable,
  enrollmentId: observable,
  levelId: observable,
  responses: observable,
  programId: observable,
  programs: observable,
  anamnese: observable,
  questionCurrent: observable,
  listResponse: observable,
  navigator: observable,
  finishQuestion: observable,
  title: observable,
  subTitle: observable,
  levelName: observable,
  setPrograms: action,
  setResponse: action,
  setProgramId: action,
  setEnrollmentId: action,
  setLevelId: action,
  questionRoute: computed,
  headerQuestion: computed,
  doRequestGetProgramLevels: action,
  doRequestLevelChange: action,
  doRequestLevelTraining: action,
  setAnamnese: action,
  getAnswerList: action,
  doRequestGetQuestionAnamnesis: action,
  doRequestPostQuestionAnamnesis: action,
  makeObjAnamnesis: action,
  setLevelName: action,
});

export const ProgramSchema = {
  anamnesisStarted: true,
  enrollmentId: true,
  levelId: true,
  programId: true,
  navigator: true,
  title: true,
  subTitle: true,
  levelName: true,
  anamnese: {
    type: 'object',
  },
  questionCurrent: {
    type: 'object',
  },
  listResponse: {
    type: 'list',
  },
  programs: {
    type: 'list',
    schema: {
      id: true,
      name: true,
    },
  },
};

export default ProgramStore;
