import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import {
  Anamnese,
  Button,
  Title,
  SubTitle,
  Recommend,
} from 'components';

import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import ModalNetwork from 'components/modal/modal-network';

const LevelChange = ({ history }) => {
  const context = useContext(Context);
  const {
    i18n,
    programStore,
    peopleStore,
    toggleStore,
    trainingCreationStore,
    modalStore,
  } = context;
  const [levels, setLevels] = useState(null);
  const [levelId, setLevelId] = useState(null);

  useEffect(() => {
    const call = async () => {
      const results = await programStore.doRequestGetProgramLevels();
      if (results.data.levels) {
        setLevels(results.data.levels);
      }
    };

    if (levels === null) {
      call();

      PostGaAnalytics('send', {
        hitType: 'pageview',
        page: 'level',
      });
    }
  }, [levels, programStore]);

   const hasAllowEmphasis = () => {
      return toggleStore.allowRenderCountry('toggleEmphasisTraining');
   }

   const hasAllowToDuration = () => {
    return toggleStore.validateToggle({
      toggleUnitAllow: 'allowShowTrainingSizeUnit',
      toggleCountry: 'allowShowTrainingSizeCountry',
    });
   };

  const reloadActionLevelChange = () => {
    const id = levelId;
    modalStore.closeModalError();
    actionLevelChange(id);
  };

  const actionLevelChange = async (id, levelName) => {
    const allowEmphasisCountry = hasAllowEmphasis();
    const urlNavigation = allowEmphasisCountry
      ? '/training-creation/emphasis' : '/training-creation/frenquency';

    const url = ConcatUnitInUrl(urlNavigation);
    let result;

    setLevelId(id);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:level',
      eventAction: 'click:button',
      eventLabel: `level_${id}`,
    });

    try {
      result = await programStore.doRequestLevelTraining(id);
      programStore.setLevelId(id);
      programStore.setLevelName(levelName);

      if(hasAllowToDuration()) {
        trainingCreationStore.resetDuration();
      }

      if(hasAllowEmphasis()) {
        trainingCreationStore.resetEmphasis();
        await trainingCreationStore.doRequestGetListEmphasis();
      }

      history.push(url);
    } catch (err) {
      result = false;
      modalStore.showModalError(err);
    }

    return result;
  };

  if (!levels) {
    return false;
  }

  if (levels && !levels.length) {
    return false;
  }

  const renderRecommend = (name) => {
    const nameLevel = i18n.t(`${name}`);

    if (peopleStore.level && peopleStore.level === nameLevel) {
      return (
        <Recommend>{i18n.t('recommended')}</Recommend>
      );
    }

    return false;
  };

  const modalNetwork = modalStore.modalNetwork && (
    <ModalNetwork
      tryAgain={() => {
        reloadActionLevelChange();
      }}
    />
  );

  return (
    <Anamnese>
      <Title type={1} as="h2" subTitleColor="#fff">
        {i18n.t('anamnesis_level_choose_title')}
        <SubTitle fontWeight="500">{i18n.t('anamnesis_level_choose_description')}</SubTitle>
      </Title>

      {levels.map(item => (
        <Button
          key={item.id}
          onClick={() => actionLevelChange(item.id, item.name)}
          secondary="true"
          large="true"
          block="true"
          className="button-position"
        >
          {renderRecommend(item.name)}
          {i18n.t(`${item.name}`)}
        </Button>
      ))}

      <div>
        {modalNetwork}
      </div>
    </Anamnese>
  );
};

export default observer(LevelChange);
