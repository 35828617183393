import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import PropTypes from 'prop-types';

import {
  Modal,
  Title,
  Button,
} from 'components';

const ModalSelectedUnitStyle = styled.div`
  color: ${props => props.theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: ${props => props.theme.colors.white};
  margin: 0px 50px;
  border-radius: 4px;
  width: 500px;

  @media(${props => props.theme.mediaScreen.cellphone}) {
    width: 100%;
  }

  .text-send-training {
    font-family: ${props => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 325;
    font-size: ${props => props.theme.fontSizes[1]};
    line-height: 17px;
  }

  .icon-bel {
    width: 130px;
    height: 140px;
  }

  .wrapper-button {
    display: flex;
    flex-direction: column;

    .button-send-confirm {
      margin-bottom: 10px;
    }
  }
`;

const LogoutLink = styled.div`
  ffont-family: Gotham;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 21px;
  text-align: center;

  .logout-button {
    border: none;
    background: none;
    color: ${props => props.theme.colors.yellow};
    text-decoration: underline;
  }
`;

const ModalSelectedUnit = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    modalStore,
    sessionStore,
    appStore,
    locationStore,
  } = context;

  const {
    history,
  } = props;

  const confirmUnit = async () => {
    appStore.toggleloading(true);
    const locationId = sessionStore.getIdLocation;

    try {
      await sessionStore.doRequestLocationConfirmation(locationId);
      modalStore.toggleModal('modalSelectedUnit', false);
      modalStore.toggleModal('modalSuccesUnit', true);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:changeUnit',
        eventAction: 'click:confirm',
        eventLabel: 'click_confirm',
      });

      appStore.toggleloading(false);
    } catch (err) {
      const msgTitle = i18n.t('error_change_unit');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:changeUnit',
        eventAction: 'click:confirm',
        eventLabel: 'error_change_unit',
      });

      appStore.toggleloading(false);
    }
  };

  const changeUnit = async () => {
    appStore.toggleloading(true);

    try {
      locationStore.reset();
      await locationStore.doRequestListLocation(false);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:changeUnit',
        eventAction: 'click:change',
        eventLabel: 'click_change',
      });

      appStore.toggleloading(false);
      const url = ConcatUnitInUrl('/change-unit');
      history.push(url);
    } catch (err) {
      const msgTitle = i18n.t('error_location');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:changeUnit',
        eventAction: 'click:change',
        eventLabel: 'error_change_unit',
      });

      appStore.toggleloading(false);
    }
  };

  const logoutTeacher = async() => {
    if(sessionStore.validateAutoSeg()) {
      await sessionStore.doRequestDeleteToken();
    }

    sessionStore.signout();
    const url = ConcatUnitInUrl('/');
    history.push(url);
    history.push();

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  const renderButtonConfirmation = () => {
    if (sessionStore.acronym !== '' && sessionStore.acronym !== null && sessionStore.acronym !== undefined) {
      return (
        <Button
          fourth
          secondary
          className="button-send-confirm"
          onClick={confirmUnit}
        >
          {i18n.t('confirm')}
        </Button>
      );
    }

    return false;
  };

  const renderLabelInfo = () => {
    if (sessionStore.acronym !== '' && sessionStore.acronym !== null && sessionStore.acronym !== undefined) {
      return i18n.t('data_unit', {
        unidade: sessionStore.session.location.name,
        acronym: sessionStore.acronym,
      });
    }

    return i18n.t('what_unit');
  };

  return (
    <Modal>
      <ModalSelectedUnitStyle>
        <Title
          type={4}
          as="h4"
          color="#010114"
          mgBottom="8"
          fontSize="20"
          className="title"
          marginTop="8px"
          subTitleColor="#010114"
        >
          {i18n.t('hello_teacher', { name: sessionStore.session.name })}
          <br />

          {renderLabelInfo()}

          <small className="text-send-training">
            {i18n.t('warning_unity')}
          </small>
        </Title>

        <LogoutLink>
          {i18n.t('invalide_teacher', { name: sessionStore.session.name })}
          <button
            type="button"
            onClick={logoutTeacher}
            className="logout-button"
          >
            {i18n.t('click_here')}
          </button>
        </LogoutLink>

        <div className="wrapper-button">
          {renderButtonConfirmation()}

          <Button
            fourth
            secondary
            onClick={changeUnit}
          >
            {i18n.t('change')}
          </Button>
        </div>
      </ModalSelectedUnitStyle>
    </Modal>
  );
};

ModalSelectedUnit.defaultProps = {
  history: {},
};

ModalSelectedUnit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default ModalSelectedUnit;
