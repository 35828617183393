import {
  observable, action, decorate,
} from 'mobx';

import {
  listExerciseTechnicalDepartmentStor as apiListExerciseTechnicalDepartmentStor,
} from '../../services/api';

import {
  ItechnicalTrainingtStore,
  IweightTrainings,
} from './ItechnicalTrainingtStore';

import {
  SuggetionTraining,
} from '../IgenericTypes';

class TechnicalTrainingtStore implements ItechnicalTrainingtStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    filterWorkoutStore: {
      getListExercise: (e: any) => e,
    },
    i18n: {
      t: (e: any) => e,
    },
  };

  reloadListExercise = false;

  listSuggestion: SuggetionTraining[] = [];

  listSuggestionView: SuggetionTraining[] = [];

  finishListSuggestionTechnical = false;

  limitRender = 5;

  limitLoop = 1;

  currentWorkout = 0

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  resetListWorkout(): void {
    this.listSuggestion = [];
    this.listSuggestionView = [];
    this.currentWorkout = 0;
    this.limitLoop = 1;
  }

  resetView(): void {
    this.listSuggestionView = [];
    this.currentWorkout = 0;
    this.limitLoop = 1;
  }

  setListSuggestion(list :IweightTrainings): void {
    this.listSuggestion = list.weight_trainings;
  }

  doRequestListExerciseSuggestion(id: any): any {
    const params = {
      program: id,
    };

    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await apiListExerciseTechnicalDepartmentStor(
          params,
          this.rootStore.sessionStore.token,
        );

        this.resetListWorkout();
        this.setListSuggestion(res.data);
        this.renderListTrainingSuggestion();
        resolve(res.data.weight_trainings);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  plusWorkout(): void {
    this.renderListTrainingSuggestion();
  }

  setReloadListExercise(toogle: boolean): void {
    this.reloadListExercise = toogle;
  }

  statusFinishListExerciseSuggestion(list: SuggetionTraining[]): void {
    const hasPlusExercise = this.allowRenderExercise(list);
    this.finishListSuggestionTechnical = !hasPlusExercise;
  }

  makeTrainingGroup(list: string[]) {
    const initial: string = '';
    if (list.length <= 0) return '';

    const label = list.reduce((initial: string, nextValue: string) => {
      return initial += `${nextValue}/`;
    }, initial);

    return label.slice(0, label.length - 1);
  }

  hasIntesification(currentWorkout: SuggetionTraining) {
    if (currentWorkout.intensification_method === false) return '';
    return this.rootStore.i18n.t('intensification');
  }

  makeDescription(workout: SuggetionTraining) {
    const labelGroup = this.makeTrainingGroup(workout.training_group_labels);
    const intensification = this.hasIntesification(workout);
    const label = `${workout.level_name} - ${labelGroup} - ${intensification} - ${workout.frequency}x`;
    return label.replace('-  -', '-');
  }

  renderView(workout: SuggetionTraining[]) {
    const lenghtWorkout = workout.length - 1;
    const limit = (workout.length < this.limitRender) ? lenghtWorkout : this.limitRender;
    const list = workout;

    for (let i = 0; i <= limit; i += 1) {
      if (lenghtWorkout >= this.currentWorkout) {
        const description = this.makeDescription(list[this.currentWorkout]);
        list[this.currentWorkout].description = description;
        this.listSuggestionView.push(workout[this.currentWorkout]);
        this.currentWorkout += 1;
        this.limitLoop += 1;
      }
    }

    this.statusFinishListExerciseSuggestion(workout);
  }

  allowRenderExercise(list: SuggetionTraining[]): boolean {
    return (list.length > 0 && (this.limitLoop <= list.length));
  }

  renderListTrainingSuggestion() {
    const list: SuggetionTraining[] = this.rootStore
      .filterWorkoutStore.getListExercise(this.listSuggestion);

    if (this.allowRenderExercise(list)) {
      this.renderView(list);
    }
  }
}

decorate(TechnicalTrainingtStore, {
  listSuggestion: observable,
  listSuggestionView: observable,
  reloadListExercise: observable,
  doRequestListExerciseSuggestion: action,
  setReloadListExercise: action,
  plusWorkout: action,
  resetView: action,
});

export default TechnicalTrainingtStore;
