import styled from 'styled-components';

const BuildTrainingActivitiesStyle = styled.div`
  display: flex;
  z-index: 8;

  .wrapper-buttons {
    .wrapper-dropdown {
      margin-right: 10px;
      width: 50px;
      height: 50px;
      line-height: 34px;
      background-color: #202020;

      .triangle-one {
        left: 18px;
        top: 55px;
      }

      .triangle-two {
        left: 16px;
        top: 54px;
      }

      .menu {
        width: 210px;
        left: -78px;
        top: 64px;
      }
    }
  }
`;

export { BuildTrainingActivitiesStyle };
export default BuildTrainingActivitiesStyle;
