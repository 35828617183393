import {
  action, decorate, observable,
} from 'mobx';
import {
  unionBy,
} from 'lodash';

import { getFollowUp as apiGetFollowUp } from '../../services/api';

import {
  TypeExercieSlimming,
  TypeResultGraphic,
  TypelistOptionExerciseSelect,
  TypeWeightExercise,
  IFollowUpStore,
} from './IfollowUpStore';

class FollowUpStore implements IFollowUpStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
  };

  coachHypertrophy: TypeWeightExercise[] = [];

  coachSlimming: TypeExercieSlimming[] = [];

  listOptionExerciseSelect: TypelistOptionExerciseSelect[] = [];

  resultListData: TypeResultGraphic[] = [];

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  reset(): void {
    this.coachHypertrophy = [];
    this.coachSlimming = [];
    this.resultListData = [];
  }

  setListExercise(): void {
    const listTemp = this.coachHypertrophy.map(exercise => exercise.weight_exercise);
    const list = unionBy(listTemp, 'id');
    this.listOptionExerciseSelect = list;
  }

  setCoachHypertrophy(data: TypeWeightExercise[]): void {
    this.coachHypertrophy = data;
    this.setListExercise();
  }

  concatZero(value: number) {
    return (value < 10) ? `0${value}` : `${value}`;
  }

  getValueDate(dateExercise: string | undefined): string {
    if (dateExercise === undefined) return '';

    const date = new Date(dateExercise);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();


    const currentToday = this.concatZero(day);
    const currentMonth = this.concatZero(month);

    return `${currentToday}/${currentMonth}/${year}`;
  }

  makeDataSlimming(): void {
    const list = this.coachSlimming.map((exercise) => {
      const getDate = exercise.done_at;
      const getWeightLoad = exercise.weight;

      return {
        date: this.getValueDate(getDate),
        weightLoad: getWeightLoad,
      };
    });

    this.resultListData = list;
  }

  setCoachSlimming(data: TypeExercieSlimming[]): void {
    this.coachSlimming = data;
    this.makeDataSlimming();
  }

  setFollowUp(data: any[], slug: string): void {
    if (slug === 'coach_hypertrophy' || slug === 'coach_physical_fitness') {
      this.setCoachHypertrophy(data);
    }

    if (slug === 'coach_slimming') {
      this.setCoachSlimming(data);
    }
  }

  setOptionSelected(id: number): void {
    const listExercise = this.coachHypertrophy
      .filter(exercise => exercise.weight_exercise.id === id);

    const listLastExercise: TypeWeightExercise[] = [];

    listExercise.forEach((el, i) => {
      if (i < 3) {
        listLastExercise.push(el);
      }
    });

    const listResult = listLastExercise.map((exercise) => {
      const getDate = exercise.done_at || exercise.created_at;
      const getWeightLoad = exercise.weight_load || exercise.value;

      return {
        date: this.getValueDate(getDate),
        weightLoad: getWeightLoad,
      };
    });

    this.resultListData = listResult;
  }

  doRequestFollowUp(id: number, slug: string): any {
    const data = {
      peopleId: id,
      slug,
    };

    this.reset();

    const promiseGetFollowUp = async (resolve: any, reject: any) => {
      try {
        const res = await apiGetFollowUp(data, this.rootStore.sessionStore.token);
        this.setFollowUp(res.data.follow_up, slug);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseGetFollowUp(resolve, reject)
    ));
  }

  hasResultExercise(): boolean {
    if (this.coachHypertrophy.length > 0 || this.coachSlimming.length > 0) {
      return true;
    }

    return false;
  }

  hasWeightExercise(): boolean {
    if (this.coachHypertrophy.length > 0) {
      return true;
    }

    return false;
  }
}

decorate(FollowUpStore, {
  resultListData: observable,
  coachHypertrophy: observable,
  coachSlimming: observable,
  doRequestFollowUp: action,
  setOptionSelected: action,
  hasResultExercise: action,
  hasWeightExercise: action,
  setCoachHypertrophy: action,
  setCoachSlimming: action,
  reset: action,
});

export default FollowUpStore;
