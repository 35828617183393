import React, { useContext, useState } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Modal,
  Title,
  Button,
} from 'components';
import { PostGaAnalytics } from 'utils';

const ModalDateTrainingStyle = styled.div`
  .warning-date {
    border-top: 1px solid #cacace;
    padding-top: 16px;
    color: #010114;
    font-family: ${props => props.theme.fonts.Gotham};
    font-style: normal;
    font-weight: 325;
    font-size: ${props => props.theme.fontSizes[1]};
    line-height: 17px;
    margin-bottom: 35px;
  }

  .wrapper-option-date {
    color: ${props => props.theme.colors.black};
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    .option-date {
      display: flex;
      justify-content: space-between;
      border: 1px solid #A0A0A7;
      border-radius: 4px;
      margin-left: 16px;
      padding: 15px 15px;
      background: #f4f4f5;

      .recommend {
        display: none;
      }


      &.selected {
        border: 1px solid #25a769;
        border-radius: 4px;
        position: relative;

        .recommend {
          display: block;
          background: #25a769;
          border-radius: 50px;
          position: absolute;
          top: -6px;
          left: 10px;
          color: ${props => props.theme.colors.white};
          font-family: 'Gotham';
          font-style: normal;
          font-size: 10px;
          padding: 2px 6px;
          text-transform: lowercase;
        }
      }
    }

    .title {
      color: ${props => props.theme.colors.black};

      input {
        margin-right: 16px;
      }
    }
  }
`;

const ModalDateTraining = (props) => {
  const [dateTraining, setDateTraining] = useState(null);
  const context = useContext(Context);
  const {
    modalStore,
    trainingCreationStore,
    i18n,
    rulesPaisStore,
  } = context;

  const {
    saveCache,
  } = props;

  const saveExercise = async () => {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'save_date_finish',
    });

    try {
      await trainingCreationStore.doRequestFinishDate();
      modalStore.closeModalError();
    } catch (error) {
      window.scroll(0, 0);
      modalStore.setModalNetworkCallback(saveExercise);
      modalStore.showModalError(error);
    }
  };

  const onChangeValue = (e) => {
    setDateTraining(e.target.value);
  };

  const sendDateTraining = () => {
    if (dateTraining !== null) {
      trainingCreationStore.setDateFinish(dateTraining);

      if (saveCache === false) {
        saveExercise();
      }

      modalStore.toggleModal('modalDateTraining', false);
    }
  };

  const closed = () => {
    modalStore.toggleModal('modalDateTraining', false);
  };

  const renderOptions = () => {
    const listDate = [30, 45, 60];

    return listDate.map((date) => {
      const dateMinExpire = rulesPaisStore.getDayMinExpireTraining;
      const hasPremissa = trainingCreationStore.paisPremisses;
      const hasDate = (dateMinExpire === date && hasPremissa);

      return (
        <li className={`option-date ${hasDate ? 'selected' : ''}`} key={`key-${date}`}>
          <label className="title" htmlFor={`option-${date}`}>
            <input type="radio" id={`option-${date}`} name="option-date-training" value={date} />
            <span>{i18n.t('days', { days: date })}</span>
          </label>
          <div className="recommend">{i18n.t('recommended')}</div>
        </li>
      );
    });
  };

  return (
    <Modal>
      <div className="w-modal-content">
        <ModalDateTrainingStyle>
          <Title
            type={4}
            as="h4"
            color="#000"
            align="initial"
            mgBottom="8"
          >

            {i18n.t('title_modal_rules_date')}
          </Title>

          <p className="warning-date">{i18n.t('subtitle_change_date_training')}</p>

          <ul className="wrapper-option-date" onChange={onChangeValue}>
            {renderOptions()}
          </ul>

          <Title
            type={4}
            as="h4"
            color="#000"
            align="center"
            mgBottom="18"
            fontWeight="bold"
            fontSize="16"
          >
            {i18n.t('question_change_date_training')}
          </Title>

          <div>
            <Button
              fourth
              onClick={closed}
            >
              {i18n.t('cancel')}
            </Button>
            <Button
              variant
              onClick={sendDateTraining}
            >
              {i18n.t('confirm')}
            </Button>
          </div>
        </ModalDateTrainingStyle>
      </div>
    </Modal>
  );
};

ModalDateTraining.defaultProps = {
  saveCache: false,
};

ModalDateTraining.propTypes = {
  saveCache: PropTypes.bool,
};

export default ModalDateTraining;
