import React, { useContext } from 'react';
import Context from 'stores/context';
import { Text } from 'rebass';
import { observer } from 'mobx-react';


const HeaderList = () => {
  const context = useContext(Context);
  const { i18n, addExercisesStore } = context;

  return (
    <Text color="#999" textAlign="left" pl={[4]} className="text-header">
      {i18n.t('selected')}
      {`(${addExercisesStore.getExercisesSelected.length})`}
    </Text>
  );
};

export default observer(HeaderList);
