import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { PostGaAnalytics } from 'utils';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';

import {
  GridContainer,
  HeaderSession,
} from 'components';

import ModalLocation from './ModalLocation/ModalLocation';

import DateSchedule from './DateSchedule/DateSchedule';

const Schedule = ({ history }) => {
  const context = useContext(Context);
  const {
    appStore,
    i18n,
    modalStore,
    sessionStore,
    locationStore,
  } = context;

  const hasRegional = () => (sessionStore.acronym === '');

  const eventButtonHeader = () => {
    history.goBack();
  };

  const changeAcronym = async () => {
    appStore.toggleloading(true);
    let msgTitle;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:schedule',
      eventAction: 'click:button',
      eventLabel: 'schedule_acronym',
    });

    try {
      await locationStore.doRequestListLocation(true);
      appStore.toggleloading(false);
    } catch (err) {
      msgTitle = i18n.t('error_location');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);
      appStore.toggleloading(false);
    }
  };

  return (
    <GridContainer>
      <HeaderSession
        isBack
        nameClass="w-btn-back yellow"
        title="Agenda da unidade"
        subTitle={locationStore.nameLocation}
        eventButton={eventButtonHeader}
        schedule={hasRegional()}
        eventChangeAcronym={changeAcronym}
      />

      <DateSchedule history={history} />

      <ModalLocation />

      {modalStore.modalError && <ModalError />}
      {modalStore.modalNetwork && <ModalNetwork tryAgain={eventButtonHeader} />}

    </GridContainer>
  );
};

Schedule.defaultProps = {
  history: {},
};

Schedule.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(Schedule);
