import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import ModalWrapper from 'components/modal/modal-wrapper';
import ListNotices from './ListNotices';
import MessageNotices from './MessageNotices';

class ModalListNotices extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.styleModal = {
      titleColor: 'black',
      widthWrapper: '544px',
      px: '0px',
      py: '0px',
    };
  }

  showInfoInModal() {
    const { noticesStore } = this.context;
    if (noticesStore.showListNotices) {
      return (
        <ListNotices />
      );
    }

    return (
      <MessageNotices />
    );
  }

  render() {
    return (
      <ModalWrapper styleCss={this.styleModal}>
        {this.showInfoInModal()}
      </ModalWrapper>
    );
  }
}

export default observer(ModalListNotices);
