import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import LoaderSVG from './loader.svg';

const LoaderStyle = styled.div`
  text-align: center

  .img {
    animation: rotating 2s linear infinite;
  }

  .w-text-status {
    padding: 10px 0px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #808080;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

function Loader(props) {
  const context = useContext(Context);
  const {
    i18n,
  } = context;
  const {
    text,
  } = props;

  return (
    <LoaderStyle>
      <img className="img" src={LoaderSVG} alt={i18n.t(text)} />
      <p className="w-text-status">{i18n.t(text)}</p>
    </LoaderStyle>
  );
}

Loader.defaultProps = {
  text: '',
};

Loader.propTypes = {
  text: PropTypes.string,
};

export { Loader };
export default Loader;
