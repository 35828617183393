import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';
import ModalExercise from '../../BuildTraining/BuildingTrainingList/ModalExercise';
import {
  FormAddExerciseWeightTraining,
} from '../Forms';

class ExercisesSettingsWeightTraining extends Component {
  static contextType = Context;

  componentWillMount() {
    const { modalStore } = this.context;
    modalStore.toggleModal('modalExercise', true);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: 'workout_details',
    });
  }

  render() {
    const { modalStore } = this.context;
    const { history } = this.props;
    const renderModalExercise = modalStore.modalExercise && (
      <ModalExercise history={history}>
        <FormAddExerciseWeightTraining history={history} />
      </ModalExercise>
    );

    return (
      <div className="modal__add modal__add--weight-training">
        { renderModalExercise }
      </div>
    );
  }
}

export default observer(ExercisesSettingsWeightTraining);
