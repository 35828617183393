import React from 'react';
import { Error } from 'components';

const Logout = (props) => {
  const {
    history,
  } = props;

  return (
    <div>
      <Error page="logout" history={history} />
    </div>
  );
};

export default Logout;
