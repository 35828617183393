import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Text, Box, Flex,
} from 'rebass';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import {
  HeaderSession,
  GridContainer,
  Row,
  Column,
  ButtonDelete,
  ButtonSquare,
  FeedBackBar,
  TrainingHistory as TrainingHistoryContent,
} from 'components';
import ModalDelete from 'components/modal/modal-delete';
import ModalNetwork from 'components/modal/modal-network';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

const HeadText = styled(Text)`
  word-break: break-word;
  text-transform: uppercase;
  color: #ccc;
  font-weight: bold;
  font-size: 10px;

  @media screen and (min-width: 599px) {
    font-size: 12px;
  }
`;

const BodyText = styled(Text)`
  font-size: 12px;
  color: white;
  text-align: center;

  @media screen and (min-width: 599px) {
    font-size: 16px;
  }
`;

const ButtonHistory = styled(Box)`
  width: 100%;
  @media screen and (min-width: 550px) {
    width: 180px;
  }
`;

const WrapperItem = styled(Box)`
  border-bottom: solid 1px #333;
`;

const Item = styled(Box)`
  padding: 0 3px;

  @media screen and (min-width: 599px) {
    padding: 0 5px;
  }
`;

const TrainingHistory = (props) => {
  const { history } = props;
  const context = useContext(Context);
  const {
    appStore,
    trainingCreationStore,
    modalStore,
    i18n,
    sessionStore,
    feedBackBarStore,
  } = context;
  const [trainings, setTrainings] = useState(null);
  const [trainindId, setTrainingId] = useState(null);
  const cols = [
    ['80px', '100px'],
    ['60px', '100px'],
    ['50px', '100px'],
    ['30px'],
    ['50px', '150px'],
  ];


  const tryAgainLoading = () => {
    setTrainings(null);
  };

  useEffect(() => {
    const call = async () => {
      appStore.toggleloading(true);
      try {
        const res = await trainingCreationStore.doRequestGetTrainings();
        appStore.toggleloading(false);
        modalStore.closeModalError();
        setTrainings(res.data.weight_trainings);
      } catch (error) {
        appStore.toggleloading(false);
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(tryAgainLoading);
        modalStore.showModalError(error);
        setTrainings(false);
      }
    };

    if (trainings === null) {
      PostGaAnalytics('send', {
        hitType: 'pageview',
        page: 'history',
      });

      call();
    }
  }, [trainings, trainingCreationStore, appStore, modalStore]);

  const onClickSeeTraining = (id) => {
    trainingCreationStore.setWeightTrainingId(id);
    const url = ConcatUnitInUrl('/build-training/build-training-list');

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:history',
      eventAction: 'click:button',
      eventLabel: 'see_workout',
    });

    history.push(url);
  };

  const onClickDeleteTraining = (id) => {
    const msgTitle = i18n.t('question_delete_training');
    modalStore.setTitle(msgTitle);
    modalStore.modalDelete = true;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:history',
      eventAction: 'click:button',
      eventLabel: 'trash_workout',
    });

    setTrainingId(id);
  };

  const deleteTraining = async () => {
    try {
      await trainingCreationStore.doRequestDelTrainings(trainindId);
      const msg = i18n.t('training_history_delete');
      feedBackBarStore.showFeebBackToUser(true, msg);
      setTrainings(null);
    } catch (error) {
      window.scroll(0, 0);
      modalStore.setModalNetworkCallback(deleteTraining);
      modalStore.showModalError(error);
      setTrainings(false);
    }

    return true;
  };

  const hasComplete = training => (training.complete ? i18n.t('yes') : i18n.t('no'));

  modalStore.setModalDeleteCallBack(deleteTraining);

  const modalNetwork = modalStore.modalNetwork && (
    <ModalNetwork />
  );

  const renderButtons = training => (
    <ButtonSquare
      variant={training.complete ? 'secondSquare' : 'primarySquare'}
      width={['100%']}
      fontSize={['10px', '13px']}
      padding="15px 0px 15px 0px"
      onClick={() => onClickSeeTraining(training.id)}
    >
      {training.complete ? i18n.t('see_training') : i18n.t('mount_training')}
    </ButtonSquare>
  );

  const eventButtonHeader = () => {
    const url = ConcatUnitInUrl('/profile');
    feedBackBarStore.hiddenFeedBackBar();
    history.push(url);
  };

  return (
    <>
      <GridContainer>
        <Row>
          <Column sm="12" xs="12">
            <HeaderSession
              statusActionButton={false}
              isBack
              eventButton={eventButtonHeader}
              nameClass="w-btn-back"
              title={sessionStore.renderListNavigator(i18n.t('profile_title'))}
              subTitle={i18n.t('profile_title')}
            />
          </Column>
          <FeedBackBar
            showFeedBackBar={feedBackBarStore.activeBar}
            msgFeedBackBar={feedBackBarStore.message}
          />
        </Row>
      </GridContainer>

      <TrainingHistoryContent>
        <Flex justifyContent="space-between" py={['10px']}>
          <Item width={cols[0]}>
            <HeadText>
              {i18n.t('profile_level')}
            </HeadText>
          </Item>
          <Item width={cols[1]}>
            <HeadText>
              {i18n.t('training_creation_frequency_title')}
            </HeadText>
          </Item>
          <Item width={cols[2]}>
            <HeadText>
              {i18n.t('complete')}
            </HeadText>
          </Item>
          <Box width={cols[3]} />
          <Box width={cols[4]} />
        </Flex>

        {
          trainings && trainings.map(training => (
            <WrapperItem key={training.id} id={training.id} py={['10px']}>
              <Flex justifyContent="space-between" alignItems="center">
                <Item width={cols[0]}>
                  <BodyText>
                    {training.level_name}
                  </BodyText>
                </Item>
                <Item width={cols[1]}>
                  <BodyText>
                    {training.frequency}
                  </BodyText>
                </Item>
                <Item width={cols[2]}>
                  <BodyText>
                    {hasComplete(training)}
                  </BodyText>
                </Item>
                <Flex>
                  <Item>
                    <ButtonDelete
                      onClick={() => onClickDeleteTraining(training.id)}
                      padding="23px 24px 23px 24px"
                    />
                  </Item>
                  <ButtonHistory>
                    {renderButtons(training)}
                  </ButtonHistory>
                </Flex>
              </Flex>
            </WrapperItem>
          ))
        }
      </TrainingHistoryContent>

      {modalStore.modalDelete && (
        <ModalDelete />
      )}
      {modalNetwork}
    </>
  );
};

export default observer(TrainingHistory);
