import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Text, Button as TextButton, Flex,
} from 'rebass';
import Context from '../../stores/context';
import ModalWrapper from './modal-wrapper';

function ModalError({ onClick }) {
  const context = useContext(Context);
  const { modalStore, i18n } = context;

  const closeCallback = (e) => {
    modalStore.toggleModal('modalError', false);
    if (onClick) {
      onClick(e);
    }
    modalStore.postDataGa();
    modalStore.reset();
  };

  return (
    <ModalWrapper onClick={closeCallback} styleCss={modalStore.styleCss}>
      <Heading color={modalStore.styleCss.titleColor || 'red'} my={[3]}>{modalStore.title}</Heading>
      <Text mb={[3]}>{modalStore.description}</Text>
      <Flex justifyContent="center">
        <TextButton
          color="darkgray"
          variant="text"
          onClick={closeCallback}
        >
          {i18n.t('close')}
        </TextButton>
      </Flex>
    </ModalWrapper>
  );
}

ModalError.defaultProps = {
  onClick: () => {},
};

ModalError.propTypes = {
  onClick: PropTypes.func,
};

export default ModalError;
