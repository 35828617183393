/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PostGaAnalytics, ConcatUnitInUrl } from 'utils';
import ArrowSVG from './Icon/arrow.svg';

const ListHourScheduleStyle = styled.ul`
  list-style-type: none;

  .list-date {
    color: ${props => props.theme.colors.white};
  }

  .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;
    padding: 5px 0px;

    .info-client {
      display: flex;
      cursor: pointer;

      .image-client {
        border-radius: 40px;
        overflow: hidden;
        height: 60px;
      }

      .description-client {
        margin-left: 12px;

        .hour {
          font-family: ${props => props.theme.fonts.Gotham};
          font-style: normal;
          font-size: ${props => props.theme.fontSizes[1]};
          line-height: 17px;
          text-align: center;
          display: inline;
          padding: 4px 10px;
        }

        &.confirmed {
          .hour {
            background-color: ${props => props.theme.colors.green};
          }
        }

        &.scheduled {
          .hour {
            background-color: ${props => props.theme.colors.white};
            color: ${props => props.theme.colors.black};
          }
        }

        &.canceled {
          .hour {
            background-color: ${props => props.theme.colors.red};
          }

          .name, .programs {
            text-decoration: line-through;
          }
        }

        .name {
          font-family: ${props => props.theme.fonts.Gotham};
          font-size: ${props => props.theme.fontSizes[2]};
          font-weight: bold;
          margin-top: 10px;
        }

        .programs {
          font-family: ${props => props.theme.fonts.Gotham};
          font-size: ${props => props.theme.fontSizes[2]};
        }
      }
    }
  }
`;

const ListHourSchedule = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
    appStore,
    modalStore,
  } = context;

  const {
    history,
    listSchedule,
  } = props;

  const navigatorProfile = async (client) => {
    appStore.toggleloading(true);
    appStore.clearALlCache();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:schedule',
      eventAction: 'click:button',
      eventLabel: 'schedule_select_bookings',
    });

    try {
      await peopleStore.doRequestPeopleShow({ id: client.person_id });
      const url = ConcatUnitInUrl('/profile');
      history.push(url);
    } catch (err) {
      const msgTitle = i18n.t('error_show_data_studente');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);
      appStore.toggleloading(false);
    }
  };

  const renderHour = (client) => {
    const dateBooking = new Date(client.start_at);
    const hour = (dateBooking.getHours() < 9) ? `0${dateBooking.getHours()}` : dateBooking.getHours();
    const minutes = (dateBooking.getMinutes() < 9) ? `0${dateBooking.getMinutes()}` : dateBooking.getMinutes();

    return `${hour}:${minutes}h`;
  };

  return (
    <ListHourScheduleStyle>
      {listSchedule.map(client => (
        <li
          className="card"
          key={client.id}
          onClick={() => navigatorProfile(client)}
          role="presentation"
        >
          <div className="info-client">
            <div className={`description-client ${client.status}`}>
              <p className="hour">{renderHour(client)}</p>
              <p className="name">{client.person_name}</p>
              <p className="programs">{i18n.t(client.kind)}</p>
            </div>
          </div>
          <div>
            <img src={ArrowSVG} alt="arrow" />
          </div>
        </li>
      ))}
    </ListHourScheduleStyle>
  );
};

ListHourSchedule.defaultProps = {
  history: {},
  listSchedule: [],
};

ListHourSchedule.propTypes = {
  listSchedule: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};


export default ListHourSchedule;
