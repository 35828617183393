import React, { useContext } from 'react';
import Context from 'stores/context';
import {
  ListInfoModalStyle,
} from 'components';
import ItemLocation from './ItemLocation';

const ListLocation = () => {
  const context = useContext(Context);
  const {
    locationStore,
  } = context;

  return (
    <ListInfoModalStyle>
      {locationStore.getListLocation.map(location => (
        <ItemLocation key={`item-${location.id}`} location={location} />
      ))}
    </ListInfoModalStyle>
  );
};

export default ListLocation;
