/* eslint react/prop-types: 0, react/jsx-props-no-spreading: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
`;

const Content = styled(Box)`
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 0px 8px;

  @media screen and (min-width: 450px) {
    display: ${(props) => props.display || 'inline-block'};
    width: 506px;
    padding: 0px 0px;
  }

  .wrapper-content {
    background: #fff;
    border-radius: 4px;
    margin-top: 11px;
    overflow: hidden;
  }
`;

function ModalWrapperNew(props) {
  const {
    children,
    display,
  } = props;

  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Content display={display}>
        <div className="wrapper-content">
          {children}
        </div>
      </Content>
    </Wrapper>
  );
}

ModalWrapperNew.defaultProps = {
  children: [],
  display: 'inline-block',
};

ModalWrapperNew.propTypes = {
  children: PropTypes.node,
  display: PropTypes.string,
};

export { ModalWrapperNew };
export default ModalWrapperNew;
