import React, { Component } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';

import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import PropTypes from 'prop-types';

import {
  Anamnese,
  Button,
  Title,
  SubTitle,
  Textarea,
  Text,
} from 'components';

class LesionAndRestrictionEdit extends Component {
  static contextType = Context;

  state = {
    detailPrescription: '',
  };

  constructor() {
    super();
    this.setOptionSelect = this.setOptionSelect.bind(this);
    this.saveEditLesion = this.saveEditLesion.bind(this);
    this.loadingDataExercise = this.loadingDataExercise.bind(this);
  }

  componentWillMount() {
    const { lesionAndRestrictionStore } = this.context;
    this.setState({
      detailPrescription: lesionAndRestrictionStore.editDescription,
    });
  }

  setOptionSelect(option) {
    const { lesionAndRestrictionStore } = this.context;
    lesionAndRestrictionStore.setKindCurrentEdit(option.value);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestrictionEdit',
      eventAction: 'click:button',
      eventLabel: `lesion_and_restriction_edit_preciption_${option}`,
    });
  }

  loadingDataExercise() {
    const {
      trainingCreationStore,
    } = this.context;

    trainingCreationStore.setWeightTrainingId(trainingCreationStore.weightTrainingId);
  }

  saveEditLesion() {
    const {
      lesionAndRestrictionStore,
      appStore,
      modalStore,
    } = this.context;

    const {
      detailPrescription,
    } = this.state;

    const { history } = this.props;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:lesionAndRestrictionEdit',
      eventAction: 'click:button',
      eventLabel: 'lesion_and_restriction_edit_save',
    });

    appStore.toggleloading(true);
    lesionAndRestrictionStore.makeObjToEditLesion(detailPrescription);
    appStore.toggleloading(true);

    lesionAndRestrictionStore
      .doRequestSavePrescription()
      .then(() => {
        this.loadingDataExercise();
        appStore.toggleloading(false);
        const url = ConcatUnitInUrl('/build-training/build-training-list');
        history.push(url);
      })
      .catch((error) => {
        appStore.toggleloading(false);
        appStore.toggleloading(false);
        modalStore.showModalError(error);
      });
  }

  renderListOption() {
    const { lesionAndRestrictionStore } = this.context;

    if (lesionAndRestrictionStore.listKindsEdit <= 0) return false;

    return lesionAndRestrictionStore.listKindsEdit.map(option => (
      <Button
        reponse
        key={option.value}
        variant={lesionAndRestrictionStore.listKindsEditSelected.includes(option.value)}
        onClick={() => this.setOptionSelect(option)}
      >
        {option.label}
      </Button>
    ));
  }

  render() {
    const { i18n, lesionAndRestrictionStore } = this.context;
    const { detailPrescription } = this.state;

    return (
      <Anamnese>
        <Title type={1} as="h2" subTitleColor="#fff">
          {lesionAndRestrictionStore.propertyLesion}
          <SubTitle fontWeight="500">
            {i18n.t('anamnesis_lesion_subtitle')}
          </SubTitle>
        </Title>

        <div className="w-option-lesion">
          {this.renderListOption()}
        </div>

        <div className="w-lesion-details">
          <Text
            className="label"
            color="#fff"
            fontSize="16px"
          >
            {i18n.t('but_details')}
            :
          </Text>

          <Textarea
            tabIndex="0"
            id="observation-restriction"
            minHeight="100px"
            maxLength="200"
            onChange={(event) => {
              this.setState({ detailPrescription: event.target.value });
            }}
            defaultValue={lesionAndRestrictionStore.editDescription}
          />
          <div className="limit-text">
            {i18n.t('limit_name_training', {
              lenghtInput: detailPrescription.length,
              limit: '200',
            })}
          </div>
        </div>

        <div className="next-frequency">
          <Button
            reponse
            onClick={this.saveEditLesion}
          >
            { i18n.t('save')}
          </Button>
        </div>

      </Anamnese>
    );
  }
}

LesionAndRestrictionEdit.defaultProps = {
  history: {},
  match: {},
};

LesionAndRestrictionEdit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default observer(LesionAndRestrictionEdit);
