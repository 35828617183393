import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import Login from './views/Login/Login';
import RedirectAutoSeg from './views/RedirectAutoSeg/RedirectAutoSeg';
import AuthenticateAutoSeg from './views/AuthenticateAutoSeg/AuthenticateAutoSeg';
import Context from './stores/context';
import ProfileTab from './views/Profile/ProfileTab';
import SendTrainingWalletTeacher from './views/TrainingTeacher/SendTrainingWalletTeacher/SendTrainingWalletTeacher';
import TrainingHistory from './views/Profile/TrainingHistory';
import Anamnesis from './views/Anamnesis/Anamnesis';
import Suggestions from './views/Suggestions/Suggestions';
import BuildTraining from './views/BuildTraining/BuildTraining';
import BuildingEdit from './views/BuildTraining/BuildingEdit';
import BuildTrainingSend from './views/BuildTraining/BuildTrainingSend';
import TrainingCreation from './views/TrainingCreation/TrainingCreation';
import AddExercises from './views/AddExercises/AddExercises';
import SelectTheOptionWork from './views/SelectTheOptionToWork/SelectTheOptionWork';
import TeacherTrainingCreation from './views/TrainingTeacher/TeacherTrainingCreation';
import Schedule from './views/Schedule/Schedule';
import ErrorBoundary from './views/ErrorBoundary/ErrorBoundary';
import NotFound from './views/NotFound/NotFound';
import Logout from './views/Login/Logout';
import DataProfile from './views/DataProfile/DataProfile';
import ModalListNotices from './views/CentralBoard/ModalListNotices/ModalListNotices';
import LesionAndRestriction from './views/LesionAndRestriction/LesionAndRestriction';
import SearchClient from './views/SearchClient/SearchClient';
import ChangeUnit from './views/ChangeUnit/ChangeUnit';

import {
  HeaderTeacher,
  HeaderStudent,
  Loading,
  Main,
  PrivateRoute,
} from './components';

import ModalSignout from './components/modal/modal-signout';

function App() {
  const context = useContext(Context);
  const {
    appStore,
    modalStore,
    sessionStore,
    peopleStore,
    noticesStore,
  } = context;

  if (typeof sessionStore.auth !== 'undefined' && sessionStore.auth === null) {
    return false;
  }

  const headerTeacher = () => {
    if (sessionStore.auth) {
      return (
        <HeaderTeacher activeButton={noticesStore.hasNotices} />
      );
    }

    return '';
  };

  const headerStudent = () => {
    if (sessionStore.auth && peopleStore.hasPeopleId) {
      return (
        <HeaderStudent />
      );
    }

    return '';
  };

  const rulesNewLoginUnidade = () => {
    if (sessionStore.validateAutoSeg()) {
      return (
        RedirectAutoSeg
      );
    }

    return Login;
  }

  const modalSignout = modalStore.modalLogout && <ModalSignout />;
  const dataProfile = modalStore.modalDataProfile && <DataProfile />;
  const showModalNotices = modalStore.modalNotices && <ModalListNotices />;
  const Authenticate = rulesNewLoginUnidade();

  rulesNewLoginUnidade();

  return (
    <ErrorBoundary>
      {headerTeacher()}
      {headerStudent()}

      <Main>
        <Switch>
          <Route exact path="/" component={Authenticate} />
          <Route path="/authenticate-auto-seg" component={AuthenticateAutoSeg} />
          <PrivateRoute path="/select-option-work" component={SelectTheOptionWork} />
          <PrivateRoute path="/search-client-smart" component={SearchClient} />
          <PrivateRoute exact path="/profile" component={ProfileTab} />
          <PrivateRoute path="/training-history" component={TrainingHistory} />
          <PrivateRoute path="/anamnesis" component={Anamnesis} />
          <PrivateRoute path="/suggestions" component={Suggestions} />
          <PrivateRoute path="/training-creation" component={TrainingCreation} />
          <PrivateRoute path="/teacher-training-creation" component={TeacherTrainingCreation} />
          <PrivateRoute path="/lesion-and-restriction" component={LesionAndRestriction} />
          <PrivateRoute path="/wallet-send-training" component={SendTrainingWalletTeacher} />
          <PrivateRoute path="/build-training" component={BuildTraining} />
          <PrivateRoute path="/build-edit" component={BuildingEdit} />
          <PrivateRoute path="/build-training-send" component={BuildTrainingSend} />
          <PrivateRoute path="/add-exercises" component={AddExercises} />
          <PrivateRoute path="/schedule" component={Schedule} />
          <PrivateRoute path="/change-unit" component={ChangeUnit} />
          <Route path="/logout" component={Logout} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Main>

      {modalSignout}
      {dataProfile}
      {showModalNotices}
      <Loading show={!!appStore.loading} className="w-loading" />
    </ErrorBoundary>
  );
}

export default observer(App);
