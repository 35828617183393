import { Box } from 'rebass';
import styled from 'styled-components';

const TrainingHistory = styled(Box)`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  max-width: 1440px;
`;

export { TrainingHistory };
export default TrainingHistory;
