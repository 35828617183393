import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  getRulesPais as getRulesPaisApi,
} from 'services/api';

import RulesPaisListExercise from './RulesPaisListExercise';
import RulesPaisFormExercise from './RulesPaisFormExercise';
import RulesPaisHelper from './RulesPaisHelper';
import RulesPaisToggle from './RulesPaisToggle';
import {
  IrulesPaisStore,
  typeWarning,
  typeRequestDataPremissa,
  typeDataPremissa,
  typeParamsRequest,
  typeOptionRules,
  typeListExerciseAdd,
  typeDropDownPais,
  typeTrainingDurations,
} from './IrulesPaisStore';

import {
  Training,
  TypePostExercise,
} from '../IgenericTypes';
import RulesPaisGlobalTraining from './RulesPaisGlobalTraining';

class rulesPaisStore implements IrulesPaisStore {
  rootStore = {
    trainingCreationStore: {
      setDateFinish: (e: any) => {},
      paisPremisses: {},
      duration: '',
      emphasis: '',
    },
    sessionStore: {
      token: '',
    },
    teacherTrainingStore: {
      teacherInteface: false,
    },
    i18n: {},
    toggleStore: {
      validateToggle: ({}): boolean => {
        return false;
      },
      dataToggle: {
        allowPaisCountry: [''],
        allowShowTrainingSizeCountry: [''],
      },
    },
  };

  dataRules: typeDataPremissa = {
    id: 0,
    frequency: 0,
    speeds: [],
    training_durations: [],
    pais_premisses: false,
    min_exercises: 0,
    max_exercises: 0,
    min_series_by_exercise: 0,
    max_series_by_exercise: 0,
    min_series_by_training: 0,
    max_series_by_training: 0,
    min_interval: 0,
    max_interval: 0,
    min_days_expire_training: 0,
    max_days_expire_training: 0,
    exercise_repetitions: [],
    exercises_proportion: [],
    intensification_methods: {
      recommended: [],
      not_recommended: [],
    },
    unavailable_muscle_goups: [],
  };

  showRulesListExercise = false;

  showRulesFormExercise = false;

  warningListExercise = [{
    id: '',
    title: '',
    warning: [],
    checked: true,
  }];

  selectRulesPais = {
    series: [],
    repeats: [],
    interval: [],
  };

  checkTabTraining: boolean[] = [];

  listWarningErrosExercise: string[] = [];

  dropDownPais: typeDropDownPais = {
    checked: false,
    errorPremissas: [],
    amountExercise: 0,
    amountSerie: 0,
    sizeTraining: 0,
    emphasis: 0,
  };

  rulesPaisListExercise = {
    getInfoListWarningPais: (e: any) => {},
  };

  rulesPaisFormExercise = {
    getInfoFormWarningPais: (x: any, y: any) => {},
    getOptionsRulesPais: (e: any) => {},
    getGroupsListIntensification: (e: any) => {},
    getInfoFormWarningProfilePais: (x: any, y: any, z: any): any => {},
  };

  rulesPaisGlobalTraining = {
    loadingRulesPaisGlobal: (trainings: Training[],
      emphasis: string, listToggleShowRulesPremissas: string[]) => {},
    getAmountExercisesWithEmphasis: (): any => {},
  };

  listToggle = RulesPaisToggle.getRulesAllow();

  listToggleShowRulesPremissas = [...this.listToggle];

  rulesSizeTraing: typeTrainingDurations = {
    duration: '',
    min: 0,
    max: 0,
  };

  validateRulesEmphasis: boolean = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.rulesPaisListExercise = new RulesPaisListExercise(this.rootStore);
    this.rulesPaisFormExercise = new RulesPaisFormExercise(this.rootStore);
    this.rulesPaisGlobalTraining = new RulesPaisGlobalTraining(this.rootStore);
  }

  reset(): void {
    this.dataRules.min_days_expire_training = 0;
    this.showRulesListExercise = false;
    this.showRulesFormExercise = false;
  }

  resetListWarningAndcheckTabTraining(): void {
    this.checkTabTraining = [];
    this.listWarningErrosExercise = [];
    this.listToggleShowRulesPremissas = [...this.listToggle];
  }

  setPrazeTraining(): void {
    const date = this.dataRules.min_days_expire_training;
    this.rootStore.trainingCreationStore.setDateFinish(date);
  }

  setDropDownPais(training: Training) {
    this.dropDownPais = {
      checked: training.checked,
      errorPremissas: training.errorPremissas,
      amountExercise: RulesPaisListExercise.getAmountExercise(training, this.dataRules),
      amountSerie: RulesPaisListExercise.getAmountSeriesTraining(training, this.dataRules),
      sizeTraining: RulesPaisListExercise.getAmountAllExerciseList(training, this.dataRules),
      emphasis: this.rulesPaisGlobalTraining.getAmountExercisesWithEmphasis(),
    };
  }

  setRulesPais(data: typeRequestDataPremissa): void {
    this.dataRules = data.weight_training_profile;
    this.showRulesListExercise = true;
    this.showRulesFormExercise = true;
    this.setPrazeTraining();
  }

  doGetRules(params: typeParamsRequest): any {
    const promiseGetRulesPais = async (resolve: any, reject: any) => {
      try {
        const res = await getRulesPaisApi(params, this.rootStore.sessionStore.token);
        this.setRulesPais(res.data);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => (
      promiseGetRulesPais(resolve, reject)
    ));
  }

  hasErrorCheckedPraze(): boolean {
    try {
      const list = this.warningListExercise.filter(premissa => premissa.id === 'dateExpire');
      return list[0].checked === false;
    } catch (err) {}

    return false;
  }

  setWarningListExercise(): void {
    const showPais = this.showRulesPais('list');
    const hasRulesPraze = this.listToggleShowRulesPremissas.includes('dateExpire');

    if (this.dataRules && showPais && hasRulesPraze) {
      const listWarning: typeWarning[] | any = this.rulesPaisListExercise
        .getInfoListWarningPais(this.dataRules);

      this.showRulesListExercise = !(listWarning.length === 0);
      this.warningListExercise = listWarning;
    }
  }

  getWarningListExercise(): typeWarning[] {
    return this.warningListExercise;
  }

  setWarningFormExercise(values: TypePostExercise): void {
    const showPais = this.showRulesPais('form');

    if (this.dataRules && showPais) {
      const listWarning: typeWarning[] | any = this.rulesPaisFormExercise
        .getInfoFormWarningPais(this.dataRules, values);

      const listOptionRulesPais: typeOptionRules | any = this.rulesPaisFormExercise
        .getOptionsRulesPais(this.dataRules);

      this.showRulesFormExercise = !(listWarning.length === 0);
      this.selectRulesPais = listOptionRulesPais;
    }
  }

  insertIdError(rulesPais: typeWarning): void {
    const hasErro = this.listWarningErrosExercise.includes(rulesPais.id);

    if (hasErro === false) {
      this.listWarningErrosExercise.push(rulesPais.id);
    }
  }

  hasErrorPremissas(listRulesExercise: typeWarning[]): boolean {
    const list = listRulesExercise.map((rulesPais) => {
      if (rulesPais.checked === false) {
        this.insertIdError(rulesPais);
      }

      return rulesPais.checked;
    });

    if (list.length > 0) {
      return !list.includes(false);
    }

    return true;
  }

  loadingRulesPaisListExercise(training: Training) {
    RulesPaisListExercise.setRulesToListExercise(
      this.rootStore.trainingCreationStore.emphasis,
      this.rootStore.trainingCreationStore.duration,
      training,
      this.dataRules,
      this.checkTabTraining,
      this.listWarningErrosExercise,
      this.listToggleShowRulesPremissas,
      this.rootStore.toggleStore,
      this.rulesPaisGlobalTraining,
    );

    this.rulesSizeTraing = RulesPaisListExercise.getRulesDuration(
      this.dataRules,
      this.rootStore.trainingCreationStore.duration,
      this.listToggleShowRulesPremissas,
    );
  }

  loadingRulesPaisFormExercise(training: Training) {
    const listFormError = ["repeats", "interval"];

    if(training.weight_training_exercises.length <= 0) {
      listFormError.forEach((rule) => {
        const hasList = this.listToggleShowRulesPremissas.includes(rule);
        if(hasList) {
          this.listWarningErrosExercise.push(rule);
          this.checkTabTraining.push(false);
        }
      });
    } else {
      const list = training.weight_training_exercises.map((exercise) => {
        if (exercise.muscle_group === 'cardio' || exercise.cardio) {
          return true;
        }

        if (exercise.muscle_group === 'hit' || exercise.muscle_group === 'klass') {
          return true;
        }

        const listRulesExercise: typeWarning[] = this.rulesPaisFormExercise
          .getInfoFormWarningProfilePais(
            this.dataRules,
            exercise,
            this.listToggleShowRulesPremissas);

        return this.hasErrorPremissas(listRulesExercise);
      });

      this.checkTabTraining.push(...list)
    }
  }

  getCheckedTab(training: Training): boolean {
    this.loadingRulesPaisListExercise(training);
    this.loadingRulesPaisFormExercise(training);

    return !this.checkTabTraining.includes(false);
  }

  getWarningTabExercises(res: Training[]) {
    const data = res;
    const showPais = this.showRulesPais('form');

    if (this.dataRules && showPais) {

      this.rulesPaisGlobalTraining.loadingRulesPaisGlobal(
        res,
        this.rootStore.trainingCreationStore.emphasis,
        this.listToggleShowRulesPremissas
      );

      const newData = res.map((training: Training) => {
        this.resetListWarningAndcheckTabTraining();
        const cloneTraining = training;
        cloneTraining.checked = this.getCheckedTab(training);
        cloneTraining.errorPremissas = this.listWarningErrosExercise;
        return cloneTraining;
      });

      return newData;
    }

    return data;
  }

  getSelectPaisFormExercise() {
    return this.selectRulesPais;
  }

  getTogglePaisCountry() {
    return this.rootStore
      .toggleStore.validateToggle({
        toggleUnitBlock: 'toogleUnitPaisBlock',
        toggleUnitAllow: 'toogleUnitPaisAllow',
        toggleCountry: 'allowPaisCountry'
      });
  }

  showRulesPais(location: string): boolean {
    const { paisPremisses } = this.rootStore.trainingCreationStore;
    const listExercise = this.showRulesListExercise;
    const formExercise = this.showRulesFormExercise;
    const data = (location === 'form') ? formExercise : listExercise;
    const statusPais = (paisPremisses && data);

    return (statusPais && this.getTogglePaisCountry());
  }

  getMethodIntensification() {
    return this.rulesPaisFormExercise.getGroupsListIntensification(this.dataRules);
  }

  hasIntensificationMethod(): boolean {
    const hasPais = !!(this.rootStore.trainingCreationStore.paisPremisses && this.getTogglePaisCountry());
    const hasTemplateTeacher = this.rootStore.teacherTrainingStore.teacherInteface;

    return !!(hasPais && hasTemplateTeacher === false);
  }

  getCheckedAmountExercise(listExerciseSelected: typeListExerciseAdd [],
    listExerciseSave : typeListExerciseAdd []) {
    const min = this.dataRules.min_exercises;
    const max = this.dataRules.max_exercises;

    const listSelected = RulesPaisHelper
      .getLengthExercise(listExerciseSelected, this.dataRules);
    const listSaved = RulesPaisHelper
      .getLengthExercise(listExerciseSave, this.dataRules);

    const lengthExercise = listSaved + listSelected;

    return (lengthExercise >= min && lengthExercise <= max);
  }

  get getDayMinExpireTraining() {
    return this.dataRules.min_days_expire_training;
  }

  get trainingDurations() {
    return this.dataRules.training_durations;
  }

  hasTraingDuration() {
    const duration = this.dataRules.training_durations;
    return duration !== undefined && duration.length > 0;
  }
}

decorate(rulesPaisStore, {
  dataRules: observable,
  showRulesListExercise: observable,
  warningListExercise: observable,
  showRulesFormExercise: observable,
  rulesSizeTraing: observable,
  dropDownPais: observable,
  listToggleShowRulesPremissas: observable,
  setRulesPais: action,
  doGetRules: action,
  setWarningListExercise: action,
  getWarningListExercise: action,
  setWarningFormExercise: action,
  getSelectPaisFormExercise: action,
  getMethodIntensification: action,
  hasIntensificationMethod: action,
  getWarningTabExercises: action,
  hasErrorCheckedPraze: action,
  reset: action,
  setDropDownPais: action,
  getCheckedAmountExercise: action,
  getTogglePaisCountry: action,
  hasTraingDuration: action,
  getDayMinExpireTraining: computed,
  trainingDurations: computed,
});

export const rulesPaisStoreSchema = {
  dataRules: {
    type: 'object',
  },
  showRulesListExercise: true,
  showRulesFormExercise: true,
  rulesSizeTraing: {
    type: 'object',
  }
};

export default rulesPaisStore;
