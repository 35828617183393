import React, { useContext } from 'react';
import Context from 'stores/context';
import {
  Title,
} from 'components';

function DateExpirationTraining() {
  const context = useContext(Context);

  const {
    i18n,
    peopleStore,
    trainingCreationStore,
  } = context;

  const dateFinish = () => {
    const training = peopleStore.getCurrentWeightTraining;

    if (training.finish_date) {
      const numberOfDays = training.remains_days;
      const trainingExpired = (numberOfDays <= 0);

      if (trainingExpired) return i18n.t('expire_training');
      const labelDate = i18n.t('expire_training_date_plural', { count: numberOfDays });

      return (
        <small>
          {labelDate}
        </small>
      );
    }

    return false;
  };

  if (trainingCreationStore.inputManualTraining) {
    return (
      <Title
        as="h3"
        type={3}
        align="initial"
        fontSize={16}
        sizeWidthSpan={250}
        lineHeight="normal"
      >
        <span>{i18n.t('input_training')}</span>
        {dateFinish()}
      </Title>
    );
  }

  return (
    <Title as="h3" type={3} align="initial">
      <span>{i18n.t('gym')}</span>
      {dateFinish()}
    </Title>
  );
}

export { DateExpirationTraining };
export default DateExpirationTraining;
