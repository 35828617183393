const ShowDate = (startedAt) => {
  if (startedAt === null || startedAt === undefined) {
    return '00/00';
  }

  const date = new Date(startedAt);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month < 9) {
    return `${day}/0${month}/${year}`;
  }

  return `${day}/${month}/${year}`;
};

export default ShowDate;
