import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { ConcatUnitInUrl } from 'utils';
import {
  ProfileInfo,
  Button,
} from 'components';

const Restriction = ({ history }) => {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
    trainingCreationStore,
  } = context;

  const editRestriction = (paramsURl) => {
    trainingCreationStore.setEditObservationOrRestriction(true);
    const url = ConcatUnitInUrl(paramsURl);
    history.push(url);
  };

  const profileInfoObservationAndRestriction = peopleStore.observationAndRestriction && (
    <ProfileInfo highlight="true">
      <div>
        <small>{i18n.t('profile_lesion_or_observation')}</small>
        <span>
          <span>
            {peopleStore.observationAndRestriction}
          </span>
        </span>
      </div>
      <div>
        <Button
          secondary="true"
          rounded="true"
          edit="true"
          className="w-edit-button"
          onClick={() => editRestriction('/training-creation/restriction')}
        />
      </div>
    </ProfileInfo>
  );

  return (
    <>
      {profileInfoObservationAndRestriction}
    </>
  );
};

Restriction.defaultProps = {
  history: {},
};

Restriction.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export { Restriction };
export default { Restriction };
