import styled, { css } from 'styled-components';

const ContainerStyle = styled.div`
  display: flex;
  flex-wrap: ${(props) => props.flexWrap || 'wrap'};
  width: 100%;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.baseLine || 'baseline'};
  margin: ${(props) => props.margin || '0px 0px'};

  ${(props) => props.activeBorder
    && css`
      border-top: 1px solid #333;
      padding-top: 24px;
    `};

    .container {
      display: flex;
      width: 100%;
      justify-content: normal;
    }
`;

export { ContainerStyle };
export default ContainerStyle;
