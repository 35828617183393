import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import ItemCountry from './ItemCountry';

const ListCountryStyled = styled.ul`
  background-color: ${(props) => props.theme.colors.white};
  height: 400px;
  width: 240px;
  overflow-x: hidden;
  position: absolute;
  top: 71px;
  left: 0px;
  z-index: 1;

  &.hidden {
    display: none;
  }
`;

function ListCountry(props) {
  const context = useContext(Context);
  const {
    selectCountryStore,
  } = context;

  const {
    showListCountry,
    openListCountry,
  } = props;

  const selectCountry = (locale) => {
    selectCountryStore.setCountrySelect(locale);
    selectCountryStore.updateLabelDocument();
    openListCountry();
  };

  const renderList = () => selectCountryStore.getListCountry.map((locale) => (
    <li key={locale}>
      <ItemCountry locale={locale} onClick={() => selectCountry(locale)} />
    </li>
  ));

  return (
    <ListCountryStyled className={showListCountry ? '' : 'hidden'}>
      {renderList()}
    </ListCountryStyled>
  );
}

ListCountry.defaultProps = {
  showListCountry: false,
  openListCountry: () => {},
};

ListCountry.propTypes = {
  showListCountry: PropTypes.bool,
  openListCountry: PropTypes.func,
};

export default ListCountry;
