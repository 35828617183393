import React, { useContext, useState } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

import {
  Text,
  Box,
  Flex,
} from 'rebass';

import {
  Button,
} from 'components';

import ListExercises from 'views/Suggestions/Component/ListExercises';

const NewTrainingSuggestionStyle = styled.div`
  color: ${props => props.theme.colors.white};
  border: 1px solid #63636B;
  border-radius: 8px;

  .w-title {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 24px;
    width: 476px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .sub-title {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 24px;
  }

  .w-collapse-button {
    background-color: transparent;
    transform: rotateZ(0);
    transition: transform 0.2s ease-out;
    margin: 0px;

    &.w-collapse-opened {
      transform: rotateZ(180deg);
    }
  }
`;

const NewTrainingSuggestion = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(Context);
  const {
    buildTrainingStore,
  } = context;

  const training = buildTrainingStore.suggestionWorkoutBuild;
  const listExercises = open && <ListExercises id={training.id} />;

  const toggle = () => {
    setOpen(!open);
  };

  if (buildTrainingStore.templateSuggestionSend === false) {
    return false;
  }

  return (
    <NewTrainingSuggestionStyle>
      <Flex
        justifyContent="space-between"
        pt="24px"
        pb="24px"
        alignItems="center"
      >
        <Box pl="12px">
          <Flex mb={2}>
            <h4 className="w-title">
              {training.alias || training.name}
            </h4>
          </Flex>
          <Text color="white" className="sub-title">
            {training.description || training.name}
          </Text>
        </Box>

        <div className="wrapper-buttons">
          <Button
            className={`w-collapse-button ${open ? 'w-collapse-opened' : ''}`}
            secondary="true"
            rounded="true"
            minWidth="initial"
            onClick={() => toggle('conteudo')}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.66437 11.2526C7.25159 10.8857 6.61952 10.9229 6.2526 11.3356C5.88568 11.7484 5.92286 12.3805 6.33565 12.7474L15.3356 20.7474C15.7145 21.0842 16.2855 21.0842 16.6644 20.7474L25.6644 12.7474C26.0772 12.3805 26.1143 11.7484 25.7474 11.3356C25.3805 10.9229 24.7484 10.8857 24.3356 11.2526L16 18.6621L7.66437 11.2526Z"
                fill="#fff"
              />
            </svg>
          </Button>
        </div>
      </Flex>
      {listExercises}
    </NewTrainingSuggestionStyle>
  );
};

export default NewTrainingSuggestion;
