import React, { useState } from 'react';
import {
  SelectNew,
} from 'components';
import ListExercise from './ListExercise/ListExercise';

const TableExercises = (props) => {
  const { exercises } = props;
  const [kind, setKind] = useState(0);

  const selectTraining = (e) => {
    const result = parseInt(e.target.value, 10);
    setKind(result);
  };

  const optionsTab = exercises.map((training, index) => (
    <option key={training.id} value={index}>
      {`Treino ${training.label}`}
    </option>
  ));

  const listTrainingExercises = exercises.map((training, index) => (
    <ListExercise
      key={training.id}
      id={training.id}
      exercises={training.weight_training_exercises}
      show={kind === index}
    />
  ));

  return (
    <div className="content-training">
      <SelectNew onInput={(e) => { selectTraining(e); }}>
        {optionsTab}
      </SelectNew>
      {listTrainingExercises}
    </div>
  );
};

export default TableExercises;
