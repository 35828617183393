import styled from 'styled-components';

const DropDown = styled.div`
  margin-right: 10px;
  position: relative;

  .wrapper-dropdown {
    display: flex;
    flex-direction: column;
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: #202020;
    color: #fff;
    padding: '13px 30px';
    margin: '0px 15px 0px 0px';
  }

  .wrapper-circle {
    height: 4px;
    width: 4px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .circle {
    height: 4px;
    width: 4px;
    background-color: #bbb;
    border-radius: 50%;
    display: block;
  }

  .dropdown-menu {
    display: block;
    background-color: #fff;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: 'Gotham';
    font-weight: 500;
    position: absolute;
    top: 56px;
    right: 0px;
    width: 200px;
    list-style: none;
    display: none;

    &.active {
      display: block;
    }

    .dropdown-item {
      border-bottom: 1px solid #eee;
      padding: 15px 15px;

      .item {
        border: none;
        text-align: center;
        display: inline;
        font-weight: 500;
        text-transform: uppercase;
        font-family: 'Gotham';
        font-size: 15px;
        background-color: #fff;

        &.opacity {
          opacity: 0.2;

          &.active {
            opacity: 1;
          }
        }
      }
    }

  }
`;

export { DropDown };
export default DropDown;
