import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { ModalWrapperNew } from 'components/modal/modal-wrapper-new';
import { ConcatUnitInUrl } from 'utils';
import {
  HeaderClose,
  Text,
  RadioButton,
  Button,
} from 'components';

const WrapperContent = styled.div`
  padding: 24px 24px;

  .wrapper-bottom-pan {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`;

const PlanTotalPass = (props) => {
  const [namePlan, setNamePlan] = useState('client-smartfit');
  const context = useContext(Context);
  const {
    modalStore,
    peopleStore,
    appStore,
    i18n,
  } = context;
  const {
    history,
  } = props;

  const closeCallback = () => {
    modalStore.toggleModal('modalTotalPass', false);
  };

  const clickPlan = () => {
    if (namePlan === 'client-totalpass') {
      peopleStore.setPlanTotalPass();
    } else {
      peopleStore.setPlanSmartFit();
    }

    const unit = ConcatUnitInUrl('/profile');
    history.push(unit);
    appStore.toggleloading(false);
    appStore.clearObjTemp();
    modalStore.toggleModal('modalTotalPass', false);
  };

  const onChangeValue = (e) => {
    setNamePlan(e.target.value);
  };

  return (
    <ModalWrapperNew>
      <HeaderClose onClick={closeCallback} title={i18n.t('two_agreements')} />
      <WrapperContent>
        <Text color="black">{i18n.t('question_title_plan')}</Text>

        <div className="wrapper-radio" onChange={onChangeValue}>
          <RadioButton
            title="Smart Fit"
            name="company"
            value="client-smartfit"
            subTitle={i18n.t('plan_smart')}
            status
            isActive={namePlan}
          />
          <RadioButton
            title="TotalPass"
            name="company"
            value="client-totalpass"
            subTitle={i18n.t('plan_total_pass')}
            isActive={namePlan}
          />
        </div>

        <div className="wrapper-bottom-pan">
          <Button
            primary="true"
            large="true"
            className="w-btn-primary"
            onClick={clickPlan}
          >
            {i18n.t('confirm')}
          </Button>
        </div>
      </WrapperContent>
    </ModalWrapperNew>
  );
};

PlanTotalPass.defaultProps = {
  history: {},
};

PlanTotalPass.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default PlanTotalPass;
