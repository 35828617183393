import styled from 'styled-components';
import {
  Button as TextButton,
} from 'rebass';

const TextButtonRed = styled(TextButton)`
  color: ${(props) => props.theme.colors.red};
`;

export default TextButtonRed;
