import {
  observable, action, decorate,
} from 'mobx';

import {
  IwalletUnityStore,
} from './IwalletUnityStore';

import {
  ListExerciseCreateTeacher,
} from '../IgenericTypes';

import {
  getListExerciseWallet as getListExerciseWalletApi,
} from '../../services/api';

class WalletUnityStore implements IwalletUnityStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    filterWorkoutStore: {
      getListExercise: (e: any) => e,
      getListParams: (e: any): string => e,
    },
    i18n: {
      t: (x: any) => x,
    },
    appStore: {
      toggleloading: (x: boolean) => x,
    },
    teacherTrainingStore: {
      programId: 0,
    },
  };

  listWorkoutUnity: ListExerciseCreateTeacher[] = [];

  listWorkoutWAlletView: ListExerciseCreateTeacher[] = [];

  finishListSuggestionWallet = false;

  limitRender = 5;

  limitLoop = 1;

  currentWorkout = 0

  reloadListExercise = false;

  queryParamsFilter = '';

  finishRequest = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  resetListUnity() {
    this.listWorkoutUnity = [];
  }

  plusWorkout() {
    this.resetView();
    this.rootStore.appStore.toggleloading(true);
    this.setReloadListExercise(true);
  }

  resetView() {
    this.listWorkoutWAlletView = [];
    this.currentWorkout = 0;
    this.limitLoop = 1;
    this.finishListSuggestionWallet = false;
  }

  setReloadListExercise(toggle: boolean) {
    this.reloadListExercise = toggle;
  }

  doRequestListWorkoutUnity(params: any) {
    return new Promise((resolve, reject) => this.promiseListWorkoutUnity(params, resolve, reject));
  }

  promiseListWorkoutUnity = async (paramsRequest: any, resolve: any, reject: any) => {
    const queryParamsFilter = this.rootStore.filterWorkoutStore.getListParams({});
    const params = {
      id: paramsRequest.id,
      queryString: `&page=${this.limitLoop}${queryParamsFilter}`,
    };

    try {
      this.finishRequest = false;
      const res = await getListExerciseWalletApi(params, this.rootStore.sessionStore.token);
      this.renderView(res.data.weight_trainings);
      resolve(res.data.teacher_weight_trainings);
    } catch (err) {
      reject(err);
    }
  }

  statusFinishListExercise(toggle: boolean): void {
    this.finishListSuggestionWallet = toggle;
  }

  makeTrainingGroup(list: string[]): string {
    const initial: string = '';
    if (list.length <= 0) return '';

    const label = list.reduce((initial: string, nextValue: string) => {
      return initial += `${nextValue}/`;
    }, initial);

    return label.slice(0, label.length - 1);
  }

  intensificationAndRestriction(currentWorkout: ListExerciseCreateTeacher): string {
    const intensificationMethod = currentWorkout.intensification_method;
    const trainingHealthRestriction = currentWorkout.training_health_restriction;
    const labelIntensification = this.rootStore.i18n.t('intensification');
    const labelRestriction = this.rootStore.i18n.t('restriction');

    if (intensificationMethod && trainingHealthRestriction) {
      return `- ${labelIntensification} - ${labelRestriction}`;
    }

    if (intensificationMethod) {
      return `- ${labelIntensification} -`;
    }

    if (trainingHealthRestriction) {
      return `- ${labelRestriction} -`;
    }

    return '-';
  }

  bodyEmphasis(currentWorkout: ListExerciseCreateTeacher): string {
    if(currentWorkout.body_emphasis && currentWorkout.body_emphasis !== '') {
      return `- ${currentWorkout.body_emphasis}`;
    }

    return '';
  }

  makeDescription(workout: ListExerciseCreateTeacher): string {
    const labelGroup = this.makeTrainingGroup(workout.training_group_labels);
    const intensificationAndRestriction = this.intensificationAndRestriction(workout);
    const labelBodyEmphasis = this.bodyEmphasis(workout);
    const nameAndLabel = `${workout.level_name} - ${labelGroup}`;
    const label = `${nameAndLabel} ${intensificationAndRestriction} ${workout.frequency}x ${labelBodyEmphasis}`;
    return label;
  }

  renderView(listWorkout: ListExerciseCreateTeacher[]) {
    if (listWorkout.length > 0) {
      listWorkout.map((workout) => {
        const currentWorkout = workout;
        currentWorkout.description = this.makeDescription(workout);
        this.listWorkoutWAlletView.push(currentWorkout);
        return currentWorkout;
      });

      this.limitLoop += 1;
      this.finishRequest = true;
    } else {
      this.statusFinishListExercise(true);
    }
  }
}

decorate(WalletUnityStore, {
  listWorkoutUnity: observable,
  finishListSuggestionWallet: observable,
  listWorkoutWAlletView: observable,
  reloadListExercise: observable,
  setReloadListExercise: action,
  doRequestListWorkoutUnity: action,
  resetView: action,
  plusWorkout: action,
});

export default WalletUnityStore;
