import React from 'react';
import {
  ResponsiveContainer, LineChart, Line, XAxis, Tooltip,
} from 'recharts';

const CustomTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload !== null) {
    const style = {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '10px',
    };

    return (
      <div style={style}>
        <p>
          {payload[0].value}
          {' '}
          kg
        </p>
      </div>
    );
  }

  return null;
};

const Graphic = (props) => {
  const {
    data,
  } = props;

  const styleCssWrapper = {
    width: '100%',
    height: '350px',
    backgroundColor: '#000',
    marginTop: '40px',
  };

  const styleLineChart = {
    top: 30,
    right: 20,
    left: 20,
    bottom: 40,
  };

  const styleActiceDot = {
    r: 10,
    stroke: '#fff',
    fill: '#fff',
  };

  const styleDot = {
    stroke: '#ffb612',
    strokeWidth: 20,
  };

  return (
    <div style={styleCssWrapper}>
      <ResponsiveContainer>
        <LineChart
          width={400}
          height={400}
          data={data}
          margin={styleLineChart}
        >
          <XAxis dataKey="date" padding={{ left: 30 }} stroke="#fff" />
          <Tooltip content={<CustomTooltip />} />
          <Line
            yAxisId={0}
            dataKey="weightLoad"
            dot={styleDot}
            stroke="#ffb612"
            strokeWidth={3}
            activeDot={styleActiceDot}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graphic;
