import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import Item from './Item';

const GridExercises = styled.div`
  width: 100%;
`;

const List = (props) => {
  const context = useContext(Context);
  const { buildTrainingStore } = context;
  const { exercises, show } = props;

  if (!show) {
    return false;
  }

  const orderExercise = buildTrainingStore.orderListExerciseSuggestion(exercises);
  const listItems = orderExercise.map(exercise => <Item key={exercise.id} exercise={exercise} />);

  return (
    <GridExercises>
      {listItems}
    </GridExercises>
  );
};

export default List;
