import React, { useContext, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModalFull from '../../components/modal/modal-wrapper-full';
import Context from '../../stores/context';
import Header from './ModalAddExercises/Header';
import Footer from './ModalAddExercises/Footer';
import Content from './ModalAddExercises/Content';
import ModalNetwork from '../../components/modal/modal-network';

const WrapperContent = styled.div`
  box-sizing: border-box;
  padding-bottom: 100px;
  margin-top: 30px;
`;

const ModalAddExercise = (props) => {
  const { history } = props;
  const context = useContext(Context);
  const {
    addExercisesStore,
    programStore,
    modalStore,
    toggleStore,
  } = context;

  const [request, setRequest] = useState(false);

  useEffect(() => {
    const callExerciseList = async () => {
      let data;
      try {
        await addExercisesStore.doRequestGetAllExercisesNew();
        modalStore.closeModalError();
        setRequest(true);
      } catch (err) {
        modalStore.showModalError(err);
        setRequest(true);
      }

      return data;
    };

    if (!request && programStore.levelId) {
      callExerciseList();
    }
  }, [
    addExercisesStore,
    programStore,
    request,
    modalStore,
    toggleStore,
  ]);

  const modalNetwork = modalStore.modalNetwork && (
    <ModalNetwork
      tryAgain={() => {
        setRequest(false);
      }}
    />
  );

  return (
    <div>
      <ModalFull>
        <Header />
        <WrapperContent>
          <Content />
        </WrapperContent>
        <Footer history={history} />
        {modalNetwork}
      </ModalFull>
    </div>
  );
};

ModalAddExercise.defaultProps = {
  history: {},
};

ModalAddExercise.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(ModalAddExercise);
