import React, { useContext } from 'react';
import Context from 'stores/context';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ItemPais from './ItemPais';

function EmphasisPais(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
    trainingCreationStore,
  } = context;

  const {
    hasError,
  } = props;

  const focusEmphasis = i18n.t(trainingCreationStore.emphasis);
  if (trainingCreationStore.emphasis === 'full_body') return false;

  const textAmountExercise = () => (
    <Trans
      i18nKey="dropdown_pais_emphasis"
      values={{
        emphasis: focusEmphasis,
      }}
      count={rulesPaisStore.dropDownPais.emphasis}
      components={[<span className="color-white">*</span>]}
    />
  );

  return (
    <ItemPais
      rule={textAmountExercise()}
      checked={hasError}
      expect={i18n.t('dropdown_pais_dropdown_pais_emphasis_expect')}
    />
  );
}

EmphasisPais.defaultProps = {
  hasError: false,
};

EmphasisPais.propTypes = {
  hasError: PropTypes.bool,
};

export default observer(EmphasisPais);
