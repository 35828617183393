import styled, { css } from 'styled-components';

const BoxListInfoTraining = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 16px 16px;
  width: 100%;
  border-radius: 4px;

  .title-box-list-info {
    color: ${(props) => props.theme.colors.black};
    text-transform: uppercase;
    font-size: ${(props) => props.theme.fontSizes[1]};
    margin-bottom: 5px;
  }

  .wrapper-list {
    list-style-type: ${(props) => props.listStyleType || 'none'};

    ${(props) => props.listStyleType
      && css`
        padding: 0px 16px;
      `};

    .item {
      margin-top: 5px;

      .item-size {
        text-transform: uppercase;
      }
    }

    .item:first-child {
      margin-top: 0px;
    }
  }
`;

export default BoxListInfoTraining;
export { BoxListInfoTraining };
