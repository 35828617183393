import RulesPaisHelper from './RulesPaisHelper';

import {
  typeDataPremissa,
  typeReturnError,
  typeTrainingDurations,
  typeAmountExerciseEmphasis
} from './IrulesPaisStore';

import {
  Training,
  WeightTrainingExercises,
} from '../IgenericTypes';

class RulesPaisListExercise {
  rootStore = {
    buildTrainingStore: {
      currentTraining: {
        weight_training_exercises: [
          {
            cardio: false,
            exercise_material: '',
          },
        ],
      },
    },
    trainingCreationStore: {
      dateFinishCurrent: 0,
    },
    i18n: {
      t: (x: string, y?: any): string => '',
    },
  };

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  static getAmountExercise(data: Training, currentRules: typeDataPremissa): number {
    const trainingCurrent = RulesPaisHelper.removeExercisesRules(data, currentRules);
    const amountExercise = trainingCurrent.length;

    return amountExercise;
  }

  static getAmountAllExerciseList(data: Training, currentRules: typeDataPremissa): number {
    const listExercises = RulesPaisHelper.removeExercisesRules(data, currentRules);
    return listExercises.length;
  }

  static getAmountSeriesTraining(training: Training, currentRules: typeDataPremissa): number {
    const trainingCurrent = RulesPaisHelper.removeExercisesRules(training, currentRules);

    return trainingCurrent
      .reduce((initial: number, exercise: WeightTrainingExercises) => {
        if (exercise.series) {
          return initial + exercise.series;
        }

        return initial;
      }, 0);
  }

  static getRulesDuration(
    currentRules: typeDataPremissa,
    sizeTrainingSelect: string | null,
    listToggleShowRulesPremissas: string[]) {

    const objDefault = {
      duration: '',
      min: 0,
      max: 0
    };

    const hasRulesList = listToggleShowRulesPremissas.includes('size_training');
    if(hasRulesList === false) return objDefault;
    if(sizeTrainingSelect === null || currentRules.training_durations.length <= 0) return objDefault;
    const rulesSizeTraing: typeTrainingDurations[] = currentRules.training_durations.filter((objSizeTraining) => {
      return objSizeTraining.duration === sizeTrainingSelect;
    });

    return rulesSizeTraing[0];
  }

  static validateSizeTraining(
    currentRules: typeDataPremissa,
    sizeTrainingSelect: string | null,
    training: Training,
    checkTabTraining: boolean[],
    listWarningErrosExercise: string[],
    listToggleShowRulesPremissas: string[],
    ) {
    const duration = RulesPaisListExercise.
    getRulesDuration(
      currentRules,
      sizeTrainingSelect,
      listToggleShowRulesPremissas,
    );

    const hasRulesList = listToggleShowRulesPremissas.includes('size_training');
    const minExercise = RulesPaisHelper.convertToNumber(duration.min);
    const maxExercise = RulesPaisHelper.convertToNumber(duration.max);
    const newListExercise = RulesPaisHelper.removeExercisesRules(training, currentRules);
    const lengtExercise = newListExercise.length;
    const resultSizeTraining = (lengtExercise <= maxExercise && lengtExercise >= minExercise);

    if(hasRulesList && resultSizeTraining === false) {
      listWarningErrosExercise.push('size_training');
      checkTabTraining.push(false);
    }
  }

  static validateRulesAmountExercise(training: Training,
      dataRules: typeDataPremissa,
      checkTabTraining: boolean[],
      listWarningErrosExercise: string[],
      listToggleShowRulesPremissas: string[]) {
    const hasAmountPremissa = RulesPaisHelper.hasAmountExercise(training, dataRules);
    const hasRulesList = listToggleShowRulesPremissas.includes('number_of_exercise');

    if (hasAmountPremissa === false && hasRulesList) {
      listWarningErrosExercise.push('number_of_exercise');
      checkTabTraining.push(false);
    }
  }

  // Serie maxima por treino
  static validateAmountSeriesTraining(
    training: Training,
    dataRules: typeDataPremissa,
    checkTabTraining: boolean[],
    listWarningErrosExercise: string[],
    listToggleShowRulesPremissas: string[]) {
    const hasAmountSeriesTraining = RulesPaisHelper.getAmountSeriesTraining(training, dataRules);
    const hasRulesList = listToggleShowRulesPremissas.includes('serie_of_training');

    if (hasAmountSeriesTraining === false && hasRulesList) {
      listWarningErrosExercise.push('serie_of_training');
      checkTabTraining.push(false);
    }
  }

  static validateAmountExerciseEmphasis(
    checkTabTraining: boolean[],
    listWarningErrosExercise: string[],
    rulesPaisGlobalTraining: any,
    emphasis: string,
    listToggleShowRulesPremissas: string[],
  ) {
    if(emphasis === 'full_body') {
      const index = listToggleShowRulesPremissas.indexOf('emphasis');
      if(index >= 0) {
        listToggleShowRulesPremissas.splice(index, 1);
      }
      return;
    }

    const hasError = rulesPaisGlobalTraining.getWarningErros();
    const hasRulesList = listToggleShowRulesPremissas.includes('emphasis');

    if(hasError && hasRulesList) {
      listWarningErrosExercise.push('emphasis');
      checkTabTraining.push(false);
    }
  }

  static removeRulesToCountry(toggleStore: any, listToggleShowRulesPremissas: string[]) {
    const hasEmphasis = toggleStore.allowRenderCountry('toggleEmphasisTraining');
    const hasSizeTraining = toggleStore.validateToggle({
      toggleUnitAllow: 'allowShowTrainingSizeUnit',
      toggleCountry: 'allowShowTrainingSizeCountry',
    });

    if(hasSizeTraining === false) {
      const index = listToggleShowRulesPremissas.indexOf('size_training');
      if(index >= 0) {
        listToggleShowRulesPremissas.splice(index, 1);
      }
    }

    if(hasEmphasis === false) {
      const index = listToggleShowRulesPremissas.indexOf('emphasis');
      if(index >= 0) {
        listToggleShowRulesPremissas.splice(index, 1);
      }
    }
  }

  static setRulesToListExercise(
    emphasis: string,
    sizeTrainingSelect: string,
    training: Training,
    dataRules: typeDataPremissa,
    checkTabTraining: boolean[],
    listWarningErrosExercise: string[],
    listToggleShowRulesPremissas: string[],
    toggleStore: any,
    rulesPaisGlobalTraining: any,
    ) {
      RulesPaisListExercise.removeRulesToCountry(
        toggleStore,
        listToggleShowRulesPremissas
      );

      RulesPaisListExercise.validateRulesAmountExercise(
        training,
        dataRules,
        checkTabTraining,
        listWarningErrosExercise,
        listToggleShowRulesPremissas,
      );

      RulesPaisListExercise.validateAmountSeriesTraining(
        training,
        dataRules,
        checkTabTraining,
        listWarningErrosExercise,
        listToggleShowRulesPremissas,
      );

      RulesPaisListExercise.validateSizeTraining(
        dataRules,
        sizeTrainingSelect,
        training,
        checkTabTraining,
        listWarningErrosExercise,
        listToggleShowRulesPremissas,
      );

      RulesPaisListExercise.validateAmountExerciseEmphasis(
        checkTabTraining,
        listWarningErrosExercise,
        rulesPaisGlobalTraining,
        emphasis,
        listToggleShowRulesPremissas
      );
    }

  showRulesDate(currentRules: typeDataPremissa): typeReturnError | boolean {
    const { dateFinishCurrent } = this.rootStore.trainingCreationStore;
    const hasCurrent = !!(currentRules && currentRules.min_days_expire_training);
    if (hasCurrent === false) return false;

    const days = currentRules.min_days_expire_training;
    const checked = (dateFinishCurrent === days);
    const dateMin = this.rootStore.i18n.t('days', { days });

    const label = this.rootStore.i18n.t('recommended_deadline');
    const id = 'dateExpire';
    const title = this.rootStore.i18n.t('training_exchange');

    const warning = RulesPaisHelper.listDefaultWarningComponent(label, dateMin);

    return RulesPaisHelper.getTratamentRulesPais({
      id, title, warning, checked,
    });
  }

  getInfoListWarningPais(data: typeDataPremissa): typeReturnError[] | any[] {
    return [
      this.showRulesDate(data),
    ];
  }
}

export default RulesPaisListExercise;
