import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Anamnese, Title, SubTitle, Button,
} from 'components';
import { ConcatUnitInUrl } from 'utils';

import Context from '../../stores/context';
import InfoEmphasis from './component/InfoEmphasis';

function Emphasis({ history }) {
  const context = useContext(Context);

  const {
    i18n,
    trainingCreationStore,
  } = context;

  const nextPage = () => {
    const url = ConcatUnitInUrl('/training-creation/frenquency');
    history.push(url);
  };

  const setEmphasis = (value) => {
    trainingCreationStore.setEmphasis(value);
  }

  const disabledButton = (value) => trainingCreationStore
    .emphasis !== '' && trainingCreationStore.emphasis !== value;

  const renderListOption = () => trainingCreationStore.dataBodyEmphasis.map((option) => (
    <Button
      reponse
      key={option.value}
      onClick={() => setEmphasis(option.value)}
      variant={trainingCreationStore.emphasis === option.value}
      disabledButton={disabledButton(option.value)}
      disabled={disabledButton(option.value)}
    >
      {option.label}
    </Button>
  ));

  const disableButtonNext = () => trainingCreationStore.emphasis === '';

  return (
    <Anamnese>
      <Title type={1} as="h2" subTitleColor="#fff">
        {i18n.t('emphasis_title')}
        <SubTitle fontWeight="500">{i18n.t('emphasis_subtitle')}</SubTitle>
      </Title>

      <div className="w-option-lesion">
        {renderListOption()}
      </div>

      <div className="next-frequency">
        <Button
          reponse
          onClick={nextPage}
          disabled={disableButtonNext()}
        >
          { i18n.t('anamnesis_question_next')}
        </Button>
      </div>
      <InfoEmphasis />
    </Anamnese>
  );
}

Emphasis.defaultProps = {
  history: {},
};

Emphasis.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(Emphasis);
