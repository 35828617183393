import styled, { css } from 'styled-components';
import { Button } from 'rebass';

const ButtonCircle = styled(Button)`
  display: flex;
  width: auto;
  text-transform: uppercase;
  border-radius: 0px;

  cursor: pointer;
  border-radius: 30px;
  border: 1px solid ${(props) => props.theme.colors.yellow};
  width: ${(props) => props.width || 'auto'};
  font-size: 16px;
  line-height: initial;
  align-items: center;
  margin: 10px 10px;

  .text-button-register {
    margin-left: 40px;
  }

  .icon-plus {
    position: absolute;
    left: 9px;
    top: -2px;
  }

  &.w-active {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
    border: 0px;
  }

  ${(props) => props.wallet === false
    && css`
      padding: ${(props) => props.padding || '15px 16px 15px 16px'};
    `};

  ${(props) => props.wallet
    && css`
      margin: 0px;
      height: 40px;
    `};
`;

export { ButtonCircle };
export default ButtonCircle;
