import React, { Component } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import PropTypes from 'prop-types';

import {
  HeaderSession,
  NewGridContainer as Container,
  Title,
  Button,
  DataPeopleWorkout,
  BuildTrainingActivitiesStyle,
  ButtonDropDown,
} from 'components';

import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';

import NewTrainingSuggestion from './buildTrainingSend/NewTrainingSuggestion';
import CommentTraining from './buildTrainingSend/CommentTraining';
import DateWorkout from './buildTrainingSend/DateWorkout';
import ModalDateTraining from './BuildingTrainingList/DateTraining/ModalDateTraining';
import ModalSuccessSend from './buildTrainingSend/ModalSuccessSend';
import ModalErrorPremissas from './BuildingTrainingList/ModalErrorPremissas/ModalErrorPremissas';
import ModalSuccessPremissas from './BuildingTrainingList/ModalSuccessPremissas/ModalSuccessPremissas';

const BuildTrainingSendStyled = styled.div`
  margin-top: -28px;

  .wrapper-header {
    padding-left: 24px;
    padding-right: 24px;
    background-color: ${props => props.theme.colors.darkgray};
    padding-top: 15px;
    padding-bottom: 16px;
  }

  @media(${props => props.theme.mediaScreen.cellphone}) {
    wrapper-header {
      height: 100px;
    }
  }

  .wrapper-content-workout-send {
    margin-top: 20px;
  }

  .wrapper-bottom {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

class BuildTrainingSend extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.eventButtonHeader = this.eventButtonHeader.bind(this);
    this.setTextObservationExercise = this.setTextObservationExercise.bind(this);
    this.sendNewTraining = this.sendNewTraining.bind(this);
    this.onClickToSubmit = this.onClickToSubmit.bind(this);
    this.updateTraining = this.updateTraining.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.postSendGa = this.postSendGa.bind(this);
  }

  state = {
    trainingComment: false,
  };

  componentWillMount() {
    const {
      buildTrainingStore,
      peopleStore,
    } = this.context;

    if (buildTrainingStore.templateSuggestionSend === false) {
      this.setState({ trainingComment: peopleStore.observationAndRestriction });
    }
  }

  allowRenderPais() {
    const {
      toggleStore,
    } = this.context;

    return toggleStore.validateToggle({
      toggleUnitBlock: 'toogleUnitPaisBlock',
      toggleUnitAllow: 'toogleUnitPaisAllow',
      toggleCountry: 'allowPaisCountry',
    });
  }

  onClickToSubmit() {
    const {
      buildTrainingStore,
      trainingCreationStore,
    } = this.context;
    const template = buildTrainingStore.templateSuggestionSend;
    const { paisPremisses } = trainingCreationStore;

    if (template) {
      this.sendNewTraining();
    } else if(paisPremisses && this.allowRenderPais()) {
      this.getRulesPremissas();
    } else {
      this.postSendGa();
      this.updateTraining();
    }
  }

  getValueAnalytics() {
    const { buildTrainingStore } = this.context;

    const tab = buildTrainingStore.analyticsTypeTrainingSuggestion;

    if (tab === 'my_training') {
      return 'send_my_training_workout_without_editing';
    }

    if (tab === 'training_wallet') {
      return 'send_training_wallet_workout_without_editing';
    }

    return 'send_suggestion_workout_with_editing';
  }


  setTextObservationExercise(e) {
    this.setState({ trainingComment: e.currentTarget.value });
  }

  getTextObservation() {
    const { trainingCreationStore, peopleStore, buildTrainingStore } = this.context;
    const { trainingComment } = this.state;

    if (trainingComment === false || trainingComment === null) {
      if (buildTrainingStore.resendTraining) {
        return peopleStore.trainingObservation;
      }

      return trainingCreationStore.trainingObservation;
    }

    return trainingComment;
  }

  getRulesPremissas() {
    const {
      buildTrainingStore,
      rulesPaisStore,
      modalStore,
      cronometerStore,
    } = this.context;

    const hasErrorSend = buildTrainingStore.hasErrorInSendPremissa();
    const hasWarningPraze = rulesPaisStore.hasErrorCheckedPraze();

    if (hasErrorSend || hasWarningPraze) {
      modalStore.toggleModal('modalErrorPremissas', true);
    } else {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:premissas',
        eventAction: 'click:table',
        eventLabel: 'send_premissas_ok_workout_without_editing',
        timeFishExercise: `${cronometerStore.getTimeFinishExercise()}`,
        fluxAnamnese: cronometerStore.fluxAnamnese,
        acronym: window.localStorage.getItem('w-acronym'),
      });

      this.updateTraining();
    }
  }

  postSendGa() {
    const { buildTrainingStore, cronometerStore } = this.context;
    const tab = buildTrainingStore.analyticsTypeTrainingSuggestion;

    if (tab === '') {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:newsendtraining',
        eventAction: 'click:table',
        eventLabel: 'send_training',
        acronym: window.localStorage.getItem('w-acronym'),
        fluxAnamnese: cronometerStore.fluxAnamnese,
      });
    } else {
      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:newsendedittraining',
        eventAction: 'click:table',
        eventLabel: 'send_training',
        acronym: window.localStorage.getItem('w-acronym'),
        timeFishExercise: `${cronometerStore.getTimeFinishExercise()}`,
        editTemplate: buildTrainingStore.analyticsTypeTrainingSuggestion,
      });
    }
  }

  updateTraining() {
    const {
      trainingCreationStore,
      cronometerStore,
      appStore,
      modalStore,
    } = this.context;

    cronometerStore.stop();

    appStore.toggleloading(true);

    /*
      O backend permite a edicao da data se for enviado
      um novo valor, então dentro do contrato sempre passo
      null assim ele não modifica a data na hora da edição
    */
    const data = {
      body: {
        weight_training: {
          observation: this.getTextObservation(),
          finish_date_days: trainingCreationStore.dateFinishCurrent,
        },
      },
      weightTrainingId: trainingCreationStore.weightTrainingId,
    };

    trainingCreationStore
      .doRequestUpdateTraining(data)
      .then(() => {
        this.sync();
      })
      .catch((error) => {
        appStore.toggleloading(false);
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(this.onClickToSubmit);
        modalStore.showModalError(error);

      });
  }

  sendNewTraining() {
    const {
      modalStore,
      cronometerStore,
      appStore,
      trainingCreationStore,
      peopleStore,
      buildTrainingStore,
    } = this.context;

    const dataEventLabel = this.getValueAnalytics();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:newsuggestion',
      eventAction: 'click:table',
      eventLabel: dataEventLabel,
      timeFishExercise: `${cronometerStore.getTimeFinishExercise()}`,
      acronym: window.localStorage.getItem('w-acronym'),
      fluxAnamnese: cronometerStore.fluxAnamnese,
    });

    cronometerStore.stop();

    appStore.toggleloading(true);

    const data = {
      body: {
        weight_training: {
          observation: this.getTextObservation(),
          finish_date_days: trainingCreationStore.dateFinishCurrent,
          original_weight_training_id: buildTrainingStore.suggestionWorkoutBuild.id,
        },
      },
      peopleId: peopleStore.peopleId,
    };

    trainingCreationStore
      .doRequestCreateSuggestionTraining(data)
      .then(() => {
        modalStore.closeModalError();
        this.sync();
      }).catch((error) => {
        appStore.toggleloading(false);
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(this.onClickToSubmit);
        modalStore.showModalError(error);
      });

    modalStore.closeModalError();
  }

  sync() {
    const {
      buildTrainingStore,
      modalStore,
      appStore,
      trainingCreationStore,
    } = this.context;

    buildTrainingStore
      .doRequestCoachSync(trainingCreationStore.weightTrainingId)
      .then(() => {
        if (buildTrainingStore.resendEmail) {
          this.sendEmail();
        } else {
          this.messageSucced();
        }
      })
      .catch((error) => {
        appStore.toggleloading(false);
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(this.onClickToSubmit);
        modalStore.showModalError(error);
      });
  }

  sendEmail() {
    const {
      buildTrainingStore,
      trainingCreationStore,
      modalStore,
      appStore,
    } = this.context;

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:current',
      eventAction: 'click:button',
      eventLabel: 'send_email',
    });

    buildTrainingStore
      .doRequestTrainingEmail(trainingCreationStore.weightTrainingId)
      .then(() => {
        this.messageSucced();
      })
      .catch((error) => {
        appStore.toggleloading(false);
        window.scroll(0, 0);
        modalStore.setModalNetworkCallback(this.onClickToSubmit);
        modalStore.showModalError(error);
      });
  }

  messageSucced() {
    const {
      buildTrainingStore,
      modalStore,
      i18n,
      appStore,
    } = this.context;

    const msgTitle = i18n.t('updated_with_success');
    const msgDescription = '';
    buildTrainingStore.setEditSuggestionSend(false);
    buildTrainingStore.setResendTraining(false);
    modalStore.setTitle(msgTitle);
    modalStore.setDescription(msgDescription);
    appStore.toggleloading(false);
    modalStore.toggleModal('modalSuccessSend', true);
    modalStore.closeModalError();

    if (buildTrainingStore.toggleAllowEditDateTraining) {
      buildTrainingStore.setToggleAllowEditDateTraining(false);
    }

    if (buildTrainingStore.resendEmail) {
      buildTrainingStore.setResendEmail(false);
    }
  }

  eventButtonHeader() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      sessionStore,
      i18n,
      peopleStore,
      modalStore,
      trainingCreationStore,
      rulesPaisStore,
      buildTrainingStore,
    } = this.context;
    const { history } = this.props;

    if (trainingCreationStore.paisPremisses) {
      rulesPaisStore.setWarningListExercise();
    }

    const ModalSuccess = modalStore.modalSuccessSend && (
      <ModalSuccessSend
        history={history}
        closeModal={() => {
          modalStore.toggleModal('modalSuccessSend', false);
          modalStore.toggleModal('modalSuccessEdit', false);
          const url = ConcatUnitInUrl('/profile');
          buildTrainingStore.setTemplateSuggestionSend(false);
          history.push(url);
        }}
        redirectToSaveTraining={() => {
          const urlLevelChoose = ConcatUnitInUrl('/teacher-training-creation');
          history.push(urlLevelChoose);
        }}
      />
    );

    return (
      <BuildTrainingSendStyled>
        <header className="wrapper-header">
          <HeaderSession
            statusActionButton
            actionButton
            isBack
            eventButton={this.eventButtonHeader}
            nameClass="w-btn-back"
            title={sessionStore.renderListNavigator(i18n.t('profile_title'))}
            subTitle={peopleStore.peopleName.split(' ')[0]}
          >
            <BuildTrainingActivitiesStyle className="wrapper-active">
              <div className="wrapper-buttons">
                <ButtonDropDown className="send-email">
                  <li
                    onClick={() => {
                      buildTrainingStore.setResendEmail(true);
                      this.onClickToSubmit();
                    }}
                    role="presentation"
                  >
                    {i18n.t('post_email')}
                  </li>
                </ButtonDropDown>
              </div>

              <Button
                primary="true"
                className="post-training"
                onClick={this.onClickToSubmit}
                minWidth="initial"
              >
                {i18n.t('send')}
              </Button>
            </BuildTrainingActivitiesStyle>

          </HeaderSession>

        </header>

        <Container mgTop="20px" className="wrapper-content-workout-send">
          <Title type={1} as="h2">
            {i18n.t('title_buiding_training')}
          </Title>
          <NewTrainingSuggestion />
          <DataPeopleWorkout />
          <CommentTraining setTextObservationExercise={this.setTextObservationExercise} />
          <DateWorkout />
        </Container>

        {modalStore.modalError && <ModalError />}
        {modalStore.modalNetwork && <ModalNetwork />}
        {modalStore.modalDateTraining && <ModalDateTraining saveCache />}
        {ModalSuccess}
        {modalStore.modalErrorPremissas && <ModalErrorPremissas updateTraining={this.updateTraining} />}
        {modalStore.modalSuccessPremissas && <ModalSuccessPremissas history={history} />}
      </BuildTrainingSendStyled>
    );
  }
}

BuildTrainingSend.defaultProps = {
  history: {},
};

BuildTrainingSend.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(BuildTrainingSend);
