import React from 'react';
import PropTypes from 'prop-types';
import {
  ContainerStyle,
} from 'components';
import { TrainingEvaluation } from 'views/Profile/Trainings/TrainingEvaluation';
import DateExpirationTraining from './DateExpirationTraining';
import ButtonSeeTraining from './ButtonSeeTraining';

function DataTraining(props) {
  const {
    history,
    hasScore,
    evaluation,
    activeBorder,
  } = props;

  return (
    <ContainerStyle flexWrap="wrap" activeBorder={activeBorder}>
      <ContainerStyle flexWrap="nowrap">
        <DateExpirationTraining />
        <ButtonSeeTraining history={history} />
      </ContainerStyle>

      {hasScore && <TrainingEvaluation evaluation={evaluation} />}

    </ContainerStyle>
  );
}

DataTraining.defaultProps = {
  history: {},
  currentTraining: {},
  hasScore: false,
  evaluation: {},
  activeBorder: false,
};

DataTraining.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  currentTraining: PropTypes.shape({
    finish_date: '',
  }),
  hasScore: PropTypes.bool,
  evaluation: PropTypes.shape({
    satisfaction_score: null,
    satisfaction_chips: null,
  }),
  activeBorder: PropTypes.bool,
};

export { DataTraining };
export default DataTraining;
