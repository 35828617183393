import styled from 'styled-components';
import MenuSVG from './menu-hamburguer.svg';

const DragButton = styled.div`
  display: inline-flex;
  width: 28px;
  height: 20px;
  border-width: 0;
  background-color: transparent;
  background-image: url(${MenuSVG});
  background-repeat: no-repeat;
  background-position: center;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

export { DragButton };
export default DragButton;
