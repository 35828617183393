class PreLoadingRulesFilter {
  static getFrequency(frequency: number): string {
    if (frequency === 1) {
      return 'A';
    }

    if (frequency === 2) {
      return 'A/B';
    }

    if (frequency === 3) {
      return 'A/B/C';
    }

    if (frequency >= 4) {
      return 'A/B/C/D';
    }

    return '';
  }
}

export default PreLoadingRulesFilter;
