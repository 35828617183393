import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';

const ItemLocation = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    modalStore,
    appStore,
    bookingsStore,
    locationStore,
  } = context;

  const {
    location,
  } = props;

  const changeLocation = async () => {
    appStore.toggleloading(true);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:location',
      eventAction: 'click:button',
      eventLabel: 'location_selection_acronym',
    });

    try {
      await bookingsStore.doRequestBookings(location.acronym);
      locationStore.setNameLocation(location.name);
      locationStore.setAcronymSelected(location.acronym);
      appStore.toggleloading(false);
      locationStore.setShowModalLocation(false);
    } catch (err) {
      locationStore.setShowModalLocation(false);
      const msgTitle = i18n.t('error_bookings');
      modalStore.setTitle(msgTitle);
      modalStore.showModalError(err);
      appStore.toggleloading(false);
    }
  };

  return (
    <li className="list__notices locations" aria-hidden="true" onClick={() => changeLocation()}>
      <div className="list__notices__content">
        <h3 className="list__notices__title">{location.name}</h3>
      </div>
      <div className="list__notices__warning">
        <span className="list__notices__warning--arrow" />
      </div>
    </li>
  );
};

ItemLocation.defaultProps = {
  location: {
    name: '',
    acronym: '',
  },
};

ItemLocation.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    acronym: PropTypes.string,
  }),
};

export default ItemLocation;
