import React, { Component } from 'react';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';

import {
  ProfileGlobalStyle,
  ProfileInfoModal,
  Loader,
  ErrorMessage,
} from 'components';

class ProfileGlobal extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.getDataProfile = this.getDataProfile.bind(this);
    this.eventFunctionError = this.eventFunctionError.bind(this);
  }

  state = {
    showError: false,
    loading: false,
  };

  componentWillMount() {
    this.getDataProfile();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:button',
      eventLabel: 'profile_workout_summary',
    });
  }

  getDataProfile() {
    const {
      peopleStore,
    } = this.context;

    this.setState({
      loading: true,
    });

    peopleStore
      .doRequestPeopleShow({ id: peopleStore.peopleId, updateIdTraining: false })
      .then(() => {
        this.setState({
          showError: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          showError: true,
          loading: false,
        });
      });
  }

  eventFunctionError() {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:button',
      eventLabel: 'try_again_profile',
    });

    this.getDataProfile();
  }

  render() {
    const { peopleStore, i18n } = this.context;
    const { showError, loading } = this.state;

    if (loading) {
      return (
        <ProfileGlobalStyle noPadding>
          <div className="no-column">
            <div className="wrapper-loading">
              <Loader text={i18n.t('loading_profile')} />
            </div>
          </div>
        </ProfileGlobalStyle>
      );
    }

    if (showError) {
      const title = i18n.t('error_loading_profile');
      const textButton = i18n.t('try_again');

      return (
        <ErrorMessage
          title={title}
          eventFunction={this.eventFunctionError}
          textButton={textButton}
        />
      );
    }

    const profileInfoLevel = peopleStore.level && (
      <ProfileInfoModal className="w-profile-info-level">
        <small>{i18n.t('profile_level')}</small>
        {peopleStore.level}
      </ProfileInfoModal>
    );

    const profileInfoFrequency = peopleStore.frequency && (
      <ProfileInfoModal className="w-profile-info-frequency">
        <small>{i18n.t('profile_frequecy')}</small>
        {peopleStore.frequency}
      </ProfileInfoModal>
    );

    const profileObjective = peopleStore.objective && (
      <div className="no-column">
        <ProfileInfoModal>
          <small>{i18n.t('profile_goal')}</small>
          {i18n.t(peopleStore.objective)}
        </ProfileInfoModal>
      </div>
    );

    const profileInfoObservationAndRestriction = peopleStore.observationAndRestriction && (
      <div className="no-column">
        <ProfileInfoModal highlight="true" className="w-profile-info-observation">
          <small>{i18n.t('profile_lesion_or_observation')}</small>
          {peopleStore.observationAndRestriction}
        </ProfileInfoModal>
      </div>

    );

    const profileInfoTrainingObservation = peopleStore.trainingObservation && (
      <div className="no-column">
        <ProfileInfoModal highlight="true" className="w-profile-info-observation">
          <small>{i18n.t('comment_title')}</small>
          {peopleStore.trainingObservation}
        </ProfileInfoModal>
      </div>
    );

    return (
      <ProfileGlobalStyle>
        {profileInfoLevel}

        {profileInfoFrequency}

        {profileObjective}

        <ProfileInfoModal className="w-profile-info-age">
          <small>{i18n.t('profile_age')}</small>
          {peopleStore.age}
        </ProfileInfoModal>

        <ProfileInfoModal className="w-profile-info-location">
          <small>{i18n.t('profile_location_name')}</small>
          {peopleStore.locationName}
        </ProfileInfoModal>

        {profileInfoObservationAndRestriction}

        {profileInfoTrainingObservation}

      </ProfileGlobalStyle>
    );
  }
}


export default ProfileGlobal;
