import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import {
  HistoryTrainingStyle,
  ErrorMessage,
  Loader,
} from 'components';

import { PostGaAnalytics } from 'utils';
import ListTraining from './ListTraining';

class HistoryTraining extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.getListTraining = this.getListTraining.bind(this);
    this.eventFunctionError = this.eventFunctionError.bind(this);
  }

  state = {
    showError: false,
    loading: false,
  };

  componentWillMount() {
    this.getListTraining();

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:button',
      eventLabel: 'history_workout_summary',
    });
  }

  getListTraining() {
    const {
      historyTrainingStore,
    } = this.context;

    this.setState({
      loading: true,
    });

    historyTrainingStore
      .doRequestGetHistoryTraining()
      .then(() => {
        this.setState({
          showError: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          showError: true,
          loading: false,
        });
      });
  }

  headerHistoryTraining() {
    const {
      i18n,
    } = this.context;

    return (
      <div className="header-training">
        <p className="item date">{i18n.t('date')}</p>
        <p className="item goal">{i18n.t('profile_goal')}</p>
        <p className="item level">{i18n.t('profile_level')}</p>
        <p className="item">{i18n.t('division')}</p>
      </div>
    );
  }

  eventFunctionError() {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:button',
      eventLabel: 'try_again_history',
    });

    this.getListTraining();
  }

  render() {
    const { i18n, historyTrainingStore } = this.context;
    const { showError, loading } = this.state;
    const listTraining = historyTrainingStore.getLisTrainingGym();

    if (loading) {
      return (
        <HistoryTrainingStyle>
          <div className="wrapper-list-training loading">
            <div className="wrapper-loading">
              <Loader text={i18n.t('loading_training')} />
            </div>
          </div>
        </HistoryTrainingStyle>
      );
    }

    if (showError) {
      const title = i18n.t('error_loading_training');
      const textButton = i18n.t('try_again');
      return (
        <HistoryTrainingStyle>
          <div className="wrapper-list-training error">
            {this.headerHistoryTraining()}
            <ErrorMessage
              title={title}
              eventFunction={this.eventFunctionError}
              textButton={textButton}
            />
          </div>
        </HistoryTrainingStyle>
      );
    }

    return (
      <HistoryTrainingStyle>
        {this.headerHistoryTraining()}
        <ListTraining listExercise={listTraining} />
      </HistoryTrainingStyle>
    );
  }
}

export default observer(HistoryTraining);
