import React, { useContext, useState } from 'react';
import Context from 'stores/context';
import {
  Title,
  FormGroup,
  Select,
} from 'components';

import Graphic from './Graphic';

const ProgressResultExercise = () => {
  const context = useContext(Context);
  const {
    i18n,
    followUpStore,
  } = context;
  const [selected, setSelected] = useState(null);

  if (followUpStore.hasResultExercise() === false) {
    return false;
  }

  const changeExercise = (e) => {
    setSelected(e.currentTarget.value);
    const id = parseInt(e.currentTarget.value, 10);
    followUpStore.setOptionSelected(id);
  };

  const renderOption = () => followUpStore.listOptionExerciseSelect.map(item => (
    <option key={item.id} value={item.id} id={item.id}>{item.name}</option>
  ));

  const renderSelect = () => {
    if (followUpStore.hasWeightExercise()) {
      return (
        <FormGroup className="form-wrap-select">
          <Select
            onChange={e => changeExercise(e)}
            value={selected || ''}
            bgColor="#000"
            borderWidth="1"
            borderColor="#666"
            fontColor="#fff"
            IconWhite
          >
            <option value="">{i18n.t('none')}</option>
            {renderOption()}
          </Select>
        </FormGroup>
      );
    }

    return false;
  };

  return (
    <>
      <Title
        type={4}
        fontSize={20}
        align="initial"
        className="title follow-up"
      >
        {i18n.t('result_follow_up')}
      </Title>

      {renderSelect()}

      <div className="wrapper-graphic">
        <Graphic data={followUpStore.resultListData} />
      </div>
    </>
  );
};

export default ProgressResultExercise;
