import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, Button, Box } from 'rebass';
import urlImageDefault from './assets/error.svg';

const ErrorMessageStyle = styled(Flex)`
  .wrapper-image {
    text-align: center;

    img {
      width: 100px;
    }
  }

  .title {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #111;
    margin-bottom: 16px;
  }

  .subtitle {
    color: #eee;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    margin-bottom: 24px;
  }

  .exercise {
    font-family: Gotham;
    font-style: normal;
    font-size: 16px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 30px;
  }
`;

function ErrorMessage(props) {
  const {
    title,
    eventFunction,
    textButton,
    icon,
  } = props;

  const infoError = () => (
    <div className="wrapper-handle-error">
      <h2 className="title">{title}</h2>

      <Button
        variant="primarySquare"
        fontSize={[1]}
        fontWeight="unset"
        width={['100%']}
        className="exercise"
        onClick={() => { eventFunction(); }}
      >
        {textButton}
      </Button>
    </div>
  );

  const renderImage = () => {
    const imgPath = icon || urlImageDefault;

    return (
      <figure className="wrapper-image">
        <img src={imgPath} alt="error" />
      </figure>
    );
  };

  return (
    <ErrorMessageStyle alignItems="center" justifyContent="center">
      <Box className="wrapper-msg-error">
        {renderImage()}
        {infoError()}
      </Box>
    </ErrorMessageStyle>
  );
}

ErrorMessage.defaultProps = {
  title: '',
  eventFunction: () => {},
  textButton: '',
  icon: '',
  history: {},
};

ErrorMessage.propTypes = {
  title: PropTypes.string,
  eventFunction: PropTypes.func,
  textButton: PropTypes.string,
  icon: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export { ErrorMessage };
export default ErrorMessage;
