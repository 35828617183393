import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

import PowerUpSVG from './assets/power-up.svg';
import RepsSVG from './assets/reps.svg';
import SeriesSVG from './assets/series.svg';
import TimerSVG from './assets/timer.svg';

const WrapperContentExercise = styled.div`
`;

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 50% 10% 20% 20%;
  border-bottom: 1px solid #404040;
  padding: 16px 10px;

  .name-exercise {
    .description {
      font-family: ${props => props.theme.fonts.Gotham};
      font-style: normal;
      font-weight: 500;
      font-size: ${props => props.theme.fontSizes[2]};
    }

    .intensification {
      font-family: ${props => props.theme.fonts.Gotham};
      font-style: normal;
      font-weight: 325;
      font-size: ${props => props.theme.fontSizes[1]};
      color: ${props => props.theme.colors.yellow};
      margin-top: 10px;
    }

    .data-info {
      justify-content: start;
      align-items: center;

      img {
        width: 20px;
      }

      .text {
        margin-left: 5px;
      }
    }
  }

  .data-info {
    display: flex;
    justify-content: end;

    img {
      width: 30px;
      height: 20px;
    }
  }
`;

const Item = (props) => {
  const context = useContext(Context);
  const { i18n } = context;
  const { exercise } = props;

  const renderIntensification = () => {
    if (exercise.intensification_method) {
      return (
        <div className="data-info">
          <p className="icon"><img src={PowerUpSVG} alt={i18n.t('series')} /></p>
          <p className="text">{i18n.t(exercise.intensification_method)}</p>
        </div>
      );
    }

    return '';
  };

  return (
    <WrapperContentExercise>
      <GridItem>
        <div className="name-exercise">
          <div className="description">{exercise.title.toLowerCase() || '-'}</div>
          <div className="intensification">{renderIntensification()}</div>
        </div>
        <div className="data-info">
          <p className="icon"><img src={SeriesSVG} alt={i18n.t('series')} /></p>
          <p className="text">{exercise.series || '-'}</p>
        </div>
        <div className="data-info">
          <p className="icon"><img src={RepsSVG} alt={i18n.t('repeats')} /></p>
          <p className="text">
            {`${exercise.min_repetitions || '-'} - ${exercise.max_repetitions || '-'}`}
          </p>
        </div>
        <div className="data-info">
          <p className="icon"><img src={TimerSVG} alt={i18n.t('interval')} /></p>
          <p className="text">
            {`${exercise.min_interval || '-'} - ${exercise.max_interval || '-'}`}
          </p>
        </div>
      </GridItem>
    </WrapperContentExercise>
  );
};

export default Item;
