import React, { useContext } from 'react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import ItemPais from './ItemPais';

function AmountInterval(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
  } = context;

  const {
    hasError,
  } = props;

  return (
    <ItemPais
      rule={i18n.t('dropdown_pais_title_number_max_min_interval')}
      checked={hasError}
      expect={i18n.t('dropdown_pais_number_max_min_interval', {
        min: rulesPaisStore.dataRules.min_interval,
        max: rulesPaisStore.dataRules.max_interval,
      })}
    />
  );
}

AmountInterval.defaultProps = {
  hasError: false,
};

AmountInterval.propTypes = {
  hasError: PropTypes.bool,
};

export default AmountInterval;
