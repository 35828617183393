import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import {
  ContainerStyle,
  Text,
  Button,
} from 'components';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';
import { TrainingProfileStyle } from 'views/Profile/Trainings/TrainingProfileStyle';
import ShowProgressTraining from './ShowProgressTraining';
import TrainingCurrent from './TrainingCurrent/TrainingCurrent';
import ModalInputTraining from '../../../components/modal/modal-input-training';

function Trainings({ history }) {
  const context = useContext(Context);
  const {
    buildTrainingStore,
    peopleStore,
    i18n,
    cronometerStore,
    filterWorkoutStore,
    trainingCreationStore,
    lesionAndRestrictionStore,
    modalStore,
    historyTrainingStore,
    rulesPaisStore,
  } = context;

  const hasTraining = () => !!(peopleStore.getCurrentWeightTraining.id);

  const showMessageNotTraining = hasTraining() === false && (
    <ContainerStyle margin="20px 0px 0px 0px">
      <Text>
        {i18n.t('not_training')}
      </Text>
    </ContainerStyle>
  );

  const showTrainingCurrent = hasTraining() && (
    <TrainingCurrent history={history} />
  );

  const showProgressTraining = hasTraining() && (
    <ShowProgressTraining />
  );

  const newTraining = () => {
    rulesPaisStore.reset();
    buildTrainingStore.setEditSuggestionSend(false);
    buildTrainingStore.setTemplateSuggestionSend(false);
    buildTrainingStore.setResendTraining(false);
    buildTrainingStore.setAnalyticsTypeTrainingSuggestion('');
    filterWorkoutStore.resetFilter();
    trainingCreationStore.setDateFinish(45);
    lesionAndRestrictionStore.resetHealthRestrictionEdit();

    if (peopleStore.objective !== null) {
      filterWorkoutStore.loadingFilterBeforeTratament();
    }

    cronometerStore.start();

    const url = '/suggestions';
    const urlAcronym = ConcatUnitInUrl(url);
    history.push(urlAcronym);
  };

  const validateInputTraining = () => {
    if (trainingCreationStore.inputManualTraining) {
      modalStore.toggleModal('modalInpuTraining', true);
    } else {
      newTraining();
    }
  }

  const modalInputTraining = modalStore.modalInpuTraining && (
    <ModalInputTraining
      onClickLeave={newTraining}
    />
  );

  const trainingHistory = () => {
    historyTrainingStore.setShowHistoryTraining(true);
    modalStore.toggleModal('modalDataProfile', true);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:profile',
      eventAction: 'click:button',
      eventLabel: 'history_workout',
    });
  };

  return (
    <TrainingProfileStyle>
      <ContainerStyle justifyContent="space-between">
        <button
          className="show-history"
          onClick={trainingHistory}
          type="button"
        >
          {i18n.t('see_history_training')}
        </button>

        <Button
          primary
          onClick={validateInputTraining}
          className="button-new-training"
        >
          {i18n.t('send_new_training')}
        </Button>
      </ContainerStyle>

      {showTrainingCurrent}

      {showMessageNotTraining}

      {showProgressTraining}

      {modalInputTraining}

    </TrainingProfileStyle>
  );
}

Trainings.defaultProps = {
  history: {},
};

Trainings.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(Trainings);
