import React, { useContext } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Box } from 'rebass';
import { ButtonSquare } from 'components';
import Context from 'stores/context';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

const Wrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  border-top: solid 3px #efefef;
`;

const Footer = (props) => {
  const { history } = props;
  const context = useContext(Context);
  const { addExercisesStore, i18n, modalStore } = context;


  const hasCardion = () => addExercisesStore.hasExerciseCardio();

  const hasExerciseWeightTraining = () => {
    const weightTraining = addExercisesStore.hasExerciseWeightTraining();
    const cardio = hasCardion();

    return (cardio === false && weightTraining);
  };

  const validateHasExerciseDuplicate = () => {
    const hasExercise = addExercisesStore.getExercisesSelected.filter(exercise => exercise.hasList);
    return (!!hasExercise.length);
  };


  const statusButton = () => {
    const hasExercise = addExercisesStore.getHasExercisesSave();
    const hasExeciseDuplicate = validateHasExerciseDuplicate();
    const activeButton = (hasExercise && hasExeciseDuplicate === false);

    return (activeButton) ? 'primary' : 'cancel';
  };

  const redirectToFormSettingExercise = () => {
    const cardio = hasCardion();
    const weightTraining = hasExerciseWeightTraining();
    const hasExeciseDuplicate = validateHasExerciseDuplicate();

    if (hasExeciseDuplicate) {
      const trainingSelected = i18n.t('training_selected');
      modalStore.setTitle('');
      modalStore.setDescription(trainingSelected);
      modalStore.toggleModal('modalError', true);

      return false;
    }

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:add_workout',
      eventAction: 'click:button',
      eventLabel: 'next',
    });

    if (cardio) {
      const url = ConcatUnitInUrl('/add-exercises/exercises-settings-cardio');
      history.push(url);
    }

    if (weightTraining) {
      const url = ConcatUnitInUrl('/add-exercises/exercises-settings-weight-training');
      history.push(url);
    }

    return true;
  };

  return (
    <div>
      <Wrapper bg="white" p={[2]}>
        <ButtonSquare
          mr={[2]}
          variant="cancel"
          onClick={() => {
            const url = ConcatUnitInUrl('/build-training/build-training-list');
            history.push(url);

            PostGaAnalytics('send', {
              hitType: 'event',
              eventCategory: 'workout:add_workout',
              eventAction: 'click:button',
              eventLabel: 'cancel',
            });
          }}
        >
          {i18n.t('cancel')}
        </ButtonSquare>

        <ButtonSquare
          className="next__add-exercises"
          variant={statusButton()}
          onClick={redirectToFormSettingExercise}
        >
          {i18n.t('next')}
        </ButtonSquare>
      </Wrapper>
    </div>
  );
};

export default observer(Footer);
