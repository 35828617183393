import styled from 'styled-components';

const ListExerciseStyle = styled.div`
  width: 100%;
  margin-top: 16px;

  ul {
    list-style: none;
  }

  .list-title {
    display: flex;
    font-family: Gotham;
    font-style: normal;
    font-weight: 900;
    font-size: 11px;
    line-height: 13px;
    color: #808080;
    overflow: initial;
    text-transform: uppercase;

    .title-exercise {
      width: 238px;
    }

    .series {
      width: 68px;
    }

    .repeats {
      width: 96px;
    }
  }

  .list-info-exercise {
    li {
      display: flex;
      justify-content: space-between;
      color: #000;
      font-family: Gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      padding: 10px 0px;

      .name-exercise {
        width: 185px;
      }

      .icon-image {
        transform: translateY(2px);;
      }

      .intensification {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #1A5BAF
      }
    }
  }

`;

export { ListExerciseStyle };
export default ListExerciseStyle;
