import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import { ConcatUnitInUrl } from 'utils';
import {
  Modal,
  Title,
  Button,
} from 'components';
import BelSVG from './icon/icon-bel.svg';

const ModalSuccessPremissasStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.w-modal-body {
    padding: 10px;
  }

  .text-premissas {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 21px;
  }

  .icon-bel {
    width: 130px;
    height: 140px;
    background-image: url(${BelSVG});
    background-repeat: no-repeat;
    background-position: center;
  }
`;


const ModalSuccessPremissas = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    modalStore,
  } = context;
  const { history } = props;

  const closeModal = () => {
    modalStore.toggleModal('modalSuccessPremissas', false);
    const url = ConcatUnitInUrl('/profile');
    history.push(url);
  };

  return (
    <Modal>
      <div className="w-modal-content">
        <ModalSuccessPremissasStyle className="w-modal-body">

          <div className="icon-bel" />

          <Title
            type={4}
            as="h4"
            color="#1E8654"
            mgBottom="8"
            fontSize="20"
            className="title"
            marginTop="8px"
            subTitleColor="#010114"
          >
            {i18n.t('title_modal_premissas_success')}
            <small className="text-premissas">
              {i18n.t('sub_title_modal_premissas_success')}
            </small>
          </Title>

          <div>
            <Button
              fourth
              onClick={closeModal}
            >
              {i18n.t('close')}
            </Button>
          </div>
        </ModalSuccessPremissasStyle>
      </div>
    </Modal>
  );
};

export default ModalSuccessPremissas;
