import styled from 'styled-components';

const TrainingProfileStyle = styled.div`
  .show-history {
    background-color: transparent;
    border: 0;
    color: #ffb612;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    position: relative;
    text-decoration: underline;
  }

  .button-new-training {
    font-size: 12px;
  }
`;

export { TrainingProfileStyle };
export default TrainingProfileStyle;
