import styled from 'styled-components';
import { Text } from 'rebass';

const Incomplete = styled(Text)`
  background: #E98D35;
  padding: 4px 9px 4px 9px;
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fontSizes[0]};
  font-weight: 325;
  border-radius: 24px;
  height: 20px;
  position: absolute;
  top: -24px;
  left: 0px;
`;

export default Incomplete;
export { Incomplete };
