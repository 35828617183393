import React, { Component } from 'react';
import Context from 'stores/context';
import {
  Error,
} from 'components';
import styled from 'styled-components';

const ErrorBoundaryStyle = styled.div`
  @media screen and (min-width: 320px) {
    .wrapper-msg-error {
      width: 320px;
    }
  }
`;

class ErrorBoundary extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, info) {
    const {
    } = this.context;

    this.setState({ error: error, errorInfo: info });
  }

  render() {
    const {
      errorInfo,
    } = this.state;

    const {
      history,
      children,
    } = this.props;

    if (errorInfo) {
      return (
        <ErrorBoundaryStyle className="errorBoundary">
          <Error page="errorGeneric" history={history} />
        </ErrorBoundaryStyle>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
