import React, { useContext } from 'react';
import {
  FormGroup,
  Button,
} from '../../components';
import Context from '../../stores/context';

const Buttons = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    infoLabel,
    cancelForm,
    textCancel
  } = props;

  const {
    errors,
    isSubmitting,
  } = infoLabel;

  return (
    <FormGroup formActions="true" className="w-form-actions" noMarginBottom="true">
      <Button
        type="button"
        tertiary="true"
        color="#d52b1e"
        onClick={() => {
          cancelForm();
        }}
      >
        {i18n.t(textCancel)}
      </Button>

      <Button
        type="submit"
        variant={Object.keys(errors).length ? false : 'primary'}
        className="save-exercise"
      >
        {isSubmitting && i18n.t('shipping')}
        {!isSubmitting && i18n.t('saved_update')}
      </Button>
    </FormGroup>
  );
};

export default Buttons;
