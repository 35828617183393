import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToogleRestrictionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    background-color: #111111;
    border-width: 0;
    color: #fff;
    font-family: ${props => props.theme.fonts.Gotham};
    font-size: ${props => props.theme.fontSizes[3]};
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    cursor: pointer;
    transition: color 0.2s ease-out;

    @media(${props => props.theme.mediaScreen.cellphone}) {
      min-width: 140px;
      padding: 16px 15px 13px;
    }

    @media(${props => props.theme.mediaScreen.minTablet}) and (${props => props.theme.mediaScreen.maxTablet}){
      min-width: 240px;
      font-size: 16px;
      padding: 16px 75px 13px;
    }

    @media(${props => props.theme.mediaScreen.desktop}) {
      padding: 16px 105px 13px;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
      border-left: 1px solid #404040;
      border-top: 1px solid #404040;
      border-bottom: 1px solid #404040;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #404040;
      border-top: 1px solid #404040;
      border-bottom: 1px solid #404040;
    }

    &.active {
      background-color: ${props => props.theme.colors.yellow};
      color: #000;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid;
    }
  }
`;

const ToogleRestriction = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    toogle,
    toogleButton,
  } = props;

  return (
    <ToogleRestrictionStyle>
      <div>
        <button
          className={`${toogleButton ? 'active' : ''}`}
          type="button"
          onClick={() => toogle(true)}
        >
          {i18n.t('yes')}
        </button>
        <button
          className={`${toogleButton ? '' : 'active'}`}
          type="button"
          onClick={() => toogle(false)}
        >
          {i18n.t('no')}
        </button>
      </div>
    </ToogleRestrictionStyle>
  );
};

ToogleRestriction.defaultProps = {
  toogle: () => {},
  toogleButton: false,
};

ToogleRestriction.propTypes = {
  toogle: PropTypes.func,
  toogleButton: PropTypes.bool,
};


export default ToogleRestriction;
