import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import Context from 'stores/context';
import styled from 'styled-components';
import IconCheckedSVG from './icon/checked.svg';

const PremissaAmountExerciseStyle = styled.div`
  background-color: #f2f2f2;
  border-radius: 4px;
  color: ${(props) => props.theme.colors.black};
  padding: 16px 16px;
  text-align: initial;
  margin: 10px;

  .title {
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSizes[1]};
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    span {
      display: block;
    }
  }

  .subtitle {
    font-family: ${(props) => props.theme.fonts.Gotham};
    font-weight: 325;
    font-size: ${(props) => props.theme.fontSizes[0]};
  }

  .icon {
    display: none;
  }


  &.active {
    background-color: #EEFBF5;
    color:  #1e8654;

    .icon {
      background-image: url(${IconCheckedSVG});
      background-repeat: no-repeat;
      width: 20px;
      height: 12px;
      margin-left: 8px;
    }
  }
`;

function PremissaAmountExercise() {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
    addExercisesStore,
    buildTrainingStore,
    toggleStore,
  } = context;

  const checkedAmount = () => {
    const listCurrent = buildTrainingStore.currentTrainingExercises;
    const listSelected = addExercisesStore.getExercisesSelected;
    const isActive = rulesPaisStore.getCheckedAmountExercise(listSelected, listCurrent);
    return isActive ? 'active' : '';
  };

  const allowRenderPais = () => toggleStore.allowRenderParams({
    toggleUnitBlock: 'toogleUnitPaisBlock',
    toggleUnitAllow: 'toogleUnitPaisAllow',
    toggleCountry: 'allowPaisCountry',
  });

  if (allowRenderPais() === false) return false;

  return (
    <PremissaAmountExerciseStyle className={checkedAmount()}>
      <h5 className="title">
        <span>{i18n.t('premissas_recommend')}</span>
        <span className="icon" />
      </h5>
      <p className="subtitle">
        {i18n.t('amount_exercise_selected', {
          min: rulesPaisStore.dataRules.min_exercises,
          max: rulesPaisStore.dataRules.max_exercises,
        })}
      </p>
    </PremissaAmountExerciseStyle>
  );
}

export default observer(PremissaAmountExercise);
