import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Title } from 'components/Title/Title';
import { SubTitle } from 'components/SubTitle/SubTitle';

import {
  BelNotFound as BaseIconBelNotFound,
} from 'components/icons';

const ListEmptyBellStyle = styled.div`
  color: ${(props) => props.theme.colors.white};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px 32px;
  text-align: center;
`;

function ListEmptyBell(props) {
  const {
    title,
    subTitle,
  } = props;

  return (
    <ListEmptyBellStyle>
      <BaseIconBelNotFound />
      <Title type={4}>{title}</Title>
      <SubTitle fontSize="16px">{subTitle}</SubTitle>
    </ListEmptyBellStyle>
  );
}

ListEmptyBell.defaultProps = {
  title: '',
  subTitle: '',
};

ListEmptyBell.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default ListEmptyBell;
export { ListEmptyBell };
