import { observable, action, decorate } from 'mobx';
import {
  IappStore,
} from './IappStore';

class AppStore implements IappStore {
  rootStore = {
    peopleStore: {
      reset: () => {},
    },
  };

  title = '';

  loading = false;

  reload = false;

  objTemp = false;

  showPassword = false;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  setTitle(title: string): void {
    this.title = title;
  }

  toggleloading(value: boolean): void {
    this.loading = value;
  }

  toggleShowPassword(value: boolean): void {
    this.showPassword = value;
  }

  clearObjTemp(): void {
    this.objTemp = false;
  }

  setObjTemp(value: boolean): void {
    this.objTemp = value;
  }

  getStatusObjTemp(): boolean {
    if (this.objTemp === false) {
      return false;
    }

    return true;
  }

  clearALlCache(): void {
    window.sessionStorage.removeItem('localforage/program');
    window.sessionStorage.removeItem('localforage/people');
    window.sessionStorage.removeItem('localforage/training-creation');
    window.sessionStorage.removeItem('localforage/build-training');
    window.sessionStorage.removeItem('localforage/add-exercises');
    window.sessionStorage.removeItem('localforage/teacherTrainingStore');
    window.sessionStorage.removeItem('localforage/rulesPaisStore');
    this.rootStore.peopleStore.reset();
  }
}

decorate(AppStore, {
  title: observable,
  setTitle: action,
  loading: observable,
  showPassword: observable,
  toggleShowPassword: action,
  toggleloading: action,
  objTemp: observable,
  setObjTemp: observable,
  getStatusObjTemp: action,
});

export default AppStore;
