import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes, { string } from 'prop-types';

const FeedBackBarStyle = styled.div`
  width: 100%;
  background: rgba(15, 168, 38, 0.2);
  color: #0FA826
  text-align: center;
  line-height: 26px;
  transform: translate3d(0, 0, 0);
  margin-bottom 10px;
  opacity: 0;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;

  .feedbackbar-title {
    font-family: Gotham;
    font-style: normal;
    font-weight: 325;
    font-size: 13px;
  }

  ${(props) => props.success === false
    && css`
    background: rgba(213, 43, 30, 0.2);
    color: #d52b1e;
  `}

  ${(props) => props.active === true
    && css`
    opacity: 1;
    transform: translate3d(0, 10px, 0);
  `}
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class FeedBackBar extends Component {
  render() {
    const {
      showFeedBackBar,
      msgFeedBackBar,
    } = this.props;

    return (
      <FeedBackBarStyle active={showFeedBackBar} success>
        <span className="feedbackbar-title">
          {msgFeedBackBar}
        </span>
      </FeedBackBarStyle>
    );
  }
}

FeedBackBar.defaultProps = {
  showFeedBackBar: false,
  msgFeedBackBar: string,
};

FeedBackBar.propTypes = {
  showFeedBackBar: PropTypes.bool,
  msgFeedBackBar: PropTypes.string,
};

export { FeedBackBar };
