import styled, { css } from 'styled-components';
import CloseSVG from './icon/closed.svg';
import Checked from './icon/checked.svg';

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    background-color: #111111;
    border-width: 0;
    color: #fff;
    font-family: 'Gotham';
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 16px 15px 13px;
    cursor: pointer;
    transition: color 0.2s ease-out;

    ${(props) => props.tabWhite
      && css`
      width: 100%;
      background-color: #eee;
      color: ${(props) => props.theme.colors.black}
      border: 1px solid #eee;
      `};

    @media screen and (min-width: 544px) {
      min-width: 140px;
    }

    @media screen and (min-width: 768px) {
      min-width: 240px;
      font-size: 16px;
      padding: 16px 15px 13px;
    }

    span {
      display: inline-block;
      z-index: 2;
      pointer-events: none;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        border-radius: 4px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }
    }

    &.w-active {
      color: #000;

      span {
        &::before {
          opacity: 1;
        }
      }

      .wrapper-premissa {
        &::before {
          opacity: 1;
        }

        .label {
          color: #000;
        }
      }

    }

    .wrapper-premissa {
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        border-radius: 4px;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }

      .label {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #fff;
        text-transform: initial;
      }

      .validate-premissas {
        font-family: ${(props) => props.theme.fonts.Gotham};
        font-style: normal;
        font-weight: 350;
        font-size: ${(props) => props.theme.fontSizes[1]};
        line-height: 14px;
        align-items: center;
        text-align: center;
        color: ${(props) => props.theme.colors.white};

        &.premissas {
          text-transform: initial;
          border-radius: 50px;
          margin-top: 8px;
          padding: 2px 11px 2px 9px;

          &__null {
            background-color: ${(props) => props.theme.colors.textDarkLight};
            .icon {
              display: none;
            }
          }

          &__true {
            background-color:  ${(props) => props.theme.colors.green};

            .icon {
              background-image: url(${Checked});
            }
          }

          &__false {
            background-color: ${(props) => props.theme.colors.red};

            .icon {
              background-image: url(${CloseSVG});
            }
          }

          .icon {
            margin-right: 7px;
            width: 10px;
            height: 8px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px;
          }
        }
      }
    }
  }
`;

export { Tabs };
export default Tabs;
