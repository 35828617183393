import {
  observable, action, decorate, computed,
} from 'mobx';
import {
  trainingProfiles as trainingProfilesApi,
  getDataTrainingCurrent as getDataTrainingCurrentApi,
  getListBodyEmphasis as getListBodyEmphasisApi,
  suggestionsTraining as suggestionsTrainingApi,
  createTraining as createTrainingApi,
  patchUpdateTraining as patchUpdateTrainingApi,
  createNewTrainingTeacher as createNewTrainingTeacherApi,
  getTrainings as getTrainingsApi,
  delTrainings as delTrainingsApi,
  sendObservationRestriction as sendObservationRestrictionApi,
  patchEditTraining as patchEditTrainingApi,
} from '../services/api';

class TrainingCreationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.weightTrainingProfiles = [];
    this.frequencySelected = null;
    this.profileSelected = null;
    this.profileSpeedSelected = [];
    this.suggestionsTrainings = [];
    this.weightTraining = null;
    this.weightTrainingId = null;
    this.newTraining = false;
    this.trainingObservation = '';
    this.editObservationOrRestriction = false;
    this.trainingCreateAt = null;
    this.dateFinishSave = undefined;
    this.dateFinishCurrent = 45;
    this.paisPremisses = false;
    this.programName = '';
    this.levelName = '';
    this.trainingGroupLabels = [];
    this.frequency = 0;
    this.dataBodyEmphasis = [];
    this.emphasis = '';
    this.duration = null;
    this.inputManualTraining = false;
  }

  setProfileTraining(training) {
    this.setProgramName(training.alias);
    this.setLevelName(training.level_name);
    this.setTrainingGroupLabels(training.training_group_labels);
    this.setFrequency(training.frequency);
    this.setInputTrainingManual(training.created_by_person);

    if (training.weight_training_profile_id) {
      this.setProfileSelected({
        id: training.weight_training_profile_id,
      })
    }

    const lisHealthRestrictions = training.training_health_restrictions;

    if (lisHealthRestrictions && lisHealthRestrictions.length > 0) {
      this.rootStore
        .lesionAndRestrictionStore
        .setHealthRestrictionEdit(lisHealthRestrictions);
    }
  }

  setProgramName(programName = '') {
    this.programName = programName;
  }

  setLevelName(levelName = '') {
    this.levelName = levelName;
  }

  setTrainingGroupLabels(trainingGroupLabels = []) {
    this.trainingGroupLabels = trainingGroupLabels.sort();
  }

  setFrequency(frequency = 0) {
    this.frequency = frequency;
  }

  setInputTrainingManual(inputManualTraining = false) {
    this.inputManualTraining = inputManualTraining;
  }

  resetDateFinish() {
    this.dateFinishCurrent = 45;
  }

  resetWeightTrainingId() {
    this.weightTrainingId = null;
  }

  resetEmphasis() {
    this.emphasis = '';
  }

  resetDuration() {
    this.duration = null;
  }

  setWeightTraining(weightTraining) {
    this.weightTraining = weightTraining;
    this.setWeightTrainingId(weightTraining.id);
  }

  setWeightTrainingId(weightTrainingId) {
    this.weightTrainingId = weightTrainingId;
    this.doRequestGetDataTrainingCurrent();
  }

  setWeightTrainingProfiles(weightTrainingProfiles) {
    this.weightTrainingProfiles = weightTrainingProfiles;
  }

  setFrequencySelected(frequencySelected) {
    this.frequencySelected = frequencySelected;
  }

  setWeightSuggestionsTraining(suggestionsTrainings) {
    this.suggestionsTrainings = suggestionsTrainings;
  }

  setTrainingObservation(observation) {
    this.trainingObservation = observation;
  }

  setProfileSelected(profileFiltered) {
    if (profileFiltered) {
      this.profileSelected = profileFiltered.id;
    }
  }

  setPaisPremisses(profileFiltered) {
    if (profileFiltered) {
      this.paisPremisses = profileFiltered.pais_premisses || false;
    }
  }

  setEditObservationOrRestriction(status) {
    this.editObservationOrRestriction = status;
  }

  setDataTrainingCurrent(data) {
    this.trainingCreateAt = new Date(data.created_at);
    this.dateFinishSave = data.finish_date;
  }

  setDateFinish(ranger) {
    this.dateFinishCurrent = parseInt(ranger, 10);
  }

  setDateFinishEdit() {
    this.dateFinishCurrent = null;
  }

  validateEmphasis(currentTraining) {
    if(currentTraining.body_emphasis_slug) {
      this.resetEmphasis();
      this.setEmphasis(currentTraining.body_emphasis_slug);
    }
  }

  setEmphasis(emphasis) {
    if(this.emphasis === emphasis) {
      this.emphasis = '';
    } else {
      this.emphasis = emphasis
    }
  }

  setDuration(duration) {
    this.duration = duration;
  }

  setDataBodyEmphasis(data) {
    this.dataBodyEmphasis = data;
  }

  doRequestTrainingProfiles(params) {
    return new Promise((resolve, reject) => this.promiseTrainingProfiles(params, resolve, reject));
  }

  promiseTrainingProfiles = async (params, resolve, reject) => {
    try {
      const res = await trainingProfilesApi(params, this.rootStore.sessionStore.token);
      this.setWeightSuggestionsTraining([]);
      this.setWeightTrainingProfiles(res.data.weight_training_profiles);
      resolve(res.data.weight_training_profiles);
    } catch (err) {
      reject(err);
    }
  };

  doRequestSuggestionsTraining(params) {
    return new Promise((resolve, reject) => this.suggestionsTraining(params, resolve, reject));
  }

  suggestionsTraining = async (params, resolve, reject) => {
    try {
      const res = await suggestionsTrainingApi(params, this.rootStore.sessionStore.token);
      this.setWeightSuggestionsTraining(res.data.weight_trainings);
      resolve(res.data.weight_trainings);
    } catch (err) {
      reject(err);
    }
  };

  doRequestFinishDate() {
    const params = {
      enrollmentId: this.rootStore.programStore.enrollmentId,
      weightTrainingId: this.weightTrainingId,
      body: {
        weight_training: {
          finish_date_days: this.dateFinishCurrent,
        },
      },
    };

    const promise = async (resolve, reject) => {
      try {
        const res = await patchEditTrainingApi(params, this.rootStore.sessionStore.token);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestSendObservation(params) {
    const promise = async (resolve, reject) => {
      try {
        const res = await patchEditTrainingApi(params, this.rootStore.sessionStore.token);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestUpdateTraining(params) {
    const promise = async (resolve, reject) => {
      try {
        const res = await patchUpdateTrainingApi(params, this.rootStore.sessionStore.token);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestCreateSuggestionEdit(training) {
    const params = {
      body: {
        weight_training: {
          observation: '',
          finish_date_days: this.dateFinishCurrent,
          original_weight_training_id: training.trainingId,
        },
      },
      peopleId: training.peopleId,
    };

    const promise = async (resolve, reject) => {
      try {
        const res = await createTrainingApi(params, this.rootStore.sessionStore.token);
        this.setWeightTraining(res.data.weight_training);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestCreateSuggestionTraining(params) {
    const promise = async (resolve, reject) => {
      try {
        const res = await createTrainingApi(params, this.rootStore.sessionStore.token);
        this.setWeightTraining(res.data.weight_training);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestCreateNewTrainingTeacher() {
    const data = {
      teacher_weight_training: {
        alias: this.rootStore.teacherTrainingStore.alias,
        weight_training_profile_id: this.rootStore.trainingCreationStore.profileSelected,
      },
    };

   if (this.emphasis !== '') {
    data.teacher_weight_training.body_emphasis = this.emphasis;
   }

   if(this.duration !== null) {
    data.teacher_weight_training.duration = this.duration;
   };

    const promise = async (resolve, reject) => {
      try {
        const res = await createNewTrainingTeacherApi(
          {
            body: data,
          },
          this.rootStore.sessionStore.token,
        );

        this.setWeightTrainingId(res.data.teacher_weight_training.id);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestCreateNewTraining() {
    const data = {
      body: {
        weight_training: {
          observation: this.trainingObservation,
          finish_date_days: this.dateFinishCurrent,
          weight_training_profile_id: this.profileSelected,
        },
      },
      peopleId: this.rootStore.peopleStore.peopleId,
    };

    if (this.emphasis !== '') {
      data.body.weight_training.body_emphasis = this.emphasis;
    }

    if(this.duration !== null) {
      data.body.weight_training.duration = this.duration;
    }

    const promise = async (resolve, reject) => {
      try {
        const res = await createTrainingApi(
          data,
          this.rootStore.sessionStore.token,
        );
        this.newTraining = true;
        this.setWeightTrainingId(res.data.weight_training.id);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestGetTrainings() {
    const promise = async (resolve, reject) => {
      try {
        const res = await getTrainingsApi(
          {
            enrollmentId: this.rootStore.programStore.enrollmentId,
            body: {},
          },
          this.rootStore.sessionStore.token,
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doRequestDelTrainings = async (weightTrainingId) => {
    const promise = async (resolve, reject) => {
      try {
        const res = await delTrainingsApi(
          {
            enrollmentId: this.rootStore.programStore.enrollmentId,
            weightTrainingId,
            body: {},
          },
          this.rootStore.sessionStore.token,
        );
        resolve(res);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((resolve, reject) => promise(resolve, reject));
  }

  doSendObservationRestrictionApi(params) {
    return new Promise((resolve, reject) => (
      this.promiseSendObservationRestriction(params, resolve, reject)
    ));
  }

  doRequestGetDataTrainingCurrent() {
    const promise = async (resolve, reject) => {
      try {
        const res = await getDataTrainingCurrentApi(
          {
            current_weight_training_id: this.weightTrainingId,
          },
          this.rootStore.sessionStore.token,
        );

        this.setProfileTraining(res.data.weight_training);
        this.validateEmphasis(res.data.weight_training);
        this.setDuration(res.data.weight_training.duration);
        resolve(res.data.weight_training);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  promiseSendObservationRestriction = async (params, resolve, reject) => {
    try {
      const res = await sendObservationRestrictionApi(params, this.rootStore.sessionStore.token);
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  }

  doRequestGetListEmphasis() {
    const promise = async (resolve, reject) => {
      try {
        const res = await getListBodyEmphasisApi(
          this.rootStore.sessionStore.token,
        );
        this.setDataBodyEmphasis(res.data.body_emphasis);
        resolve(res.data.weight_training);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }


  get profilesFiltered() {
    return this.weightTrainingProfiles.filter(
      (profile) => profile.frequency === Number(this.frequencySelected),
    );
  }

  get isEmptyDateFinish() {
    return !(this.dateFinishSave);
  }
}

decorate(TrainingCreationStore, {
  newTraining: observable,
  weightTrainingId: observable,
  suggestionsTrainings: observable,
  frequencySelected: observable,
  profileSelected: observable,
  paisPremisses: observable,
  trainingObservation: observable,
  weightTrainingProfiles: observable,
  profileSpeedSelected: observable,
  editObservationOrRestriction: observable,
  dateFinishSave: observable,
  programName: observable,
  levelName: observable,
  trainingGroupLabels: observable,
  frequency: observable,
  emphasis: observable,
  duration: observable,
  inputManualTraining: observable,
  setWeightTrainingProfiles: action,
  setWeightSuggestionsTraining: action,
  doRequestTrainingProfiles: action,
  doRequestSuggestionsTraining: action,
  setContentObservationRestriction: action,
  doRequestCreateSuggestionTraining: action,
  doRequestSendObservation: action,
  setFrequencySelected: action,
  setProfileSelected: action,
  setPaisPremisses: action,
  setWeightTrainingId: action,
  setTrainingObservation: action,
  setDataTrainingCurrent: action,
  setDateFinish: action,
  setEmphasis: action,
  doRequestFinishDate: action,
  resetWeightTrainingId: action,
  resetDateFinish: action,
  doRequestCreateSuggestionEdit: action,
  doRequestUpdateTraining: action,
  resetEmphasis: action,
  doRequestGetListEmphasis: action,
  setDuration: action,
  setDateFinishEdit: action,
  profilesFiltered: computed,
  isEmptyDateFinish: computed,
});

export const TrainingCreationSchema = {
  newTraining: true,
  weightTrainingId: true,
  paisPremisses: true,
  editObservationOrRestriction: true,
  trainingObservation: true,
  dateFinishSave: true,
  dateFinishCurrent: true,
  programName: true,
  levelName: true,
  trainingGroupLabels: {
    type: 'list',
  },
  frequency: true,
  emphasis: true,
  duration: true,
  inputManualTraining: true,
  suggestionsTrainings: {
    type: 'list',
    schema: {
      id: true,
      name: true,
    },
  },
  frequencySelected: true,
  profileSelected: true,
  profileSpeedSelected: {
    type: 'list',
  },
  weightTrainingProfiles: {
    type: 'list',
    schema: {
      id: true,
      frequency: true,
      training_groups: {
        type: 'list',
        schema: {
          id: true,
          label: true,
        },
      },
    },
  },
};

export default TrainingCreationStore;
