import {
  observable,
  decorate,
  action,
} from 'mobx';

class ProfileStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.listTab = [];
    this.itemSeleted = 'anamnesis_title';
  }

  setInitialTable() {
    const hasProgram = this.rootStore.peopleStore.hasProgramsDefault();
    const hasProgramSmart = this.rootStore.peopleStore.hasProgramSmart();
    const hasTraining = () => !!(this.rootStore.peopleStore.getCurrentWeightTraining.id);

    if (hasProgram || hasProgramSmart) {
      this.selectTab('programs');
      return true;
    }

    if (hasTraining()) {
      this.selectTab('trainings');
      return true;
    }

    this.selectTab('anamnesis_title');
    return false;
  }

  selectTab(view) {
    this.itemSeleted = view;
  }
}

decorate(ProfileStore, {
  listTab: observable,
  itemSeleted: observable,
  selectTab: action,
  setInitialTable: action,
});

export default ProfileStore;
