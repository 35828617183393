/* eslint react/prop-types: 0, react/jsx-props-no-spreading: 0 */
import React from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  background: rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  text-align: center;
  margin-top: 72px;
`;

const Content = styled(Box)`
  min-height: 100vh;
`;

function ModalWrapperFull(props) {
  const {
    children,
  } = props;
  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Content
        bg="white"
        p={[2]}
        width={['100%']}
      >
        {children}
      </Content>
    </Wrapper>
  );
}

export default ModalWrapperFull;
