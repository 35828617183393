import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';
import ModalInputTraining from 'components/modal/modal-input-training';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import {
  Button,
  FlexDiv,
  Fieldset,
  FormGroup,
  Input,
  Label,
  Title,
  SubTitle,
  DataPeopleWorkout,
  HeaderSession,
  NewGridContainer as Container,
  SelectCountry,
} from 'components';

import PlanTotalPass from '../../SearchClient/PlanTotalPass/PlanTotalPass';
import NewTrainingSuggestion from '../../BuildTraining/buildTrainingSend/NewTrainingSuggestion';

const SendTrainingWalletTeacherContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: -28px;

  .wrapper-header {
    padding-left: 24px;
    padding-right: 24px;
    background-color: ${props => props.theme.colors.darkgray};
    padding-top: 15px;
    padding-bottom: 16px;
    width: 100%;
    margin-bottom: 30px;
  }


  @media(${props => props.theme.mediaScreen.cellphone}) {
    .wrapper-header {
      height: 100px;
    }
  }

  @media(${props => props.theme.mediaScreen.desktop}) {
    .wrapper-content {
      max-width: 400px;
    }
  }

  .sub-title-send {
    margin-bottom: 32px;
  }

  .w-form-search {
    margin-top: 50px;

    .w-form-group {
      @media screen and (min-width: 544px) {
        padding-right: 234px;
      }

      input {
        margin-bottom: 18px;

        @media screen and (min-width: 544px) {
          margin-bottom: 0;
        }
      }

      .w-btn-primary {
        width: 100%;

        @media screen and (min-width: 544px) {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 210px;
          min-width: initial;
        }
      }
    }
`;

const ButtonsContainer = styled.div`
  margin-top: 15px;

  button {
    height: 40px;
    padding: 13px 20px;
  }

  .button-profile {
    background-color: ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    min-width: 140px;
    margin-left: 15px;
  }
`;

const SendTrainingWalletTeacher = ({ history }) => {
  const context = useContext(Context);
  const {
    i18n,
    peopleStore,
    appStore,
    modalStore,
    toggleStore,
    selectCountryStore,
    trainingCreationStore,
  } = context;

  const [registryNumber, setRegistryNumber] = useState(null);

  const validateInputTraining = () => {
    if (trainingCreationStore.inputManualTraining) {
      modalStore.toggleModal('modalInpuTraining', true);
    } else {
      sendTraining();
    }
  }

  const sendTraining = () => {
    const url = ConcatUnitInUrl('/build-training-send');
    history.push(url);

    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:wallet',
      eventAction: 'click:button',
      eventLabel: 'button_wallet_selected',
    });
  };

  const showProfile = () => {
    PostGaAnalytics('send', {
      hitType: 'event',
      eventCategory: 'workout:wallet',
      eventAction: 'click:button',
      eventLabel: 'button_wallet_profile',
    });

    modalStore.toggleModal('modalDataProfile', true);
  };

  const renderDataPeople = () => {
    if (peopleStore.peopleName !== null && peopleStore.peopleName !== '') {
      return (
        <div className="wrapper-data">
          <DataPeopleWorkout />
        </div>
      );
    }

    return false;
  };

  const renderButtonSend = () => {
    if (peopleStore.peopleId !== null && peopleStore.peopleId !== undefined) {
      return (
        <ButtonsContainer>
          <Button
            minWidth="initial"
            className="button-send"
            onClick={validateInputTraining}
          >
            {i18n.t('select')}
          </Button>

          <Button
            className="button-profile"
            onClick={showProfile}
          >
            {i18n.t('search_client_button')}
          </Button>
        </ButtonsContainer>
      );
    }

    return false;
  };

  const validateTotalPass = () => {
    if (peopleStore.origin === 'totalpass_smartfit') {
      modalStore.toggleModal('modalTotalPass', true);
      appStore.toggleloading(false);
    } else {
      peopleStore.setPlanSmartFit();
    }
  };

  const loadingDataPeople = async (event) => {
    event.preventDefault();
    appStore.toggleloading(true);

    try {
      if(toggleStore.allowRenderCountry('allowRenderSearchSelectCountry')) {
        const { locale } = selectCountryStore;

        await peopleStore.doRequestFindPeopleLocale({
          registryNumber,
          locale,
        });
      } else {
        await peopleStore.doRequestFindPeople({
          registryNumber,
        });
      }


      validateTotalPass();
      appStore.toggleloading(false);
    } catch (error) {
      const msgTitle = i18n.t('student_not_found');
      const msgDescription = i18n.t('validate_cpf');
      const params = {
        hitType: 'event',
        eventCategory: 'workout:home',
        eventAction: 'modal_message',
        eventLabel: 'cpf_not_found',
      };

      modalStore.setModalAnalytics(params);
      appStore.toggleloading(false);
      modalStore.setTitle(msgTitle);
      modalStore.setDescription(msgDescription);
      modalStore.showModalError(error);
    }
  };

  const setValueRegister = (event) => {
    setRegistryNumber(event.target.value);
  };

  const eventButtonHeader = () => {
    history.goBack();
  };

  const hasBack = true;

  const renderOptionSelectCountry = () => {
    if(toggleStore.allowRenderCountry('allowRenderSearchSelectCountry')) {
      return (
        <SelectCountry />
      );
    }

    return '';
  };

  const renderTextDocument = () => {
    if(toggleStore.allowRenderCountry('allowRenderSearchSelectCountry')) {
      selectCountryStore.updateLabelDocument();
      return selectCountryStore.textDocument;
    }

    return i18n.t('search_client_label');
  };

  return (
    <SendTrainingWalletTeacherContainer>
      <header className="wrapper-header">
        <HeaderSession
          statusActionButton={false}
          isBack={hasBack}
          eventButton={eventButtonHeader}
          nameClass="w-btn-back"
        />
      </header>

      <Container className="wrapper-content">
        <FlexDiv display="inline-flex" width="auto" minHeight="initial">
          <Title>{i18n.t('search_client_title')}</Title>
          <SubTitle
            fontSize="16px"
            fontWeight="325"
            className="sub-title-send"
          >
            {i18n.t('select_client_send_training')}
          </SubTitle>

          <NewTrainingSuggestion />

          <form className="w-form-search">
            {renderOptionSelectCountry()}
            <Fieldset>
              <FormGroup className="w-form-group" noMarginBottom>
                <Label color="#fff" padding="0px 24px 0px 0px">{renderTextDocument()}</Label>
                <Input
                  type="text"
                  className="search__id"
                  onChange={event => setValueRegister(event)}
                />
                <Button
                  primary="true"
                  large="true"
                  className="w-btn-primary"
                  onClick={event => loadingDataPeople(event)}
                >
                  {i18n.t('find_button')}
                </Button>
              </FormGroup>
            </Fieldset>
          </form>

        </FlexDiv>
        <div className="wrapper-data">
          {renderDataPeople()}
          {renderButtonSend()}
        </div>


        {modalStore.modalError && <ModalError />}
        {modalStore.modalNetwork && <ModalNetwork tryAgain={loadingDataPeople} />}
        {modalStore.modalTotalPass && <PlanTotalPass history={history} />}
        {modalStore.modalInpuTraining &&
          <ModalInputTraining onClickLeave={sendTraining} />
        }
      </Container>


    </SendTrainingWalletTeacherContainer>
  );
};


SendTrainingWalletTeacher.defaultProps = {
  history: {},
};

SendTrainingWalletTeacher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(SendTrainingWalletTeacher);
