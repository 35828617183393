import React, { useContext } from 'react';
import {
  FormGroup,
  Label,
  Input,
} from '../../components';
import Context from '../../stores/context';

const LabelExerciseIsometry = (props) => {
  const context = useContext(Context);
  const {
    i18n,
  } = context;

  const {
    infoLabel,
  } = props;

  const {
    values,
    handleChange,
    handleBlur,
  } = infoLabel;

  const series = (value) => {
    if (value.series > 0) {
      document.querySelector('#duration').focus();

      return true;
    }

    return false;
  };

  const duration = (value) => {
    if (value.duration >= 10) {
      document.querySelector('#rest').focus();
      return true;
    }

    return false;
  };

  return (
    <div className="w-training-main-info w-training-weight-training">
      <FormGroup justifyContent="center" noMarginBottom="true">
        <Label htmlFor="series">{i18n.t('series')}</Label>
        <Input
          type="number"
          id="series"
          square="true"
          small="true"
          width="48px"
          textAlign="center"
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={() => series(values)}
          placeholder="0"
          value={values.series || ''}
        />
      </FormGroup>

      <FormGroup justifyContent="center" noMarginBottom="true">
        <Label htmlFor="duration">{i18n.t('duration')}</Label>
        <Input
          type="number"
          id="duration"
          square="true"
          small="true"
          width="48px"
          textAlign="center"
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={() => duration(values)}
          placeholder="Seg"
          value={values.duration || ''}
        />
      </FormGroup>

      <FormGroup justifyContent="center" noMarginBottom="true">
        <Label htmlFor="rest">{i18n.t('rest')}</Label>
        <Input
          type="number"
          id="rest"
          square="true"
          small="true"
          width="48px"
          textAlign="center"
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Seg"
          value={values.rest || ''}
        />
      </FormGroup>
    </div>
  );
};

export default LabelExerciseIsometry;
