import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import {
  StyledCheckBox,
} from 'components';
import Context from 'stores/context';
import { PostGaAnalytics } from 'utils';
import TabsPaisOrCustomize from './TabsPaisOrCustomize';
import ShowErros from './ShowErros';
import LabelPatternExercise from './LabelPatternExercise';
import LabelPaisPatternExercise from './LabelPaisPatternExercise';
import LabelExerciseIsometry from './LabelExerciseIsometry';
import LabelExerciseCardio from './LabelExerciseCardio';
import SelectItensificationMethod from './SelectItensificationMethod';
import ObservationExercise from './ObservationExercise';
import Buttons from './Buttons';
import ModalExerciseForm from './ModalExerciseForm/ModalExerciseForm';

function FormExercise(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
    toggleStore,
  } = context;
  const {
    toggleIsometry,
    checkedIsometry,
    infoLabel,
    setErrors,
    listIntensification,
    cancelForm,
    textCancel,
    isCardio,
    isHit,
    selectedPaisOrCustomize,
    isFormEdit,
  } = props;

  const [loadingComponent, setLoadingComponent] = useState(true);

  const rulesCountryAndWallet = () => toggleStore.validateToggle({
    toggleUnitBlock: 'toogleUnitPaisBlock',
    toggleUnitAllow: 'toogleUnitPaisAllow',
    toggleCountry: 'allowPaisCountry',
  });

  const hiddenRulesPais = () => {
    const showPais = rulesPaisStore.showRulesPais('form');
    const allowPaisCountry = rulesCountryAndWallet();

    if (showPais === false || isHit || checkedIsometry || isCardio || allowPaisCountry === false) return true;
    return false;
  };

  useEffect(() => {
    const postGa = () => {
      if (hiddenRulesPais()) return false;

      PostGaAnalytics('send', {
        hitType: 'event',
        eventCategory: 'workout:current',
        eventAction: 'print:rules_pais_form',
        eventLabel: 'loading_premissas_pais_form',
      });

      setLoadingComponent(false);
      return true;
    };

    if (loadingComponent) {
      postGa();
    }
  });

  const renderLabelInput = () => {
    if (isCardio) {
      return (
        <LabelExerciseCardio infoLabel={infoLabel} />
      );
    }

    if (checkedIsometry) {
      return <LabelExerciseIsometry infoLabel={infoLabel} />;
    }

    if (isHit) {
      return '';
    }

    if (selectedPaisOrCustomize === 'premissas' && hiddenRulesPais() === false) {
      return (
        <LabelPaisPatternExercise
          infoLabel={infoLabel}
          optionSelectPais={rulesPaisStore.getSelectPaisFormExercise()}
        />
      );
    }

    return (
      <LabelPatternExercise infoLabel={infoLabel} />
    );
  };

  const renderIsometry = () => {
    if (isCardio || isHit) return '';

    return (
      <div className="w-isometry">
        <StyledCheckBox className="w-checkbox">
          <input
            type="checkbox"
            id="isometry"
            checked={checkedIsometry}
            onChange={() => {
              setErrors({});
              toggleIsometry();
            }}
          />
          <label htmlFor="isometry">
            <span className="w-sr-only">{i18n.t('isometry_exercise')}</span>
            <input type="hidden" id="hidden" />
          </label>
        </StyledCheckBox>
      </div>
    );
  };

  const renderIntensificationMethod = () => {
    if (isCardio || isHit) return '';

    return (
      <SelectItensificationMethod
        infoLabel={infoLabel}
        listIntensification={listIntensification}
      />
    );
  };

  const renderObservartin = () => <ObservationExercise infoLabel={infoLabel} />;

  const renderButtonsForm = () => (
    <Buttons
      infoLabel={infoLabel}
      cancelForm={cancelForm}
      textCancel={textCancel}
    />
  );

  const renderTabs = () => {
    if (hiddenRulesPais()) return '';

    return (
      <TabsPaisOrCustomize eventTab={props} infoLabel={infoLabel} />
    );
  };

  const renderListExerciseForm = () => {
    if (isFormEdit) return false;
    return (
      <ModalExerciseForm isCardio={isCardio} />
    );
  };

  return (
    <>
      {renderListExerciseForm()}

      {renderTabs()}

      <ShowErros infoLabel={infoLabel} />

      <div className="wrapper-form">
        {renderLabelInput()}

        {renderIsometry()}

        {renderIntensificationMethod()}

        {renderObservartin()}

        {renderButtonsForm()}

      </div>
    </>
  );
}

export default observer(FormExercise);
