import React, { useContext } from 'react';
import Context from 'stores/context';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import ItemPais from './ItemPais';

function AmountExercise(props) {
  const context = useContext(Context);
  const {
    i18n,
    rulesPaisStore,
  } = context;

  const {
    hasError,
  } = props;

  const textAmountExercise = () => (
    <Trans
      i18nKey="dropdown_pais_number_exercise"
      count={rulesPaisStore.dropDownPais.amountExercise}
      components={[<span className="color-white">*</span>]}
    />
  )

  return (
    <ItemPais
      rule={textAmountExercise()}
      checked={hasError}
      expect={i18n.t('dropdown_pais_number_exercise_expect', {
        min: rulesPaisStore.dataRules.min_exercises,
        max: rulesPaisStore.dataRules.max_exercises,
      })}
    />
  );
}

AmountExercise.defaultProps = {
  hasError: false,
};

AmountExercise.propTypes = {
  hasError: PropTypes.bool,
};

export default AmountExercise;
