import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  CloseWhite as CloseWhiteIcon,
  Profile as ProfileIcon,
} from '../icons';

const ButtonFilterTeacherStyle = styled.div`
  width: 100%;
  margin-left: 20px;
  margin-top: 4px;

  &.remove-margin {
    margin-left: 0px;
  }
`;

function ButtonFilterTeacher(props) {
  const {
    positionButton,
    clickShowListTeacher,
    isActiveButtonFilter,
  } = props;

  const getIcon = () => {
    if (isActiveButtonFilter) {
      return <CloseWhiteIcon className="icon-profile" />
    }

    return <ProfileIcon className="icon-profile" />
  }

  return (
    <ButtonFilterTeacherStyle
      className={positionButton ? '' : 'remove-margin'}
      onClick={() => {
        clickShowListTeacher();
      }}
    >
      {getIcon()}
    </ButtonFilterTeacherStyle>
  );
}

ButtonFilterTeacher.defaultProps = {
  clickShowListTeacher: () => {},
  positionButton: false,
  isActiveButtonFilter: true,

};

ButtonFilterTeacher.propTypes = {
  clickShowListTeacher: PropTypes.func,
  positionButton: PropTypes.bool,
  isActiveButtonFilter: PropTypes.bool,

};

export default ButtonFilterTeacher;
export { ButtonFilterTeacher };
