import styled, { css } from 'styled-components';
import { Box } from 'rebass';

const ItemList = styled(Box)`
  border-bottom: ${(props) => props.borderBottom || '1px solid #fff'};

  .wrapper-info-training {
    position: relative;
  }

  .wrapper-buttons {
    display: flex;
    height: 52px;

    .button-send {
      height: 40px;
      padding: 13px 20px;
    }

    .wrapper-dropdown {
      margin-right: 16px;
    }

    .color-red {
      color: #C92C3F;
    }
  }

  .name-teacher {
    margin-top: 12px;
  }

  ${(props) => props.wallet
    && css`
      .wrapper-buttons {
        height: auto;
        align-items: center;
      }
    `};
`;

export default ItemList;
export { ItemList };
