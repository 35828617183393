import React, { useContext } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';

const DateWorkoutStyle = styled.div`
  color: ${(props) => props.theme.colors.white};

  .w-title {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin-bottom 8px;
  }

  .wrapper-content {
    display: flex;
  }

  .date-training {
    display: flex;
    justify-content: center;
    background-color: #3b3b40;
    border: 1px solid ${(props) => props.theme.colors.yellow};
    border-radius: 4px;
    width: 179px;
    height: 70px;
    align-items: center;
    margin-bottom: 24px;
  }

  .text-recomendation {
    width: 400px;
    margin-left: 20px;

    .text {
      font-family: 'Gotham';
      font-style: normal;
      font-weight: 325;
      font-size: 16px;
      color: ${(props) => props.theme.colors.grayLight};
    }
  }

  .change-date {
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.yellow};
    border: none;
    text-decoration: underline;
    margin-top: 17px;
  }
`;

function DateWorkout() {
  const context = useContext(Context);
  const {
    i18n,
    trainingCreationStore,
    peopleStore,
    modalStore,
    buildTrainingStore,
  } = context;

  const getDateFinishTraining = () => trainingCreationStore.dateFinishCurrent;
  const openModalDateFinishid = () => {
    modalStore.toggleModal('modalDateTraining', true);
  };

  const allowEditDateTraining = buildTrainingStore.toggleAllowEditDateTraining;
  const allowShowDate = buildTrainingStore.resendTraining;

  if (allowEditDateTraining === false && allowShowDate) {
    trainingCreationStore.setDateFinishEdit();
    return false;
  }

  return (
    <DateWorkoutStyle>
      <p className="w-title">{i18n.t('validate_training')}</p>

      <div className="wrapper-content">
        <div className="date-training">
          {i18n.t('days', { days: getDateFinishTraining() })}
        </div>

        <div className="text-recomendation">
          <p className="text">{i18n.t('recommendation_praze_training', { peopleName: peopleStore.peopleName })}</p>
          <button
            className="change-date"
            type="button"
            onClick={openModalDateFinishid}
          >
            {i18n.t('title_modal_rules_date')}
          </button>
        </div>
      </div>
    </DateWorkoutStyle>
  );
}

export default DateWorkout;
