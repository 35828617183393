import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CountStyle = styled.div`
  display: none;
  background-color: ${(props) => props.theme.colors.yellow};
  color: ${(props) => props.theme.colors.black};
  border-radius: 26px;
  width: 24px;
  height: 24px;
  font-size: ${(props) => props.theme.fontSizes[0]};
  line-height: 25px;
  margin-left: 7px;

  &.active {
    display: block;
  }
`;

function Count(props) {
  const {
    count,
    toogle,
  } = props;

  return (
    <CountStyle className={toogle ? 'active' : ''}>
      {count}
    </CountStyle>
  );
}

Count.defaultProps = {
  count: 0,
  toogle: false,
};

Count.propTypes = {
  count: PropTypes.number,
  toogle: PropTypes.bool,
};

export default Count;
export { Count };
