import styled from 'styled-components';

const StyledLink = styled.div`
  position: relative;
  display: inline-flex;
  color: ${(props) => props.color || '#fff'};
  font-family: 'Gotham';
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: ${(props) => props.fontweight || '500'};
  line-height: ${(props) => props.lineHeight || '21px'};
  text-transform: ${(props) => props.textTransform || 'uppercase'};
  text-decoration: none;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.color || '#fff'};
    transition: transform 0.2s ease-out;
  }

  &:hover,
  &:focus {
    &::before {
      transform: scale(0.9);
    }
  }

  &:active {
    &::before {
      transform: scale(0.8);
    }
  }
`;

export { StyledLink };
export default StyledLink;
