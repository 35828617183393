import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Context from 'stores/context';
import {
  Tabs,
} from 'components';

const TabsPaisOrCustomise = (props) => {
  const context = useContext(Context);
  const { i18n } = context;
  const { eventTab, infoLabel } = props;
  const {
    errors,
    values,
  } = infoLabel;
  const {
    selectedTabPaisOrCustomize,
    selectedPaisOrCustomize,
  } = eventTab;

  const [tabActive, setTabActive] = useState(selectedPaisOrCustomize);

  const resetValues = () => {
    values.duration = '';
    values.interval = '';
    values.max_interval = '';
    values.max_repetitions = '';
    values.min_interval = '';
    values.min_repetitions = '';
    values.repeats = '';
    values.rest = '';
    values.series = '';

    errors.duration = '';
    errors.interval = '';
    errors.max_interval = '';
    errors.max_repetitions = '';
    errors.min_interval = '';
    errors.min_repetitions = '';
    errors.repeats = '';
    errors.rest = '';
    errors.series = '';
  };

  const activeTab = (e) => {
    const itemSelected = e.currentTarget.id;
    setTabActive(itemSelected);
    selectedTabPaisOrCustomize(itemSelected);
    resetValues();
  };

  const renderButton = () => {
    const listOptions = [
      'premissas',
      'customize',
    ];

    const buttonsTabs = listOptions.map(item => (
      <button
        key={item}
        id={item}
        type="button"
        className={tabActive === item ? 'w-active' : ''}
        onClick={e => activeTab(e)}
      >
        <span>{`${i18n.t(item)}`}</span>
      </button>
    ));

    return buttonsTabs;
  };

  return (
    <Tabs className="w-tabs" tabWhite="true">{renderButton()}</Tabs>
  );
};

TabsPaisOrCustomise.defaultProps = {
  eventTab: {
    selectedTabPaisOrCustomize: () => '',
  },
  infoLabel: {
    errors: {},
    values: {},
  },
};

TabsPaisOrCustomise.propTypes = {
  eventTab: PropTypes.shape({
    selectedTabPaisOrCustomize: PropTypes.func,
    selectedPaisOrCustomize: PropTypes.string,
  }),
  infoLabel: PropTypes.shape({
    errors: PropTypes.object,
    values: PropTypes.object,
  }),
};

export default TabsPaisOrCustomise;
