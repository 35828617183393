import styled from 'styled-components';
import { Button } from 'rebass';

const ButtonDark = styled(Button)`

  border-radius: 8px;
  padding: 23px 23px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.darkgray};
  font-size: ${(props) => props.theme.fontSizes[2]};
  border: 1px solid ${(props) => props.theme.colors.bordeDarkLight};
  &:last-child {
    margin-right: 0px;
  }

  &:active {
    border: 1px solid ${(props) => props.theme.colors.yellow};
  }

  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  @media(${(props) => props.theme.mediaScreen.minTablet}) and (${(props) => props.theme.mediaScreen.maxTablet}){
    width: 245px;
    margin-right: 10px;
  }

  @media(${(props) => props.theme.mediaScreen.desktop}) {
    width: 349px;
    margin-right: 22px;
  }

`;

export { ButtonDark };
export default ButtonDark;
