import React, { useContext } from 'react';
import Context from 'stores/context';

import {
  HeaderModal,
  TitleModal,
  Button,
} from 'components';

const HeaderModalLocation = () => {
  const context = useContext(Context);
  const {
    i18n,
    locationStore,
  } = context;


  const closeModal = () => {
    locationStore.setShowModalLocation(false);
    locationStore.reset();
  };

  return (
    <HeaderModal>
      <TitleModal padding="0px 0px 0px 34px">
        {i18n.t('change_acronym')}
      </TitleModal>

      <Button
        back="true"
        primary="true"
        noBackgroundColor="true"
        rounded="true"
        close="true"
        className="w-btn-close"
        onClick={closeModal}
      />
    </HeaderModal>
  );
};

export default HeaderModalLocation;
