import styled from 'styled-components';
import IcoArrow from './icon/arrow-right.svg';

const ListInfoModalStyle = styled.ul`
  list-style: none;
  height: 400px;
  overflow-x: hidden;

  .list__notices {
    border-bottom: 1px solid #eee;
    display: flex;

    &.locations {
      align-items: center;

      .list__notices__content {
        padding-bottom: 17px;
        padding-top: 24px;
      }
    }

    &__content {
      width: 90%;
      text-align: initial;
      padding-left: 34px;
      padding-top: 20px;
      font-family: Gotham;
      padding-right: 5px;
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 6px;
    }

    &__description {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 540px;
    }

    &__warning {
      display: flex;

      &--circle {
        display: block;
        width: 12px;
        height: 12px;
        background-color: red;
        border-radius: 10px;
        align-self: center;
      }

      &--arrow {
        display: block;
        width: 10px;
        height: 20px;
        background-image: url(${IcoArrow});
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

`;

export default ListInfoModalStyle;
export { ListInfoModalStyle };
