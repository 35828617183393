import styled from 'styled-components';

const Box = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${(props) => props.maxWidth || '480px'};
  background-color: ${(props) => (props.outline ? 'transparent' : '#fff')};
  padding: 24px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) => (props.outline ? '#202020' : '#fff')};

  @media screen and (min-width: 768px) {
    width: ${(props) => props.width || '100%'};
    padding: 32px;
  }
`;

export { Box };
export default Box;
