import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import IconChange from './icon/change.svg';
import { Button } from '../Button/Button';

const HeaderSessionStyle = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 72px;

  .w-btn-back {
    position: absolute
    top: 0;
    left: 0;
  }

  .wrapper-active {
    height: 48px;
  }

  .w-title {
    width: 100%;
    min-height: 48px;
    color: #fff;
    font-family: 'Gotham';
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    box-sizing: border-box;

    &.schedule {
      .regional-change {
        display: flex;
        align-items: center;
      }

      .button-change {
        background-image: url(${IconChange});
        background-repeat: no-repeat;
        background-position: center;
        width: 32px;
        height: 32px;
        margin-left: 9px;
        border: none;
        background-color: ${(props) => props.theme.colors.black};
      }
    }

    .subtTitle {
      font-size: 23px;
    }

    @media screen and (min-width: 544px) {
      width: auto;
    }

    @media screen and (min-width: 768px) {
      width: auto;
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 0;
    }

    small {
      font-family: Gotham;
      font-style: normal;
      font-weight: 325;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #808080;
    }
  }

  @media screen and (min-width: 544px) {
    padding-top: 0;
    padding-left: 72px;

    .w-title {
      line-height: 0px;

      .subtTitle {
        line-height: 40px;
      }
    }
  }

  .w-btn-new-workout {
    width: 100%;

    @media screen and (min-width: 544px) {
      width: auto;
    }
  }

  @media screen and (max-width: 540px) {
    padding-top: 0px;
    justify-content: start;

    .w-btn-back {
      position: initial;
      top: 0;
      left: 0;
    }

    .w-title {
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 200px;
      display: block;
      padding-right: 0px;

      .subtTitle {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    ${(props) => props.actionButton === true
      && css`
        justify-content: space-between;

        .w-btn-back, .wrapper-active {
          margin-top: 60px;
        }

        .w-title {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 295px;
          margin-left: 0px;
        }
      `};
  }

  ${(props) => props.actionButton === true
    && css`
    @media screen and (min-width: 544px) and (max-width: 650px){
      .w-title {
        width: 290px;
      }
    }
  `};

  .health-insurance {
    font-family: ${(props) => props.theme.fonts.Gotham};
    line-height: 24px;

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSizes[0]};
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.white};
    }

    .plan {
      font-style: normal;
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSizes[2]};
      color: ${(props) => props.theme.colors.greenLight};
      text-transform: uppercase;

      &.smart {
        color: ${(props) => props.theme.colors.yellow};
      }
    }
  }

  @media(${(props) => props.theme.mediaScreen.cellphone}) {
    .health-insurance {
      margin-left: 60px;
      margin-top: 16px;
    }
  }
`;

export default class HeaderSession extends Component {
  static contextType = Context;

  static renderHeaderTitle(schedule, title, subTitle, eventChangeAcronym) {
    if (schedule) {
      return (
        <h1 className="w-title schedule">
          <small>{title}</small>
          <div className="regional-change">
            <p className="subtTitle">{subTitle}</p>
            <button
              type="button"
              aria-label="eventChangeAcronym"
              className="button-change"
              onClick={eventChangeAcronym}
            />
          </div>
        </h1>
      );
    }

    return (
      <h1 className="w-title">
        <small>{title}</small>
        <p className="subtTitle">{subTitle}</p>
      </h1>
    );
  }

  renderButton(eventButton, nameClass, isBack) {
    const { i18n } = this.context;
    const hasBack = !!(isBack);
    const hasClosed = !(hasBack);

    return (
      <Button
        back={hasBack}
        close={hasClosed}
        tertiary="true"
        rounded="true"
        className={nameClass}
        onClick={eventButton}
      >
        <span>{i18n.t('back_navigate')}</span>
      </Button>
    );
  }

  render() {
    const {
      statusActionButton,
      isBack,
      eventButton,
      nameClass,
      title,
      subTitle,
      titlePlan,
      namePlan,
      children,
      schedule,
      eventChangeAcronym,
    } = this.props;

    return (
      <HeaderSessionStyle actionButton={statusActionButton} className="header__session">
        {this.renderButton(eventButton, nameClass, isBack)}

        {HeaderSession.renderHeaderTitle(schedule, title, subTitle, eventChangeAcronym)}

        <div className="health-insurance">
          <p className="title">{titlePlan}</p>
          <p className={`plan ${namePlan === 'TotalPass' ? '' : 'smart'}`}>{namePlan}</p>
        </div>
        {children}
      </HeaderSessionStyle>
    );
  }
}

HeaderSession.defaultProps = {
  statusActionButton: false,
  isBack: false,
  eventButton: () => {},
  nameClass: '',
  title: '',
  subTitle: '',
  titlePlan: '',
  namePlan: '',
  children: <div />,
  schedule: false,
  eventChangeAcronym: () => {},
};

HeaderSession.propTypes = {
  statusActionButton: PropTypes.bool,
  isBack: PropTypes.bool,
  eventButton: PropTypes.func,
  nameClass: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titlePlan: PropTypes.string,
  namePlan: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  schedule: PropTypes.bool,
  eventChangeAcronym: PropTypes.func,
};

export { HeaderSession };
