const RenderNameExercise = (obj) => {
  const getNameExercicio = (name) => {
    if (typeof name === 'string') {
      const aux = name.toLocaleLowerCase();
      return aux.charAt(0).toUpperCase() + aux.slice(1);
    }

    return name;
  };

  const getNumberEquipment = (equipment) => {
    if (equipment && equipment !== '-') {
      return `${equipment} - `;
    }
    return '';
  };

  const paramsName = obj.name || obj.title;
  const name = getNameExercicio(paramsName);
  const equipament = getNumberEquipment(obj.equipment);
  const nameExercise = `${equipament}${name}`;

  return nameExercise.trim();
};

export default RenderNameExercise;
