import React, { useContext } from 'react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ConcatUnitInUrl } from 'utils';
import { debounce } from 'lodash';

import {
  Column,
  FlexDiv,
  Fieldset,
  FormGroup,
  Input,
  Label,
  HeaderSession,
  Title,
} from 'components';

import ListUnity from './ListUnity';

import ModalChangeUnit from './ModalChangeUnit';

const ChangeUnitStyle = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 60px;
  align-items: flex-start;

  .select-company {
    margin-bottom: 42px;

    .title-company {
      font-family: Gotham;
      font-style: normal;
      font-weight: 900;
      font-size: 19px;
      color: #fff;
      margin-bottom: 16px;
    }

    .form-radio {
      display: flex;
    }
  }

  .w-form-search-unit {
    width: 100%;

    .w-form-group {
      display: flex;
      justify-content: space-between;

      input {
        margin-bottom: 18px;
        width: 100%;

        @media screen and (min-width: 544px) {
          margin-bottom: 0;
        }
      }
    }
  }

  .wrapper-list-unit {
    color #fff;
  }
`;

const ChangeUnit = (props) => {
  const context = useContext(Context);
  const {
    i18n,
    sessionStore,
    modalStore,
    locationStore,
  } = context;

  const {
    history,
  } = props;

  const onChange = (e, target) => {
    locationStore.searchValue = target.value;
  };


  const debounced = debounce(onChange, 500);

  return (
    <>
      <Column sm="12" xs="12">
        <HeaderSession
          statusActionButton={false}
          isBack
          eventButton={() => {
            const url = ConcatUnitInUrl('/select-option-work/list-exercise');
            history.push(url);
          }}
          nameClass="w-btn-back"
          title={sessionStore.renderListNavigator(i18n.t('find_unit'))}
          subTitle={i18n.t('find_unit')}
        />
      </Column>
      <ChangeUnitStyle>
        <Title marginBottom={34}>
          {i18n.t('find_unit')}
        </Title>
        <FlexDiv display="flex" width="100%" minHeight="initial">
          <form className="w-form-search-unit">
            <Fieldset>
              <FormGroup className="w-form-group" noMarginBottom>
                <Label color="#fff" padding="0px 24px 0px 0px">{i18n.t('find_name_unit')}</Label>
                <Input
                  type="text"
                  onChange={(event) => {
                    debounced(event, event.target);
                  }}
                />
              </FormGroup>
            </Fieldset>
          </form>
        </FlexDiv>
        <ListUnity />

        {modalStore.modalSuccesUnit && <ModalChangeUnit />}
      </ChangeUnitStyle>
    </>
  );
};

ChangeUnit.defaultProps = {
  history: {},
};

ChangeUnit.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(ChangeUnit);
