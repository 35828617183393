import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import Context from 'stores/context';
import PropTypes from 'prop-types';

import {
  Button,
  GridContainer as Container,
  FlexDiv,
  HeaderSession,
  Column,
  Title,
  SubTitle,
  Fieldset,
  FormGroup,
  Label,
  Input,
} from 'components';

import { ConcatUnitInUrl, PostGaAnalytics } from 'utils';

import ModalSuccess from 'components/modal/modal-success';
import ModalError from 'components/modal/modal-error';
import ModalNetwork from 'components/modal/modal-network';

const TeacherTrainingCreationStyle = styled.div`
  .wrapper-content {
    margin-top: 55px;
  }

  .w-form-name-training {
    margin-top: 40px;
  }

  .limit-text {
    color: ${props => props.theme.colors.white};
    text-align: end;
    width: 100%;
    margin-top: 10px;
  }

  .w-button {
    margin-top: 80px;
    width: 100%;
    text-align: center;
  }

  @media(${props => props.theme.mediaScreen.desktop}) {
    width: auto;

    .sub-title-send {
      width: 100%;
      text-align: center;
    }
  }
`;

class TeacherTrainingCreation extends Component {
  static contextType = Context;

  state = {
    nameTraining: '',
  };


  constructor(props) {
    super(props);
    this.saveTraining = this.saveTraining.bind(this);
    this.setNameTraining = this.setNameTraining.bind(this);

    PostGaAnalytics('send', {
      hitType: 'pageview',
      page: ' blank_workout',
    });
  }

  setNameTraining(event) {
    const {
      teacherTrainingStore,
      modalStore,
      i18n,
    } = this.context;
    const { history } = this.props;
    const { nameTraining } = this.state;

    event.preventDefault();
    teacherTrainingStore.setAlias(nameTraining);

    if (this.hasAliasInListWorkout()) {
      modalStore.title = i18n.t('alias_repetition');
      modalStore.description = '';
      modalStore.toggleModal('modalMessage', true);
    } else {
      const urlLevelChoose = ConcatUnitInUrl('/anamnesis/level-choose');
      history.push(urlLevelChoose);
    }
  }

  hasAliasInListWorkout() {
    const { nameTraining } = this.state;
    const { teacherTrainingStore } = this.context;

    const listAlias = teacherTrainingStore.listExercise
      .filter(workout => workout.alias === nameTraining);

    return listAlias.length > 0;
  }

  saveTraining(event) {
    const {
      teacherTrainingStore,
      modalStore,
      i18n,
      appStore,
      trainingCreationStore,
    } = this.context;
    const { nameTraining } = this.state;

    event.preventDefault();

    teacherTrainingStore.setAlias(nameTraining);
    teacherTrainingStore.setWeightTrainingId(trainingCreationStore.weightTrainingId);
    let msgTitle;
    let msgDescription;

    appStore.toggleloading(true);

    teacherTrainingStore
      .doRequestSaveExerciseTeacher()
      .then(() => {
        appStore.toggleloading(false);
        msgTitle = i18n.t('register_training_title');
        msgDescription = i18n.t('register_training_subtitle');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription(msgDescription);
        modalStore.toggleModal('modalFormTeacherSucess', true);
      })
      .catch((error) => {
        msgTitle = i18n.t('register_training_error_title');
        msgDescription = i18n.t('register_training_error_subtitle');
        modalStore.setTitle(msgTitle);
        modalStore.setDescription(msgDescription);
        teacherTrainingStore.setReloadListExercise(false);
        appStore.toggleloading(false);
        modalStore.showModalError(error);
      });
  }

  renderButtonAction() {
    const {
      teacherTrainingStore,
      i18n,
    } = this.context;

    if (teacherTrainingStore.teacherInteface === false) {
      return (
        <Button
          second="true"
          large="true"
          onClick={this.saveTraining}
        >
          {i18n.t('save')}
        </Button>
      );
    }

    return (
      <Button
        second="true"
        large="true"
        onClick={event => this.setNameTraining(event)}
      >
        {i18n.t('next')}
      </Button>
    );
  }

  render() {
    const { i18n, sessionStore, modalStore } = this.context;
    const { history } = this.props;
    const { nameTraining } = this.state;

    const eventButtonHeader = () => {
      history.goBack();
    };

    const modalSuccessFormTeacher = modalStore.modalFormTeacherSucess && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalFormTeacherSucess', false);
          modalStore.toggleModal('modalSuccessSend', false);
          const url = ConcatUnitInUrl('/profile');
          history.push(url);
        }}
      />
    );

    const modalMessage = modalStore.modalMessage && (
      <ModalSuccess
        onClick={() => {
          modalStore.toggleModal('modalMessage', false);
          modalStore.toggleModal('modalFormTeacherSucess', false);
          modalStore.toggleModal('modalSuccessSend', false);
        }}
      />
    );

    return (
      <TeacherTrainingCreationStyle>
        <Container>
          <Column sm="12" xs="12" spacing="0px">
            <HeaderSession
              statusActionButton={false}
              isBack
              eventButton={eventButtonHeader}
              nameClass="w-btn-back"
              title={sessionStore.renderListNavigator(i18n.t('profile_button_new_training'))}
              subTitle={i18n.t('register_create_new_training')}
            />
          </Column>

          <FlexDiv display="inline-flex" width="auto" minHeight="initial" className="wrapper-content">
            <Title>
              {i18n.t('name_training')}
            </Title>
            <SubTitle
              fontSize="16px"
              fontWeight="325"
              className="sub-title-send"
            >
              {i18n.t('create_name_training_easy')}
            </SubTitle>

            <form className="w-form-name-training">
              <Fieldset>
                <FormGroup className="w-form-group" noMarginBottom>
                  <Label color="#fff" padding="0px 24px 0px 0px">
                    {i18n.t('name_training')}
                  </Label>
                  <Input
                    type="text"
                    maxLength="100"
                    onChange={event => this.setState({ nameTraining: event.target.value })}
                  />
                  <div className="limit-text">
                    {i18n.t('limit_name_training', {
                      lenghtInput: nameTraining.length,
                      limit: '100',
                    })}
                  </div>

                  <div className="w-button">
                    {this.renderButtonAction()}
                  </div>
                </FormGroup>
              </Fieldset>
            </form>

          </FlexDiv>
        </Container>
        {modalStore.modalError && <ModalError />}
        {modalStore.modalNetwork && <ModalNetwork tryAgain={this.saveTraining} />}
        {modalSuccessFormTeacher}
        {modalMessage}
      </TeacherTrainingCreationStyle>
    );
  }
}

TeacherTrainingCreation.defaultProps = {
  history: {},
};

TeacherTrainingCreation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default observer(TeacherTrainingCreation);
