import styled from 'styled-components';

const StyleEditDuration = styled.div`
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 544px) {
    max-width: 480px;
  }

  legend {
    color: #000;
    font-family: 'Gotham';
    font-size: 24px;
    font-weight: 300;
    line-height: 29px;
    text-align: center;
    margin-bottom: 34px;

    @media screen and (min-width: 544px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .w-training-main-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    > div {
      display: inline-flex;
      width: auto;

      &:first-child {
        @media screen and (min-width: 544px) {
          width: 60px;
        }
      }

      @media screen and (min-width: 544px) {
        max-width: 105px;
      }
    }

    label {
      font-size: 16px;
      line-height: 19px;
      justify-content: center;
      color: #808080;
      text-transform: uppercase;
      padding-left: 0;
      padding-right: 0;
    }
  }

  label {
    font-size: 13px;
    line-height: 16px;
  }
`;

export { StyleEditDuration };
export default StyleEditDuration;
