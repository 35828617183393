import React, { useContext } from 'react';
import {
  Heading, Text, Flex,
} from 'rebass';
import {
  Button,
} from 'components';
import Context from 'stores/context';
import ModalWrapper from 'components/modal/modal-wrapper';
import PropTypes from 'prop-types';

function ModalInputTraining({ onClickLeave }) {
  const context = useContext(Context);
  const { modalStore, i18n } = context;

  const closeCallback = () => {
    modalStore.toggleModal('modalInpuTraining', false);
  };

  const leaveCallback = (e) => {
    modalStore.toggleModal('modalAnamnesis', false);
    if (onClickLeave) {
      onClickLeave(e);
    }
  };

  return (
    <ModalWrapper onClick={closeCallback}>
      <Heading
        my={[3]}
        color="#008a1f"
        fontSize="20px"
      >
        {i18n.t('title_modal_input_training')}
      </Heading>
      <Text mb={[4]} fontSize="14px">
        {i18n.t('subtitle_modal_input_training')}
      </Text>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        width={330}
      >
        <Button
          primary
          onClick={leaveCallback}
          className="button-new-training"
          marginButton="0px 0px 18px 0px"
        >
          {i18n.t('continue')}
        </Button>

        <Button
          fourth
          onClick={closeCallback}
          className="button-new-training"
          marginButton="0px 0px 18px 0px"
        >
          {i18n.t('close')}
        </Button>
      </Flex>
    </ModalWrapper>
  )
}

ModalInputTraining.defaultProps = {
  onClickLeave: () => {},
};

ModalInputTraining.propTypes = {
  onClickLeave: PropTypes.func,
};

export default ModalInputTraining;
