/* eslint-disable no-loop-func */
import {
  observable, action, decorate,
} from 'mobx';

import {
  getBookings as getBookingsApi,
  getPeopleListBookings as getPeopleListBookingsApi,
  postPresenceBookings as postPresenceBookingsApi,
} from '../services/api';

class BookingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.listBookings = [];
    this.dateBookings = [];
    this.amountDay = 6;
    this.idPeopleBooking = null;
    this.datePeopleMeet = null;
    this.bookingObj = null;
    this.showButtonConfirmed = null;
  }

  reset() {
    this.dateBookings = [];
    this.listBookings = [];
  }

  setListBookings(data) {
    this.listBookings = data;
  }

  concatZero(value) {
    return (value < 10) ? `0${value}` : value;
  }

  makeLabelDate(today, month, year) {
    const dateLabel = this.concatZero(today);
    const monthLabel = this.concatZero(month);

    if (year === undefined) {
      return `${dateLabel}/${monthLabel}`;
    }

    return `${dateLabel}/${monthLabel}/${year}`;
  }

  makeHour(startAt) {
    const dateBooking = new Date(startAt);
    const hour = this.concatZero(dateBooking.getHours());
    const minutes = this.concatZero(dateBooking.getMinutes());

    return `${hour}:${minutes}h`;
  }

  filterDateList(today, month) {
    const list = this.listBookings.filter((booking) => {
      const dateBooking = new Date(booking.start_at).getDate();
      const monthBooking = new Date(booking.start_at).getMonth() + 1;

      if (dateBooking === today && monthBooking === month) {
        return booking;
      }

      return false;
    });

    return list;
  }

  insertIntroDateBookings(list, today, month, year) {
    if (list.length > 0) {
      const date = {
        dateSchedule: this.makeLabelDate(today, month, year),
        listSchedule: list,
      };

      this.dateBookings.push(date);
    }
  }

  makeListBookings() {
    const todayCurrent = new Date();
    let today = todayCurrent.getDate();
    let month = todayCurrent.getMonth() + 1;
    let year = todayCurrent.getFullYear();
    let index;

    for (index = 0; index < this.amountDay; index += 1) {
      const list = this.filterDateList(today, month);

      this.insertIntroDateBookings(list, today, month, year);

      todayCurrent.setDate(todayCurrent.getDate() + 1);
      today = todayCurrent.getDate();
      month = todayCurrent.getMonth() + 1;
      year = todayCurrent.getFullYear();
    }
  }

  doRequestBookings(acronym) {
    const promiseBookings = async (resolve, reject) => {
      try {
        const res = await getBookingsApi(
          { acronym },
          this.rootStore.sessionStore.token,
        );

        this.reset();
        this.setListBookings(res.data.bookings);
        this.makeListBookings();
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseBookings(resolve, reject)
    ));
  }

  resetDatePeople() {
    this.idPeopleBooking = null;
    this.datePeopleMeet = null;
    this.bookingObj = null;
    this.showButtonConfirmed = null;
  }

  setDateMeet(booking) {
    const today = booking.dataConvert.getDate();
    const month = booking.dataConvert.getMonth() + 1;
    const dateMeet = this.makeLabelDate(today, month);
    const hourMeet = this.makeHour(booking.start_at);

    this.datePeopleMeet = `${dateMeet} - ${hourMeet}`;
  }

  setShowButton(booking) {
    const today = booking.dataConvert.getDate();
    const month = booking.dataConvert.getMonth() + 1;

    const currentDate = new Date();
    const currentDateToday = currentDate.getDate();
    const currentDateMonth = currentDate.getMonth() + 1;

    const hasDate = (today === currentDateToday);
    const hasMonth = (month === currentDateMonth);

    const showButton = (hasDate && hasMonth);
    this.showButtonConfirmed = showButton;
  }

  setRulesToShowDatePeople(booking) {
    this.bookingObj = booking;
    this.idPeopleBooking = booking.id;
    this.setDateMeet(booking);
    this.setShowButton(booking);
  }

  filterCurrentBooking(data) {
    if (data.length <= 0) return false;
    const listBooking = data.filter((booking) => booking.status === 'scheduled' || booking.status === 'confirmed');

    if (listBooking.length <= 0) return false;

    const newListSchedued = listBooking.map((booking) => {
      const convetDate = new Date(booking.start_at);
      return {
        ...booking,
        dataConvert: convetDate,
      };
    });

    newListSchedued.sort((a, b) => new Date(a.dataConvert) - new Date(b.dataConvert));

    this.setRulesToShowDatePeople(newListSchedued[0]);
    return true;
  }

  doRequestDateBookings(objParams) {
    const promiseBookings = async (resolve, reject) => {
      try {
        const res = await getPeopleListBookingsApi(
          objParams,
          this.rootStore.sessionStore.token,
        );
        this.resetDatePeople();
        this.filterCurrentBooking(res.data.bookings);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseBookings(resolve, reject)
    ));
  }

  resultBeforeHourConfirmed(result) {
    if (result >= 0 && result <= 4) {
      return 'faixa_1_before';
    }

    if (result >= 5 && result <= 9) {
      return 'faixa_2_before';
    }

    if (result >= 10 && result <= 14) {
      return 'faixa_3_before';
    }

    if (result >= 15 && result <= 19) {
      return 'faixa_4_before';
    }

    if (result >= 20 && result <= 24) {
      return 'faixa_5_before';
    }

    return '';
  }

  resultAfterHourConfirmed(result) {
    if (result <= 0 && result >= -4) {
      return 'faixa_1_after';
    }

    if (result <= -5 && result >= -9) {
      return 'faixa_2_after';
    }

    if (result <= -10 && result >= -14) {
      return 'faixa_3_after';
    }

    if (result <= -15 && result >= -19) {
      return 'faixa_4_after';
    }

    if (result <= -20 && result >= -24) {
      return 'faixa_5_after';
    }

    return '';
  }

  validateDataConfirmed() {
    const confirmed = new Date();

    const bookingToday = this.bookingObj.dataConvert.getDate();
    const bookingMonth = this.bookingObj.dataConvert.getMonth() + 1;
    const bookingYear = this.bookingObj.dataConvert.getFullYear();

    const confirmedToday = confirmed.getDate();
    const confirmedMonth = confirmed.getMonth() + 1;
    const confirmedYear = confirmed.getFullYear();

    const year = (bookingYear === confirmedYear);
    const month = (bookingMonth === confirmedMonth);
    const date = (bookingToday === confirmedToday);

    return (year && month && date);
  }

  calculandoHour() {
    const booking = this.bookingObj.dataConvert.getTime();
    const confirmed = new Date().getTime();
    const result = booking - confirmed;
    const time = Math.floor((result / (1000 * 60 * 60)) % 24);

    if (time <= 0) {
      return this.resultAfterHourConfirmed(time);
    }

    return this.resultBeforeHourConfirmed(time);
  }

  getTimeSendConfirmation() {
    const hasDateIntro = this.validateDataConfirmed();

    if (hasDateIntro) {
      return this.calculandoHour();
    }

    return 'long_time';
  }

  doRequestConfirmedTraining(objParams) {
    const promiseBookings = async (resolve, reject) => {
      try {
        const res = await postPresenceBookingsApi(
          objParams,
          this.rootStore.sessionStore.token,
        );

        this.resetDatePeople();
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };
    return new Promise((resolve, reject) => (
      promiseBookings(resolve, reject)
    ));
  }
}

decorate(BookingsStore, {
  listBookings: observable,
  dateBookings: observable,
  idPeopleBooking: observable,
  bookingObj: observable,
  datePeopleMeet: observable,
  showButtonConfirmed: observable,
  setListBookings: action,
  doRequestBookings: action,
  doRequestDateBookings: action,
  reset: action,
  insertIntroDateBookings: action,
  doRequestConfirmedTraining: action,
  resetDatePeople: action,
  setDateMeet: action,
  getTimeSendConfirmation: action,
  resultBeforeHourConfirmed: action,
});

export const BookingsSchema = {
  dateBookings: {
    type: 'list',
    schema: {
      dateSchedule: true,
      listSchedule: {
        type: 'list',
      },
    },
  },
};

export default BookingsStore;
