import {
  observable, action, decorate,
} from 'mobx';

import {
  SuggestionsTrainings,
  ListPrograms,
  IteacherTrainingStore,
} from './IteacherTrainingStore';

import {
  ListExerciseCreateTeacher,
} from '../IgenericTypes';

import {
  postSaveExerciseTeacher as postSaveExerciseTeacherApi,
  getSuggestionsTrainingSaveTeacher as getSuggestionsTrainingSaveTeacherApi,
  getListExerciseTeacher as getListExerciseTeacherApi,
  deleteExerciseTeacher as deleteExerciseTeacherApi,
  getListProgramsTeacher as getListProgramTeacherApi,
} from '../../services/api';


class TeacherTrainingStore implements IteacherTrainingStore {
  rootStore = {
    sessionStore: {
      token: '',
    },
    filterWorkoutStore: {
      getListExercise: (e: any) => e,
    },
    i18n: {
      t: (x: any) => x,
    },
  };

  suggestionsTrainings: SuggestionsTrainings[] = [];

  listPrograms: ListPrograms[] = [];

  programId = 0;

  programName = '';

  listExercise: ListExerciseCreateTeacher[] = [];

  listWorkoutTeacherView: ListExerciseCreateTeacher[] = [];

  weightTrainingId = 0;

  reloadListExercise = false;

  teacherInteface = false;

  teacherDuplicateTraining = false;

  alias = '';

  idExercise = 0;;

  finishListSuggestionTeacher = false;

  limitRender = 5;

  limitLoop = 1;

  currentWorkout = 0

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  setIdExercise(id: number): void {
    this.idExercise = id;
  }

  setProgramId(programId: number): void {
    this.programId = programId;
  }

  setProgramName(programName: string): void {
    this.programName = programName.replace(/(Coach|Entrenador|Físico)/gm, '');
  }

  setListPrograms(listPrograms: ListPrograms[]): void {
    this.listPrograms = listPrograms;
  }

  setTeacherDuplicateTraining(toogle: boolean): void {
    this.teacherDuplicateTraining = toogle;
  }

  setAlias(title: string): void {
    this.alias = title;
  }

  setTeacherInteface(toogle: boolean): void {
    this.teacherInteface = toogle;
  }

  setReloadListExercise(toogle: boolean): void {
    this.reloadListExercise = toogle;
  }

  setWeightTrainingId(id: number): void {
    this.weightTrainingId = id;
  }

  setListExercise(list: ListExerciseCreateTeacher[]): void {
    this.listExercise = list;
  }

  getListProgramSmart(res: any): ListPrograms[] {
    return res.data.programs.filter((program: ListPrograms) => {
      const { id } = program;
      return (id === 16 || id === 15 || id === 1);
    });
  }

  doRequestListProgramTeacher() {
    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await getListProgramTeacherApi(
          {},
          this.rootStore.sessionStore.token,
        );

        const listPrograms = this.getListProgramSmart(res);

        this.setListPrograms(listPrograms);
        this.setProgramId(this.listPrograms[0].id);
        this.setProgramName(this.listPrograms[0].name);
        res.data.allowProgram = listPrograms;
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  doRequestSaveExerciseTeacher() {
    const data = {
      teacher_weight_training: {
        alias: this.alias,
      },
    };

    const promise = async (resolve: any, reject: any) => {
      try {
        const res = await postSaveExerciseTeacherApi(
          {
            current_weight_training_id: this.weightTrainingId,
            data,
          },
          this.rootStore.sessionStore.token,
        );
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    };

    return new Promise((res, rej) => promise(res, rej));
  }

  setSuggestionsTrainingTeacher(suggestionsTrainings: SuggestionsTrainings[]) {
    this.suggestionsTrainings = suggestionsTrainings;
  }

  doRequestSuggestionsTraining(params: any) {
    return new Promise((resolve, reject) => this.promiseSuggestionsTraining(params,
      resolve, reject));
  }

  promiseSuggestionsTraining = async (params: any, resolve: any, reject: any) => {
    try {
      const res = await getSuggestionsTrainingSaveTeacherApi(params,
        this.rootStore.sessionStore.token);
      this.setSuggestionsTrainingTeacher(res.data.teacher_weight_trainings);
      resolve(res.data.teacher_weight_trainings);
    } catch (err) {
      reject(err);
    }
  }

  doRequestListExerciseTeacher(params: any) {
    return new Promise((resolve, reject) => this.promiseListExercise(params, resolve, reject));
  }

  promiseListExercise = async (params: any, resolve: any, reject: any) => {
    try {
      const res = await getListExerciseTeacherApi(params, this.rootStore.sessionStore.token);
      this.resetListWorkout();
      this.setListExercise(res.data.teacher_weight_trainings);
      this.renderListTraining();
      resolve(res.data.teacher_weight_trainings);
    } catch (err) {
      reject(err);
    }
  }

  doRequestRemoveExercise(params: any) {
    return new Promise((resolve, reject) => this.promiseRemoveExercise(params, resolve, reject));
  }

  promiseRemoveExercise = async (params: any, resolve: any, reject: any) => {
    try {
      const res = await deleteExerciseTeacherApi(params, this.rootStore.sessionStore.token);
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  }

  resetView() {
    this.listWorkoutTeacherView = [];
    this.currentWorkout = 0;
    this.limitLoop = 1;
  }

  resetListWorkout() {
    this.listWorkoutTeacherView = [];
    this.currentWorkout = 0;
    this.limitLoop = 1;
  }

  plusWorkout(): void {
    this.renderListTraining();
  }

  statusFinishListExercise(list: ListExerciseCreateTeacher[]): void {
    const hasPlusExercise = this.allowRenderExercise(list);
    this.finishListSuggestionTeacher = !hasPlusExercise;
  }

  makeTrainingGroup(list: string[]): string {
    const initial: string = '';
    if (list.length <= 0) return '';

    const label = list.reduce((initial: string, nextValue: string) => {
      return initial += `${nextValue}/`;
    }, initial);

    return label.slice(0, label.length - 1);
  }

  hasIntesification(currentWorkout: ListExerciseCreateTeacher): string {
    if (currentWorkout.intensification_method === false) return '';
    return this.rootStore.i18n.t('intensification');
  }

  hasRestriction(currentWorkout: ListExerciseCreateTeacher): string {
    if (currentWorkout.training_health_restriction === false) return '';
    return this.rootStore.i18n.t('restriction');
  }

  intensificationAndRestriction(currentWorkout: ListExerciseCreateTeacher): string {
    const intensificationMethod = currentWorkout.intensification_method;
    const trainingHealthRestriction = currentWorkout.training_health_restriction;
    const labelIntensification = this.rootStore.i18n.t('intensification');
    const labelRestriction = this.rootStore.i18n.t('restriction');

    if (intensificationMethod && trainingHealthRestriction) {
      return `- ${labelIntensification} - ${labelRestriction}`;
    }

    if (intensificationMethod) {
      return `- ${labelIntensification} -`;
    }

    if (trainingHealthRestriction) {
      return `- ${labelRestriction} -`;
    }

    return '-';
  }

  bodyEmphasis(currentWorkout: ListExerciseCreateTeacher): string {
    if(currentWorkout.body_emphasis && currentWorkout.body_emphasis !== '') {
      return `- ${currentWorkout.body_emphasis}`;
    }

    return '';
  }

  makeDescription(workout: ListExerciseCreateTeacher): string {
    const labelGroup = this.makeTrainingGroup(workout.training_group_labels);
    const intensificationAndRestriction = this.intensificationAndRestriction(workout);
    const labelBodyEmphasis = this.bodyEmphasis(workout);
    const nameAndLabel = `${workout.level_name} - ${labelGroup}`;
    const label = `${nameAndLabel} ${intensificationAndRestriction} ${workout.frequency}x ${labelBodyEmphasis}`;
    return label;
  }

  renderView(workout: ListExerciseCreateTeacher[]) {
    const lenghtWorkout = workout.length - 1;
    const limit = (workout.length < this.limitRender) ? lenghtWorkout : this.limitRender;
    const list = workout;

    for (let i = 0; i <= limit; i += 1) {
      if (lenghtWorkout >= this.currentWorkout) {
        const description = this.makeDescription(list[this.currentWorkout]);
        list[this.currentWorkout].description = description;
        this.listWorkoutTeacherView.push(list[this.currentWorkout]);
        this.currentWorkout += 1;
        this.limitLoop += 1;
      }
    }

    this.statusFinishListExercise(list);
  }

  allowRenderExercise(list: ListExerciseCreateTeacher[]): boolean {
    return (list.length > 0 && (this.limitLoop <= list.length));
  }

  renderListTraining(): void {
    const list: ListExerciseCreateTeacher[] = this.rootStore
      .filterWorkoutStore.getListExercise(this.listExercise);

    if (this.allowRenderExercise(list)) {
      this.renderView(list);
    }
  }
}

decorate(TeacherTrainingStore, {
  idExercise: observable,
  suggestionsTrainings: observable,
  listPrograms: observable,
  programId: observable,
  programName: observable,
  listExercise: observable,
  weightTrainingId: observable,
  reloadListExercise: observable,
  setReloadListExercise: observable,
  teacherInteface: observable,
  teacherDuplicateTraining: observable,
  alias: observable,
  finishListSuggestionTeacher: observable,
  listWorkoutTeacherView: observable,
  plusWorkout: action,
  setProgramId: action,
  setProgramName: action,
  setAlias: action,
  setListPrograms: action,
  setTeacherDuplicateTraining: action,
  setTeacherInteface: action,
  setWeightTrainingId: action,
  setListExercise: action,
  setIdExercise: action,
  doRequestSuggestionsTraining: action,
  doRequestSaveExerciseTeacher: action,
  doRequestListExerciseTeacher: action,
  doRequestListProgramTeacher: action,
  resetView: action,
});

export const TeacherTrainingSchema = {
  teacherInteface: true,
  teacherDuplicateTraining: true,
  alias: true,
};

export default TeacherTrainingStore;
