import React, { useContext, useState } from 'react';
import Context from 'stores/context';
import styled from 'styled-components';
import DropDownPais from './component/DropDownPais';
import AmountExercise from './component/AmountExercise';
import AmountSeriesByTraining from './component/AmountSeriesByTraining';
import NumberRepetition from './component/NumberRepetition';
import AmountInterval from './component/AmountInterval';
import SizeTraining from './component/SizeTraining';
import EmphasisPais from './component/EmphasisPais';

const CollapseButtonPais = styled.div`
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 2px
  color: ${(props) => props.theme.colors.white};
  padding: 0px 20px;
  margin-bottom: 36px;
  background-color: ${(props) => props.theme.colors.darkgray};
  height: 50px;
  overflow: hidden;

  &.open {
    height: auto;
    overflow: initial;
  }
`;

function WarningRulesPaisPremisass() {
  const [openCollpase, setOpenCollpase] = useState(true);

  const context = useContext(Context);
  const {
    rulesPaisStore,
  } = context;

  const toggleCollpase = () => {
    setOpenCollpase(!openCollpase);
  }

  const hasTrainingEmpty = () => {
    const hasError = (rulesPaisStore.dropDownPais.checked === false);
    const trainingEmpty = rulesPaisStore.dropDownPais.errorPremissas.length <= 0;
    return (hasError && trainingEmpty);
  }

  const hasError = (nameWarning) => {
    if (hasTrainingEmpty()) {
      return true;
    }

    return rulesPaisStore.dropDownPais.errorPremissas.includes(nameWarning);
  };

  if (rulesPaisStore.dropDownPais.checked) return false;

  const listComponentWarning = {
    number_of_exercise: <AmountExercise key="number_of_exercise" hasError={hasError('number_of_exercise')} />,
    serie_of_training: <AmountSeriesByTraining key="serie_of_training" hasError={hasError('serie_of_training')} />,
    repeats: <NumberRepetition key="repeats" hasError={hasError('repeats')} />,
    interval: <AmountInterval key="interval" hasError={hasError('interval')} />,
    size_training: <SizeTraining key="size_training" hasError={hasError('size_training')} />,
    emphasis: <EmphasisPais key="emphasis" hasError={hasError('emphasis')} />,
  };

  const renderListWarning = () => rulesPaisStore.listToggleShowRulesPremissas
    .map((warning) => listComponentWarning[warning]);

  return (
    <CollapseButtonPais className={`${openCollpase ? 'open' : ''}`}>
      <DropDownPais toggleCollpase={toggleCollpase} openCollpase={openCollpase} />
      <div className="wrapper-content-warning">
        {renderListWarning()}
      </div>
    </CollapseButtonPais>
  );
}

export default WarningRulesPaisPremisass;
