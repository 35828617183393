import styled, { css } from 'styled-components';

const Title = styled.h1`
  display: block;
  width: 100%;
  color: ${(props) => props.color || '#fff'};
  font-family: ${(props) => props.theme.fonts.Gotham};
  font-weight: ${(props) => props.fontWeight || '500'};
  text-align: ${(props) => props.align || 'center'};

  small {
    display: block;
    color: ${(props) => props.subTitleColor || '#a0a0a0'};
  }

  ${(props) => (props.type === 1 || props.type === undefined)
    && css`
      font-size: 32px;
      line-height: 1;
      margin-bottom: ${(props) => props.marginBottom || '32'}px;

      small {
        font-size: 16px;
        line-height: 22px;
      }

      @media screen and (min-width: 768px) {
        font-size: 48px;
        line-height: 58px;

        small {
          font-size: 24px;
          line-height: 29px;
        }
      }
    `};

  ${(props) => props.type === 2
    && css`
      font-size: 24px;
      line-height: 1;
      margin-bottom: 24px;

      small {
        font-size: 14px;
        line-height: 1;
        margin-top: ${(props) => props.marginTop || '0px'};
      }

      @media screen and (min-width: 768px) {
        font-size: 32px;
        line-height: 38px;
        font-weight: 500;

        small {
          font-size: 16px;
          line-height: 19px;
        }
      }
    `};

    ${(props) => props.type === 3
      && css`
        font-family: Gotham;
        font-style: normal;
        font-weight: 325;
        font-size: ${(props) => props.fontSize || '24'}px;
        margin-bottom: 24px;
        margin-top: ${(props) => props.mgTop || '0px'};
        display: flex;

        span {
          width: ${(props) => props.sizeWidthSpan || '130'}px;
        }

        small {
          font-size: 12px;
          margin-top: ${(props) => props.marginTop || '0px'};
          margin: 0 auto;
          line-height: ${(props) => props.lineHeight || '31px'};
        }

        @media(${(props) => props.theme.mediaScreen.cellphone}) { {
          font-size: 16px;
          display: block;
        }
      `};

      ${(props) => props.type === 4
        && css`
          font-family: Gotham;
          font-style: normal;
          font-weight: ${(props) => props.fontWeight || 'bold'};
          margin-bottom: ${(props) => props.mgBottom || '24'}px;
          margin-top: ${(props) => props.mgTop || '0px'};
          font-size: ${(props) => props.fontSize || '24'}px;

          small {
            margin-top: ${(props) => props.marginTop || '0px'};
          }

          @media(${(props) => props.theme.mediaScreen.cellphone}) {
            font-size: 18px;
          }
        `};
`;

export { Title };
export default Title;
