class FilterSearchWorkout {
  static normalizeNameKey(nameLevel: string) {
    const level = nameLevel.replace(/[0-9]/g, '').replace(/ /g,'');

    if (level === 'Iniciante' || level === 'Principiante' || level === 'InicianteSmart') {
      return 'initial';
    }

    if (level === 'Intermediário' || level === 'Intermedio') {
      return 'intermediary';
    }

    if (level === 'Avançado' || level === 'Avanzado') {
      return 'advanced';
    }

    return '';
  }

  static TableLevel() {
    return [
      {
        program: 1,
        list: [
          {
            key: 'initial',
            levelId: 2300,
          },
          {
            key: 'intermediary',
            levelId: 2301,
          },
          {
            key: 'advanced',
            levelId: 2302,
          },
        ],
      },
      {
        program: 15,
        list: [
          {
            key: 'initial',
            levelId: 2342,
          },
          {
            key: 'intermediary',
            levelId: 2343,
          },
          {
            key: 'advanced',
            levelId: 2344,
          },
        ],
      },
      {
        program: 16,
        list: [
          {
            key: 'initial',
            levelId: 2347,
          },
          {
            key: 'intermediary',
            levelId: 2348,
          },
          {
            key: 'advanced',
            levelId: 2349,
          },
        ],
      },
    ];
  }

  static filterLevel(programId: number | undefined, filterKey: string | undefined) {
    if (programId === undefined || filterKey === undefined) return '';

    const listLevel = this.TableLevel();
    const levelResult = listLevel.filter(level => programId === level.program)[0];
    const levelKey = levelResult.list.filter(keyLevel => keyLevel.key === filterKey)[0];

    if (levelKey.levelId) {
      return levelKey.levelId;
    }

    return '';
  }

  static getLevel(listLevel: string[], programId: number) {
    if (listLevel.length <= 0) return '';

    let params = '';
    listLevel.forEach((level) => {
      const idLevel = this.filterLevel(programId, level);
      params += `&level_id[]=${idLevel}`;
    });

    return params;
  }

  static getDuration(listSizeTraing: string[]) {
    if (listSizeTraing.length <= 0) return '';

    let params = '';
    listSizeTraing.forEach((sizeTrainig) => {
      params += `&duration[]=${sizeTrainig}`;
    });

    return params;
  }

  static getFrequency(listFrequency: number[]) {
    if (listFrequency.length <= 0) return '';

    let params = '';
    listFrequency.forEach((frequency) => {
      params += `&frequency[]=${frequency}`;
    });

    return params;
  }


  static getGroupLabel(listGroupLabel: string[]) {
    if (listGroupLabel.length <= 0) return '';

    let params = '';
    listGroupLabel.forEach((label) => {
      params += `&training_group_labels[]=${label}`;
    });

    return params;
  }

  static getIntensification(hasIntensificationMethod: boolean) {
    if (hasIntensificationMethod) {
      return '&has_intensification_method=true';
    }

    return '';
  }

  static getRestriction(hasRestriction: boolean) {
    if (hasRestriction) {
      return '&has_health_restriction=true';
    }

    return '';
  }

  static getBodyEmphasis(listEmphasis: string[]) {
    if(listEmphasis.length <= 0) return '';

    let params = '';
    listEmphasis.forEach((emphasis) => {
      params += `&body_emphasis[]=${emphasis}`;
    });

    return params;
  }

  static getNameTeacherId(idTeacher: number | undefined)  {
    if(idTeacher === undefined) return '';
    return `&teacher_id=${idTeacher}`;

  }
}

export default FilterSearchWorkout;
