import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Context from 'stores/context';

function List(props) {
  const context = useContext(Context);
  const {
    filterWorkoutStore,
    i18n,
    toggleStore,
  } = context;
  const {
    selectedGroupLabel,
    selectedFrequency,
    setToggleIntensifcation,
    selectedLevel,
    selectedRestriction,
    selectEmphasis,
    hasEmphasis,
    selectSizeTraining,
  } = props;

  const renderListGroupLabel = () => {
    const list = ['A', 'A/B', 'A/B/C', 'A/B/C/D'];

    return list.map((filter) => (
      <li
        className={filterWorkoutStore.groupLabel.includes(filter) ? 'item active' : 'item'}
        key={filter}
        role="presentation"
        onClick={() => selectedGroupLabel(filter)}
      >
        {filter}
      </li>
    ));
  };

  const renderListFrequency = () => [1, 2, 3, 4, 5].map((filter) => (
    <li
      className={filterWorkoutStore.groupFrequency.includes(filter) ? 'item active' : 'item'}
      key={filter}
      role="presentation"
      onClick={() => selectedFrequency(filter)}
    >
      {`${filter}x`}
    </li>
  ));

  const renderIntesification = () => {
    const intensification = i18n.t('intensification');

    return [intensification].map((filter) => (
      <li
        className={filterWorkoutStore.intensificationMethod ? 'item active' : 'item'}
        key={filter}
        role="presentation"
        onClick={() => setToggleIntensifcation(filter)}
      >
        {filter}
      </li>
    ));
  };

  const renderLevel = () => {
    const initial = {
      key: 'initial',
      text: i18n.t('Iniciante'),
    };

    const intermediary = {
      key: 'intermediary',
      text: i18n.t('Intermediário'),
    };

    const advanced = {
      key: 'advanced',
      text: i18n.t('Avançado'),
    };

    return [initial, intermediary, advanced].map((filter) => (
      <li
        className={filterWorkoutStore.groupLevel.includes(filter.text) ? 'item active' : 'item'}
        key={filter.text}
        role="presentation"
        onClick={() => selectedLevel(filter.text, filter.key)}
      >
        {filter.text}
      </li>
    ));
  };

  const renderRestriction = () => {
    const restriction = i18n.t('restriction');

    return (
      <li
        className={filterWorkoutStore.restriction ? 'item active' : 'item'}
        key={restriction}
        role="presentation"
        onClick={() => selectedRestriction()}
      >
        {restriction}
      </li>
    );
  };

  const renderListEmphasis = () => filterWorkoutStore.bodyEmphasis.map((emphasis) => (
    <li
      key={emphasis.value}
      className={filterWorkoutStore
        .selecedtBodyEmphasis.includes(emphasis.value) ? 'item active' : 'item'}
      role="presentation"
      onClick={() => selectEmphasis(emphasis)}
    >
      {emphasis.label}
    </li>
  ));

  const renderEmphasis = () => {
    if (hasEmphasis === false) return '';

    return (
      <>
        <p className="wrapper-buttons-filters-title">
          {i18n.t('emphasis_title')}
        </p>
        <ul className="wrapper-buttons-filters">
          {renderListEmphasis()}
        </ul>
      </>

    );
  }

  const renderSizeTraining = () => {
    const hasSizeTraining = toggleStore.validateToggle({
      toggleUnitAllow: 'allowShowTrainingSizeUnit',
      toggleCountry: 'allowShowTrainingSizeCountry',
    });

    if (hasSizeTraining === false) return false;

    const small = {
      key: 'p',
      text: i18n.t('size_training_small'),
    };

    const average = {
      key: 'm',
      text: i18n.t('size_training_average'),
    };

    const big = {
      key: 'g',
      text: i18n.t('size_training_big'),
    };

    return [small, average, big].map((filter) => (
      <li
        className={filterWorkoutStore.groupSizeTraining.includes(filter.key) ? 'item active' : 'item'}
        key={filter.text}
        role="presentation"
        onClick={() => selectSizeTraining(filter.key)}
      >
        {filter.text}
      </li>
    ));
  };

  return (
    <>
      <ul className="wrapper-buttons-filters">
        {renderListGroupLabel()}
        {renderIntesification()}
        {renderListFrequency()}
        {renderLevel()}
        {renderRestriction()}
        {renderSizeTraining()}
      </ul>
      {renderEmphasis()}
    </>
  );
}

List.defaultProps = {
  selectedGroupLabel: () => {},
  selectedFrequency: () => {},
  setToggleIntensifcation: () => {},
  selectedLevel: () => {},
  selectedRestriction: () => {},
  selectEmphasis: () => {},
  hasEmphasis: false,
  selectSizeTraining: () => {},
};

List.propTypes = {
  selectedGroupLabel: PropTypes.func,
  selectedFrequency: PropTypes.func,
  setToggleIntensifcation: PropTypes.func,
  selectedLevel: PropTypes.func,
  selectedRestriction: PropTypes.func,
  selectEmphasis: PropTypes.func,
  hasEmphasis: PropTypes.bool,
  selectSizeTraining: PropTypes.func,
};

export default observer(List);
